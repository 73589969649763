import tablestyle from '../../../style/table.module.css';
import FilterSelectCheckbox from './filterSelectCheckbox';

const FilterSelect = (props) => {
  const options = (props.selectOptions)
  // console.log('options',options)

   const selectOptions = (
    <div className={props.setClassFor === 'tableControl' && `${tablestyle.filterModalFields} ${tablestyle.filterModalSelect}`}
    >
    {/* className={tablestyle.selectOptions}> */}
        <>
          {options < 1 || options === undefined || options === null || options == 'No Options' ? <p >No options available</p> :
          <>
            {options
            .sort((a, b) => {
              if (a.name === null) return 1;
              if (b.name === null) return -1;
              if (a.name === null && b.name === null) return 0;
              return (
                a.name.toString().localeCompare(b.name.toString(), "en", {
                  numeric: true,
                })
              );
            })
            .map((item) => (
              <FilterSelectCheckbox
                id={item.id}
                key={item.id}
                name={item.name}
                filterSelectIsChecked={props.filterSelectIsChecked}
                fieldName={props.fieldName}
                fieldType={props.fieldType}
                handleSetFilterValues={props.handleSetFilterValues}
              />
            ))}
            <FilterSelectCheckbox
                id='0'
                key='blank'
                name='(blank)'
                filterSelectIsChecked={props.filterSelectIsChecked}
                fieldName={props.fieldName}
                fieldType={props.fieldType}
                handleSetFilterValues={props.handleSetFilterValues}
              />
          </>
            }
        </>
    </div>
  )

  return (
    <>
      {selectOptions}
    </>

  )
};
export default FilterSelect;