import { Fragment } from 'react';
import TopNav from './topnav/topnav';
import Footer from './footer/footer';
import { Outlet } from "react-router-dom";
import globalStyle from '../style/global.css';

const Layout = (props) => {
  return (
    <Fragment>
      <TopNav />
      <Outlet />
      <Footer />
    </Fragment>
  );
};

export default Layout;
