//source: https://www.c-sharpcorner.com/learn/reactjs-for-beginners/introduction-to-react-hooks

import React,{useState} from 'react' 
import styles from '../../../style/form.module.css';

function Counter() {
  const[count,setCount] = useState(0)
  
    return (    
        <div className={styles.center}>
            <h2>Counter</h2>       
            <h3>{count}</h3>
            <div>
              <button className={styles.red} onClick={() => setCount(count-1)}>- 1</button> 
              <button className={styles.green} onClick={() => setCount(count+1)}>+ 1</button>
              <br></br>
              <button className={styles.red} onClick={() => setCount(count-5)}>- 5</button> 
              <button className={styles.green} onClick={() => setCount(count+5)}>+ 5</button>
              <br></br>
              <button className={styles.orange} onClick={() => setCount(count-count)}>Reset</button>     
            </div>
        </div>    
    )    
}   
  
export default Counter; 