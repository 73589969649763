//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'
import { SailingLogLocationSelectOptionsAPI } from '../locations'
import { SailingLogTripSelectOptionsAPI } from '../trips'
import { SailingLogLogEntrySummaryByPassageAPI } from './logEntry'
import { SailingLogCrewSummaryByPassageAPI, SailingLogCrewDeleteAPI } from './crew'


export const moduleDefinitions = {
  moduleName: 'Passages',
  moduleNameSingular: 'Passage',
  moduleLink: '/fun/sailinglog/passages/',
  linktoView: '/sailinglog/passage/list/items/?listid=',
  thOptionsWidth: '115px',
}

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
    fieldErrorMessage = ""

  switch (fieldName) {

    case "name":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Name is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break

    case "depature_from":
      if (value === "") {
        hasError = true
        fieldErrorMessage = "Depature from is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break

    default:
      break
  }
  return { hasError, fieldErrorMessage }
}


///////////// Constants /////////////////////////
export const SailingLogPassageCreateRequest = 'SailingLogPassageCreateRequest'
export const SailingLogPassageCreateSuccess = 'SailingLogPassageCreateSuccess'
export const SailingLogPassageCreateFail = 'SailingLogPassageCreateFail'
export const SailingLogPassageCreateReset = 'SailingLogPassageCreateReset'

export const SailingLogPassageDetailsRequest = 'SailingLogPassageDetailsRequest'
export const SailingLogPassageDetailsSuccess = 'SailingLogPassageDetailsSuccess'
export const SailingLogPassageDetailsFail = 'SailingLogPassageDetailsFail'
export const SailingLogPassageDetailsReset = 'SailingLogPassageDetailsReset'

export const SailingLogPassageUpdateRequest = 'SailingLogPassageUpdateRequest'
export const SailingLogPassageUpdateSuccess = 'SailingLogPassageUpdateSuccess'
export const SailingLogPassageUpdateFail = 'SailingLogPassageUpdateFail'
export const SailingLogPassageUpdateReset = 'SailingLogPassageUpdateReset'

export const SailingLogPassageDeleteRequest = 'SailingLogPassageDeleteRequest'
export const SailingLogPassageDeleteSuccess = 'SailingLogPassageDeleteSuccess'
export const SailingLogPassageDeleteFail = 'SailingLogPassageDeleteFail'
export const SailingLogPassageDeleteReset = 'SailingLogPassageDeleteReset'

export const SailingLogPassageListRequest = 'SailingLogPassageListRequest'
export const SailingLogPassageListSuccess = 'SailingLogPassageListSuccess'
export const SailingLogPassageListFail = 'SailingLogPassageListFail'

export const SailingLogPassageSummaryRequest = 'SailingLogPassageSummaryRequest'
export const SailingLogPassageSummarySuccess = 'SailingLogPassageSummarySuccess'
export const SailingLogPassageSummaryFail = 'SailingLogPassageSummaryFail'


///////////// API Config /////////////////////////

const APIVariablesCreate = {
  APIRequest: SailingLogPassageCreateRequest,
  APISuccess: SailingLogPassageCreateSuccess,
  APIFail: SailingLogPassageCreateFail,
  APIEndPoint: 'sailinglog/v1/passage/add',
}
const APIVariablesListAll = {
  APIRequest: SailingLogPassageListRequest,
  APISuccess: SailingLogPassageListSuccess,
  APIFail: SailingLogPassageListFail,
  APIEndPoint: 'sailinglog/v1/passage/list'
}
const APIVariablesSelectOptions = {
  APIRequest: 'SailingLogPassageSelectOptionsRequest',
  APISuccess: 'SailingLogPassageSelectOptionsSuccess',
  APIFail: 'SailingLogPassageSelectOptionsFail',
  APIEndPoint: 'sailinglog/v1/passage/selectoptions'
}
const APIVariablesSummaryByTrip = {
  APIRequest: SailingLogPassageSummaryRequest,
  APISuccess: SailingLogPassageSummarySuccess,
  APIFail: SailingLogPassageSummaryFail,
  APIEndPoint: 'sailinglog/v1/passage/tripsummary'
}
const APIVariablesSummaryByLocation = {
  APIRequest: 'SailingLogPassageSummaryByLocationRequest',
  APISuccess: 'SailingLogPassageSummaryByLocationSuccess',
  APIFail: 'SailingLogPassageSummaryByLocationFail',
  APIEndPoint: 'sailinglog/v1/passage/locationsummary'
}
const APIVariablesItemDetails = {
  APIRequest: SailingLogPassageDetailsRequest,
  APISuccess: SailingLogPassageDetailsSuccess,
  APIFail: SailingLogPassageDetailsFail,
  APIEndPoint: 'sailinglog/v1/passage/'
}
const APIVariablesUpdate = {
  APIRequest: SailingLogPassageUpdateRequest,
  APISuccess: SailingLogPassageUpdateSuccess,
  APISuccess_2: SailingLogPassageDetailsSuccess,
  APIFail: SailingLogPassageUpdateFail,
  APIEndPoint: 'sailinglog/v1/passage/'
}
const APIVariablesDelete = {
  APIRequest: SailingLogPassageDeleteRequest,
  APISuccess: SailingLogPassageDeleteSuccess,
  APIFail: SailingLogPassageDeleteFail,
  APIEndPoint: 'sailinglog/v1/passage/'
}

export const SailingLogPassageCreateAPI = (item) => async (dispatch, getState) => {
  const cleanDepatureDate = item.depature_date === '' ? null : item.depature_date;
  const cleanDepatureTime = item.depature_time === '' ? null : item.depature_time;
  const cleanArrivalDate = item.arrival_date === '' ? null : item.arrival_date;
  const cleanArrivalTime = item.arrival_time === '' ? null : item.arrival_time;

  const API_Body = {
    trip: item.trip_name,
    trip_name: item.trip_name,
    trip_passage_no: 99,
    depature_from: item.depature_from,
    depature_date: cleanDepatureDate,
    depature_time: cleanDepatureTime,
    arrival_at: item.arrival_at,
    arrival_date: cleanArrivalDate,
    arrival_time: cleanArrivalTime,
    distance: item.distance,
    over60nm: item.over60nm,
    night_hours: item.night_hours,
    tidal: item.tidal,
    racing: item.racing,
    notes: item.notes,
  }
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}
export const SailingLogPassageListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}
export const SailingLogPassageSelectOptionsAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesSelectOptions, dispatch, getState, query)
}
export const SailingLogPassageSummaryByTripAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesSummaryByTrip, dispatch, getState, query)
}
export const SailingLogPassageSummaryByLocationAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesSummaryByLocation, dispatch, getState, query)
}
export const SailingLogPassageDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}
export const SailingLogPassageUpdateAPI = (item) => async (dispatch, getState) => {
  const cleanDepatureDate = item.depature_date === '' ? null : item.depature_date;
  const cleanDepatureTime = item.depature_time === '' ? null : item.depature_time;
  const cleanArrivalDate = item.arrival_date === '' ? null : item.arrival_date;
  const cleanArrivalTime = item.arrival_time === '' ? null : item.arrival_time;

  const API_Body = {
    trip_name: item.trip_name,
    trip_passage_no: item.trip_passage_no,
    depature_from: item.depature_from,
    depature_date: cleanDepatureDate,
    depature_time: cleanDepatureTime,
    arrival_at: item.arrival_at,
    arrival_date: cleanArrivalDate,
    arrival_time: cleanArrivalTime,
    distance: item.distance,
    over60nm: item.over60nm,
    night_hours: item.night_hours,
    tidal: item.tidal,
    racing: item.racing,
    notes: item.notes,
  }
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const SailingLogPassageDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

///////////// REDUCERS/////////////////////////
// State is an empty object
export const SailingLogPassageCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SailingLogPassageCreateRequest:
      return {
        loading: true
      }

    case SailingLogPassageCreateSuccess:
      return {
        loading: false,
        success: true,
        SailingLogPassage: action.payload
      }

    case SailingLogPassageCreateFail:
      return {
        loading: false,
        error: action.payload
      }

    case SailingLogPassageCreateReset:
      return {}


    default:
      return state
  }
}

export const SailingLogPassageUpdateReducer = (state = { SailingLogPassage: {} }, action) => {
  switch (action.type) {
    case SailingLogPassageUpdateRequest:
      return { loading: true }

    case SailingLogPassageUpdateSuccess:
      return { loading: false, success: true }

    case SailingLogPassageUpdateFail:
      return { loading: false, error: action.payload }

    case SailingLogPassageUpdateReset:
      return { SailingLogPassage: {} }

    default:
      return state
  }
}

export const SailingLogPassageDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SailingLogPassageDeleteRequest:
      return { loading: true }

    case SailingLogPassageDeleteSuccess:
      return {
        loading: false,
        success: true
      }

    case SailingLogPassageDeleteFail:
      return { loading: false, error: action.payload }

    case SailingLogPassageDeleteReset:
      return {}

    default:
      return state
  }
}

export const SailingLogPassageDetailsReducer = (state = { loading: true, SailingLogPassage: [] }, action) => {
  switch (action.type) {
    case SailingLogPassageDetailsRequest:
      return {
        ...state,
        loading: true
      }

    case SailingLogPassageDetailsSuccess:
      return {
        loading: false,
        SailingLogPassage: action.payload
      }

    case SailingLogPassageDetailsFail:
      return {
        loading: false,
        error: action.payload
      }


    default:
      return state
  }
}


export const SailingLogPassageListReducer = (state = { SailingLogPassage: [] }, action) => {
  switch (action.type) {
    case SailingLogPassageListRequest:
      return {
        loading: true
      }

    case SailingLogPassageListSuccess:
      return {
        loading: false,
        SailingLogPassage: action.payload
      }

    case SailingLogPassageListFail:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const SailingLogPassageSelectOptionsReducer = (state = { SailingLogtrip: [] }, action) => {
  switch (action.type) {
    case 'SailingLogPassageSelectOptionsRequest':
      return {
        loading: true
      }

    case 'SailingLogPassageSelectOptionsSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
      }

    case 'SailingLogPassageSelectOptionsFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const SailingLogPassageSummaryByTripReducer = (state = { SailingLogPassage: [] }, action) => {
  switch (action.type) {
    case SailingLogPassageSummaryRequest:
      return {
        loading: true
      }

    case SailingLogPassageSummarySuccess:
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
        SailingLogPassage: action.payload
      }

    case SailingLogPassageSummaryFail:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const SailingLogPassageSummaryByLocationReducer = (state = { SailingLogPassage: [] }, action) => {
  switch (action.type) {
    case 'SailingLogPassageSummaryByLocationRequest':
      return {
        loading: true
      }

    case 'SailingLogPassageSummaryByLocationSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
        SailingLogPassage: action.payload
      }

    case 'SailingLogPassageSummaryByLocationFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}


function SailingLogPassageScreen(props) {
  const createAPI = SailingLogPassageCreateAPI
  const listAPI = SailingLogPassageListAllAPI
  const detailsAPI = SailingLogPassageDetailsAPI
  const updateAPI = SailingLogPassageUpdateAPI
  const deleteAPI = SailingLogPassageDeleteAPI

  const resetCreate = SailingLogPassageCreateReset
  const resetUpdate = SailingLogPassageUpdateReset
  const resetViewDetails = SailingLogPassageDetailsReset
  const resetDelete = SailingLogPassageDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const paramsItem = useParams()
  const paramsItemSplit = paramsItem.action.split(':')
  const paramsID = paramsItemSplit[1]

  const queryParameters = new URLSearchParams(window.location.search)
  const trip_name = queryParameters.get("trip_name")

  // console.log('queryParameters',queryParameters)
  //  console.log('trip_name',trip_name)

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const SailingLogPassageCreate = useSelector(state => state.SailingLogPassageCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = SailingLogPassageCreate

  const SailingLogPassageList = useSelector(state => state.SailingLogPassageList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    SailingLogPassage: dataList } = SailingLogPassageList

  const SailingLogPassageDetails = useSelector(state => state.SailingLogPassageDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    SailingLogPassage: dataItem } = SailingLogPassageDetails

  const SailingLogPassageUpdate = useSelector(state => state.SailingLogPassageUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = SailingLogPassageUpdate

  const SailingLogPassageDelete = useSelector(state => state.SailingLogPassageDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = SailingLogPassageDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingList || loadingDetails
    )
  )

  const SailingLogLogEntryPassageSummary = useSelector(state => state.SailingLogLogEntryPassageSummary)
  const {
    loading: loadingLogEntrySummary,
    error: errorLogEntrySummary,
    SailingLogLogEntry: listLogLogEntrySummary } = SailingLogLogEntryPassageSummary

  const SailingLogCrewPassageSummary = useSelector(state => state.SailingLogCrewPassageSummary)
  const {
    loading: loadingCrewSummary,
    error: errorCrewSummary,
    SailingLogCrew: listCrewSummary } = SailingLogCrewPassageSummary

  const viewTabs = [
    {
      label: 'Crew',
      addLink: '/fun/sailinglog/crew/',
      editLink: '/fun/sailinglog/crew/',
      viewLink: '/fun/sailinglog/contacts/',
      viewLinkIDField: 'name',
      addLinkRef: 'passage',
      addadditionalLinkRef: 'trip_name',
      dataList: listCrewSummary,
      loading: loadingCrewSummary,
      error: errorCrewSummary,
      // edit: (dataitem) => navigate('/fun/sailinglog/crew/' + 'edit:' + dataitem.id ),
      delete: (id) => dispatch(SailingLogCrewDeleteAPI(id)),
      // delete: (id) => console.log('Crew delete id:', id),
      // tabContent: 'Crew details coming soon',
      tableFields: [
        {
          label: 'Name',
          fieldName: 'name',
          fieldLongName: 'name_name',
          fieldType: 'selectfromdb',
          tableDisplayOrder: '',
          tableDisplayPriority: '1',
        }, {
          label: 'Role(s)',
          fieldName: 'role',
          fieldLongName: 'role_name',
          fieldType: 'text',
          tableDisplayOrder: '',
          tableDisplayPriority: '1',
          tableFilterHide: true,

        }
      ]
    },
    {
      label: 'Log Entries',
      viewLink: '/fun/sailinglog/logentry/',
      addLink: '/fun/sailinglog/logentry/',
      addLinkRef: 'passage',
      addadditionalLinkRef: 'trip_name',
      dataList: listLogLogEntrySummary,
      loading: loadingLogEntrySummary,
      error: errorLogEntrySummary,
      tabContent: '',
      tableFields: [
        {
          label: 'Log No.',
          fieldName: 'log_entry_no',
          fieldType: 'number',
          tableDisplayOrder: '',
          tableDisplayPriority: '1',
        }, {
          label: 'Date',
          fieldName: 'date',
          fieldType: 'date',
          tableDisplayOrder: '',
          tableDisplayPriority: '1',
        }, {
          label: 'Time',
          fieldName: 'time',
          fieldType: 'time',
          tableDisplayOrder: '',
          tableDisplayPriority: '1',
        }
      ]
    },

  ]


  const moduleFields = [
    {
      label: 'Trip Name',
      mandatory: 'true',
      fieldName: 'trip_name',
      fieldLongName: 'trip_name_name',
      fieldType: 'autoCompleteText',
      options: 'SailingLogTripSelectOptions',
      // addNewLink: '/fun/sailinglog/trips/add/',
      openAddNewModule: 'sailinglog/trips',
      addFormInitialValue: trip_name,
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '1',
    }, {
      label: 'Passage No.',
      // mandatory: 'true',
      fieldName: 'trip_passage_no',
      fieldType: 'number',
      addFormOrder: 0,
      editFormOrder: 0,
      tableDisplayOrder: '0',
      tableDisplayPriority: '2',
    }, {
      label: 'Depature From',
      mandatory: 'true',
      fieldName: 'depature_from',
      fieldLongName: 'depature_from_name',
      fieldType: 'autoCompleteText',
      options: 'SailingLogLocationSelectOptions',
      // addNewLink: '/fun/sailinglog/locations/add/',
      openAddNewModule: 'sailinglog/locations',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '1',
    }, {
      label: 'Depature Date',
      fieldName: 'depature_date',
      fieldType: 'date',
      fieldGroupPosition: 'Left', // or 'Right' // Optional
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '4',
    }, {
      label: 'Depature Time',
      fieldName: 'depature_time',
      fieldType: 'time',
      fieldGroupPosition: 'Right', // or 'Right' // Optional
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '5',
    }, {
      label: 'Arrival At',
      fieldName: 'arrival_at',
      fieldLongName: 'arrival_at_name',
      fieldType: 'autoCompleteText',
      options: 'SailingLogLocationSelectOptions',
      // addNewLink: '/fun/sailinglog/locations/add/',
      openAddNewModule: 'sailinglog/locations',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '1',
    }, {
      label: 'Arrival Date',
      fieldName: 'arrival_date',
      fieldType: 'date',
      fieldGroupPosition: 'Left', // or 'Right' // Optional
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '4',
    }, {
      label: 'Arrival Time',
      fieldName: 'arrival_time',
      fieldType: 'time',
      fieldGroupPosition: 'Right', // or 'Right' // Optional
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '5',
    }, {
      label: 'Total Distance (nm)',
      fieldName: 'distance',
      fieldType: 'number',
      fieldGroupPosition: 'Left', // or 'Right' // Optional
      fieldGroupSize: '30%',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '4',
    }, {
      label: 'Over 60nm',
      fieldName: 'over60nm',
      fieldType: 'checkbox',
      fieldGroupPosition: 'Middle', 
      fieldGroupSize: '30%',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '0',
      tableDisplayPriority: '0',
    }, {
      label: 'Total No. of Days',
      fieldName: 'total_days',
      fieldType: 'number',
      fieldGroupPosition: 'Right',
      fieldGroupSize: '30%',
      addFormOrder: 0,
      editFormOrder: 0,
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
    }, {
      label: 'Night Hours',
      fieldName: 'night_hours',
      fieldType: 'number',
      fieldGroupPosition: 'Left', 
      fieldGroupSize: '30%',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
    }, {
      label: 'Tidal',
      fieldName: 'tidal',
      fieldType: 'checkbox',
      fieldGroupPosition: 'Middle', 
      fieldGroupSize: '30%',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
    }, {
      label: 'Racing',
      fieldName: 'racing',
      fieldType: 'checkbox',
      fieldGroupPosition: 'Right',
      fieldGroupSize: '30%',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
    }, {
      label: 'Notes',
      fieldName: 'notes',
      fieldType: 'textarea',
      rows: "4",
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
      tableFilterHide: true
    },
  ]

  useEffect(() => {
    dispatch(SailingLogPassageListAllAPI())
    dispatch(SailingLogLocationSelectOptionsAPI())
    dispatch(SailingLogTripSelectOptionsAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }
    if (paramsID) {
      dispatch(SailingLogLogEntrySummaryByPassageAPI('?listid=' + paramsID))
      dispatch(SailingLogCrewSummaryByPassageAPI('?listid=' + paramsID))
    }
  }, [successDelete, successUpdate,
    errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete, paramsID])


  return (
    <ModuleHome
          formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
    moduleModalVisibility={props.moduleModalVisibility}
    setModuleModalVisibility={props.setModuleModalVisibility}
    
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      rowOnClickAction={'view'}
      view={(dataitem) => navigate(moduleDefinitions.moduleLink + 'view:' + dataitem.id)}
      add={true}
      edit={(dataitem) => navigate(moduleDefinitions.moduleLink + 'edit:' + dataitem.id)}
      delete={(id) => dispatch(deleteAPI(id))}

      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      getDataList={listAPI}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}

      //View Page
      viewTabs={viewTabs}
      // headingName={dataItem.passage_name}
    />
  )
}

export default SailingLogPassageScreen
