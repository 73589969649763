import { useNavigate } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import GenericButton from './genericButton';

const BackButton = (props) => {
  const navigate = useNavigate()
  return (
    <GenericButton 
      name='Back'
      onClick={() => {
        //If Module Modal then close the module Modal
        props.moduleModalVisibility===true ? props.setModuleModalVisibility(false) :
        //If Form Modal then close the form Modal
        <> {props.resetForm && props.resetForm()};
        {props.formModalVisibility===true ? props.setFormModalVisibility(false) :
        //Other Navigate back
       navigate(-1)}
       </>
      }}
      icon={MdArrowBack}
      iconSize={'30px'}
      className='backButton'
    />
  )
}
export default BackButton