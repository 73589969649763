// React
import { useState, useEffect } from 'react';
//  Style
import tablestyle from '../../style/table.module.css';
import formstyle from '../../style/form.module.css';
// Components
import TableControls from './tableControls'
import TableHeader, { TableHeaderOptions } from './tableHeader'
import { TableRowData } from './tableRowData'
import { TableRowOptionView, TableRowOptionComplete, TableRowOptionEdit, TableRowOptionDelete, TableRowOptionAction, TableRowOptionSave, TableRowOptionCancel } from './tableRowButtons'
import { TableRowAdd } from './tableRowAdd'
import Loader from '../loading/spinner';
import Calendar from './calendar'
// Hooks
import handleSelectOptions from '../../hooks/handleSelectOptions'
import handleFilterNumber from '../../hooks/handleFilterNumber'
import handleFilterDate from '../../hooks/handleFilterDate'
import handleFilterTime from '../../hooks/handleFilterTime'

const Table = (props) => {
  const [searchResults, setSearchResults] = useState('')
  const [filterValues, setFilterValues] = useState([]);
  const [filteredData, setFilteredData] = useState(searchResults);
  const [editRow, setEditRow] = useState('')
  const [optionsColumWidth, setOptionsColumWidth] = useState(0)
  const [groupBy, setGroupBy] = useState([]);
  const [viewType, setViewType] = useState(props.moduleDefinitions ? props.moduleDefinitions.viewDefault ? props.moduleDefinitions.viewDefault : 'List' : 'List')

  // console.log('props.moduleFields',props.moduleFields)
  // console.log('Table - loadingList',props.loadingList )
  // console.log('Table - datalist',props.dataList )
  // console.log('Table - filteredData',filteredData )

  // Tables with inline ability to save data.
  const [fieldValues, setFieldValues] = useState([]);

  const handleChange = (field, value) => {
    // console.log('handleChange', field, value)
    const getindex = fieldValues.findIndex(e => e.fieldName === field)
    if (getindex < 0) {
      const newItem = { 'fieldName': field, 'value': value }
      setFieldValues(previous => [...previous, newItem])
    } else {
      return setFieldValues(
        fieldValues.map((currentData, index) => {
          if (index == getindex) {
            return { ...currentData, 'fieldName': field, 'value': value }
          } else {
            return { ...currentData }
          }
        }))
    }
  }

  // Extract required data from the Array to be sent to the DB.
  const dataForDB = fieldValues.reduce(
    (obj, item) => Object.assign(obj, { [item.fieldName]: item.value }), {});


  const dataForDBWithID = (id) => {
    const insertID = { id: id }
    return { ...dataForDB, ...insertID }
  }

  const fieldValue = (field) => {
    const fieldstate = fieldValues.find(e => e.fieldName === field)
    if (fieldstate === undefined) { return '' } else { return fieldstate.value }
  }

  // Used for AutoComplete inLine Editings
  const fieldValueName = (field) => {
    const fieldstate = fieldValues.find(e => e.fieldName === field)
    // console.log('fieldstate.fieldValueName',fieldstate.fieldValueName)
    if (fieldstate === undefined) { return '' } else { return fieldstate.fieldValueName }
  }

  // Load default / intial field and fitler values 
  useEffect(() => {
    // console.log('Set Initial Filters when data loading finishes')

    if (props.loadingList === false) {
      if (props.dataList != undefined) {
        // console.log('props.dataList',props.dataList)
        // Remove duplicate logic
        const myData = props.dataList
        const newArray = Array.from(new Set(myData.map(JSON.stringify))).map(JSON.parse);
        setSearchResults(newArray)
      }

      props.moduleFields.map((defaults) => {
        if (defaults.tableDefaultFilter === undefined) { } else {
          const newSelectObject = [{ fieldName: defaults.fieldName, fieldType: defaults.fieldType, filterValue: defaults.tableDefaultFilter }]
          return setFilterValues([...filterValues, ...newSelectObject]);
        }
      })
    }
    const options = [props.edit, props.delete, props.view, props.complete]
    calculateOptionsColumnWidth(options)
  }, [props.loadingList]);

  const handleSetFilterValues = (field, type, value, name) => {
    // console.log('SetFilter,', field, type, value, name)
    const newTextObject = [{ fieldName: field, fieldType: type, filterValue: value, filterValueName: name }]
    const newCheckboxObject = [{ fieldName: field, fieldType: type, filterValue: true }]
    const newSelectObject = [{ fieldName: field, fieldType: type, filterValue: [value] }]
    if (type === 'text' || type === 'autoCompleteText') { // Check to see if exisiting filter value exisits
      if (filterValues.find(e => e.fieldName === field) !== undefined) { // if field exisits in filter 
        const filterIndex = filterValues.findIndex(e => e.fieldName === field) // get index
        const newData = [...filterValues] // new array
        newData.splice(filterIndex, 1); // remove old object
        newData.push(...newTextObject) // add new object
        return setFilterValues(newData)
      } else { // if does not exisits create new object
        return setFilterValues([...filterValues, ...newTextObject]);
      }
    }
    if (type === 'checkbox') { // Check to see if exisiting filter value exisits
      if (filterValues.find(e => e.fieldName === field) !== undefined) { // if field exisits in filter 
        const filterIndex = filterValues.findIndex(e => e.fieldName === field) // get index
        const newData = [...filterValues] // new array
        newData.splice(filterIndex, 1); // remove old object
        // newData.push(...newCheckboxObject) // add new object
        return setFilterValues(newData)
      } else { // if does not exisits create new object
        return setFilterValues([...filterValues, ...newCheckboxObject]);
      }
    }
    if (type === 'select' || type === 'selectfromdb' || type === 'selectnoid') {
      console.log('SetFilter, for Select Value', field, type, value)
      if (filterValues.find(e => e.fieldName === field) !== undefined) {  // is there a filter for this field?
        const filterIndex = filterValues.findIndex(e => e.fieldName === field) // if so get index of filter
        if (filterValues[filterIndex].filterValue.find(e => e == value)) { //find the index of the filter value
          const valueIndex = filterValues[filterIndex].filterValue.findIndex(e => e === value) // get index of value
          if (filterValues[filterIndex].filterValue.length == 1) { // if the last filtervalue in array, delete the whole filter for that field
            // console.log('SetFilter, for Select Value,last value in array, deleting array', field, type, value)
            const newData = [...filterValues] // new array
            newData.splice(filterIndex, 1)
            return setFilterValues(newData)
          } else {
            // console.log('SetFilter, for Select Value,filter value already exists', field, type, value)
            // if multiple filter values then just remove the single value from the filter values array
            const newData = [...filterValues] // new array
            newData[filterIndex].filterValue.splice(valueIndex, 1); // remove old object
            return setFilterValues(newData)
          }
        } else {
          // if the filter field exists but the filter value does not exist in array, then add the value to the filter values array
          // console.log('SetFilter, for Select Value,add new value', field, type, value)
          const newData = [...filterValues] // new array
          newData[filterIndex].filterValue.push(...[value])
          return setFilterValues(newData)
        }
      } else { // if the filter field does not exisits create new object
        // console.log('SetFilter, for Select Value,add new field and value', field, type, value)
        return setFilterValues([...filterValues, ...newSelectObject]);
      }
    }

    // // old logic before changing select filter values into an array
    // if (type === 'select' || type === 'selectfromdb') {
    //   if (filterValues.find(e => e.fieldName === field && e.filterValue === value) !== undefined) { // if field exisits in filter
    //     const index = filterValues.findIndex(e => e.fieldName === field && e.filterValue === value) // get index
    //     const newData = [...filterValues] // new array
    //     newData.splice(index, 1); // remove old object
    //     return setFilterValues(newData)
    //   } else { // if does not exisits create new object
    //     return setFilterValues([...filterValues, ...newObject]);
    //   }
    // }
  }

  const handleSetFilterNumbers = (field, type, value, logic, value2, logic2) => {
    console.log('handleSetFilterNumbers,', field, type, value, logic, value2, logic2)
    const newNumberObject = [{ fieldName: field, fieldType: type, filterValue: value, filterLogic: logic }]
    if (filterValues.find(e => e.fieldName === field) !== undefined) { // if field exisits in filter 
      const filterIndex = filterValues.findIndex(e => e.fieldName === field) // get index
      console.log('numbers - value', value)
      // If not value delete filter
      if (value == '') {
        const newData = [...filterValues] // new array
        newData.splice(filterIndex, 1); // remove old object
        return setFilterValues(newData)
      } else {
        // Field exists and their is a filtervalue,  
        const newData = [...filterValues] // new array
        newData.splice(filterIndex, 1); // remove old object
        newData.push(...newNumberObject) // add new object
        return setFilterValues(newData)
      }
    } else { // if does not exisits create new object
      // console.log('numbers - All logic skipped ')
      return setFilterValues([...filterValues, ...newNumberObject]);
    }
  }

  // Handles the second number in number filter ranges, greater than, less than. 
  const handleSetFilterNumbersTwo = (field, type, value, logic) => {
    if (type === 'number' || type === 'currency') { // Check to see if exisiting filter value exisits
      if (filterValues.find(e => e.fieldName === field) !== undefined) { // if field exisits in filter 
        const filterIndex = filterValues.findIndex(e => e.fieldName === field) // get index
        console.log('handleSetFilterValuesTwo', field, type, value, logic)
        if (value == '') {
          // If no value delete second values
          const newNumberObject = [{
            fieldName: field,
            fieldType: type,
            filterValue: filterValues[filterIndex].filterValue,
            filterLogic: filterValues[filterIndex].filterLogic,
          }]
          const newData = [...filterValues] // new array
          newData.splice(filterIndex, 1); // remove old object
          newData.push(...newNumberObject) // add new object
          return setFilterValues(newData)
        } else {
          // Field exists and their is a filtervalue,  
          const newNumberObject = [{
            fieldName: field,
            fieldType: type,
            filterValue: filterValues[filterIndex].filterValue,
            filterLogic: filterValues[filterIndex].filterLogic,
            filterValue2: value,
            filterLogic2: logic,
          }]
          const newData = [...filterValues] // new array
          newData.splice(filterIndex, 1); // remove old object
          newData.push(...newNumberObject) // add new object
          return setFilterValues(newData)
        }
      } //if no filter field then do nothing. 
    }
  }


  const handleSetFilterDates = (field, type, value, datePart, logic) => {
    // console.log('handleSetFilterDates', field, value, datePart)
    if (filterValues.find(e => e.fieldName === field) !== undefined) { // if field exisits in filter 
      const filterIndex = filterValues.findIndex(e => e.fieldName === field) // get index
      const currentDate = filterValues[filterIndex].filterValue
      const currentYear = currentDate ? currentDate.split('-')[0] : '0000'
      const currentMonth = currentDate ? currentDate.split('-')[1] : '00'
      const currentDay = currentDate ? currentDate.split('-')[2] : '00'
      // If no value given then delete filter
      if (value == '') {
        const newData = [...filterValues] // new array
        newData.splice(filterIndex, 1); // remove old object
        return setFilterValues(newData)
      }
      if (value == '(Clear)') {
        const newData = [...filterValues] // new array
        newData.splice(filterIndex, 1); // remove old object
        if (datePart === 'Year') {
          const newDateObject = [{
            fieldName: field,
            fieldType: type,
            filterValue: '0000' + '-' + currentMonth + '-' + currentDay,
            filterLogic: logic,
            filterValue2: filterValues[filterIndex].filterValue2,
            filterLogic2: filterValues[filterIndex].filterLogic2,
          }]
          newData.push(...newDateObject) // add new object
          return setFilterValues(newData)
        }
        if (datePart === 'Month') {
          const newDateObject = [{
            fieldName: field,
            fieldType: type,
            filterValue: currentYear + '-' + '00' + '-' + currentDay,
            filterLogic: logic,
            filterValue2: filterValues[filterIndex].filterValue2,
            filterLogic2: filterValues[filterIndex].filterLogic2,
          }]
          newData.push(...newDateObject) // add new object
          return setFilterValues(newData)
        }
        if (datePart === 'Day') {
          const newDateObject = [{
            fieldName: field,
            fieldType: type,
            filterValue: currentYear + '-' + currentMonth + '-' + '00',
            filterLogic: logic,
            filterValue2: filterValues[filterIndex].filterValue2,
            filterLogic2: filterValues[filterIndex].filterLogic2,
          }]
          newData.push(...newDateObject) // add new object
          return setFilterValues(newData)
        }

      } else {  // Field exists and their is a filtervalue,  
        const newDateObject = [{
          fieldName: field, fieldType: type,
          filterValue: (
            datePart === 'Year' ? (value + '-' + currentMonth + '-' + currentDay) :
              datePart === 'Month' ? (currentYear + '-' + value + '-' + currentDay) :
                datePart === 'Day' ? (currentYear + '-' + currentMonth + '-' + value) :
                  datePart === 'Full' && value),
          filterLogic: logic,
          filterValue2: filterValues[filterIndex].filterValue2,
          filterLogic2: filterValues[filterIndex].filterLogic2,

        }]
        const newData = [...filterValues]
        newData.splice(filterIndex, 1); // remove old object
        newData.push(...newDateObject) // add new object
        return setFilterValues(newData);
      }
    } else { // if does not exisits create new object
      const newDateObject = [{
        fieldName: field, fieldType: type,
        filterValue: (
          datePart === 'Year' ? (value + '-' + '00' + '-' + '00') :
            datePart === 'Month' ? ('0000' + '-' + value + '-' + '00') :
              datePart === 'Day' ? ('0000' + '-' + '00' + '-' + value) :
                datePart === 'Full' && value),
        filterLogic: logic
      }]
      return setFilterValues([...filterValues, ...newDateObject]);
    }
  }

  const handleSetFilterDatesSecondValue = (field, type, value, datePart, logic) => {
    // console.log('handleSetFilterDates', field, value, datePart)
    if (filterValues.find(e => e.fieldName === field) !== undefined) { // if field exisits in filter 
      const filterIndex = filterValues.findIndex(e => e.fieldName === field) // get index
      const currentDate = filterValues[filterIndex].filterValue2
      const currentYear = currentDate ? currentDate.split('-')[0] : '00'
      const currentMonth = currentDate ? currentDate.split('-')[1] : '00'
      const currentDay = currentDate ? currentDate.split('-')[2] : '00'
      // If no value given then delete second filter value only
      if (value == '') {
        const newObject = [{
          fieldName: field,
          fieldType: type,
          filterValue: filterValues[filterIndex].filterValue,
          filterLogic: filterValues[filterIndex].filterLogic,
        }]
        const newData = [...filterValues] // new array
        newData.splice(filterIndex, 1); // remove old object
        newData.push(...newObject) // add new object
        return setFilterValues(newData)
      }
      if (value == '(Clear)') {
        const newData = [...filterValues] // new array
        newData.splice(filterIndex, 1); // remove old object
        if (datePart === 'Year') {
          const newDateObject = [{
            fieldName: field,
            fieldType: type,
            filterValue: filterValues[filterIndex].filterValue,
            filterLogic: filterValues[filterIndex].filterLogic,
            filterValue2: '0000' + '-' + currentMonth + '-' + currentDay,
            filterLogic2: logic,
          }]
          newData.push(...newDateObject) // add new object
          return setFilterValues(newData)
        }
        if (datePart === 'Month') {
          const newDateObject = [{
            fieldName: field,
            fieldType: type,
            filterValue: filterValues[filterIndex].filterValue,
            filterLogic: filterValues[filterIndex].filterLogic,
            filterValue2: currentYear + '-' + '00' + '-' + currentDay,
            filterLogic2: logic,
          }]
          newData.push(...newDateObject) // add new object
          return setFilterValues(newData)
        }
        if (datePart === 'Day') {
          const newDateObject = [{
            fieldName: field,
            fieldType: type,
            filterValue: filterValues[filterIndex].filterValue,
            filterLogic: filterValues[filterIndex].filterLogic,
            filterValue2: currentYear + '-' + currentMonth + '-' + '00',
            filterLogic2: logic,
          }]
          newData.push(...newDateObject) // add new object
          return setFilterValues(newData)
        }
      } else {  // Field exists and their is a filtervalue,  
        const newDateObject = [{
          fieldName: field,
          fieldType: type,
          filterValue: filterValues[filterIndex].filterValue,
          filterLogic: filterValues[filterIndex].filterLogic,
          filterValue2: (
            datePart === 'Year' ? (value + '-' + currentMonth + '-' + currentDay) :
              datePart === 'Month' ? (currentYear + '-' + value + '-' + currentDay) :
                datePart === 'Day' ? (currentYear + '-' + currentMonth + '-' + value) :
                  datePart === 'Full' && value),
          // filterDatePart: datePart, 
          filterLogic2: logic
        }]
        const newData = [...filterValues]
        newData.splice(filterIndex, 1); // remove old object
        newData.push(...newDateObject) // add new object
        return setFilterValues(newData);
      }
    } //if no filter field then do nothing. 
  }


  // Used for number filter select options, e.g. greater than, less than etc. 
  const handleSetFilterLogic = (field, type, value, logicNumber) => {
    console.log('handleSetFilterLogic1', field, type, value, logicNumber)
    if (filterValues.find(e => e.fieldName === field) !== undefined) {// if field exisits in filter ...
      console.log('handleSetFilterLogic2', field, value, logicNumber)
      const filterIndex = filterValues.findIndex(e => e.fieldName === field) // get index
      // console.log('filterValues',)
      if (logicNumber === 1) {
        const newNumberObject = [{
          fieldName: field, fieldType: type,
          filterValue: filterValues[filterIndex].filterValue, filterLogic: value,
          filterValue2: filterValues[filterIndex].filterValue2, filterLogic2: filterValues[filterIndex].filterLogic2
        }]
        // console.log('newNumberObject', newNumberObject)
        const newData = [...filterValues] // new array
        newData.splice(filterIndex, 1); // remove old object
        newData.push(...newNumberObject) // add new object
        return setFilterValues(newData)
      }
      if (logicNumber === 2) {
        const newNumberObject = [{
          fieldName: field, fieldType: type,
          filterValue: filterValues[filterIndex].filterValue, filterLogic: filterValues[filterIndex].filterLogic,
          filterValue2: filterValues[filterIndex].filterValue2, filterLogic2: value
        }]
        // console.log('newNumberObject', newNumberObject)
        const newData = [...filterValues] // new array
        newData.splice(filterIndex, 1); // remove old object
        newData.push(...newNumberObject) // add new object
        return setFilterValues(newData)
      }

    } else { // if does not exisits create new object
      const filterIndex = filterValues.findIndex(e => e.fieldName === field) // get index
      if (logicNumber === 1) {
        const newNumberObject = [{
          fieldName: field, fieldType: type,
          filterLogic: value,
          filterLogic2: null
        }]
        // console.log('newNumberObject', newNumberObject)
        const newData = [...filterValues] // new array
        newData.push(...newNumberObject) // add new object
        return setFilterValues(newData)
      }
      if (logicNumber === 2) {
        const newNumberObject = [{
          fieldName: field, fieldType: type,
          filterLogic: null,
          filterLogic2: value
        }]
        // console.log('newNumberObject', newNumberObject)
        const newData = [...filterValues] // new array
        newData.push(...newNumberObject) // add new object
        return setFilterValues(newData)
      }
    }
  }

  const handleClearFilter = (field) => {
    const indexesToBeRemoved = filterValues.map((filter, index) => (filter.fieldName === field ? index : null)) // get the index of each filter value that matches the clear request
    const indexWithNullsRemoved = indexesToBeRemoved.filter(function (el) { return el != null; }); //remove any null indexes
    for (var i = indexWithNullsRemoved.length - 1; i >= 0; i--) // for each index, 
      filterValues.splice(indexWithNullsRemoved[i], 1); //remove it from the filters. 
    setFilterValues([...filterValues]) //reset the state to reload the data 
  }

  // Apply filters
  useEffect(() => {
    if (props.loadingList === false) {
      if (filterValues.length < 1) {
        setFilteredData(searchResults);
      } else {
        // if all filterValues.filterValue are blank then skip (filterValues are created when filter logic is added, this can happend before actual filter values have been entered.)
        const currentFilterValues = filterValues.map((filter) => filter.filterValue)
        // console.log('currentFilterValues1', currentFilterValues)
        // console.log('currentFilterValues First Item',currentFilterValues[0] )
        // console.log('currentFilterValues All',currentFilterValues.every(bool => bool))
        // console.log('currentFilterValues All == undefined', currentFilterValues.every((item) => item == undefined))
        // console.log('currentFilterValues Some',currentFilterValues.some((item) => item == undefined))
        if (currentFilterValues.every((item) => item == undefined)) {
          setFilteredData(searchResults);
        } else {

          // 1. Loop each row from the database
          //  2. Loop each field from the row
          //    3. Loop each filters and apply to that field 
          //    4. If a single filter apply equals to logic
          //    5. If multiple filters, apply OR logic
          //  6. Return True or False for the field
          // 7. Return True or False for the row 

          // 1. Loop each row from the database
          const filterDBResult = searchResults
            .filter((databaseRow) => {
              //  2. Loop each field from the row
              const filterRowResults = Object.entries(databaseRow)
                .map(([dbFieldName, dbValue]) => {
                  //    3. Loop each filters and apply to that field
                  // console.log('databaseRow',databaseRow)
                  const filterFieldResults = filterValues
                    .filter((filter) => filter.filterValue != undefined)
                    .map((filter) => {
                      // console.log('filter',filter.fieldName, filter.filterValue)
                      if (filter.fieldName == dbFieldName) {
                        if (filter.fieldType === 'text') {
                          // console.log('dbValue',dbValue)
                          const filterValueLC = filter.filterValue.toLowerCase().trim()
                          const dbValueLC = dbValue.toLowerCase().trim()
                          const result = dbValueLC.includes(filterValueLC)
                          return result //either true or false 
                        }
                        if (filter.fieldType === 'checkbox') { return dbValue == filter.filterValue }
                        if (filter.fieldType === 'date') { return handleFilterDate(filter, dbValue) }
                        if (filter.fieldType === 'number') { return handleFilterNumber(filter, dbValue) }
                        if (filter.fieldType === 'time') { return handleFilterTime(filter, dbValue) }

                        if (filter.fieldType === 'autoCompleteText') {
                          // console.log('Text: filter.filterValue',filter.filterValue)
                          if (filter.fieldName == dbFieldName && filter.filterValue == dbValue) {
                            return true
                          } else {
                            return false
                          }
                        }

                        if (filter.fieldType == 'select' ||
                          filter.fieldType == 'selectfromdb' ||
                          filter.fieldType == 'selectnoid') {
                          const selectFilterValues = filter.filterValue.map((singleFilterValue) => {
                            if (singleFilterValue === '0' && dbValue === null) {
                              return true
                            }
                            else {

                              if (filter.fieldName == dbFieldName && singleFilterValue == dbValue) {
                                return true
                              } else {
                                return false
                              }
                            }
                          })
                          return selectFilterValues.some((item) => item === true);
                        }
                      } else {
                        return true
                      }

                    })
                  // console.log('filterFieldResults',dbFieldName,filterFieldResults)
                  return filterFieldResults.every(bool => bool);
                })
              // console.log('filterRowResults',filterRowResults)
              return filterRowResults.every(bool => bool);
            })
          setFilteredData(filterDBResult);
        }
      }
    }
  }, [filterValues, setFilterValues, searchResults]);

  const handleResetAllFilters = () => {
    setFilterValues([])
    setFilteredData(searchResults)
  }

  const filterValue = (field) => {
    // console.log('filterValue',field)
    const fieldstate = filterValues.find(e => e.fieldName === field)
    if (fieldstate === undefined) {
      return ''
    } else {
      // console.log('fieldstate.filterValue',fieldstate.filterValue)
      return fieldstate.filterValue
    }
  }

  const filterValueName = (field) => {
    const fieldstate = filterValues.find(e => e.fieldName === field)
    if (fieldstate === undefined) {
      return ''
    } else {
      // console.log('fieldstate.filterValueName',fieldstate.filterValueName)
      return fieldstate.filterValueName
    }
  }

  // Used for number filters that have logic conditions applied 
  const filterValue2 = (field) => {
    const fieldstate = filterValues.find(e => e.fieldName === field)
    if (fieldstate === undefined) {
      return ''
    } else {
      // console.log('fieldstate.filterValueName',fieldstate.filterValueName)
      return fieldstate.filterValue2
    }
  }

  const filterValueDate = (field, datePart) => {
    const fieldstate = filterValues.find(e => e.fieldName === field && e.filterDatePart === datePart)
    // console.log('filterValueDate',field, datePart )
    if (fieldstate === undefined) {
      return ''
    } else {
      // console.log('fieldstate.filterValue',fieldstate.filterValue)
      return fieldstate.filterValue
    }
  }


  const filterLogic = (field, logicNumber) => {
    const fieldstate = filterValues.find(e => e.fieldName === field)
    if (fieldstate === undefined) {
      return ''
    } else {
      // console.log('table-filterLogic', field, logicNumber)
      if (logicNumber == 1) {
        // console.log('table-filterLogic-fieldstate-1', fieldstate.filterLogic)
        return fieldstate.filterLogic
      }
      if (logicNumber == 2) {
        // console.log('table-filterLogic-fieldstate-2', fieldstate.filterLogic2)
        return fieldstate.filterLogic2
      } else {
        // console.log('table-filterLogic-fieldstate-no data')
      }
    }
  }

  const fieldIsChecked = (field) => {
    if (fieldValues.find(e => e.fieldName === field) !== undefined) {
      const fieldIndex = fieldValues.findIndex(e => e.fieldName === field)
      const fieldValue = fieldValues[fieldIndex].value
      return fieldValue
    }
  }

  const fieldRowItemIsChecked = (field, id) => {
    // console.log('fieldRowItemIsChecked', field, id)
    // console.log('dataList',props.dataList)

    if (props.dataList.find(e => e.id === id) !== undefined) {
      // console.log('fieldRowItemIsChecked', 'found field', field)
      const filterIndex = props.dataList.findIndex(e => e.id === id)
      // console.log('fieldRowItemIsChecked - index', filterIndex)
      const fieldStatus = props.dataList[filterIndex][field]
      // console.log('fieldRowItemIsChecked - fieldStatus', fieldStatus)
      return fieldStatus
    }
  }

  const filterSelectIsChecked = (field, id) => {
    const currentFilterValues = filterValues.map((filter) => filter.filterValue)
    if (currentFilterValues[0] == undefined) {
      return false
    } else if (filterValues.find(e => e.fieldName === field) !== undefined) {
      const filterIndex = filterValues.findIndex(e => e.fieldName === field)
      if (filterValues[filterIndex].filterValue.find(e => e == id)) {
        return true
      }
    }
  }

  const filterCheckBoxIsChecked = (field) => {
    // console.log('filterCheckBoxIsChecked',field )

    if (filterValues.find(e => e.fieldName === field) !== undefined) {
      const filterIndex = filterValues.findIndex(e => e.fieldName === field)
      // console.log('filterIndex',filterIndex )
      return true
    }
    // const currentFilterValues = filterValues.map((filter) => filter.filterValue)
    // console.log('currentFilterValues',currentFilterValues )
    // if (currentFilterValues[0] == undefined) { 
    //   console.log('currentFilterValues undefined' )
    //   return false
    // } else if (filterValues.find(e => e.fieldName === field) !== undefined) {
    //     const filterIndex = filterValues.findIndex(e => e.fieldName === field)
    //     console.log('filterIndex',filterIndex )
    //     console.log('filterValues',filterValues )
    //     console.log('filterValues[filterIndex]',filterValues[filterIndex] )
    //     console.log('filterValues[filterIndex]',filterValues[filterIndex] )
    //   // if (filterValues[filterIndex].filterValue.find(e => e == id)) {
    //   //   console.log('match' )
    //   //   return true
    //   // } else {
    //   //   console.log('no match1' )
    //   //   // return false
    //   // }
    // } 
    // console.log('no match2' )

  }

  const tableDisplayPriorityCalc = (column) =>
    (column.tableDisplayPriority === undefined ? 0 : column.tableDisplayPriority)

  const tableHeaderSelectOptions = (column) =>
    (column.fieldLongName === undefined ? column.fieldName : column.fieldLongName)

  const editARow = (dataitem) => {
    // console.log('table - edit a row - dataitem:',dataitem)
    // console.log('props.moduleFields',props.moduleFields)

    const dbFieldValues = props.moduleFields.map((fields) => {
      return {
        fieldName: fields.fieldName,
        value: dataitem[fields.fieldName],
        fieldValueName: dataitem[fields.fieldLongName],
        touched: false,
        hasError: false,
        fieldErrorMessage: "",
      };
    })

    setFieldValues(dbFieldValues)
    setEditRow(dataitem.id)
  }

  const editActions = (dataitem) => {
    // console.log('dataitem',dataitem)
    props.setFormModalVisibility(true)
    props.setFormTypeAction('Edit')
    props.setTableRowClickItemID(dataitem.id)
  }

  const calculateOptionsColumnWidth = (options) => {
    var width = 0
    options.map((option => {
      if (option == undefined || option == false) {
        return width = width
      } else {
        return width = width + 1
      }
    }))
    if (width > 1) {
      setOptionsColumWidth(width * 41 + 'px')
    }
  }

  const handleRowItemClick = (field, id) => {
    // console.log('handleRowItemClick', field, id)
    // console.log('moduleFields', props.moduleFields)
    if (props.moduleFields.findIndex(e => e.fieldName === field) !== undefined) {
      const index = props.moduleFields.findIndex(e => e.fieldName === field)
      // console.log('index', index)
      const tableRowFieldClickAction = props.moduleFields[index].tableRowFieldClickAction

      // console.log('tableRowFieldClickAction', tableRowFieldClickAction)
    }

  }

  const rowHeadingName = (field) => {
    return (<td colspan="4" style={{ borderLeft: '0px', borderRight: '0px' }}> <h3 style={{ marginBottom: '0px' }}>{field} </h3> </td>)
  }

  const getGroupByRowHeadingName = (group, data, index, array) => {
    // console.log('getGroupName', group, data, index, array)
    const moduleFields = props.moduleFields.find((item) => item.fieldName === groupBy)
    const fieldLongName = moduleFields.fieldLongName
    if (fieldLongName) {
      // console.log('fieldLongName', fieldLongName)
      // console.log('fieldLongName for current row:', data[fieldLongName], 'fieldLongName for next row:', array[index + 1] ? array[index + 1][fieldLongName] : 'empty');
      // For the first heading 
      if (index === 0) {
        return rowHeadingName(data[fieldLongName])
      }
      //Each subsequent heading
      if (array[index - 1]) {
        if (data[fieldLongName] != array[index - 1][fieldLongName]) {
          return rowHeadingName(data[fieldLongName])
        }
      }
    } else {
      // console.log('fieldName', groupBy)
      if (data[groupBy] != array[index + 1][groupBy]) {
        return rowHeadingName(data[groupBy])
      }
    }
  }

  const rowTotals = (field) => {
    return (<td colspan="4"> <span style={{ fontWeight: 600 }}> Totals for  {field}</span> </td>)
  }

  const getGroupByRowTotals = (group, data, index, array) => {
    // console.log('getGroupName', group, data, index, array)
    const moduleFields = props.moduleFields.find((item) => item.fieldName === groupBy)
    const fieldLongName = moduleFields.fieldLongName
    if (fieldLongName) {
      // console.log('fieldLongName', fieldLongName)
      // console.log('fieldLongName for current row:', data[fieldLongName], 'fieldLongName for next row:', array[index + 1] ? array[index + 1][fieldLongName] : 'empty');
      if (array[index + 1]) {
        if (data[fieldLongName] != array[index + 1][fieldLongName]) {
          return rowTotals(data[fieldLongName])
        }
      }
    } else {
      // console.log('fieldName', groupBy)
      if (data[groupBy] != array[index + 1][groupBy]) {
        return rowTotals(data[groupBy])
      }
    }
  }

  const onRowDelete = (id) => {
    // get the array {searchResults} and find by index for the row that is being deleted
    const index = searchResults.findIndex(e => e.id === id)
    const currentObject = searchResults[index]
    // console.log('currentObject',currentObject)
    //add a new item {rowdelete} to the object
    const newItem = { rowDeleted: true }
    const newObject = { ...currentObject, ...newItem }
    // console.log('newObject',newObject)
    // add the updated object back into thr array
    // console.log('searchResults - Before ',searchResults)
    const updateArray = searchResults.slice(0);
    updateArray[index] = newObject
    // console.log('searchResults - After',updateArray)
    return setSearchResults(updateArray)
    // filter the display to exlcude items that have a row deleted indicator.
  }

  // only for Tasks
  const onRowComplete = (id) => {
    // get the array {searchResults} and find by index for the row that is being deleted
    const index = searchResults.findIndex(e => e.id === id)
    const currentObject = searchResults[index]
    console.log('currentObject', currentObject)
    //add a new item {rowdelete} to the object
    const newItem = { status: 3, status_name: "Completed" }
    const newObject = { ...currentObject, ...newItem }
    console.log('newObject', newObject)
    // add the updated object back into thr array
    console.log('searchResults - Before ', searchResults)
    const updateArray = searchResults.slice(0);
    updateArray[index] = newObject
    console.log('searchResults - After', updateArray)
    return setSearchResults(updateArray)
  }

  return (
    <div>
      {props.errorLoadingList && <div className={formstyle.errormessage}>{props.errorLoadingList}</div>}
      {props.tableControls !== false &&
        <TableControls
          //From Module
          datalist={props.dataList}
          loadingList={props.loadingList}
          moduleFields={props.moduleFields}
          moduleDefinitions={props.moduleDefinitions}
          {...props}

          //Table State
          viewType={viewType}
          setViewType={setViewType}

          // Search Function
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          // Sort Function
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          // Filter Function
          fieldIsChecked={fieldIsChecked}
          filterCheckBoxIsChecked={filterCheckBoxIsChecked}
          filterSelectIsChecked={filterSelectIsChecked}
          filterValue={filterValue}
          filterValue2={filterValue2}
          filterValueName={filterValueName}
          filterValueDate={filterValueDate}
          filterLogic={filterLogic}
          handleSetFilterValues={handleSetFilterValues}
          handleSetFilterNumbers={handleSetFilterNumbers}
          handleSetFilterNumbersTwo={handleSetFilterNumbersTwo}
          handleSetFilterDates={handleSetFilterDates}
          handleSetFilterDatesSecondValue={handleSetFilterDatesSecondValue}
          handleSetFilterLogic={handleSetFilterLogic}
          handleResetAllFilters={handleResetAllFilters}
          handleClearFilter={handleClearFilter}

          // Alignment with table headers
          handleSelectOptions={handleSelectOptions}
          tableHeaderSelectOptions={tableHeaderSelectOptions}
          setGroupBy={setGroupBy}
          groupBy={groupBy}
        />
      }
      {viewType === 'List' &&
        <>
          <table className={tablestyle.table}>
            <thead>
              <tr>
                {props.moduleFields
                  .sort((a, b) => a.tableDisplayOrder - b.tableDisplayOrder)
                  .map((column) => (
                    <TableHeader
                      key={column.fieldName}
                      label={column.label}
                      moduleFields={props.moduleFields}
                      {...props}
                      fieldName={column.fieldName}
                      fieldType={column.fieldType}
                      fieldIsChecked={fieldIsChecked}
                      selectOptions={handleSelectOptions(props.dataList, column.fieldName, tableHeaderSelectOptions(column))}
                      tableDisplayPriority={tableDisplayPriorityCalc(column)}
                      // Sort & Filter Function
                      searchResults={searchResults}
                      filteredData={filteredData}
                      setFilteredData={setFilteredData}
                      // Filter Function
                      filterValues={filterValues}
                      setFilterValues={setFilterValues}

                      filterValue={filterValue}
                      filterValue2={filterValue2}
                      filterValueName={filterValueName}
                      filterValueDate={filterValueDate}
                      filterLogic={filterLogic}
                      filterCheckBoxIsChecked={filterCheckBoxIsChecked}
                      filterSelectIsChecked={filterSelectIsChecked}
                      handleSetFilterValues={handleSetFilterValues}
                      handleSetFilterNumbers={handleSetFilterNumbers}
                      handleSetFilterNumbersTwo={handleSetFilterNumbersTwo}
                      handleSetFilterDates={handleSetFilterDates}
                      handleSetFilterDatesSecondValue={handleSetFilterDatesSecondValue}
                      handleSetFilterLogic={handleSetFilterLogic}
                      handleClearFilter={handleClearFilter}
                      autoCompleteOptions={column.options}
                      dataList={props.dataList}
                      tableFilterHide={column.tableFilterHide}
                    />
                  ))}

                {optionsColumWidth !== 0 &&
                  <TableHeaderOptions style={{
                    width: optionsColumWidth,
                    cursor: 'default',
                    padding: '0.5rem',
                  }} />}
              </tr>
            </thead>
            {!filteredData || props.loadingList === true ? '' :
              <tbody>
                {filteredData
                  .filter((item) => item.rowDeleted != true)
                  .map((dataitem, index, array) => (
                    <>
                      {groupBy.length > 1 &&
                        <>
                          <tr colspan="5">
                            {getGroupByRowHeadingName(groupBy, dataitem, index, array)}
                          </tr>
                        </>
                      }
                      <tr key={dataitem.id} className={tablestyle.trhover}>
                        {props.moduleFields.map((column) => (
                          <>
                            {/* {console.log('dataitem',column.fieldName,dataitem)}
                      {console.log('dataitem[column.fieldName]',column.fieldName,dataitem[column.fieldName])}
                      {console.log('dataitem[column.fieldLongName]',column.fieldName, dataitem[column.fieldLongName])}
                      {console.log('data',dataitem[column.fieldLongName] === undefined ? dataitem[column.fieldName] : dataitem[column.fieldLongName])} */}
                            <TableRowData
                              key={column.fieldName}
                              tableDisplayPriority={tableDisplayPriorityCalc(column)}
                              onClick={() =>
                                props.rowOnClickAction === 'edit' ? editActions(dataitem) :
                                  // props.rowOnClickAction === undefined ? props.edit(dataitem) :
                                  props.rowOnClickAction === 'view' ? props.view(dataitem) :
                                    props.rowOnClickAction === 'inLineEdit' && editARow(dataitem)
                              }
                              data={(dataitem[column.fieldLongName] === undefined ? dataitem[column.fieldName] : dataitem[column.fieldLongName])}

                              // Interactive save/edit features 
                              fieldName={column.fieldName}
                              fieldType={column.fieldType}
                              fieldValueName={fieldValueName(column.fieldName)}
                              tableRowFieldClickable={column.tableRowFieldClickable}
                              tableRowEditField={column.tableRowEditField}
                              openAddNewModule={column.openAddNewModule}

                              value={fieldValue(column.fieldName)}
                              inLineEdit={props.inLineEdit}
                              options={column.options}
                              editRow={editRow}
                              dataitemID={dataitem.id}
                              onChange={e => handleChange(column.fieldName, e.target.value)}

                              //Checkbox
                              id={dataitem.id}
                              fieldIsChecked={fieldIsChecked}
                              fieldRowItemIsChecked={fieldRowItemIsChecked}
                              handleChange={handleChange}
                              handleRowItemClick={handleRowItemClick}

                              //Grouping
                              groupBy={groupBy}
                            />
                          </>
                        ))}
                        {/* Options icons at the end of the row */}
                        {optionsColumWidth !== 0 &&
                          <td style={{textAlign: 'center', padding: '3px 3px'}}>
                            {/* When the row is in edit mode, show the Save and Cancel Options */}
                            {dataitem.id === editRow ?
                              <>
                                <TableRowOptionSave onClick={(e) => props.edit(dataForDBWithID(dataitem.id))} />
                                <TableRowOptionCancel onClick={(e) => { setEditRow(''); setFieldValues([]) }} />
                              </> : <>
                                {/* When the row is in view mode, View,Edit, Delete etc... Options */}
                                {props.view && <TableRowOptionView onClick={() => props.view(dataitem)} />}
                               
                                {props.complete && <TableRowOptionComplete onClick={() => { onRowComplete(dataitem.id); props.complete(dataitem) }} />}

                                {props.action && <TableRowOptionAction
                                  onClick={() => props.action(dataitem)}
                                  actionIcon={props.actionIcon}
                                  actionTitle={props.actionTitle} />}

                                {/* When the table is being viewed as a tab (rather than in the module home) navigate to a new page. Otherwise use a pop up Modal.  */}
                                {props.edit && props.source === 'viewTab' ?
                                  <TableRowOptionEdit onClick={() => props.edit(dataitem)} />
                                  :
                                  <TableRowOptionEdit onClick={() => editActions(dataitem)} />}
                                 {props.delete && <TableRowOptionDelete onClick={() => { onRowDelete(dataitem.id); props.delete(dataitem.id) }} />}

                              </>}
                          </td>
                        }
                      </tr>
                      {/* {groupBy.length > 1 &&
                      <>
                        <tr colspan="5">
                          {getGroupByRowTotals(groupBy, dataitem, index, array)}
                        </tr>
                      </>
                    } */}
                    </>
                  ))}
                {props.inLineAdd && editRow == '' && <tr>
                  {props.moduleFields.map((column) => (
                    <TableRowAdd
                      key={column.fieldName}
                      label={column.label}
                      fieldName={column.fieldName}
                      fieldType={column.fieldType}
                      value={fieldValue(column.fieldName)}
                      onChange={e => handleChange(column.fieldName, e.target.value)}

                      options={column.options}
                      tableDisplayPriority={tableDisplayPriorityCalc(column)}
                      tableRowAddField={column.tableRowAddField}
                      openAddNewModule={column.openAddNewModule}
                      //AutoComplete
                      handleChange={handleChange}
                      addNewLink={column.addNewLink}
                      //Checkbox
                      fieldIsChecked={fieldIsChecked}
                    />

                  ))}
                  <td 
                  // className={tablestyle.colDisP1}
                  >
                    <button
                      className={`${formstyle.mediumButton} ${formstyle.green} containter`}
                      onClick={(e) => props.save(dataForDB)}
                    >
                      Save</button>
                  </td>
                </tr>
                }
              </tbody>
            }
          </table>
          {props.loadingList === true ? <Loader /> :
            props.dataList === undefined || props.dataList.length === 0 ? (<p style={{ color: 'red', fontWeight: 'bold' }}>No data available fom the database</p>) :
              !filteredData || filteredData.length === 0 ? <p>Current filters are not returning any data.</p> : ''
          }
        </>
      }
      {/* {console.log('viewType',viewType)} */}
      {viewType === 'Month' &&
      <>
        {!filteredData || props.loadingList === true ? <Loader /> :
        <Calendar
          filteredData={filteredData}
          calendar={props.calendar}
          setCalendar={props.setCalendar}
          //Filtering
          // dataBaseFilter={props.dataBaseFilter}
          // setDataBaseFilter={props.setDataBaseFilter}

          //Filters
          setFilterRangeStart={props.setFilterRangeStart}
          setFilterRangeEnd={props.setFilterRangeEnd}
          
          //Edit Actions
          onClickAction={props.rowOnClickAction}
          editActions={editActions}
          // Add Actions
          setFormModalVisibility={props.setFormModalVisibility}
          setFormTypeAction={props.setFormTypeAction}
          setStartDate={props.setStartDate}

          // onClick={() =>
          //   props.rowOnClickAction === 'edit' ? editActions(dataitem) :
          //     // props.rowOnClickAction === undefined ? props.edit(dataitem) :
          //     props.rowOnClickAction === 'view' ? props.view(dataitem) :
          //       props.rowOnClickAction === 'inLineEdit' && editARow(dataitem)
          // }

        />
        }
        </>
      }


    </div>
  );
};

export default Table;