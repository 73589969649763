const handleFilterNumber = (filter,dbValue) => {
  if (filter.filterLogic === 'eqaulTo' || filter.filterLogic === undefined) {
        return dbValue == filter.filterValue;
      }
      if (filter.filterLogic === 'lessThan') {
        return dbValue < filter.filterValue
      }
      if (filter.filterLogic === 'equalToLessThan') {
        return dbValue <= filter.filterValue
      }
      if (filter.filterLogic === 'notEqualTo') {
        return dbValue != filter.filterValue
      }
      if (filter.filterLogic === 'greaterThan') {
        if (filter.filterLogic2 === 'lessThan' ) {
          return dbValue > filter.filterValue && dbValue < filter.filterValue2
        } else if (filter.filterLogic2 === 'equalToLessThan') {
          return dbValue > filter.filterValue && dbValue <= filter.filterValue2
        } else {
          return dbValue > filter.filterValue
        }
      }
      if (filter.filterLogic === 'equalToGreaterThan') {
        if (filter.filterLogic2 === 'equalToLessThan') {
          return dbValue >= filter.filterValue && dbValue <= filter.filterValue2
        } else if (filter.filterLogic2 === 'lessThan') {
          return dbValue >= filter.filterValue && dbValue < filter.filterValue2
        } else {
          return dbValue >= filter.filterValue
        }
      }
}
export default handleFilterNumber