
import Square from './components/square';
import { MdOutlineSailing, MdPeople, MdMenuBook, MdTimeline, MdOutlineWatchLater, MdOutlineLoop, MdBuild, MdSettings, MdLocationPin } from "react-icons/md";
import style from './sailingLog.module.css'

const SailingLogHome = () => {
  const squares = [
    {
      name: 'Contacts',
      icon: <MdPeople />,
      link: "/fun/sailinglog/contacts/list",
    },
    {
      name: 'Boats',
      icon: <MdOutlineSailing />,
      link: '/fun/sailinglog/boats/list',
    },
    {
      name: 'Locations',
      icon: <MdLocationPin />,
      link: "/fun/sailinglog/locations/list",
      hoverText: 'Marinas, Ports, Anchorages',
    },
    {
      name: 'Trips',
      icon: <MdOutlineLoop />,
      hoverText: 'A sailing trip could be a holiday, a weekend away or a series of races.',
      link: "/fun/sailinglog/trips/list",
    },
    {
      name: 'Passages',
      icon: <MdTimeline />,
      hoverText: 'A passage is a single journey between two locations.',
      link: "/fun/sailinglog/passages/list",
    },
    {
      name: 'Log',
      icon: <MdOutlineWatchLater />,
      hoverText: `It is recommended to keep a log at regular intervals when sailing.`,
      link: "/fun/sailinglog/logentry/list",
    },
    {
      name: 'Reference',
      icon: <MdMenuBook />,
      hoverText: 'Useful information related to sailing.',
      link: "/fun/sailinglog/reference",
    },
    {
      name: 'Tools',
      icon: <MdBuild />,
      link: "/fun/sailinglog/tools",
    },
    {
      name: 'Settings',
      icon: <MdSettings />,
      link: "/fun/sailinglog/settings",
    },

  ]


  return (
    <div className={style.gridContainer}>
      {squares.map((square) =>
        <Square
          key={square.name}
          name={square.name}
          icon={square.icon}
          link={square.link}
          disabled={square.disabled}
          hoverText={square.hoverText}
        />)}
    </div>
  );
};
export default SailingLogHome;