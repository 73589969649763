import { MdOutlineModeEditOutline } from 'react-icons/md';
import formstyle from '../../style/form.module.css';

const EditButton = (props) => {

  const editActions = (dataitem) => {
    props.setFormModalVisibility(true)
    props.setFormTypeAction('Edit')
    props.setTableRowClickItemID(dataitem.id)
  }

  return (
    < button
      className={`${formstyle.navButton} ${formstyle.editButton}`}
      onClick={() => {editActions(props.dataItem)}}
    >

      <div
        className={formstyle.navButtonDiv}
      >
        <span
          className={formstyle.navButtonIcon}>
          <MdOutlineModeEditOutline
            style={{
              verticalAlign: 'middle',
              fontSize: '30px'
            }}
          />
        </span>
        <span
          className={formstyle.navButtonText}
        >Edit
        </span>
      </div>

    </button >
  )
}
export default EditButton