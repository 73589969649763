import formstyle from '../../../style/form.module.css';

const Checkbox = (props) => {
  return (
    <div 
    className={formstyle.selectOption}
    onClick={() => props.handleSetFilterValues(props.fieldName, props.fieldType, props.id)}>
      <input
        style={{
          width: '10%',
          cursor: 'pointer',
          height: '1.1em',
        }}
        key={props.id}
        type="checkbox"
        // value={props.id === null ? '' : props.id }
        value={props.id}

        checked={props.filterSelectIsChecked(props.fieldName, props.id)}
        // onChange={e => props.handleSetFilterValues(props.fieldName, props.fieldType, e.target.value)}
      />
      <span> {props.name}</span>
    </div>
  );
};
export default Checkbox;