//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../components/containers/moduleHome'

import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../APIs/genericAPIs'
import {CountryCodesSelectOptionsAPI} from '../shared/countryCodes'
import {SailingLogPassageSummaryByLocationAPI} from './passage/passages'
 
export const moduleDefinitions = {
  moduleName: 'Locations',
  moduleNameSingular: 'Location',
  moduleLink: '/fun/sailinglog/locations/',
  linktoView: '/sailinglog/location/list/items/?listid=',
  thOptionsWidth: '115px',
}

export const moduleFields = [
  {
    label: 'Location Name', 
    mandatory: 'true', 
    fieldName: 'name',
    fieldType: 'text',
    addFormOrder: 10,
    editFormOrder: 10,
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  } ,  {
    label: 'Line 1', 
    fieldName: 'line1',
    fieldType: 'text',
    addFormOrder: 20,
    editFormOrder: 20,
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  } ,  {
    label: 'Line 2', 
    fieldName: 'line2',
    fieldType: 'text',
    addFormOrder: 30,
    editFormOrder: 30,
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  } ,  {
    label: 'Line 3', 
    fieldName: 'line3',
    fieldType: 'text',
    addFormOrder: 40,
    editFormOrder: 40,
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  } ,  {
    label: 'Town/City', 
    fieldName: 'towncity',
    fieldType: 'text',
    addFormOrder: 50,
    editFormOrder: 50,
    tableDisplayOrder: '2',
    tableDisplayPriority: '3',
  } ,  {
    label: 'County', 
    fieldName: 'county',
    fieldType: 'text',
    addFormOrder: 60,
    editFormOrder: 60,
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  } ,  {
    label: 'Postcode', 
    fieldName: 'postcode',
    fieldType: 'text',
    addFormOrder: 70,
    editFormOrder: 70,
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  } ,  {
    label: 'Country', 
    fieldName: 'country',
    fieldLongName: 'country_name',
    fieldType: 'selectfromdb',
    options: 'CountryCodesSelectOptions',
    addFormOrder:80,
    editFormOrder: 80,
    tableDisplayOrder: '2',
    tableDisplayPriority: '2',
  } ,  {
    label: 'Latitude', 
    fieldName: 'latitude',
    fieldType: 'text',
    addFormOrder: 90,
    editFormOrder: 90,
    tableDisplayOrder: '0',
    tableDisplayPriority: '0',
  } ,  {
    label: 'Longitude', 
    fieldName: 'longitude',
    fieldType: 'text',
    addFormOrder: 100,
    editFormOrder: 100,
    tableDisplayOrder: '0',
    tableDisplayPriority: '0',
  } ,  {
    label: 'Notes', 
    fieldName: 'notes',
    fieldType: 'textarea',
    addFormOrder: 110,
    editFormOrder: 110,
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  } ,
]

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
      fieldErrorMessage = ""

  switch (fieldName) {

    case "name":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Name is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break
      
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}


  ///////////// Constants /////////////////////////
export const SailingLogLocationListCreateRequest = 'SailingLogLocationListCreateRequest'
export const SailingLogLocationListCreateSuccess = 'SailingLogLocationListCreateSuccess'
export const SailingLogLocationListCreateFail = 'SailingLogLocationListCreateFail'
export const SailingLogLocationListCreateReset = 'SailingLogLocationListCreateReset'

export const SailingLogLocationListDetailsRequest = 'SailingLogLocationListDetailsRequest'
export const SailingLogLocationListDetailsSuccess = 'SailingLogLocationListDetailsSuccess'
export const SailingLogLocationListDetailsFail = 'SailingLogLocationListDetailsFail'
export const SailingLogLocationListDetailsReset = 'SailingLogLocationListDetailsReset'

export const SailingLogLocationListUpdateRequest = 'SailingLogLocationListUpdateRequest'
export const SailingLogLocationListUpdateSuccess = 'SailingLogLocationListUpdateSuccess'
export const SailingLogLocationListUpdateFail = 'SailingLogLocationListUpdateFail'
export const SailingLogLocationListUpdateReset = 'SailingLogLocationListUpdateReset'

export const SailingLogLocationListDeleteRequest = 'SailingLogLocationListDeleteRequest'
export const SailingLogLocationListDeleteSuccess = 'SailingLogLocationListDeleteSuccess'
export const SailingLogLocationListDeleteFail = 'SailingLogLocationListDeleteFail'
export const SailingLogLocationListDeleteReset = 'SailingLogLocationListDeleteReset'

export const SailingLogLocationListListRequest = 'SailingLogLocationListListRequest'
export const SailingLogLocationListListSuccess = 'SailingLogLocationListListSuccess'
export const SailingLogLocationListListFail = 'SailingLogLocationListListFail'


  ///////////// API Config /////////////////////////

const APIVariablesCreate = {
  APIRequest: SailingLogLocationListCreateRequest,
  APISuccess: SailingLogLocationListCreateSuccess,
  APIFail: SailingLogLocationListCreateFail,
  APIEndPoint: 'sailinglog/v1/location/add',
}
const APIVariablesListAll = {
  APIRequest: SailingLogLocationListListRequest,
  APISuccess: SailingLogLocationListListSuccess,
  APIFail: SailingLogLocationListListFail,
  APIEndPoint: 'sailinglog/v1/location/list'
}
const APIVariablesSelectOptions = {
  APIRequest: 'SailingLogLocationSelectOptionsRequest',
  APISuccess: 'SailingLogLocationSelectOptionsSuccess',
  APIFail: 'SailingLogLocationSelectOptionsFail',
  APIEndPoint: 'sailinglog/v1/location/selectoptions'
}
const APIVariablesItemDetails = {
  APIRequest: SailingLogLocationListDetailsRequest,
  APISuccess: SailingLogLocationListDetailsSuccess,
  APIFail: SailingLogLocationListDetailsFail,
  APIEndPoint: 'sailinglog/v1/location/'
}
const APIVariablesUpdate = {
  APIRequest: SailingLogLocationListUpdateRequest,
  APISuccess: SailingLogLocationListUpdateSuccess,
  APISuccess_2: SailingLogLocationListDetailsSuccess,
  APIFail: SailingLogLocationListUpdateFail,
  APIEndPoint: 'sailinglog/v1/location/'
}
const APIVariablesDelete = {
  APIRequest: SailingLogLocationListDeleteRequest,
  APISuccess: SailingLogLocationListDeleteSuccess,
  APIFail: SailingLogLocationListDeleteFail,
  APIEndPoint: 'sailinglog/v1/location/'
}

const API_Body_Contents = (item) => {
  return ({
      name: item.name,
      line1: item.line1,
      line2: item.line2,
      line3: item.line3,
      towncity: item.towncity,
      county: item.county,
      postcode: item.postcode,
      country: item.country,
      latitude: item.latitude,
      longitude: item.longitude,
      notes: item.notes,
})}

export const SailingLogLocationCreateAPI = (item) => async (dispatch, getState) => {
  const API_Body = API_Body_Contents(item)
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}
export const SailingLogLocationListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}
export const SailingLogLocationSelectOptionsAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesSelectOptions, dispatch, getState, query)
}
export const SailingLogLocationDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}
export const SailingLogLocationUpdateAPI = (item) => async (dispatch, getState) => {
  const API_Body = API_Body_Contents(item)
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}
export const SailingLogLocationDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}
  
  ///////////// REDUCERS/////////////////////////
  // State is an empty object
  export const SailingLogLocationCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case SailingLogLocationListCreateRequest:
            return {
                loading: true
            }
  
        case SailingLogLocationListCreateSuccess:
            return {
                loading: false,
                success: true,
                SailingLogLocation: action.payload
            }
  
        case SailingLogLocationListCreateFail:
            return {
                loading: false,
                error: action.payload
            }
  
        case SailingLogLocationListCreateReset:
            return {}
  
  
        default:
            return state
    }
  }
  
  export const SailingLogLocationUpdateReducer = (state = { SailingLogLocation: {} }, action) => {
    switch (action.type) {
        case SailingLogLocationListUpdateRequest:
            return { loading: true }
  
        case SailingLogLocationListUpdateSuccess:
            return { loading: false, success: true }
  
        case SailingLogLocationListUpdateFail:
            return { loading: false, error: action.payload }
  
        case SailingLogLocationListUpdateReset:
            return { SailingLogLocation: {} }
  
        default:
            return state
    }
  }
  
  export const SailingLogLocationDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case SailingLogLocationListDeleteRequest:
            return { loading: true }
  
        case SailingLogLocationListDeleteSuccess:
            return { 
              loading: false, 
              success: true }
  
        case SailingLogLocationListDeleteFail:
            return { loading: false, error: action.payload }
  
        case SailingLogLocationListDeleteReset:
              return { }
  
        default:
            return state
    }
  }
  
  export const SailingLogLocationDetailsReducer = (state = { loading: true, SailingLogLocation: [] }, action) => {
    switch (action.type) {
        case SailingLogLocationListDetailsRequest:
            return {
                ...state,
                loading: true
            }
  
        case SailingLogLocationListDetailsSuccess:
            return {
                loading: false,
                SailingLogLocation: action.payload
            }
  
        case SailingLogLocationListDetailsFail:
            return {
                loading: false,
                error: action.payload
            }
  
  
        default:
            return state
    }
  }
   
  
  export const SailingLogLocationListReducer = (state = { SailingLogLocation: [] }, action) => {
    switch (action.type) {
        case SailingLogLocationListListRequest:
            return {
                loading: true
            }
  
        case SailingLogLocationListListSuccess:
            return {
                loading: false,
                fieldOptionValues: [...action.payload],
                SailingLogLocation: action.payload
            }
  
        case SailingLogLocationListListFail:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
  }

  export const SailingLogLocationSelectOptionsReducer = (state = { fieldOptionValues: [] }, action) => {
    switch (action.type) {
      case 'SailingLogLocationSelectOptionsRequest':
        return {
          loading: true
        }
  
      case 'SailingLogLocationSelectOptionsSuccess':
        return {
          loading: false,
          fieldOptionValues: [...action.payload],
        }
  
      case 'SailingLogLocationSelectOptionsFail':
        return {
          loading: false,
          error: action.payload
        }
      default:
        return state
    }
  }

function SailingLogLocationScreen(props) {
  const createAPI = SailingLogLocationCreateAPI
  const listAPI = SailingLogLocationListAllAPI
  const detailsAPI = SailingLogLocationDetailsAPI
  const updateAPI = SailingLogLocationUpdateAPI
  const deleteAPI = SailingLogLocationDeleteAPI

  const resetCreate = SailingLogLocationListCreateReset
  const resetUpdate = SailingLogLocationListUpdateReset
  const resetViewDetails = SailingLogLocationListDetailsReset
  const resetDelete = SailingLogLocationListDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const paramsItem = useParams()
  // console.log('paramsItem',paramsItem)
  const paramsItemSplit = paramsItem.action.split(':')
  const paramsID = paramsItemSplit[1]

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const SailingLogLocationCreate = useSelector(state => state.SailingLogLocationCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = SailingLogLocationCreate

  const SailingLogLocationList = useSelector(state => state.SailingLogLocationList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    SailingLogLocation: dataList } = SailingLogLocationList

  const SailingLogLocationDetails = useSelector(state => state.SailingLogLocationDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    SailingLogLocation: dataItem } = SailingLogLocationDetails

  const SailingLogLocationUpdate = useSelector(state => state.SailingLogLocationUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = SailingLogLocationUpdate

  const SailingLogLocationDelete = useSelector(state => state.SailingLogLocationDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = SailingLogLocationDelete

  const SailingLogPassageSummaryByLocation = useSelector(state => state.SailingLogPassageSummaryByLocation)
  const {
    loading: loadingPassageSummary,
    error: errorPassageSummary,
    SailingLogPassage: listPassageSummary } = SailingLogPassageSummaryByLocation

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingList || loadingDetails
    )
  )

  const viewTabs = [
    {
      label: 'Passages',
      viewLink: '/fun/sailinglog/trips/',
      dataList: listPassageSummary,
      loading: loadingPassageSummary,
      error: errorPassageSummary,
      // tabContent: 'Coming Soon',
      tableFields: [
        {
          label: 'Trip',
          fieldName: 'trip_name',
          fieldLongName: 'trip_name_name',
          fieldType: 'text',
          tableDisplayOrder: '',
          tableDisplayPriority: '1',
        }, {
          label: 'Arrival Date',
          fieldName: 'arrival_date',
          fieldType: 'date',
          tableDisplayOrder: '',
          tableDisplayPriority: '1',
        // }, {
        //   label: 'Depature Date',
        //   fieldName: 'depature_date',
        //   fieldType: 'date',
        //   tableDisplayOrder: '',
        //   tableDisplayPriority: '1',
        }
      ]
    }
  ]

  useEffect(() => {
    dispatch(SailingLogLocationListAllAPI())
    dispatch(CountryCodesSelectOptionsAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      // dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      // dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
     if (errorLoadingList) {setServerErrorMessage(errorLoadingList)}
    if (errorCreate) {setServerErrorMessage(errorCreate)}
    if (errorUpdate) {setServerErrorMessage(errorUpdate)}
    if (errorGetDetails) {setServerErrorMessage(errorGetDetails)}
    if (errorDelete) {setServerErrorMessage(errorDelete)}
    if (paramsID) {
      dispatch(SailingLogPassageSummaryByLocationAPI('?listid=' + paramsID))
    }
  }, [successDelete, successUpdate,
    errorLoadingList,errorCreate,errorUpdate,errorGetDetails, errorDelete,paramsID])


  return (
    <ModuleHome
          formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
    moduleModalVisibility={props.moduleModalVisibility}
    setModuleModalVisibility={props.setModuleModalVisibility}
    
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      rowOnClickAction={'view'}
      add={true}
      edit={true}
      view={(dataitem) => navigate(moduleDefinitions.moduleLink + 'view:' + dataitem.id)}
      delete={(id) => dispatch(deleteAPI(id))}

      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      getDataList={listAPI}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}

      // View Page
      viewTabs={viewTabs} 
      headingName={dataItem.name}
   
        /> 
  )
}

export default SailingLogLocationScreen