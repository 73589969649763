//  React
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
// Components
import { ItemListAll } from '../../../APIs/genericAPIs'

const APIVariablesListAll = {
  APIRequest: 'SupportTicketStatusOptionsListRequest',
  APISuccess: 'SupportTicketStatusOptionsListSuccess',
  APIFail: 'SupportTicketStatusOptionsListFail',
  APIEndPoint: 'support/v1/ticket/statusoptions/list'
}

export const listAllSupportTicketStatusOptions = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const SupportTicketStatusOptionsListReducer = (state = { fieldOptionValues: [] }, action) => {
  switch (action.type) {
    case 'SupportTicketStatusOptionsListRequest':
      return {
        loading: true
      }

    case 'SupportTicketStatusOptionsListSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
      }

    case 'SupportTicketStatusOptionsListFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function SupportTicketStatusOptions() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listAllSupportTicketStatusOptions())
  }, [])

  return 
}

export default SupportTicketStatusOptions