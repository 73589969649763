import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { MdArrowBack, MdAdd } from 'react-icons/md';
//  Style
import style from '../../style/global.module.css';
import formstyle from '../../style/form.module.css';
// Components
import Table from '../tables/table'
import AddEditForm from '../forms/AddEditForm'
import ViewScreen from './viewScreen'
import AddButton from '../buttons/addButton'
import BackButton from '../buttons/backButton'
import { AddEditFormModal } from '../modals/addEditFormModal';

const ModuleHome = (props) => {
  const navigate = useNavigate()
  const paramsItemSplit = props.paramsItem.action.split(':')
  const paramsItemId = paramsItemSplit[1]
  const selectScreen = (
    props.moduleModalVisibility == true ? 'list':
    props.paramsItem.action === 'view' ? 'view' :
    props.paramsItem.action.includes('list') ? 'list' :
      props.paramsItem.action === 'add' ? 'Add' :
        props.paramsItem.action.startsWith('edit') ? 'Edit' :
          'view')

  const [formTypeAction, setFormTypeAction] = useState('');
  
  const [tableRowClickItemID, setTableRowClickItemID] = useState('');

  // console.log('Module Home - paramsItem.action:',props.paramsItem.action)
  // console.log('Module Home - paramsItem:',props.paramsItem)
  // console.log('Module Home - dataList',props.dataList)
  console.log('Module Home - selectScreen',selectScreen)
  // const {show, toggle } = useModal();
  console.log('Module Home -props.formModalVisibility',props.moduleDefinitions.moduleName,props.formModalVisibility)
  console.log('Module Home -props.moduleModalVisibility',props.moduleDefinitions.moduleName,props.moduleModalVisibility)

  const displayTable = () => {
    return (
      <div className={style.section}>
        <div className={`${style.responsivecontainer} ${style.center}`}>
            <BackButton
              formModalVisibility={props.formModalVisibility}
              setFormModalVisibility={props.setFormModalVisibility}
              moduleDefinitions={props.moduleDefinitions}
              moduleModalVisibility={props.moduleModalVisibility}
              setModuleModalVisibility={props.setModuleModalVisibility}
              resetForm={props.resetForm}
            />

             {/* <GenericButton
              name='Back'
              onClick={() => {
                // modalMode is for Modal Modules
                props.modalMode ? props.setFormModalVisibility() :
                  // Next conditions are for the add edit form
                  <> {props.resetForm && props.resetForm()}
                    {props.setFormModalVisibility ? props.setFormModalVisibility() : navigate(-1)}
                  </>
              }}
              icon={MdArrowBack}
              iconSize={'30px'}
              className='backButton'
            /> */}
            {props.add &&
            <AddButton 
              setFormTypeAction={setFormTypeAction}
              setFormModalVisibility={props.setFormModalVisibility}
              resetInitialValues={props.resetInitialValues}
            />
          
            // <GenericButton
            //   name='Add'
            //   onClick={() => {
            //     setFormTypeAction('Add');
            //     props.setFormModalVisibility(true);
            //     props.resetInitialValues && props.resetInitialValues()
            //   }}
            //   icon={MdAdd}
            //   iconSize={'30px'}
            //   className={`${'green'} ${'addEditButtons'}`}
            // />
          }
          <h1>
            {props.customModuleName ? props.customModuleName :
              props.moduleDefinitions.moduleName}
          </h1>

          {props.moduleDefinitions.moduleDescription}
          {props.errorLoadingList
            ? (<div className={formstyle.errormessage}>{props.errorLoadingList}</div>)
            : (
              <>{props.serverErrorMessage && <><div className={formstyle.errormessage}>{props.serverErrorMessage}</div><br></br></>}
                <Table
                  setFormTypeAction={setFormTypeAction}
                  setTableRowClickItemID={setTableRowClickItemID}
                  {...props}
                />
              </>
            )
          }
        </div>
      </div>
    )
  }

  const displayReadOnly = () => {
    return (
      <ViewScreen
        setFormTypeAction={setFormTypeAction}
        setTableRowClickItemID={setTableRowClickItemID}
        itemId={paramsItemId ? paramsItemId : tableRowClickItemID}
        {...props}
      />
    )
  }

  {/* Screen for users accessing the form using a direct link */ }
  const displayAddEditForm = () => {
    return (
      <AddEditForm
        formType={selectScreen}
        itemId={paramsItemId}
        {...props}
      />
    )
  }

  {/* Modal for users accessing the form via the moduleHome page */ }
  const displayAddEditFormModal = () => {
    return (
      <AddEditFormModal
        formType={formTypeAction}
        itemId={paramsItemId ? paramsItemId : tableRowClickItemID}
        {...props}
      >
      </AddEditFormModal>
    )
  }

  const selectDisplay = () => {
    if (selectScreen === 'list') { return displayTable() }
    if (selectScreen === 'view') { return displayReadOnly() }
    if (selectScreen === 'Add' || selectScreen === 'Edit') { return displayAddEditForm() }
  }

  return (
    <>
      {selectDisplay()}
      {displayAddEditFormModal()}
    </>
  )
}

export default ModuleHome;