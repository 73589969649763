import * as API from './APISettings';
import apiCall from './api';


AbortSignal.timeout ??= function timeout(ms) {
  const ctrl = new AbortController()
  setTimeout(() => ctrl.close(), ms)
  return ctrl.signal
}

export const ItemCreate = (API_Body,APIVariablesCreate,dispatch,getState ) => {
  const APIRequest = APIVariablesCreate.APIRequest
  const APISuccess = APIVariablesCreate.APISuccess
  const APIFail = APIVariablesCreate.APIFail
  const APIEndPoint = APIVariablesCreate.APIEndPoint
  const API_Method = 'POST'
  const {user_Login: { userInfo }} = getState()
  const API_Headers =  {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userInfo.access
  }

  dispatch({type: APIRequest})
    fetch(API.SERVER + APIEndPoint, {
      signal: AbortSignal.timeout(API.AbortSignalTimeOut),
      method: API_Method,
      headers: API_Headers,
      body: JSON.stringify(API_Body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            if (data.messages) {
              throw new Error(API.GenericError + response.status + ': ' + data.messages[0].message)
            } else if (data) {
              throw new Error('Please check the following fields for errors: ' +  Object.entries(data) )
            } else {
              throw new Error(API.GenericError + response.status)
            }
        })}
      })
      .then((data) => {
        dispatch({
          type: APISuccess,
          payload: data
        })
      })
      .catch((error) => {API.CatchErrorHandler(error, dispatch, APIFail)}
      );
}

export const ItemListAll = (APIVariablesListAll,dispatch,getState,query = '') => {
  // console.log('APIVariablesListAll,dispatch,getState,query',APIVariablesListAll,dispatch,getState,query)
  const { user_Login: { userInfo } } = getState()
  const APIRequest = APIVariablesListAll.APIRequest
  const APISuccess = APIVariablesListAll.APISuccess
  const APIFail = APIVariablesListAll.APIFail
  const APIEndPoint = APIVariablesListAll.APIEndPoint + query
  const API_Method = 'GET'
  const API_Headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + userInfo.access
  }

  dispatch({ type: APIRequest })

  fetch(API.SERVER + APIEndPoint, {
  // fetch(API.SERVER + APIEndPoint, {
    signal: AbortSignal.timeout(API.AbortSignalTimeOut),
    method: API_Method,
    headers: API_Headers,
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {   
        return response.json().then((data) => {
          if (data.messages) {
            throw new Error(API.GenericError + response.status + ': ' + data.messages[0].message)
          } else if (data) {
            throw new Error('Please check the following fields for errors: ' +  Object.entries(data) )
          } else {
            throw new Error(API.GenericError + response.status)
          }

        // return response.text().then((text) => {
        // console.log('response.status', response.status);
        // console.log('response', response);
        // if (response.status === 400) {
        //   console.log('Error 1',text,  response.status);
        //   throw new Error(text, API.Error400);
        // } else if (response.status === 401) {
        //   console.log('Error 401',text,  response.status);
        //   throw new Error(text, API.Error401);
        // } else if (response.status === 404) {
        //   console.log('Error 404',text,  response.status);
        //   throw new Error(text, API.Error404);
        // } else {
        //   console.log('Error 4',text,  response.status);
        //   throw new Error(text, API.ErrorUnkown);
        // }
      })

      }
    })
    .then((data) => {dispatch({type: APISuccess,payload: data})})
    .catch((error) => {API.CatchErrorHandler(error, dispatch, APIFail)});
}

export const ItemDetails = (itemId,APIVariablesItemDetails,dispatch, getState) => {
  const { user_Login: { userInfo } } = getState()
  const APIRequest = APIVariablesItemDetails.APIRequest
  const APISuccess = APIVariablesItemDetails.APISuccess
  const APIFail = APIVariablesItemDetails.APIFail
  const APIEndPoint = APIVariablesItemDetails.APIEndPoint + itemId
  const API_Method = 'GET'
  const API_Headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + userInfo.access
  }

  dispatch({ type: APIRequest })

  fetch(API.SERVER + APIEndPoint, {
    signal: AbortSignal.timeout(API.AbortSignalTimeOut),
    method: API_Method,
    headers: API_Headers
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.json().then((data) => {
          if (data.messages) {
            throw new Error(API.GenericError + response.status + ': ' + data.messages[0].message)
          } else if (data) {
            throw new Error('Please check the following fields for errors: ' +  Object.entries(data) )
          } else {
            throw new Error(API.GenericError + response.status)
          }

        // return response.json().then((data) => {
        //   console.log('response.status', response.status);
        //   console.log('response.statusText', response.statusText);
        //   console.log('response', response);
        //   if (response.status === 400) {
        //     console.log('Error 1', response.status);
        //     throw new Error(API.Error400);
        //   } else if (response.status === 401) {
        //     console.log('Error 2', response.status);
        //     throw new Error(API.Error401);
        //   } else if (response.status === 404) {
        //     console.log('Error 3', response.status);
        //     throw new Error(API.Error404);
        //   } else {
        //     console.log('Error 4', response.status);
        //     throw new Error(API.ErrorUnkown);
        //   }
        })
      }
    })
    .then((data) => {dispatch({type: APISuccess,payload: data})})
    .catch((error) => {API.CatchErrorHandler(error, dispatch, APIFail)});
}

// Put replaces the whole item, Patch updates fields sent 
export const ItemUpdate = (item,API_Body,APIVariablesUpdate,dispatch, getState) => {
  const { user_Login: { userInfo } } = getState()
  const APIRequest = APIVariablesUpdate.APIRequest
  const APISuccess = APIVariablesUpdate.APISuccess
  const APISuccess_2 = APIVariablesUpdate.APISuccess_2
  const APIFail = APIVariablesUpdate.APIFail
  const APIEndPoint = APIVariablesUpdate.APIEndPoint + item.id
  const API_Method = 'PUT'
  const API_Headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + userInfo.access
  }

  dispatch({type: APIRequest})

    fetch(API.SERVER + APIEndPoint, {
      signal: AbortSignal.timeout(API.AbortSignalTimeOut),
      method: API_Method,
      headers: API_Headers,
      body: JSON.stringify(API_Body)
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            if (data.messages) {
              throw new Error(API.GenericError + response.status + ': ' + data.messages[0].message)
            } else if (data) {
              throw new Error('Please check the following fields for errors: ' +  Object.entries(data) )
            } else {
              throw new Error(API.GenericError + response.status)
            }

            // throw new Error(UserErrorMessage);
          // return response.json().then((data) => {
          //   console.log('response.status', response.status);
          //   console.log('response', response);
          //   if (response.status === 400) {
          //     console.log('Error 1', response.status);
          //     throw new Error(data.detail);
          //   } else if (response.status === 401) {
          //     console.log('Error 2', response.status);
          //     throw new Error(API.Error401);
          //   } else if (response.status === 404) {
          //     console.log('Error 3', response.status);
          //     throw new Error(API.Error404);
          //   } else {
          //     console.log('Error 4', response.status);
          //     throw new Error(API.ErrorUnkown);
          //   }
          })
        }
      })
      .then((data) => {
        dispatch({
          type: APISuccess,
          payload: data
        })
        dispatch({
          type: APISuccess_2,
          payload: data
      })
      })
      .catch((error) => {API.CatchErrorHandler(error, dispatch, APIFail)}
      );
}

// Patch only updates the items sent. 
export const ItemUpdatePatch = (item,API_Body,APIVariablesUpdate,dispatch, getState) => {
  const { user_Login: { userInfo } } = getState()
  const APIRequest = APIVariablesUpdate.APIRequest
  const APISuccess = APIVariablesUpdate.APISuccess
  const APISuccess_2 = APIVariablesUpdate.APISuccess_2
  const APIFail = APIVariablesUpdate.APIFail
  const APIEndPoint = APIVariablesUpdate.APIEndPoint + item.id
  const API_Method = 'PATCH'
  const API_Headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + userInfo.access
  }

  dispatch({type: APIRequest})

    fetch(API.SERVER + APIEndPoint, {
      signal: AbortSignal.timeout(API.AbortSignalTimeOut),
      method: API_Method,
      headers: API_Headers,
      body: JSON.stringify(API_Body)
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            if (data.messages) {
              throw new Error(API.GenericError + response.status + ': ' + data.messages[0].message)
            } else if (data) {
              throw new Error('Please check the following fields for errors: ' +  Object.entries(data) )
            } else {
              throw new Error(API.GenericError + response.status)
            }
          })
        }
      })
      .then((data) => { dispatch({type: APISuccess,payload: data})
        dispatch({type: APISuccess_2, payload: data })
      })
      .catch((error) => {API.CatchErrorHandler(error, dispatch, APIFail)}
      );
}

export const ItemDelete = (itemId,APIVariablesDelete,dispatch, getState) => {
  const APIRequest = APIVariablesDelete.APIRequest
  const APISuccess = APIVariablesDelete.APISuccess
  const APIFail = APIVariablesDelete.APIFail
  const APIEndPoint = APIVariablesDelete.APIEndPoint + itemId
  const API_Method = 'DELETE'
  const {user_Login: { userInfo }} = getState()
  const API_Headers =  {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userInfo.access
  }

  dispatch({type: APIRequest})
    fetch(API.SERVER + APIEndPoint, {
      signal: AbortSignal.timeout(API.AbortSignalTimeOut),
      method: API_Method,
      headers: API_Headers,
    })
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          dispatch({
            type: APISuccess,
          })
        } else {
          return response.json().then((data) => {
            if (data.messages) {
              throw new Error(API.GenericError + response.status + ': ' + data.messages[0].message)
            } else if (data) {
              throw new Error('Please check the following fields for errors: ' +  Object.entries(data) )
            } else {
              throw new Error(API.GenericError + response.status)
            }

          // return response.json().then((data) => {
          //   console.log('response.status', response.status);
          //   console.log('response', response);
          //   if (response.status === 400) {
          //     console.log('Error 1', response.status);
          //     throw new Error(data.detail);
          //   } else if (response.status === 401) {
          //     console.log('Error 2', response.status);
          //     throw new Error(API.Error401);
          //   } else if (response.status === 404) {
          //     console.log('Error 3', response.status);
          //     throw new Error(API.Error404);
          //   } else {
          //     console.log('Error 4', response.status);
          //     throw new Error(API.ErrorUnkown);
          //   }
          })
        }
      })
      .catch((error) => {API.CatchErrorHandler(error, dispatch, APIFail)}
      );
}
