// https://levelup.gitconnected.com/lets-create-an-autocomplete-component-in-react-hook-70a3f20008d2
// Works with id name objects in an array
// React
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
//Style
import autocompleteStyle from '../../../style/autocomplete.css'
import formstyle from '../../../style/form.module.css'
//Components
import Loader from '../../loading/spinnerSmall';
import ListOfSelectValues from './listOfSelectValues'
import { Modal } from '../../modals/moduleModal';
import useModal from '../../../hooks/useModal';

const Autocomplete = (props) => {
  const navigate = useNavigate()
  const [active, setActive] = useState(0);
  const [filtered, setFiltered] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [input, setInput] = useState(props.value);
  const dataList = useSelector(state => state[props.options])
  const { show, toggle } = useModal();

  if (dataList !== undefined) {
    var {
      loading: loading,
      error: error, } = dataList
  }
  // console.log('openAddNewModule', props.openAddNewModule)

  // const getValueName = (value) => { 
  //   if(dataList.fieldOptionValues && value ){
  //   console.log('value',value)
  //   console.log('fieldName',props.fieldName)
  //   console.log('dataList.fieldOptionValues',dataList.fieldOptionValues)

  //   const valueName = dataList.fieldOptionValues.find(e => e.id == value) 
  //   setInput(valueName.name)

  //   console.log('valueName2',valueName)
  //   console.log('valueName.name',valueName.name)
  //   }
  // }

  // useEffect(() => {
  //   if (props.fieldValueName) {setInput(props.fieldValueName)}
  // }, [props.fieldValueName])

  // console.log('props.value',props.value)  

  useEffect(() => {
    setInput(props.value)
    // console.log('dataList.fieldOptionValues',dataList.fieldOptionValues)   
    // console.log('props.value',props.value)  
    if (props.multipleSelect) {
      setInput('')
    }else{
    if (dataList.fieldOptionValues && props.value) {
      const valueName = dataList.fieldOptionValues.find(e => e.id == props.value)
      setInput(valueName.name)
    }
  }
  }, [props.value, dataList])

  const AutocompleteOnChange = e => {
    const suggestions = dataList.fieldOptionValues
    const input = e.currentTarget.value;

    // console.log('props.fieldValueName',props.fieldValueName)
    // console.log('suggestions', suggestions)
    // console.log('e.currentTarget.value', e.currentTarget.value)
    // console.log('input', input)

    // Auto complete to match any part of the word
    // const newFilteredSuggestions = suggestions.filter(
    //   suggestion =>
    //     suggestion.toLowerCase().indexOf(input.toLowerCase()) > -1
    // );

    // Auto complete to exactly first letter in order from start of the word
    const newFilteredSuggestions =
      suggestions.filter((suggestion) => {
        const match = suggestion.name.substr(0, input.length);
        return match && match.toLowerCase() === input.toLowerCase();
      })

    setActive(0);
    setFiltered(newFilteredSuggestions);
    setIsShow(true);
    setInput(e.currentTarget.value)
  };

  const actions = (id, name) => {
    // console.log('actions props', props)
    // console.log('actions: id,name', id, name)
    setActive(0);
    setIsShow(false);
    setInput(name);
    if (props.filtermode === true) {
      props.handleSetFilterValues(props.fieldName, props.fieldType, id, name)
    } else {
      if (props.multipleSelect) {
        // console.log('multipleSelect mode', props.fieldName, id)
        props.handleMultiSelectAdd(props.fieldName, id)
      } else {
      props.handleChange(props.fieldName, id)
    }}
  }

  const deleteMultiSelectFieldValue = (e, value) => {
    e.preventDefault();
    // console.log('deleteMultiSelectFieldValue', props.fieldName, value)
    props.handleMultiSelectDelete(props.fieldName, value)
  }

  const onClick = (id, name) => {
    setFiltered([]);
    actions(id, name)
  };

  const onKeyDown = e => {
    if (e.keyCode === 13) { // enter key
      actions(filtered[active].id, filtered[active].name)
    }
    else if (e.keyCode === 38) { // up arrow
      return (active === 0) ? null : setActive(active - 1);
    }
    else if (e.keyCode === 40) { // down arrow
      return (active - 1 === filtered.length) ? null : setActive(active + 1);
    }
    else if (e.keyCode === 9) { // tab
      if (isShow) {
        actions(filtered[active].id, filtered[active].name)
      }
    }
  };

  const renderAutocomplete = () => {
    if (isShow && input) {
      if (filtered.length) {
        return (
          <ul className="autocomplete">
            {filtered.map((suggestion, index) => {
              let className;
              if (index === active) {
                className = "active";
              }
              return (
                <li
                  className={className}
                  key={suggestion.id}
                  onClick={e => onClick(suggestion.id, suggestion.name)}>
                  {suggestion.name}
                </li>
              );
            })}
          </ul>
        );
      } else {
        if (props.filtermode === true) {
          return (
            <div className={autocompleteStyle.noAutocomplete}>
              <em>No results found</em>
            </div>
          );
        } else {
          return (
            <>
              <div className={autocompleteStyle.noAutocomplete}>
                <em>No results found</em>
                {props.openAddNewModule &&
                  <button
                    className={`${formstyle.smallButton} ${formstyle.blue} containter`}
                    onClick={() => navigate(props.addNewLink)}
                  // onClick={toggle}
                  >Add New</button>
                }
              </div>
              <Modal
                showModal={show}
                setFormModalVisibility={toggle}
                openAddNewModule={props.openAddNewModule}
              />
            </>
          );
        }

      }
    }
    return <></>;
  }

  const clearInputField = () => {
    // console.log('clearInputField props', props)
    if (!props.multipleSelect) { props.handleChange(props.fieldName, '') }
    setInput('')
  }

// Multi select logic
// console.log('dataList.fieldOptionValues',dataList.fieldOptionValues)
//   const displayValues = (
//     dataList.fieldOptionValues === undefined ? '' :
//       props.addFormConditionalSelectBasedOn ?
//         dataList.fieldOptionValues
//           .filter((field) => field[props.addFormConditionalSelectLinkField] == props.conditionalSelectFieldValue)
//           .map((field => ({ id: field.id, name: field.name })))
//         : dataList.fieldOptionValues
//   )

//   if (dataList.fieldOptionValues && props.value) {
//     const valueName = dataList.fieldOptionValues.find(e => e.id == props.value)
//     setInput(valueName.name)
//   }

  const fieldName = (value) => {
    // console.log('autocomplete - fieldName - value', value)
    // console.log('autocomplete - fieldName - dataList.fieldOptionValues', dataList.fieldOptionValues)
    if (dataList.fieldOptionValues === undefined) { return 'No select values defined' }
    else {
      const selectValueItem = dataList.fieldOptionValues.find(e => e.id === value)
      // console.log('name', selectValueItem)
      // console.log('selectValues', selectValues)
      if (selectValueItem === undefined) { return '' } else { return selectValueItem.name }
    }
  }

  return (
    loading ? <Loader /> :
      dataList === undefined ?
        <p className={formstyle.errormessage}
          style={{ margin: '0px' }}>
          System Error: Not able to get any values</p> :

        !dataList.fieldOptionValues ?
          <p className={formstyle.warningmessage}
            style={{ margin: '0px' }}
          >
            Warning: No Select Values</p> :
          <>
            <div
              className={formstyle.wrapper}
              style={{
                position: 'relative',
                // right:'5px'
              }}

            >
              {props.multipleSelect ?
                <input
                  className={formstyle.autocompleteInput}
                  type="text"
                  placeholder={props.placeholder}
                  // placeholder="Multiselect"
                  onChange={AutocompleteOnChange}
                  onKeyDown={onKeyDown}
                  value={input}
                /> :
                <input
                  className={formstyle.autocompleteInput}
                  type="text"
                  placeholder={props.placeholder}
                  // placeholder="Normal Field"
                  onChange={AutocompleteOnChange}
                  onKeyDown={onKeyDown}
                  value={input}
                />
              }
              {input &&
                <span
                  style={{
                    position: 'absolute',
                    top: '-4px',
                    right: '12px',
                    fontSize: '24px',
                    color: 'grey',
                    cursor: 'pointer',
                  }}
                  onClick={clearInputField}>&times;
                </span>
              }

            </div>
            {renderAutocomplete()}
            {props.multipleSelect &&
            <ListOfSelectValues
             {...props}
              deleteMultiSelectFieldValue={deleteMultiSelectFieldValue}
              fieldName={fieldName}
            />
}
          </>
  );
}
export default Autocomplete;