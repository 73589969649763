import style from '../../style/global.module.css';
import formstyle from '../../style/form.module.css';
import { useNavigate } from 'react-router-dom'
import { MdArrowBack } from 'react-icons/md';

const FixedContainer = (props) => {
    const navigate = useNavigate()
  return (
    <div className={style.section}>
      <div className={style.fixedcontainer}>
        <button
          className={formstyle.backButton}
          onClick={() => navigate(-1)}>
          <MdArrowBack />Back</button>
        <h2 className={style.center}>{props.formType} {props.heading}</h2>
        {props.contents}
      </div>
    </div>
  );
};
export default FixedContainer;