// import { useState } from 'react';
import { useState } from 'react';
import { MdContentCopy,MdVisibility, MdOutlineFilterAlt, MdSort, MdReorder, MdUpload } from 'react-icons/md';
import formstyle from '../../style/form.module.css';
import global from '../../style/global.module.css';
// Components
import FilterButton from '../buttons/filterButton'
import DropDownButton from '../buttons/dropDownButton'
import SearchField from '../fields/searchField'
import InputFields from '../fields/inputFields/inputFields';
import { Label } from '../fields/label';
import HorizontalRadioSelect from '../switches/horizontalRadioSwitch'
import GenericButton from '../buttons/genericButton';

import FilterCheckbox from '../fields/filterFields/filterCheckbox';
import FilterDate from '../fields/filterFields/filterDate'
import FilterNumber from '../fields/filterFields/filterNumber'
import FilterSelect from '../fields/filterFields/filterSelect'
import FilterText from '../fields/filterFields/filterText'
import FilterTime from '../fields/filterFields/filterTime'

// Hooks
import HandleSort from '../../hooks/handleSort';
import HandleGroup from '../../hooks/handleGroup';
import { handleExport } from '../../hooks/handleExport'

const TableControls = (props) => {
  // console.log('table controls - loadingList', props.loadingList)
  // console.log('props', props)

  const [viewOptionsDropDownButton, setViewOptionsDropDownButton] = useState(false);
  const [sortByDropDownButton, setSortByDropDownButton] = useState(false);
  const [handleByDropDownButton, setHandleByDropDownButton] = useState(false);

  const filterFields = (props) => {
    return (
      <>
        {props.moduleFields
          .sort((a, b) => a.editFormOrder - b.editFormOrder)
          .map((column) => (column.tableFilterHide === undefined &&
            <div key={column.fieldName}>
              {column.fieldType === 'autoCompleteText' &&
                <div style={{ paddingBottom: '7px' }}>
                  <Label label={column.label} />
                  <InputFields
                    placeholder='Type to filter...'
                    fieldName={column.fieldName}
                    fieldType={column.fieldType}
                    options={column.options}
                    value={props.filterValue(column.fieldName)}
                    fieldValueName={props.filterValueName(column.fieldName)}
                    // multiple='multiple'
                    fieldIsChecked={props.fieldIsChecked}
                    filterIsChecked={props.filterIsChecked}
                    onClick={e => props.handleClearFilter(column.fieldName)}
                    onChange={e => props.handleSetFilterValues(column.fieldName, column.fieldType, e.target.value)}
                    handleSetFilterValues={props.handleSetFilterValues}
                    filtermode
                  />
                </div>}

              {column.fieldType === 'checkbox' &&
                <div style={{ paddingBottom: '7px' }}>
                  <Label label={column.label} />
                  <FilterCheckbox
                    {...props}
                    id={1}
                    // onChange={} this is missing!!!!!!
                    fieldName={column.fieldName}
                    fieldType={column.fieldType}
                    setClassFor='tableControl'
                  />
                </div>}
              {props.viewType === 'List' && <>
                {column.fieldType === 'date' &&
                  <div style={{ paddingBottom: '7px' }}>
                    <Label label={column.label} />
                    <FilterDate
                      {...props}
                      selectOptions={props.handleSelectOptions(props.datalist, column.fieldName, props.tableHeaderSelectOptions(column))}
                      fieldName={column.fieldName}
                      fieldType={column.fieldType}
                      setClassFor='tableControl'
                    />
                  </div>}
                {column.fieldType === 'time' &&
                  <div style={{ paddingBottom: '7px' }}>
                    <Label label={column.label} />
                    <FilterTime
                      {...props}
                      fieldName={column.fieldName}
                      fieldType={column.fieldType}
                      setClassFor='tableControl'
                    />
                  </div>}
              </>}

              {(column.fieldType === 'select' ||
                column.fieldType === 'selectfromdb' ||
                column.fieldType === 'selectnoid') &&
                <div key={column.fieldName}
                  style={{ paddingBottom: '7px' }}>
                  <Label label={column.label} />
                  <FilterSelect
                    selectOptions={
                      props.handleSelectOptions(
                        props.datalist,
                        column.fieldName,
                        props.tableHeaderSelectOptions(column))}
                    {...props}
                    fieldName={column.fieldName}
                    fieldType={column.fieldType}
                    setClassFor='tableControl'
                  />
                </div>}

              {column.fieldType === 'text' &&
                <div key={column.fieldName}
                  style={{ paddingBottom: '7px' }}>
                  <Label label={column.label} />
                  <FilterText
                    {...props}
                    fieldName={column.fieldName}
                    fieldType={column.fieldType}
                    setClassFor='tableControl'
                  />
                </div>}
              {(column.fieldType === 'number' || column.fieldType === 'currency') &&
                <div key={column.fieldName}
                  style={{ paddingBottom: '7px' }}>
                  <Label label={column.label} />
                  <FilterNumber
                    {...props}
                    fieldName={column.fieldName}
                    fieldType={column.fieldType}
                    setClassFor='tableControl'
                  />
                </div>}

            </div>
          ))
        }
      </>
    )
  }

  const sortByFields = (
    props.moduleFields
      .filter((column) => column.tableFilterHide != true)
      // .sort((a, b) => a - b)
      .map((column) => (
        <HandleSort
          {...props}
          setIsDropDownButtonActive={setSortByDropDownButton}
          key={column.fieldName}
          fieldName={column.fieldName}
          sortName={'By ' + column.label}
        />
      )))

  const groupByFields = (
    props.moduleFields
      .filter((column) => column.tableFilterHide != true)
      .map((column) => (
        <HandleGroup
          {...props}
          setIsDropDownButtonActive={setHandleByDropDownButton}
          key={column.fieldName}
          fieldName={column.fieldName}
          sortName={'By ' + column.label}
        />
      )))

  const viewOptions = (
    props.moduleDefinitions.viewOptions &&
    props.moduleDefinitions.viewOptions.map((type) =>
      <a
        href="#"
        onClick={() => { 
          props.setViewType(type); 
          setViewOptionsDropDownButton(false);

          //If Month set database filter to month
          type === 'Month' ? props.setDataBaseFilter('Month') : 
          //If not month, set database filter to default filter option 
          props.setDataBaseFilter(props.dataBaseFilterOptions
            .filter((item)=> item.default===true )
            .map((item) => item.name));
         }}
      >
        {type}
      </a>
    ))

  return (
    <>
      <SearchField
        datalist={props.datalist}
        searchResults={props.searchResults}
        setSearchResults={props.setSearchResults}
        moduleName={props.moduleDefinitions.moduleName}
      />
      <div>
      {props.moduleDefinitions.allowCopy &&
          <GenericButton
            name='Copy'
            icon={MdContentCopy}
            iconSize={'25px'}
            onClick={() => (console.log('copy'))}
            disabled={props.loadingList}
          >
          </GenericButton>
        }
        
        {props.moduleDefinitions.viewOptions &&
          <DropDownButton
            isDropDownButtonActive={viewOptionsDropDownButton}
            setIsDropDownButtonActive={setViewOptionsDropDownButton}
            className={formstyle.blue}
            contents={viewOptions}
            icon={MdVisibility}
            name='View'
            loadingList={props.loadingList}
          >
          </DropDownButton>
        }
        <FilterButton
          className={formstyle.blue}
          contents={filterFields(props)}
          // onClick={props.filter}
          icon={MdOutlineFilterAlt}
          name='Filters'
          actionName='Apply Filters'
          handleResetAllFilters={props.handleResetAllFilters}
          loadingList={props.loadingList}
        >
        </FilterButton>
        {props.viewType === 'List' &&
          <>
            <DropDownButton
              isDropDownButtonActive={sortByDropDownButton}
              setIsDropDownButtonActive={setSortByDropDownButton}
              className={formstyle.blue}
              contents={sortByFields}
              icon={MdSort}
              name='Sort'
              loadingList={props.loadingList}
            >
            </DropDownButton>

            <DropDownButton
              isDropDownButtonActive={handleByDropDownButton}
              setIsDropDownButtonActive={setHandleByDropDownButton}
              className={formstyle.blue}
              contents={groupByFields}
              icon={MdReorder}
              name='Group'
              loadingList={props.loadingList}
            >
            </DropDownButton>

      <GenericButton
        name='Export'
        onClick={() => handleExport(props.filteredData, props.moduleDefinitions.moduleName)}
        icon={MdUpload}
        iconSize={'25px'}
        disabled={props.loadingList}
        className='hideOnMobile'
      />

            {/* <button
              className={formstyle.blue + ' ' + global.hideOnMobile}
              onClick={() => handleExport(props.filteredData, props.moduleDefinitions.moduleName)}
              disabled={props.loadingList}

            >
              <MdUpload />
              Export
            </button> */}
        <br></br>
        {props.loadingList === false ?
          props.datalist === undefined ||
            // props.datalist.length === 0 ? '' :
            // !props.filteredData ? <p>Current filters are not returning any data.</p> :
              props.filteredData.length === 0 ? <>
                {props.dataBaseFilterOptions &&
                      props.viewType === 'List' ? 
                      <>
                        <HorizontalRadioSelect
                          switchValues={props.dataBaseFilterOptions}
                          selected={props.dataBaseFilter}
                          onChange={props.setDataBaseFilter} />
                      </>
                      : <span>Current filters are </span>
                    }
                    <span> not returning any data.</span>
                    </>
                : <span > Showing {props.filteredData.length} out of {props.datalist.length} items
                  {props.dataBaseFilterOptions &&
                    props.viewType === 'List' &&
                    <>
                      <span> from  </span>
                      <HorizontalRadioSelect
                        switchValues={props.dataBaseFilterOptions}
                        selected={props.dataBaseFilter}
                        onChange={props.setDataBaseFilter} />
                      <span>  data. </span>
                    </>

                  }
                </span>
          : ''}
                    </>}
        {props.datalist === undefined ? '' :
          props.datalist.length !== props.filteredData.length && <>
            <button onClick={props.handleResetAllFilters} className={formstyle.orange}>Reset all filters</button>
          </>}
        {props.groupBy.length > 1 && <button onClick={() => props.setGroupBy('')} className={formstyle.orange}>Remove Grouping</button>}
      </div>


    </>
  );
};

export default TableControls;