//  React
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
// Components
import { ItemListAll } from '../../../APIs/genericAPIs'

const APIVariablesListAll = {
  APIRequest: 'SupportTicketMobileTypeListRequest',
  APISuccess: 'SupportTicketMobileTypeListSuccess',
  APIFail: 'SupportTicketMobileTypeListFail',
  APIEndPoint: 'support/v1/ticket/mobiletype/list'
}

export const listAllSupportTicketMobileType = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const SupportTicketMobileTypeListReducer = (state = { fieldOptionValues: [] }, action) => {
  switch (action.type) {
    case 'SupportTicketMobileTypeListRequest':
      return {
        loading: true
      }

    case 'SupportTicketMobileTypeListSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
      }

    case 'SupportTicketMobileTypeListFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function SupportTicketMobileType() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listAllSupportTicketMobileType())
  }, [])

  return 
}

export default SupportTicketMobileType