//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

// Components
import ModuleHome from '../../../components/containers/moduleHome'
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'

export const moduleDefinitions = {
  moduleName: 'Qualifications',
  moduleNameSingular: 'Qualification',
  moduleLink: '/fun/sailinglog/settings/qualifications/',
  thOptionsWidth: '115px',
}

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
    fieldErrorMessage = ""

  switch (fieldName) {

    case "name":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Name is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break


    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

///////////// API Config /////////////////////////

const APIVariablesCreate = {
  APIRequest: 'SailingLogQualificationCreateRequest',
  APISuccess: 'SailingLogQualificationCreateSuccess',
  APIFail: 'SailingLogQualificationCreateFail',
  APIEndPoint: 'sailinglog/v1/qualification/add',
}
const APIVariablesListAll = {
  APIRequest: 'SailingLogQualificationListRequest',
  APISuccess: 'SailingLogQualificationListSuccess',
  APIFail: 'SailingLogQualificationListFail',
  APIEndPoint: 'sailinglog/v1/qualification/list'
}
const APIVariablesSelectOptions = {
  APIRequest: 'SailingLog_QualificationSelectOptionsRequest',
  APISuccess: 'SailingLog_QualificationSelectOptionsSuccess',
  APIFail: 'SailingLog_QualificationSelectOptionsFail',
  APIEndPoint: 'sailinglog/v1/qualification/selectoptions'
}
const APIVariablesItemDetails = {
  APIRequest: 'SailingLogQualificationDetailsRequest',
  APISuccess: 'SailingLogQualificationDetailsSuccess',
  APIFail: 'SailingLogQualificationDetailsFail',
  APIEndPoint: 'sailinglog/v1/qualification/'
}
const APIVariablesUpdate = {
  APIRequest: 'SailingLogQualificationUpdateRequest',
  APISuccess: 'SailingLogQualificationUpdateSuccess',
  APISuccess_2: 'SailingLogQualificationDetailsSuccess',
  APIFail: 'SailingLogQualificationUpdateFail',
  APIEndPoint: 'sailinglog/v1/qualification/'
}
const APIVariablesDelete = {
  APIRequest: 'SailingLogQualificationDeleteRequest',
  APISuccess: 'SailingLogQualificationDeleteSuccess',
  APIFail: 'SailingLogQualificationDeleteFail',
  APIEndPoint: 'sailinglog/v1/qualification/'
}

export const SailingLogQualificationCreateAPI = (item) => async (dispatch, getState) => {
  const API_Body = {
    name: item.name,
    description: item.description,
  }
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const SailingLogQualificationListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}
export const SailingLogQualificationSelectOptionsAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesSelectOptions, dispatch, getState)
}

export const SailingLogQualificationDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const SailingLogQualificationUpdateAPI = (item) => async (dispatch, getState) => {
  const API_Body = {
    name: item.name,
    description: item.description,
  }
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const SailingLogQualificationDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

///////////// REDUCERS/////////////////////////
// State is an empty object
export const SailingLogQualificationCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SailingLogQualificationCreateRequest':
      return {
        loading: true
      }

    case 'SailingLogQualificationCreateSuccess':
      return {
        loading: false,
        success: true,
        SailingLogQualification: action.payload
      }

    case 'SailingLogQualificationCreateFail':
      return {
        loading: false,
        error: action.payload
      }

    case 'SailingLogQualificationCreateReset':
      return {}


    default:
      return state
  }
}

export const SailingLogQualificationUpdateReducer = (state = { SailingLogQualification: {} }, action) => {
  switch (action.type) {
    case 'SailingLogQualificationUpdateRequest':
      return { loading: true }

    case 'SailingLogQualificationUpdateSuccess':
      return { loading: false, success: true }

    case 'SailingLogQualificationUpdateFail':
      return { loading: false, error: action.payload }

    case 'SailingLogQualificationUpdateReset':
      return { SailingLogQualification: {} }

    default:
      return state
  }
}

export const SailingLogQualificationDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SailingLogQualificationDeleteRequest':
      return { loading: true }

    case 'SailingLogQualificationDeleteSuccess':
      return {
        loading: false,
        success: true
      }

    case 'SailingLogQualificationDeleteFail':
      return { loading: false, error: action.payload }

    case 'SailingLogQualificationDeleteReset':
      return {}

    default:
      return state
  }
}

export const SailingLogQualificationDetailsReducer = (state = { loading: true, SailingLogQualification: [] }, action) => {
  switch (action.type) {
    case 'SailingLogQualificationDetailsRequest':
      return {
        ...state,
        loading: true
      }

    case 'SailingLogQualificationDetailsSuccess':
      return {
        loading: false,
        SailingLogQualification: action.payload
      }

    case 'SailingLogQualificationDetailsFail':
      return {
        loading: false,
        error: action.payload
      }


    default:
      return state
  }
}


export const SailingLogQualificationListReducer = (state = { SailingLogQualification: [] }, action) => {
  switch (action.type) {
    case 'SailingLogQualificationListRequest':
      return {
        loading: true
      }

    case 'SailingLogQualificationListSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
        SailingLogQualification: action.payload
      }

    case 'SailingLogQualificationListFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const SailingLogQualificationSelectOptionsReducer = (state = { SailingLogQualification: [] }, action) => {
  switch (action.type) {
    case 'SailingLogQualificationSelectOptionsRequest':
      return {
        loading: true
      }

    case 'SailingLogQualificationSelectOptionsSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
      }

    case 'SailingLogQualificationSelectOptionsFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}


function SailingLogQualificationScreen(props) {
  const createAPI = SailingLogQualificationCreateAPI
  const listAPI = SailingLogQualificationListAllAPI
  const detailsAPI = SailingLogQualificationDetailsAPI
  const updateAPI = SailingLogQualificationUpdateAPI
  const deleteAPI = SailingLogQualificationDeleteAPI

  const resetCreate = 'SailingLogQualificationCreateReset'
  const resetUpdate = 'SailingLogQualificationUpdateReset'
  const resetViewDetails = 'SailingLogQualificationDetailsReset'
  const resetDelete = 'SailingLogQualificationDeleteReset'

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()
  const paramsItemSplit = paramsItem.action.split(':')
  const paramsID = paramsItemSplit[1]

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const SailingLogQualificationCreate = useSelector(state => state.SailingLogQualificationCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = SailingLogQualificationCreate

  const SailingLogQualificationList = useSelector(state => state.SailingLogQualificationList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    SailingLogQualification: dataList } = SailingLogQualificationList

  const SailingLogQualificationDetails = useSelector(state => state.SailingLogQualificationDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    SailingLogQualification: dataItem } = SailingLogQualificationDetails

  const SailingLogQualificationUpdate = useSelector(state => state.SailingLogQualificationUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = SailingLogQualificationUpdate

  const SailingLogQualificationDelete = useSelector(state => state.SailingLogQualificationDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = SailingLogQualificationDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingList || loadingDetails
    )
  )

   const moduleFields = [
    {
      label: 'Name',
      mandatory: 'true',
      fieldName: 'name',
      fieldType: 'text',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '1',
    }, {
      label: 'Description',
      fieldName: 'description',
      fieldType: 'textarea',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '1',
    },
  ]
  
  useEffect(() => {
    dispatch(SailingLogQualificationListAllAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }

  }, [successDelete, successUpdate,
    errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete,paramsID])


  return (
    <ModuleHome
        formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
    moduleModalVisibility={props.moduleModalVisibility}
    setModuleModalVisibility={props.setModuleModalVisibility}

    paramsItem={paramsItem}
    moduleDefinitions={moduleDefinitions}
    moduleFields={moduleFields}

    //Table Data
    dataList={dataList}
    loadingList={loadingList}
    errorLoadingList={errorLoadingList}

    rowOnClickAction={'edit'}
    add={true}
    edit={true}
    view={false}
    delete={(id) => dispatch(deleteAPI(id))}

    //AddEditForm
    fieldValidation={fieldValidation}

    loading={loading}
    serverErrorMessage={serverErrorMessage}
    setServerErrorMessage={setServerErrorMessage}

    getDataList={listAPI}

    itemDetails={detailsAPI}
    dataItem={dataItem}
    itemDetailsReset={resetViewDetails}

    itemCreate={createAPI}
    successCreate={successCreate}
    resetCreate={resetCreate}

    itemUpdate={updateAPI}
    successUpdate={successUpdate}
    resetUpdate={resetUpdate}

    itemDelete={deleteAPI}
    successDelete={successDelete}
    resetDelete={resetDelete}

    //View Page
    // viewTabs={viewTabs} 
    // headingName={dataItem.name}
 
      /> 
  )
}

export default SailingLogQualificationScreen
