//  ReactTicketType
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
// Components
import { ItemListAll } from '../../../APIs/genericAPIs'

const APIVariablesListAll = {
  APIRequest: 'SupportTicketApplicationOptionsListRequest',
  APISuccess: 'SupportTicketApplicationOptionsListSuccess',
  APIFail: 'SupportTicketApplicationOptionsListFail',
  APIEndPoint: 'support/v1/ticket/applicationoptions/list'
}

export const listAllSupportTicketApplicationOptions = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const SupportTicketApplicationOptionsListReducer = (state = { fieldOptionValues: [] }, action) => {
  switch (action.type) {
    case 'SupportTicketApplicationOptionsListRequest':
      return {
        loading: true
      }

    case 'SupportTicketApplicationOptionsListSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
      }

    case 'SupportTicketApplicationOptionsListFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function SupportTicketApplicationOptions() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listAllSupportTicketApplicationOptions())
  }, [])

  return 
}

export default SupportTicketApplicationOptions