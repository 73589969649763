import { MdOutlineModeEditOutline, MdCheckCircle, MdCheck, MdDeleteForever, MdVisibility, MdCancel } from 'react-icons/md';

export const TableRowOptionView = (props) => {
  return (
    <span
      onClick={props.onClick}
      title="View">
      <i
        // style={{ color: "#4169e1" }} //dark blue
      ><MdVisibility /> </i>
    </span>
  );
};

export const TableRowOptionComplete = (props) => {
  return (
    <span
      onClick={props.onClick}
      title="Complete">
      <i
        style={{ color: "#5cb85c" }} //green
      ><MdCheck /> </i>
    </span>
  );
};

export const TableRowOptionAction = (props) => {
  return (
    <span
      onClick={props.onClick}
      title={props.actionTitle}>
      <i
        style={{ color: "#5cb85c" }} //green
      >{props.actionIcon}</i>
    </span>
  );
};

export const TableRowOptionEdit = (props) => {
  return (
    <span
      onClick={props.onClick}
      title="Edit">
      <i
        // style={{ color: "#4169e1" }} //dark blue
      ><MdOutlineModeEditOutline /> </i>
    </span>
  );
};

export const TableRowOptionDelete = (props) => {
  return (
    
    <span
      onClick={props.onClick}
      title="Delete">
      <i
      style={{ color: '#d9534f' }} //red
      ><MdDeleteForever /></i>
    </span>
  );
};

export const TableRowOptionSave = (props) => {
  return (
    <span
      onClick={props.onClick}
      title="Save">
      <i
      style={{ color: "#5cb85c" }} // green
      ><MdCheckCircle /></i>
    </span>
  );
};


export const TableRowOptionCancel = (props) => {
  return (
    <span
      onClick={props.onClick}
      title="Cancel">
      <i
        style={{ color: '#d9534f' }}  //red
      ><MdCancel /></i>
    </span>
  );
};

