import style from '../../style/global.module.css';
import BackButton from '../buttons/backButton'

const ResponsiveContainer = (props) => {
  return (
    <div className={style.section}>
      <div className={style.responsivecontainer}>
        <BackButton />
        <h2 className={style.center}>{props.formType} {props.heading}</h2>
        {props.contents}
      </div>
    </div>
  );
};
export default ResponsiveContainer;