import * as Constants from '../modules/user/userConstants'
import {ItemCreate, ItemListAll,ItemDetails,ItemUpdate, ItemDelete} from './genericAPIs'

import * as API from './APISettings';
import jwt_decode from 'jwt-decode';

const APIVariablesItemDetails = {
  APIRequest: Constants.USER_PROFILERequest ,
  APISuccess: Constants.USER_PROFILESuccess ,
  APIFail: Constants.USER_PROFILEFail ,
  APIEndPoint: 'auth/user/profile/view/'
}

const APIVariablesUpdate = {
  APIRequest: Constants.USERUpdate_PASSWORDRequest ,
  APISuccess: Constants.USERUpdate_PASSWORDSuccess ,
  APISuccess_2: '',
  APIFail: Constants.USERUpdate_PASSWORDFail ,
  APIEndPoint: 'auth/user/password/update/'
}


// POST REQUESTS
export const signin = (email, password) => async (dispatch) => {
   
  const APIRequest = Constants.USER_LOGINRequest
  const APISuccess = Constants.USER_LOGINSuccess
  const APIFail = Constants.USER_LOGINFail
  const APIEndPoint = 'auth/jwt/login/'
  const API_Method = 'POST'
  // const {user_Login: { userInfo }} = getState()
  const API_Headers =  {
      'Content-Type': 'application/json',
      // 'Authorization': 'Bearer ' + userInfo.token
  }
  const API_Body = JSON.stringify({
    username: email,
    password: password,
    // returnSecureToken: true,
      })

  // Reusable logic - no custom values below this line. 
  dispatch({type: APIRequest})

    fetch(API.SERVER + APIEndPoint, {
      signal: AbortSignal.timeout(5000),
      method: API_Method,
      headers: API_Headers,
      body: API_Body,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        else {
          return response.json().then((data) => {
            console.log('response.status', response.status);
            console.log('response', response);
            if (response.status === 400) {
              console.log('Error 1', response.status);
              throw new Error('The email and password combiniation you have entered is not valid. Please try again.');
            } else if (response.status === 401) {
              console.log('Error 2', response.status);
              throw new Error(API.Error400);
            } else if (response.status === 404) {
              console.log('Error 3', response.status);
              throw new Error(API.Error404);
            } else {
              console.log('Error 4', response.status);
              throw new Error(API.ErrorUnkown);
            }
          })
        }
      })
      .then((data) => {
        const decoded = jwt_decode(data.access);
        // console.log('decoded',decoded)
        const store = Object.assign(decoded,data)
        localStorage.setItem('userInfo', JSON.stringify(store))  //SignIn
        // console.log('store',store)

        const accessTokenExpiration = decoded.exp
        const accessTokenExpirationTime = accessTokenExpiration * 1000
        // const step2 = Date.now() 
        // const step3 = step1 - step2
        localStorage.setItem('accessTokenExpirationTime', accessTokenExpirationTime) //SignIn

        dispatch({
          type: APISuccess,
          payload: store
        })
      })
      .catch((error) => {API.CatchErrorHandler(error, dispatch, APIFail)});
}

export const signout = (source) => (dispatch) => {
  console.log('Signed out', source)
  localStorage.removeItem('userInfo')
  dispatch({ type: Constants.USER_LOGOUT   })
  dispatch({ type: Constants.USER_PROFILEReset })
}

export const sendRefreshToken = (refreshToken) => async (dispatch) => {
  console.log('sendRefreshToken -triggered')
  const APIRequest = Constants.USER_REFRESHRequest
  const APISuccess1 = Constants.USER_REFRESHSuccess
  const APISuccess2 = Constants.USER_LOGINSuccess
  const APIFail = Constants.USER_REFRESHFail
  const APIEndPoint = 'auth/token/refresh/'
  const API_Method = 'POST'
  const API_Headers =  {
      'Content-Type': 'application/json',
  }
  const API_Body = JSON.stringify({
    refresh: refreshToken,
      })
  // Reusable logic - no custom values below this line. 
  dispatch({type: APIRequest})
    fetch(API.SERVER + APIEndPoint, {
      signal: AbortSignal.timeout(5000),
      method: API_Method,
      headers: API_Headers,
      body: API_Body,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        else {
          return response.json().then((data) => {
            if (data.messages) {
              throw new Error(API.GenericError + response.status + ': ' + data.messages[0].message)
            } else if (data) {
              throw new Error('Please check the following fields for errors: ' +  Object.entries(data) )
            } else {
              throw new Error(API.GenericError + response.status)
            }

          // return response.json().then((data) => {
          //   console.log('response.status', response.status);
          //   console.log('response', response);
          //   if (response.status === 400) {
          //     console.log('Error 1', response.status);
          //     throw new Error(API.Error400);
          //   } else if (response.status === 401) {
          //     console.log('Error 2', response.status);
          //     throw new Error(API.Error401);
          //   } else if (response.status === 404) {
          //     console.log('Error 3', response.status);
          //     throw new Error(API.Error404);
          //   } else {
          //     console.log('Error 4', response.status);
          //     throw new Error(API.ErrorUnkown);
          //   }
          })
        }
      })
      .then((data) => {
        const decoded = jwt_decode(data.access);
        // console.log('decoded',decoded)
        const store = Object.assign(decoded,data)
        // console.log('store',store)
        localStorage.setItem('userInfo', JSON.stringify(store))  //Refresh
        // const tokenExpirationDate = decoded.exp
        // // Code for auto log out
        // const step1 = tokenExpirationDate * 1000
        // const step2 = Date.now() 
        // const step3 = step1 - step2
        // // console.log('user API- step1',step1)
        // // console.log('user API- step2',step2)
        // // console.log('user API- step3',step3)
        // // console.log('Time remaining HH:MM:', new Date(step3).toISOString().substring(11, 16))
        // localStorage.setItem('tokenTimeRemaining', step3)
        // localStorage.setItem('tokenExpirationDate', tokenExpirationDate)

        const accessTokenExpiration = decoded.exp
        const accessTokenExpirationTime = accessTokenExpiration * 1000
        localStorage.setItem('accessTokenExpirationTime', accessTokenExpirationTime) //Refresh
        
        dispatch({
          type: APISuccess1,
        })
        dispatch({
          type: APISuccess2,
          payload: store
        })
      })
      .catch((error) => {API.CatchErrorHandler(error, dispatch, APIFail)});
}

// GET REQUESTS
export const getUserProfile = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId,APIVariablesItemDetails,dispatch,getState)
}

// PATCH REQUESTS
export const updateUserProfile = (item) => async (dispatch, getState) => {
  // console.log(user)
  const { user_Login: { userInfo } } = getState()
  const APIRequest = Constants.USERUpdate_PROFILERequest
  const APISuccess = Constants.USERUpdate_PROFILESuccess
  const APIFail = Constants.USERUpdate_PROFILEFail
  const APIEndPoint = 'auth/user/profile/update/' + item.id
  const API_Method = 'PATCH'
  const API_Headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + userInfo.access
  }
  const API_Body = JSON.stringify({
    first_name: item.first_name,
    last_name: item.last_name,
    email: item.email,
  })

  dispatch({type: APIRequest})

    fetch(API.SERVER + APIEndPoint, {
      signal: AbortSignal.timeout(2000),
      method: API_Method,
      headers: API_Headers,
      body: API_Body,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        else {
          return response.json().then((data) => {
            console.log('response.status', response.status);
            console.log('response', response);
            if (response.status === 400) {
              console.log('Error 1', response.status);
              throw new Error(data.detail);
            } else if (response.status === 401) {
              console.log('Error 2', response.status);
              throw new Error(API.Error401);
            } else if (response.status === 404) {
              console.log('Error 3', response.status);
              throw new Error(API.Error404);
            } else {
              console.log('Error 4', response.status);
              throw new Error(API.ErrorUnkown);
            }
          })
        }
      })
      .then((data) => {dispatch({type: APISuccess,payload: data})})
      .catch((error) => {API.CatchErrorHandler(error, dispatch, APIFail)});
}

export const updateUserPassword = (item) => async (dispatch, getState) => {
    const API_Body = {
    old_password: item.currentPassword,
    password: item.newPassword,
    password2: item.confirmPassword,
  }
  ItemUpdate(item,API_Body,APIVariablesUpdate,dispatch, getState)
}