
import { useState, useEffect } from "react";
import styles from '../../../style/form.module.css';
// import style from '../../../style/global.module.css';
import ResponsiveContainer from '../../../components/containers/responsiveContatiner';

const Countdown = () => {
  const [isActive, setIsActive] = useState(false)
  const [isPaused, setIsPaused] = useState(true)
  const [coutdownTime, setCountdownTime] = useState(5 * 60)
  const [timeEntered, setTimeEntered] = useState(true)

  // const [days,setDays] = useState(0);
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState('');

  useEffect(() => {
    let interval = null;

    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        setCountdownTime((timer) => timer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);


  const handleStart = () => {
    setIsActive(true)
    setIsPaused(false)

    setCountdownTime((
      // +(days*(60*60*24)) 
      +(hours * (60 * 60))
      + (minutes * 60)
      + (seconds * 1)
    ))

  }

  const handlePause = () => {
    // clearInterval(countRef.current)
    setIsPaused(true)
  }

  const handleResume = () => {
    setIsPaused(false)
  }

  const handleRestart = () => {
    // clearInterval(countRef.current)
    setIsActive(true)
    setIsPaused(false)

    setCountdownTime((
      // +(days*(60*60*24)) 
      +(hours * (60 * 60))
      + (minutes * 60)
      + (seconds * 1)
    ))
  }

  const handleReset = () => {
    setIsActive(false)
    setIsPaused(true)
    setTimeEntered(false)
    // setDays(0)
    setHours('')
    setMinutes('')
    setSeconds('')
  }

  const display = () => {
    if (coutdownTime > 0) {
      const getSeconds = `0${(coutdownTime % 60)}`.slice(-2)
      const minutes = `${Math.floor(coutdownTime / 60)}`
      const getMinutes = `0${minutes % 60}`.slice(-2)
      const getHours = `0${Math.floor(coutdownTime / 3600)}`.slice(-2)

      return (
        <p style={{ fontSize: '50px', margin: '10px' }}>
          {getHours > 0 && getHours}
          {getHours > 0 && ':'}
          {getMinutes + getHours > 0 && getMinutes}
          {getMinutes + getHours > 0 && ':'}
          {getSeconds} </p>
      )
    }
    else {
      return (
        <p style={{ fontSize: '50px', margin: '10px' }}>  Time's Up </p>)
    }
  }

  const formfields = {
    width: "60px",
  }

  return (
    <ResponsiveContainer 

    contents={
<>
<div style={{textAlign: 'center'}}>
  <br></br>
  <br></br>
        <h2>Race Countdown</h2>

        <form>
          <table className={styles.center}>
            <tbody>
              <tr>
                {/* <th> Days    </th> */}
                <th> Hours   </th>
                <th> Minutes </th>
                <th> Seconds </th>
              </tr>
              <tr>
                {/* <td>
              <input 
                type='number'
                id='days' 
                max="365"
                style={formfields}
                value={days}
                onChange={e => setDays(e.target.value) }
                />
            </td> */}
                <td>
                  <input
                    type='number'
                    id='hours'
                    max="24"
                    style={formfields}
                    value={hours}
                    onChange={e => { setHours(e.target.value); setTimeEntered(true) }}
                  />
                </td>
                <td>
                  <input
                    type='number'
                    id='minutes'
                    max="60"
                    style={formfields}
                    value={minutes}
                    onChange={e => { setMinutes(e.target.value); setTimeEntered(true) }}
                  />
                </td>
                <td>
                  <input
                    type='number'
                    id='seconds'
                    max="60"
                    style={formfields}
                    value={seconds}
                    onChange={e => { setSeconds(e.target.value); setTimeEntered(true) }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
        {isActive &&
          <p>{display()}</p>}
        {/* {timeEntered &&  */}
        <button className={styles.red} onClick={handleReset} disabled={!timeEntered}>Reset Timer</button>
        {/* } */}
        {isActive && <button className={styles.orange} onClick={handleRestart} disabled={!isActive}>Restart Timer</button>
        }
        {!isActive && isPaused &&
          // timeEntered && 
          <button className={styles.green} onClick={handleStart} disabled={!timeEntered}>Start</button>
        }
        {isActive && !isPaused && <button className={styles.blue} onClick={handlePause}>Pause</button>}
        {isActive && isPaused && timeEntered && <button className={styles.green} onClick={handleResume}>Resume</button>}
        <div style={{ textAlign: 'left' }}>
            <h3>User Guide</h3>
            <p>5 minutes to start: Sound horn and raise class flag.</p>
            <p>4 minutes to start: Sound horn and raise preparatory flag up.
              <br></br>
              This will be a blue flag with a white square in the centre.</p>
            <p> 1 minutes to start: Sound horn and lower preparatory flag.</p>
            <p>0 minutes: Sound horn and lower class flag </p>
          </div>
          </div>
</>
    }/>
  );
}

export default Countdown;
