import formstyle from '../../../style/form.module.css';

const Checkbox = (props) => {
  // console.log('props',props)
  return (
    <div 
    className={formstyle.selectOption}
    // onClick={() => props.handleSetFilterValues(props.fieldName, props.fieldType)}
    >
      <input
        style={{
          width: '10%',
          cursor: 'pointer',
          height: '1.1em',
        }}
        key={props.id}
        type="checkbox"
        // value={props.id === null ? '' : props.id }
        // value={props.id}

        checked={props.filterCheckBoxIsChecked(props.fieldName)}
        onChange={e => props.handleSetFilterValues(props.fieldName, props.fieldType)}
      />
      {/* <span> {props.name}</span> */}
    </div>
  );
};
export default Checkbox;