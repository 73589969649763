//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
import { ItemListAll } from '../../../APIs/genericAPIs'

const moduleDefinitions = {
  moduleName: 'Visibility',
  moduleNameSingular: 'Visibility',
  moduleLink: '/fun/sailinglog/visibilitys/',
}

const moduleFields = [
  {
    label: 'Visibility', 
    mandatory: 'true', 
    fieldName: 'name',
    fieldType: 'text',
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  } ,  {
    label: 'Description', 
    fieldName: 'description',
    fieldType: 'text',
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  } 
]



export const SailingLogVisibilityListListRequest = 'SailingLogVisibilityListListRequest'
export const SailingLogVisibilityListListSuccess = 'SailingLogVisibilityListListSuccess'
export const SailingLogVisibilityListListFail = 'SailingLogVisibilityListListFail'


const APIVariablesListAll = {
  APIRequest: SailingLogVisibilityListListRequest,
  APISuccess: SailingLogVisibilityListListSuccess,
  APIFail: SailingLogVisibilityListListFail,
  APIEndPoint: 'sailinglog/v1/visibility/list'
}



export const SailingLogVisibilityListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

   
  export const SailingLogVisibilityListReducer = (state = { SailingLogVisibility: [] }, action) => {
    switch (action.type) {
        case SailingLogVisibilityListListRequest:
            return {
                loading: true
            }
  
        case SailingLogVisibilityListListSuccess:
            return {
                loading: false,
                fieldOptionValues: [...action.payload],
                SailingLogVisibility: action.payload
            }
  
        case SailingLogVisibilityListListFail:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
  }

function SailingLogVisibilityScreen() {

  const listAPI = SailingLogVisibilityListAllAPI

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

  const SailingLogVisibilityList = useSelector(state => state.SailingLogVisibilityList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    SailingLogVisibility: dataList } = SailingLogVisibilityList


  useEffect(() => {
    dispatch(SailingLogVisibilityListAllAPI())
  }, [])



  return (
    <ModuleHome
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}
/>
  )
}

export default SailingLogVisibilityScreen