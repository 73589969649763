import tablestyle from '../../../style/table.module.css';

const FilterTime = (props) => {

  return (
    <div className={props.setClassFor === 'tableControl' && `${tablestyle.filterModalFields} ${tablestyle.filterModalTime}`}>

    <div >
      <select
        name="logic"
        id="logic"
        value={props.filterLogic(props.fieldName,1)}
        onChange={e => {props.handleSetFilterLogic(props.fieldName, props.fieldType, e.target.value,1)}}
      >
        <option value="eqaulTo">At:</option>
        <option value="equalToGreaterThan">At & After:</option>
        <option value="equalToLessThan">At & Before:</option>
        <option value="greaterThan">After:</option>
        <option value="lessThan">Before:</option>
        <option value="notEqualTo">Not At:</option>
      </select>
      <input
        type='time'
        value={props.filterValue(props.fieldName)}
        onChange={e => props.handleSetFilterNumbers(props.fieldName, props.fieldType, e.target.value,
          ((
            props.filterLogic(props.fieldName,1) == undefined || 
            props.filterLogic(props.fieldName,1) == ''
            ) ? "eqaulTo" : props.filterLogic(props.fieldName,1)))}
      >
      </input>
    </div>

    {props.filterLogic(props.fieldName,1) == 'equalToGreaterThan' &&
      <>
      <br></br>
      <select
        name="logic"
        id="logic"
        value={props.filterLogic(props.fieldName,2)}
        onChange={e => {props.handleSetFilterLogic(props.fieldName, props.fieldType, e.target.value,2)}}
      >
        <option value="equalToLessThan">At & Before:</option>
        <option value="lessThan">Before:</option>
      </select>
        <input
          type='time'
          value={props.filterValue2(props.fieldName)}
          onChange={e => props.handleSetFilterNumbersTwo(props.fieldName, props.fieldType, e.target.value,
            ((
              props.filterLogic(props.fieldName,2) == undefined || 
              props.filterLogic(props.fieldName,2) == ''
            )
            ? "equalToLessThan" : props.filterLogic(props.fieldName,2)))}
        >
        </input>
      </>
    }  

    {props.filterLogic(props.fieldName,1) == 'greaterThan' &&
      <>
      <br></br>
      <select
        name="logic"
        id="logic"
        value={props.filterLogic(props.fieldName,2)}
        onChange={e => {props.handleSetFilterLogic(props.fieldName, props.fieldType, e.target.value,2)}}
      >
        <option value="lessThan">Before:</option>
        <option value="equalToLessThan">At & Before:</option>
      </select>
        <input
          type='time'
          value={props.filterValue2(props.fieldName)}
          onChange={e => props.handleSetFilterNumbersTwo(props.fieldName, props.fieldType, e.target.value, 
            ((
              props.filterLogic(props.fieldName,2) == undefined || 
              props.filterLogic(props.fieldName,2) == ''
            )
            ? "lessThan" : props.filterLogic(props.fieldName,2)))}
        >
        </input>
      </>
    }




    </div>

  )
}

export default FilterTime