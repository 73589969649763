export const USER_LOGINRequest = 'USER_LOGINRequest'
export const USER_LOGINSuccess = 'USER_LOGINSuccess'
export const USER_LOGINFail = 'USER_LOGINFail'

export const USER_REFRESHRequest = 'USER_REFRESHRequest'
export const USER_REFRESHSuccess = 'USER_REFRESHSuccess'
export const USER_REFRESHFail = 'USER_REFRESHFail'

export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTERRequest = 'USER_REGISTERRequest'
export const USER_REGISTERSuccess = 'USER_REGISTERSuccess'
export const USER_REGISTERFail = 'USER_REGISTERFail'

export const USER_PROFILERequest = 'USER_PROFILERequest'
export const USER_PROFILESuccess = 'USER_PROFILESuccess'
export const USER_PROFILEFail = 'USER_PROFILEFail'
export const USER_PROFILEReset = 'USER_PROFILEReset'

export const USERUpdate_PROFILERequest = 'USERUpdate_PROFILERequest'
export const USERUpdate_PROFILESuccess = 'USERUpdate_PROFILESuccess'
export const USERUpdate_PROFILEFail = 'USERUpdate_PROFILEFail'
export const USERUpdate_PROFILEReset = 'USERUpdate_PROFILEReset'

export const USERUpdate_PASSWORDRequest = 'USERUpdate_PASSWORDRequest'
export const USERUpdate_PASSWORDSuccess = 'USERUpdate_PASSWORDSuccess'
export const USERUpdate_PASSWORDFail = 'USERUpdate_PASSWORDFail'
export const USERUpdate_PASSWORDReset = 'USERUpdate_PASSWORDReset'

export const USERListRequest = 'USERListRequest'
export const USERListSuccess = 'USERListSuccess'
export const USERListFail = 'USERListFail'
export const USERListReset = 'USERListReset'

export const USERDeleteRequest = 'USERDeleteRequest'
export const USERDeleteSuccess = 'USERDeleteSuccess'
export const USERDeleteFail = 'USERDeleteFail'

export const USERUpdateRequest = 'USERUpdateRequest'
export const USERUpdateSuccess = 'USERUpdateSuccess'
export const USERUpdateFail = 'USERUpdateFail'
export const USERUpdateReset = 'USERUpdateReset'

