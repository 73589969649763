import { useState, useEffect } from "react";
import formStyle from '../../../style/form.module.css';
import style from '../../../style/global.module.css';
import ResponsiveContainer from '../../../components/containers/responsiveContatiner';

const RaceManager = () => {
  const [newparticipant, setNewparticipant] = useState('')
  const [participants, setParticipants] = useState('')
  const [isActive, setIsActive] = useState(false)
  const [isPaused, setIsPaused] = useState(true)
  const [coutdownTime, setCountdownTime] = useState(5 * 60)
  const [timeEntered, setTimeEntered] = useState(true)

  // const [days,setDays] = useState(0);
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState('');

  useEffect(() => {
    let interval = null;

    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        setCountdownTime((timer) => timer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);


  useEffect(() => {
    let interval = null;

    if (coutdownTime < 1 && isPaused === false) {
      interval = setInterval(() => {
        setTimer((timer) => timer + 10);
      }, 10);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [coutdownTime, isPaused]);


  const handleStart = () => {
    setIsActive(true)
    setIsPaused(false)

    setCountdownTime((
      // +(days*(60*60*24)) 
      +(hours * (60 * 60))
      + (minutes * 60)
      + (seconds * 1)
    ))

  }

  const handlePause = () => {
    // clearInterval(countRef.current)
    setIsPaused(true)
  }

  const handleResume = () => {
    setIsPaused(false)
  }

  const handleRestart = () => {
    // clearInterval(countRef.current)
    setIsActive(true)
    setIsPaused(false)
    setTimer(0)

    setCountdownTime((
      // +(days*(60*60*24)) 
      +(hours * (60 * 60))
      + (minutes * 60)
      + (seconds * 1)
    ))
  }

  const handleReset = () => {
    setIsActive(false)
    setIsPaused(true)
    setTimeEntered(false)
    setTimer(0)
    // setDays(0)
    setHours('')
    setMinutes('')
    setSeconds('')
  }

  //Stopwatch
  const [timer, setTimer] = useState(0)
  const getHours = `0${Math.floor((timer / 6000000) % 60)}`.slice(-2)
  const getMinutes = `0${Math.floor(timer / 60000) % 60}`.slice(-2)
  const getSeconds = `0${Math.floor(timer / 1000) % 60}`.slice(-2)
  const getSplitSeconds = `0${((timer / 10) % 100)}`.slice(-2)

  const display = () => {
    if (coutdownTime > 0) {
      const getSeconds = `0${(coutdownTime % 60)}`.slice(-2)
      const minutes = `${Math.floor(coutdownTime / 60)}`
      const getMinutes = `0${minutes % 60}`.slice(-2)
      const getHours = `0${Math.floor(coutdownTime / 3600)}`.slice(-2)

      return (
        <p style={{ fontSize: '50px', margin: '10px' }}>
          {getHours > 0 && getHours}
          {getHours > 0 && ':'}
          {getMinutes + getHours > 0 && getMinutes}
          {getMinutes + getHours > 0 && ':'}
          {getSeconds} </p>
      )
    }
    else {
      return (
        <>
          <div className='stopwatch-card' >
            <table style={{ fontSize: '30px', margin: '10px', textAlign: 'center', margin: 'auto' }} className={formStyle.center} >
              <thead >
                <tr>
                  <th>HH :</th>
                  <th>MM :</th>
                  <th>SS :</th>
                  <th>MS</th>
                </tr>
              </thead>
              <tbody>
                <tr >
                  <td>{getHours} :</td>
                  <td>{getMinutes} :</td>
                  <td>{getSeconds} :</td>
                  <td>{getSplitSeconds}</td>
                </tr>
              </tbody>
            </table>
            {/* <p>{formatTime()}</p>  */}
            <div></div>
          </div>
        </>





      )
    }
  }

  const formfields = {
    width: "60px",
  }

  const removeParticipant = (name) => {
    const index = participants.findIndex(person => person.name === name); //find index of participant
    console.log('index', index)
    const newData = [...participants] // new array
    newData.splice(index, 1); // remove old object
    return setParticipants(newData)
  }

  const raceFinishForParticipant = (name) => {
    const index = participants.findIndex(person => person.name === name); //find index of participant
    console.log('index', index)
    const newObject =[{name: name , finishTime: timer }]
    console.log('newObject',newObject)
    const newData = [...participants] // new array
    newData.splice(index, 1); // remove old object
    newData.push(...newObject) // add new object
    console.log('newData',newData)
    return setParticipants(newData)
  }


  return (
    <ResponsiveContainer 
    contents={
<>
<div className={style.center}>
        <h2>Race Manager</h2>
        <div>
        <h3>1. Add race Participants</h3>
        <form>
            <label>Name </label>
            <input
              type="text"
              value={newparticipant}
              onChange={(e) => setNewparticipant(e.target.value)}
            ></input>
            <button onClick={(e) => {
              e.preventDefault();
              setParticipants([...participants, { name: newparticipant }]);
              setNewparticipant('')
            }}>
              Add
            </button>
            </form>
          </div>
        <form>
        <h3>2. Set countdown timer</h3>
          <table className={formStyle.center}>
            <tbody>
              <tr>
                {/* <th> Days    </th> */}
                <th> Hours   </th>
                <th> Minutes </th>
                <th> Seconds </th>
              </tr>
              <tr>
                <td>
                  <input
                    type='number'
                    id='hours'
                    max="24"
                    style={formfields}
                    value={hours}
                    onChange={e => { setHours(e.target.value); setTimeEntered(true) }}
                  />
                </td>
                <td>
                  <input
                    type='number'
                    id='minutes'
                    max="60"
                    style={formfields}
                    value={minutes}
                    onChange={e => { setMinutes(e.target.value); setTimeEntered(true) }}
                  />
                </td>
                <td>
                  <input
                    type='number'
                    id='seconds'
                    max="60"
                    style={formfields}
                    value={seconds}
                    onChange={e => { setSeconds(e.target.value); setTimeEntered(true) }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
        {isActive &&
          <p>{display()}</p>}
        {/* {timeEntered &&  */}
        <button className={formStyle.red} onClick={handleReset} disabled={!timeEntered}>Reset Timer</button>
        {/* } */}
        {isActive && <button className={formStyle.orange} onClick={handleRestart} disabled={!isActive}>Restart Timer</button>
        }
        {!isActive && isPaused &&
          // timeEntered && 
          <button className={formStyle.green} onClick={handleStart} disabled={!timeEntered}>Start</button>
        }
        {isActive && !isPaused && <button className={formStyle.blue} onClick={handlePause}>Pause</button>}
        {isActive && isPaused && timeEntered && <button className={formStyle.green} onClick={handleResume}>Resume</button>}

        <div>
        <h3>3. Record finish times</h3>
        <p>HH:MM:SS:MS</p>

          {participants ?
            participants.map((participant) =>
              <div>
                <label>{participant.name} </label>
                {participant.finishTime ? 
                <>
                {`0${Math.floor((participant.finishTime  / 6000000) % 60)}`.slice(-2)} : {`0${Math.floor(participant.finishTime  / 60000) % 60}`.slice(-2)} : {`0${Math.floor(participant.finishTime  / 1000) % 60}`.slice(-2)} : {`0${((participant.finishTime  / 10) % 100)}`.slice(-2)}
                
                </>
                
                :
                  <>
                    <button
                      className={formStyle.green}
                      onClick={() => raceFinishForParticipant(participant.name)}
                    >
                      Finish
                    </button>
                    <button
                      className={formStyle.red}
                      onClick={() => removeParticipant(participant.name)}
                    >Delete</button>
                  </>
                }
              </div>
            )
            : <p>No participants, please add them above </p>}



        </div>

      </div>
      </>
    }/>
  );
}

export default RaceManager;
