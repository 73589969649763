import tablestyle from '../../../style/table.module.css';

const FilterDate = (props) => {
  // console.log('filterDateValue',props.filterDateValue(props.field, 'year'))
  // console.log('filterValue',props.filterValue(props.field))
  // console.log('props',props)
  // console.log('filterValue',props.fieldName,props.filterValue)
  
  const ShowSelectOptions = (props, datePart, values, currentValue) => {

    return (
      <select
        key={datePart}
        id={datePart}
        name={datePart}
        value={currentValue}
        onChange={props.onChange}
        style={{ fontWeight: 'normal' }}
      >
        <option
          key='0'
          hidden>
          {datePart}...
        </option>
        {values.map((option) => (
          <option key={option}
            value={option}>
            {option}
          </option>
        ))}
          <option
          key='99'
          onClick={() => props.handleSetFilterDates(props.fieldName, props.fieldType, '', datePart)}
          >
          (Clear)
        </option>
      </select>
    )
  }

  const filterValueDate = props.filterValue((props.fieldName))
  // console.log('filterValueDate',filterValueDate)
  const currentYear = filterValueDate ? filterValueDate.split('-')[0] : '00'
  const currentMonth = filterValueDate ? filterValueDate.split('-')[1] : '00'
  const currentDay = filterValueDate ? filterValueDate.split('-')[2] : '00'

  const filterValueDate2 = props.filterValue2((props.fieldName))
  // console.log('filterValueDate',filterValueDate)
  const currentYear2 = filterValueDate2 ? filterValueDate2.split('-')[0] : '00'
  const currentMonth2 = filterValueDate2 ? filterValueDate2.split('-')[1] : '00'
  const currentDay2 = filterValueDate2 ? filterValueDate2.split('-')[2] : '00'

  const FilterYearOptions = (props) => {
    const substring = props.selectOptions
      .map((option) => option.name.substring(0, 4))
      .sort((a, b) => b - a)
    const values = [... new Set(substring)]
    // console.log('logicNumber',props.logicNumber)

    return ShowSelectOptions(props, 'Year', values, props.logicNumber === 1 ? currentYear : currentYear2)

  };

  const FilterMonthOptions = (props) => {

    const split = props.selectOptions
      .map((option) => option.name.split('-')[1])
      .sort((a, b) => a - b)
    const values = [... new Set(split)]

    return ShowSelectOptions(props, 'Month', values, props.logicNumber === 1 ? currentMonth : currentMonth2)

  };

  const FilterDayOptions = (props) => {

    const split = props.selectOptions
      .map((option) => option.name.split('-')[2])
      .sort((a, b) => a - b)
    const values = [... new Set(split)]

    return ShowSelectOptions(props, 'Day', values, props.logicNumber === 1 ? currentDay : currentDay2)

  };

  const dateFields = (props, logicNumber) => {
    return (
      <>
    <div >
          <div className={tablestyle.container} >
            <div className={tablestyle.divA}>
              <FilterYearOptions
                {...props}
                logicNumber={logicNumber}
                onChange={e => {(logicNumber === 2 ? props.handleSetFilterDatesSecondValue :
                  props.handleSetFilterDates)(props.fieldName, props.fieldType, e.target.value, 'Year',
                  ((
                    props.filterLogic(props.fieldName, logicNumber) == undefined ||
                    props.filterLogic(props.fieldName, logicNumber) == ''
                  ) ? logicNumber ===1 ? "eqaulTo" : "equalToLessThan" : props.filterLogic(props.fieldName, logicNumber)))}
                }
              />
            </div>
            <div className={tablestyle.divB}>
              <FilterMonthOptions
                {...props}
                logicNumber={logicNumber}
                onChange={e => (logicNumber === 2 ? props.handleSetFilterDatesSecondValue :
                  props.handleSetFilterDates)(props.fieldName, props.fieldType, e.target.value, 'Month',
                  ((
                    props.filterLogic(props.fieldName, logicNumber) == undefined ||
                    props.filterLogic(props.fieldName, logicNumber) == ''
                  ) ? logicNumber ===1 ? "eqaulTo" : "equalToLessThan": props.filterLogic(props.fieldName, logicNumber)))}
              />
            </div>
            <div className={tablestyle.divC}>
              <FilterDayOptions
                {...props}
                logicNumber={logicNumber}
                onChange={e => (logicNumber === 2 ? props.handleSetFilterDatesSecondValue :
                  props.handleSetFilterDates)(props.fieldName, props.fieldType, e.target.value, 'Day',
                  ((
                    props.filterLogic(props.fieldName, logicNumber) == undefined ||
                    props.filterLogic(props.fieldName, logicNumber) == ''
                  ) ? logicNumber ===1 ? "eqaulTo" : "equalToLessThan" : props.filterLogic(props.fieldName, logicNumber)))}
              />
            </div>
          </div>

          <input
            type='date'
            value={logicNumber === 1 ? props.filterValue(props.fieldName) : props.filterValue2(props.fieldName)}
            onChange={e => {(logicNumber === 2 ? props.handleSetFilterDatesSecondValue :
              props.handleSetFilterDates)(props.fieldName, props.fieldType, e.target.value, 'Full',
              ((
                props.filterLogic(props.fieldName, logicNumber) == undefined ||
                props.filterLogic(props.fieldName, logicNumber) == ''
              ) ? "eqaulTo" : props.filterLogic(props.fieldName, logicNumber)))}
            }
          >
          </input>
        </div>
      </>
    )
  }

  return (
    <div  className={props.setClassFor === 'tableControl' && `${tablestyle.filterModalFields} ${tablestyle.filterModalDate}`}>

      <div>
        <select
          name="logic"
          id="logic"
          value={props.filterLogic(props.fieldName, 1)}
          onChange={e => {
            props.handleSetFilterLogic(props.fieldName, props.fieldType, e.target.value,1)
          }}
        >
          <option value="eqaulTo">On:</option>
          <option value="equalToGreaterThan">On & After:</option>
          <option value="equalToLessThan">On & Before:</option>
          <option value="greaterThan">After:</option>
          <option value="lessThan">Before:</option>
          <option value="notEqualTo">Not On:</option>
        </select>
        {dateFields(props, 1)}
      </div>

{props.filterLogic(props.fieldName,1) == 'equalToGreaterThan' &&
      <>
      <br></br>
      <select
        name="logic"
        id="logic"
        value={props.filterLogic(props.fieldName,2)}
        onChange={e => {props.handleSetFilterLogic(props.fieldName, props.fieldType, e.target.value,2)}}
      >
        <option value="equalToLessThan">On & Before:</option>
        <option value="lessThan">Before:</option>
      </select>
      {dateFields(props, 2)}
      </>
    }  

    {props.filterLogic(props.fieldName,1) == 'greaterThan' &&
      <>
      <br></br>
      <select
        name="logic"
        id="logic"
        value={props.filterLogic(props.fieldName,2)}
        onChange={e => {props.handleSetFilterLogic(props.fieldName, props.fieldType, e.target.value,2)}}
      >
        <option value="lessThan">Before:</option>
        <option value="equalToLessThan">On & Before:</option>
      </select>
      {dateFields(props, 2)}
      </>
    }
    </div>
  )
}

export default FilterDate