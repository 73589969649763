//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { useParams, useLocation, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../components/containers/moduleHome'
import { ItemListAll } from '../../APIs/genericAPIs'

const moduleDefinitions = {
  moduleName: 'Country Codes',
  moduleNameSingular: 'Country Code',
}

const moduleFields = [
  {
    label: 'Country Code',
    fieldName: 'name',
    fieldType: 'text',
    tableDisplayOrder: 1,
    tableDisplayPriority: 1,
  }, {
    label: 'Alpha Code 2',
    fieldName: 'alpha2_code',
    fieldType: 'text',
    tableDisplayOrder: 2,
    tableDisplayPriority: 1,
  }, {
    label: 'Alpha Code 3',
    fieldName: 'alpha3_code',
    fieldType: 'text',
    tableDisplayOrder: 3,
    tableDisplayPriority: 1,
  }, {
    label: 'Numeric',
    fieldName: 'numeric',
    fieldType: 'text',
    tableDisplayOrder: 4,
    tableDisplayPriority: 2,
  }, {
    label: 'Dialling Code',
    fieldName: 'dialling_code',
    fieldType: 'text',
    tableDisplayOrder: 5,
    tableDisplayPriority: 1,
  }
]

const APIVariablesListAll = {
  APIRequest: 'CountryCodesListRequest',
  APISuccess: 'CountryCodesListSuccess',
  APIFail: 'CountryCodesListFail',
  APIEndPoint: 'shared/v1/countrycodes/list'
}
const APIVariablesSelectOptions = {
  APIRequest: 'CountryCodesListSelectOptionsRequest',
  APISuccess: 'CountryCodesListSelectOptionsSuccess',
  APIFail: 'CountryCodesListSelectOptionsFail',
  APIEndPoint: 'shared/v1/countrycodes/selectoptions'
}

export const CountryCodesListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}
export const CountryCodesSelectOptionsAPI = () => async (dispatch, getState) => {
  console.log('CountryCodesSelectOptions-Triggered')
  ItemListAll(APIVariablesSelectOptions, dispatch, getState)
}

export const CountryCodesListReducer = (state = { category: [], fieldOptionValues: [] }, action) => {
  switch (action.type) {
    case 'CountryCodesListRequest':
      return {
        loading: true
      }

    case 'CountryCodesListSuccess':
      return {
        loading: false,
        countrycode: action.payload
      }

    case 'CountryCodesListFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const CountryCodesSelectOptionsReducer = (state = { category: [], fieldOptionValues: [] }, action) => {
  switch (action.type) {
    case 'CountryCodesListSelectOptionsRequest':
      return {
        loading: true
      }

    case 'CountryCodesListSelectOptionsSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
      }

    case 'CountryCodesListSelectOptionsFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function CountryCodeListScreen() {
  const listAPI = CountryCodesListAllAPI

  const dispatch = useDispatch()

  const CountryCodesList = useSelector(state => state.CountryCodesList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    countrycode: dataList } = CountryCodesList


  useEffect(() => {
    dispatch(listAPI())
  }, [])

  return (
    <ModuleHome
      // Shared items
      paramsItem={{ action: 'list' }}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}
    />
  )
}

export default CountryCodeListScreen


