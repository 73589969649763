import { useState } from 'react';
// Styles
import tablestyle from '../../style/table.module.css';
import formstyle from '../../style/form.module.css';
// Components
import menuicon from '../../static/icons/KebabMenu.png'
import HandleSort from '../../hooks/handleSort';
import InputFields from '../fields/inputFields/inputFields';

import FilterCheckbox from '../fields/filterFields/filterCheckbox';
import FilterDate from '../fields/filterFields/filterDate'
import FilterNumber from '../fields/filterFields/filterNumber'
import FilterSelect from '../fields/filterFields/filterSelect'
import FilterText from '../fields/filterFields/filterText'
import FilterTime from '../fields/filterFields/filterTime'

// Hooks
import { HandleOutsideClick } from '../../hooks/handleOutsideClick';

const TableHeader = (props) => {
  // console.log('showDropDownContent',showDropDownContent)
  // console.log('props.tableFilterHide',props.tableFilterHide)
  // console.log('filterValue',props.fieldName,props.filterValue)
  // console.log('filterValueDate',props.fieldName,props.filterValueDate(props.fieldName, 'year'))
  const colclass = ('colDisP' + (props.tableDisplayPriority))
  const [showDropDownContent, setShowDropDownContent] = useState(false)

  const dropdowncontent = (props) => {
    return (
      <div
        className={showDropDownContent ?
          `${tablestyle.dropdowncontent} ${tablestyle.show}` :
          tablestyle.dropdowncontent}
      >

        <HandleSort
         {...props}
          sortName={'Sort'}
        />
        <hr className={tablestyle.divider}></hr>

        {props.fieldType === 'autoCompleteText' &&
          <InputFields
            placeholder='Type to filter...'
            fieldName={props.fieldName}
            fieldType={props.fieldType}
            options={props.autoCompleteOptions}
            fieldValue={props.filterValue(props.fieldName)}
            fieldValueName={props.filterValueName(props.fieldName)}
            // multiple='multiple'
            filterIsChecked={props.filterIsChecked}
            fieldIsChecked={props.fieldIsChecked}
            onClick={e => props.handleClearFilter(props.fieldName)}
            onChange={e => props.handleSetFilterValues(props.fieldName, props.fieldType, e.target.value)}
            handleSetFilterValues={props.handleSetFilterValues}
            filtermode
          />
        }

        {props.fieldType === 'checkbox' && <FilterCheckbox {...props}/> }
        {props.fieldType === 'date' && <FilterDate {...props}/>}
        
        {(props.fieldType === 'select' || 
          props.fieldType === 'selectfromdb' || 
          props.fieldType === 'selectnoid') && <FilterSelect {...props} /> 
          }
        {props.fieldType === 'text' && <FilterText {...props}/>}
        {props.fieldType === 'time' && <FilterTime {...props}/>}
        {props.fieldType === 'number' &&  <FilterNumber {...props}/> }
        {props.fieldType === 'currency' && <FilterNumber {...props}/> }


        <hr className={tablestyle.divider}></hr>
        <div
        // style={{marginLeft: '5%'}}
        >
          <button
            className={formstyle.orange}
            // href="#"
            // className={tablestyle.warning}
            onClick={e => {
              props.handleClearFilter(props.fieldName);
              setShowDropDownContent(false);
            }}
          >
            Clear filter
          </button>
          <button
            className={formstyle.green}
            onClick={e => { setShowDropDownContent(false) }}
          >
            Apply filter
          </button>
        </div>
      </div>

    )
  }

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  return (
    <>
    {props.tableFilterHide === undefined ?
      <th className={tablestyle[colclass]} >
        <div className={tablestyle.clickableHeader} onClick={() => setShowDropDownContent(current => !current)} >
          <section className={tablestyle.container} >
            <div className={tablestyle.headerLeft}>
              {props.label}
            </div>
            <div className={tablestyle.headerRight}>
              <img className={tablestyle.menuicon} src={menuicon} alt="menu icon" />
            </div>
          </section>
        </div>
        <div className={tablestyle.dropdownTableMenu}>
          <HandleOutsideClick
            show={showDropDownContent}
            onClickOutside={async event => { await delay(10); setShowDropDownContent(false) }}
            contents={dropdowncontent(props)} />
        </div>
      </th>
      :
      <th className={tablestyle[colclass]} >
      <div className={tablestyle.notClickableHeader}>
        <section className={tablestyle.container} >
          <div className={tablestyle.headerLeft}>
            {props.label}
          </div>
          <div className={tablestyle.headerRight}>
          </div>
        </section>
      </div>
    </th>
}
    </>
  );
};
export default TableHeader;

export const TableHeaderOptions = (props) => {
  return (
    <th style={props.style}>
      Options
    </th>
  );
};