//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
// APIs
import {ItemCreate, ItemListAll,ItemDetails,ItemUpdate, ItemDelete} from '../../../APIs/genericAPIs'

export const moduleDefinitions = {
  moduleName: 'Event Types',
  moduleNameSingular: 'Event Type',
  moduleLink: '/system/settings/events/type/',
}

export const moduleFields = [
  {
    label: 'Event Type',
    fieldName: 'name',
    fieldType: 'text',
    addFormOrder: 1,
    editFormOrder: 1,
    tableDisplayOrder: 1,
    tableDisplayPriority: 1,
  },
]

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
      fieldErrorMessage = ""
  switch (fieldName) {
    case "type":
      break
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

const APIVariablesCreate = {
  APIRequest: 'EventTypeCreateRequest',
  APISuccess: 'EventTypeCreateSuccess' ,
  APIFail: 'EventTypeCreateFail' ,
  APIEndPoint: 'events/v1/type/add',
}
const APIVariablesListAll = {
  APIRequest: 'EventTypeListRequest' ,
  APISuccess: 'EventTypeListSuccess' ,
  APIFail: 'EventTypeListFail' ,
  APIEndPoint: 'events/v1/type/list'
}
const APIVariablesSelectOptions = {
  APIRequest: 'EventTypeSelectOptionsRequest',
  APISuccess: 'EventTypeSelectOptionsSuccess',
  APIFail: 'EventTypeSelectOptionsFail',
  APIEndPoint: 'events/v1/type/selectoptions'
}
const APIVariablesItemDetails = {
  APIRequest: 'EventTypeDetailsRequest' ,
  APISuccess: 'EventTypeDetailsSuccess' ,
  APIFail: 'EventTypeDetailsFail' ,
  APIEndPoint: 'events/v1/type/'
}
const APIVariablesUpdate = {
  APIRequest: 'EventTypeUpdateRequest' ,
  APISuccess: 'EventTypeUpdateSuccess ',
  APISuccess_2: 'EventTypeDetailsSuccess',
  APIFail: 'EventTypeUpdateFail' ,
  APIEndPoint: 'events/v1/type/'
}
const APIVariablesDelete = {
  APIRequest: 'EventTypeDeleteRequest',
  APISuccess: 'EventTypeDeleteSuccess' ,
  APIFail: 'EventTypeDeleteFail' ,
  APIEndPoint: 'events/v1/type/'
}

export const EventTypeCreateAPI = (item) => async (dispatch, getState) => {  
  const API_Body = {
    name: item.name,
      }
  ItemCreate(API_Body,APIVariablesCreate,dispatch,getState)
} 

export const EventTypeListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll,dispatch,getState)
}

export const EventTypeSelectOptionsAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesSelectOptions,dispatch,getState)
}

export const EventTypeDetailsAPI = (itemId)  => async (dispatch, getState) => { 
  ItemDetails(itemId,APIVariablesItemDetails,dispatch,getState)
}

export const EventTypeUpdateAPI = (item) => async (dispatch, getState) => {
  const API_Body = {
    name: item.name,
  }
  ItemUpdate(item,API_Body,APIVariablesUpdate,dispatch, getState)
}

export const EventTypeDeleteAPI = (itemId) => async (dispatch, getState) => { 
  ItemDelete(itemId,APIVariablesDelete,dispatch,getState) 
}


// State is an empty object
export const EventTypeCreateReducer = (state = {}, action) => {
  switch (action.type) {
      case 'EventTypeCreateRequest':
          return {
              loading: true
          }

      case 'EventTypeCreateSuccess':
          return {
              loading: false,
              success: true,
              EventType: action.payload
          }

      case 'EventTypeCreateFail':
          return {
              loading: false,
              error: action.payload
          }

      case 'EventTypeCreateReset':
          return {}


      default:
          return state
  }
}

export const EventTypeUpdateReducer = (state = { EventType: {} }, action) => {
  switch (action.type) {
      case 'EventTypeUpdateRequest':
          return { loading: true }

      case 'EventTypeUpdateSuccess':
          return { loading: false, success: true }

      case 'EventTypeUpdateFail':
          return { loading: false, error: action.payload }

      case 'EventTypeUpdateReset':
          return { status: {} }

      default:
          return state
  }
}

export const EventTypeDeleteReducer = (state = {}, action) => {
  switch (action.type) {
      case 'EventTypeDeleteRequest':
          return { loading: true }

      case 'EventTypeDeleteSuccess':
          return { 
            loading: false, 
            success: true }

      case 'EventTypeDeleteFail':
          return { loading: false, error: action.payload }

      case 'EventTypeDeleteReset':
            return { }

      default:
          return state
  }
}

export const EventTypeDetailsReducer = (state = { loading: true, status: [] }, action) => {
  switch (action.type) {
      case 'EventTypeDetailsRequest':
          return {
              ...state,
              loading: true
          }

      case 'EventTypeDetailsSuccess':
          return {
              loading: false,
              success: true,
              EventType: action.payload
          }

      case 'EventTypeDetailsFail':
          return {
              loading: false,
              error: action.payload,
              EventType: 'error'
          }

      case 'EventTypeDetailsReset':
        return { status: {} }

      default:
          return state
  }
}


export const EventTypeListReducer = (state = { statuss: [],fieldOptionValues:[] }, action) => {
  switch (action.type) {
      case 'EventTypeListRequest':
          return {
              loading: true
          }

      case 'EventTypeListSuccess':
          return {
              loading: false,
              fieldOptionValues: [...action.payload],
              success: true,
              statuss: action.payload
          }

      case 'EventTypeListFail':
          return {
              loading: false,
              error: action.payload
          }
      default:
          return state
  }
}

export const EventTypeSelectOptionsReducer = (state = { fieldOptionValues: [] }, action) => {
  switch (action.type) {
    case 'EventTypeSelectOptionsRequest':
      return {
        loading: true
      }

    case 'EventTypeSelectOptionsSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
      }

    case 'EventTypeSelectOptionsFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function EventTypeScreen() {

  const createAPI = EventTypeCreateAPI
  const listAPI = EventTypeListAllAPI
  const detailsAPI = EventTypeDetailsAPI
  const updateAPI = EventTypeUpdateAPI
  const deleteAPI = EventTypeDeleteAPI

  const resetCreate = 'EventTypeCreateReset'
  const resetUpdate = 'EventTypeUpdateReset'
  const resetViewDetails = 'EventTypeDetailsReset'
  const resetDelete = 'EventTypeDeleteReset'

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const EventTypeCreate = useSelector(state => state.EventTypeCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = EventTypeCreate

  const EventTypeList = useSelector(state => state.EventTypeList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    statuss: dataList } = EventTypeList

  const EventTypeDetails = useSelector(state => state.EventTypeDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    status: dataItem } = EventTypeDetails

  const EventTypeUpdate = useSelector(state => state.EventTypeUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = EventTypeUpdate

  const EventTypeDelete = useSelector(state => state.EventTypeDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = EventTypeDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingDetails
    )
  )


  useEffect(() => {
    dispatch(listAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
     if (errorLoadingList) {setServerErrorMessage(errorLoadingList)}
    if (errorCreate) {setServerErrorMessage(errorCreate)}
    if (errorUpdate) {setServerErrorMessage(errorUpdate)}
    if (errorGetDetails) {setServerErrorMessage(errorGetDetails)}
    if (errorDelete) {setServerErrorMessage(errorDelete)}
  }, [successDelete, successUpdate,
    errorLoadingList,errorCreate,errorUpdate,errorGetDetails, errorDelete])


  return (
    <ModuleHome
      // Shared items
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      rowOnClickAction='edit'
      add={true}
      edit={true}
      view={false}
      delete={(id) => dispatch(deleteAPI(id))}

      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      getDataList={listAPI}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}
    />
  )
}

export default EventTypeScreen