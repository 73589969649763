import tablestyle from '../../../style/table.module.css';

const FilterNumber = (props) => {

  return (
  <div className={props.setClassFor === 'tableControl' && `${tablestyle.filterModalFields} ${tablestyle.filterModalNumber}`}>
   
    <div >
      <select
        name="logic"
        id="logic"
        value={props.filterLogic(props.fieldName,1)}
        onChange={e => {props.handleSetFilterLogic(props.fieldName, props.fieldType, e.target.value,1)}}
      >
        <option value="eqaulTo">Equal to:</option>
        <option value="equalToGreaterThan">Equal to & Greater than:</option>
        <option value="equalToLessThan">Equal to & Less than:</option>
        <option value="greaterThan">Greater than:</option>
        <option value="lessThan">Less than:</option>
        <option value="notEqualTo">Not Equal to:</option>
      </select>
      <input
        type='number'
        placeholder='Enter a number...'
        value={props.filterValue(props.fieldName)}
        onChange={e => props.handleSetFilterNumbers(props.fieldName, props.fieldType, e.target.value,
          ((
            props.filterLogic(props.fieldName,1) == undefined || 
            props.filterLogic(props.fieldName,1) == ''
            ) ? "eqaulTo" : props.filterLogic(props.fieldName,1)))}
      >
      </input>
    </div>

    {props.filterLogic(props.fieldName,1) == 'equalToGreaterThan' &&
      <>
      <br></br>
      <select
        name="logic"
        id="logic"
        value={props.filterLogic(props.fieldName,2)}
        onChange={e => {props.handleSetFilterLogic(props.fieldName, props.fieldType, e.target.value,2)}}
      >
        <option value="equalToLessThan">Equal to & Less than:</option>
        <option value="lessThan">Less than:</option>
      </select>
        <input
          type='number'
          placeholder='Enter a number...'
          value={props.filterValue2(props.fieldName)}
          onChange={e => props.handleSetFilterNumbersTwo(props.fieldName, props.fieldType, e.target.value,
            ((
              props.filterLogic(props.fieldName,2) == undefined || 
              props.filterLogic(props.fieldName,2) == ''
            )
            ? "equalToLessThan" : props.filterLogic(props.fieldName,2)))}
        >
        </input>
      </>
    }  

    {props.filterLogic(props.fieldName,1) == 'greaterThan' &&
      <>
      <br></br>
      <select
        name="logic"
        id="logic"
        value={props.filterLogic(props.fieldName,2)}
        onChange={e => {props.handleSetFilterLogic(props.fieldName, props.fieldType, e.target.value,2)}}
      >
        <option value="lessThan">Less than:</option>
        <option value="equalToLessThan">Equal to & Less than:</option>
      </select>
        <input
          type='number'
          placeholder='Enter a number...'
          value={props.filterValue2(props.fieldName)}
          onChange={e => props.handleSetFilterNumbersTwo(props.fieldName, props.fieldType, e.target.value, 
            ((
              props.filterLogic(props.fieldName,2) == undefined || 
              props.filterLogic(props.fieldName,2) == ''
            )
            ? "lessThan" : props.filterLogic(props.fieldName,2)))}
        >
        </input>
      </>
    }
  </div>
)
  }

export default FilterNumber