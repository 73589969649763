const handleSelectOptions = (data, fieldId, fieldName) => {

  const getuniquevalues = [];
  const selectOptions = [];

  // console.log('data',data)
  if (data === undefined) { return 'No Options' }
  else {
    data.map(item => {
      if (item[fieldName] !== null ) {
      if (getuniquevalues.indexOf(item[fieldName]) === -1) {
        getuniquevalues.push(item[fieldName])
        if (item[fieldName] !== undefined) {
          selectOptions.push({ id: item[fieldId], name: item[fieldName] })
        }

      }
  }});
    // console.log(`selectOptions for ${fieldName}`,selectOptions)
    return selectOptions
  }
}

export default handleSelectOptions;