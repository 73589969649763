import { configureStore, combineReducers } from "@reduxjs/toolkit";
import thunk from 'redux-thunk';
import {
  user_LoginReducer,
  user_RefreshReducer,
  user_RegisterReducer,
  user_ProfileReducer,
  userUpdateProfileReducer,
  userUpdatePasswordReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  // userDetailsReducer,
} from './modules/user/userReducers'

import {
  TaskCreateReducer,
  TaskDetailsReducer,
  TaskUpdateReducer,
  TaskDeleteReducer,
  TaskListReducer,
} from './modules/calendar/tasks/tasks'

import {
  TaskStatusCreateReducer,
  TaskStatusDetailsReducer,
  TaskStatusUpdateReducer,
  TaskStatusDeleteReducer,
  TaskStatusListReducer,
} from './modules/calendar/tasks/status'

import {
  TaskPriorityCreateReducer,
  TaskPriorityDetailsReducer,
  TaskPriorityUpdateReducer,
  TaskPriorityDeleteReducer,
  TaskPriorityListReducer,
} from './modules/calendar/tasks/priority'

import {
  EventCreateReducer,
  EventDetailsReducer,
  EventUpdateReducer,
  EventDeleteReducer,
  EventListReducer,
} from './modules/calendar/events/events'

import {
  EventStatusCreateReducer,
  EventStatusDetailsReducer,
  EventStatusUpdateReducer,
  EventStatusDeleteReducer,
  EventStatusListReducer,
} from './modules/calendar/events/status'

import {
  EventTypeCreateReducer,
  EventTypeDetailsReducer,
  EventTypeUpdateReducer,
  EventTypeDeleteReducer,
  EventTypeListReducer,
  EventTypeSelectOptionsReducer
} from './modules/calendar/events/types'

import {
  EventVisibilityCreateReducer,
  EventVisibilityDetailsReducer,
  EventVisibilityUpdateReducer,
  EventVisibilityDeleteReducer,
  EventVisibilityListReducer,
  EventVisibilitySelectOptionsReducer
} from './modules/calendar/events/visibility'

import {
  ContactsContactCreateReducer,
  ContactsContactDetailsReducer,
  ContactsContactSelectOptionsReducer,
  ContactsContactUpdateReducer,
  ContactsContactDeleteReducer,
  ContactsContactListReducer,
} from './modules/contacts/contact'

import {
  ContactsGroupCreateReducer,
  ContactsGroupDetailsReducer,
  ContactsGroupSelectOptionsReducer,
  ContactsGroupUpdateReducer,
  ContactsGroupDeleteReducer,
  ContactsGroupListReducer,
} from './modules/contacts/groups'

import {
  ContactsOrganisationCreateReducer,
  ContactsOrganisationDetailsReducer,
  ContactsOrganisationSelectOptionsReducer,
  ContactsOrganisationUpdateReducer,
  ContactsOrganisationDeleteReducer,
  ContactsOrganisationListReducer,
} from './modules/contacts/organisation'

// import {
//   ContactsStaffCreateReducer,
//   ContactsStaffDetailsReducer,
//   ContactsStaffSelectOptionsReducer,
//   ContactsStaffUpdateReducer,
//   ContactsStaffDeleteReducer,
//   ContactsStaffListReducer,
// } from './modules/contacts/staff'

import {
  ContactsPersonCreateReducer,
  ContactsPersonDetailsReducer,
  ContactsPersonSelectOptionsReducer,
  ContactsPersonUpdateReducer,
  ContactsPersonDeleteReducer,
  ContactsPersonListReducer,
} from './modules/contacts/person'

import {
  InventoryItemCreateReducer,
  InventoryItemDetailsReducer,
  InventoryItemUpdateReducer,
  InventoryItemDeleteReducer,
  InventoryItemListReducer,
} from './modules/inventory/items'

import {
 InventoryLocationCreateReducer,
 InventoryLocationDetailsReducer,
 InventoryLocationUpdateReducer,
 InventoryLocationDeleteReducer,
 InventoryLocationListReducer,
} from './modules/inventory/locations'

import {
  InventoryChecklistListCreateReducer,
  InventoryChecklistListDetailsReducer,
  InventoryChecklistListUpdateReducer,
  InventoryChecklistListDeleteReducer,
  InventoryChecklistListListReducer,
} from './modules/inventory/checklist/lists'

import {
  InventoryChecklistItemCreateReducer,
  InventoryChecklistItemDetailsReducer,
  InventoryChecklistItemUpdateReducer,
  InventoryChecklistItemDeleteReducer,
  InventoryChecklistItemListReducer,
} from './modules/inventory/checklist/items'

import {
  InventoryChecklistCriteriaCreateReducer,
  InventoryChecklistCriteriaDetailsReducer,
  InventoryChecklistCriteriaUpdateReducer,
  InventoryChecklistCriteriaDeleteReducer,
  InventoryChecklistCriteriaListReducer,
} from './modules/inventory/checklist/criteria'

import {
  InventoryCategoryCreateReducer,
  InventoryCategoryDetailsReducer,
  InventoryCategoryUpdateReducer,
  InventoryCategoryDeleteReducer,
  InventoryCategoryListReducer,
} from './modules/inventory/settings/category'

import {
  AddressCreateReducer,
  AddressListReducer,
  AddressDetailsReducer,
  AddressUpdateReducer,
  AddressDeleteReducer,
} from './modules/shared/address'

import {
  CategoryCreateReducer,
  CategoryListReducer,
  CategoryDetailsReducer,
  CategoryUpdateReducer,
  CategoryDeleteReducer,
} from './modules/shared/category'

import { 
  CountryCodesListReducer,
  CountryCodesSelectOptionsReducer
} from './modules/shared/countryCodes'

import { 
  CurrencyCodeListReducer,
  CurrencyCodeSelectOptionsReducer
} from './modules/shared/currencyCodes'


import {
  SailingLogAcronymCreateReducer,
  SailingLogAcronymListReducer,
  SailingLogAcronymDetailsReducer,
  SailingLogAcronymUpdateReducer,
  SailingLogAcronymDeleteReducer,
} from './modules/sailingLog/reference/acronyms'

import {
  SailingLogBoatCreateReducer,
  SailingLogBoatListReducer,
  SailingLogBoatSelectOptionsReducer,
  SailingLogBoatContactSummaryReducer,
  SailingLogBoatLocationSummaryReducer,
  SailingLogBoatDetailsReducer,
  SailingLogBoatUpdateReducer,
  SailingLogBoatDeleteReducer,
} from './modules/sailingLog/boats'

import {
  SailingLogBoatTypeCreateReducer,
  SailingLogBoatTypeListReducer,
  SailingLogBoatTypeSelectOptionsReducer,
  SailingLogBoatTypeDetailsReducer,
  SailingLogBoatTypeUpdateReducer,
  SailingLogBoatTypeDeleteReducer,
} from './modules/sailingLog/settings/boatTypes'

import {
  SailingLogContactCreateReducer,
  SailingLogContactListReducer,
  SailingLogContactSelectOptionsReducer,
  SailingLogContactDetailsReducer,
  SailingLogContactUpdateReducer,
  SailingLogContactDeleteReducer,
} from './modules/sailingLog/contacts/contacts'

import {
  SailingLogContactQualificationCreateReducer,
  SailingLogContactQualificationListReducer,
  SailingLogContactQualificationSelectOptionsReducer,
  SailingLogContactQualificationContactSummaryReducer,
  SailingLogContactQualificationDetailsReducer,
  SailingLogContactQualificationUpdateReducer,
  SailingLogContactQualificationDeleteReducer,
} from './modules/sailingLog/contacts/qualifications'

import {
  SailingLogCrewCreateReducer,
  SailingLogCrewListReducer,
  SailingLogCrewContactSummaryReducer,
  SailingLogCrewBoatSummaryReducer,
  SailingLogCrewTripSummaryReducer,
  SailingLogCrewPassageSummaryReducer,
  SailingLogCrewDetailsReducer,
  SailingLogCrewUpdateReducer,
  SailingLogCrewDeleteReducer,
} from './modules/sailingLog/passage/crew'

import {
  SailingLogGlossaryCreateReducer,
  SailingLogGlossaryListReducer,
  SailingLogGlossaryDetailsReducer,
  SailingLogGlossaryUpdateReducer,
  SailingLogGlossaryDeleteReducer,
} from './modules/sailingLog/reference/glossary'

import {
  SailingLogLocationCreateReducer,
  SailingLogLocationListReducer,
  SailingLogLocationSelectOptionsReducer,
  SailingLogLocationDetailsReducer,
  SailingLogLocationUpdateReducer,
  SailingLogLocationDeleteReducer,
} from './modules/sailingLog/locations'

import {
  SailingLogLogEntryCreateReducer,
  SailingLogLogEntryListReducer,
  SailingLogLogEntryTripSummaryReducer,
  SailingLogLogEntryPassageSummaryReducer,
  SailingLogLogEntryDetailsReducer,
  SailingLogLogEntryUpdateReducer,
  SailingLogLogEntryDeleteReducer,
} from './modules/sailingLog/passage/logEntry'

import {
  SailingLogQualificationCreateReducer,
  SailingLogQualificationListReducer,
  SailingLogQualificationSelectOptionsReducer,
  SailingLogQualificationDetailsReducer,
  SailingLogQualificationUpdateReducer,
  SailingLogQualificationDeleteReducer,
} from './modules/sailingLog/settings/qualifications'

import {
  SailingLogTripCreateReducer,
  SailingLogTripListReducer,
  SailingLogTripSelectOptionsReducer,
  // SailingLogTripSummaryReducer,
  SailingLogTripBoatSummaryReducer,
  SailingLogTripSummaryByLocationReducer,
  SailingLogTripDetailsReducer,
  SailingLogTripUpdateReducer,
  SailingLogTripDeleteReducer,
} from './modules/sailingLog/trips'

import {
  SailingLogTripTypeCreateReducer,
  SailingLogTripTypeListReducer,
  SailingLogTripTypeSelectOptionsReducer,
  SailingLogTripTypeDetailsReducer,
  SailingLogTripTypeUpdateReducer,
  SailingLogTripTypeDeleteReducer,
} from './modules/sailingLog/settings/tripTypes'

import {
  SailingLogPassageCreateReducer,
  SailingLogPassageListReducer,
  SailingLogPassageSelectOptionsReducer,
  SailingLogPassageSummaryByTripReducer,
  SailingLogPassageSummaryByLocationReducer,
  SailingLogPassageDetailsReducer,
  SailingLogPassageUpdateReducer,
  SailingLogPassageDeleteReducer,
} from './modules/sailingLog/passage/passages'

// Reference
import { SailingLogAlphabetListReducer } from './modules/sailingLog/reference/alphabet'
import { SailingLogSeaStateListReducer } from './modules/sailingLog/reference/seaState'
import { SailingLogSoundListReducer } from './modules/sailingLog/reference/sound'
import { SailingLogVisibilityListReducer } from './modules/sailingLog/reference/visibility'
import { SailingLogWindSpeedListReducer } from './modules/sailingLog/reference/windSpeed'

import {
  SailingLogRoleCreateReducer,
  SailingLogRoleListReducer,
  SailingLogRoleSelectOptionsReducer,
  SailingLogRoleDetailsReducer,
  SailingLogRoleUpdateReducer,
  SailingLogRoleDeleteReducer,
} from './modules/sailingLog/settings/role'

import {
  SupportTicketCreateReducer,
  SupportTicketListReducer,
  SupportTicketDetailsReducer,
  SupportTicketUpdateReducer,
  SupportTicketDeleteReducer,
} from './modules/support/tickets'

import {SupportTicketApplicationOptionsListReducer } from './modules/support/reference/applicationOptions'
import {SupportTicketBrowserOptionsListReducer } from './modules/support/reference/browserOptions'
import {SupportTicketDeviceTypeListReducer } from './modules/support/reference/deviceType'
import {SupportTicketMobileTypeListReducer } from './modules/support/reference/mobileType'
import {SupportTicketModuleOptionsListReducer } from './modules/support/reference/moduleOptions'
import {SupportTicketPriorityOptionsListReducer } from './modules/support/reference/priorityOptions'
import {SupportTicketStatusOptionsListReducer} from './modules/support/reference/statusOptions'
import {SupportTicketTicketTypeListReducer } from './modules/support/reference/ticketType'

const reducer = combineReducers({
  user_Login: user_LoginReducer,
  user_Refresh: user_RefreshReducer,
  user_Register: user_RegisterReducer,
  user_Profile: user_ProfileReducer,
  // userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userUpdatePassword: userUpdatePasswordReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,

  TaskCreate: TaskCreateReducer,
  TaskDetails: TaskDetailsReducer,
  TaskDelete: TaskDeleteReducer,
  TaskUpdate: TaskUpdateReducer,
  TaskList: TaskListReducer,

  TaskStatusCreate: TaskStatusCreateReducer,
  TaskStatusDetails: TaskStatusDetailsReducer,
  TaskStatusDelete: TaskStatusDeleteReducer,
  TaskStatusUpdate: TaskStatusUpdateReducer,
  TaskStatusList: TaskStatusListReducer,

  TaskPriorityCreate: TaskPriorityCreateReducer,
  TaskPriorityDetails: TaskPriorityDetailsReducer,
  TaskPriorityDelete: TaskPriorityDeleteReducer,
  TaskPriorityUpdate: TaskPriorityUpdateReducer,
  TaskPriorityList: TaskPriorityListReducer,

  EventCreate: EventCreateReducer,
  EventDetails: EventDetailsReducer,
  EventDelete: EventDeleteReducer,
  EventUpdate: EventUpdateReducer,
  EventList: EventListReducer,

  EventStatusCreate: EventStatusCreateReducer,
  EventStatusDetails: EventStatusDetailsReducer,
  EventStatusDelete: EventStatusDeleteReducer,
  EventStatusUpdate: EventStatusUpdateReducer,
  EventStatusList: EventStatusListReducer,

  EventTypeCreate: EventTypeCreateReducer,
  EventTypeDetails: EventTypeDetailsReducer,
  EventTypeDelete: EventTypeDeleteReducer,
  EventTypeUpdate: EventTypeUpdateReducer,
  EventTypeList: EventTypeListReducer,
  EventTypeSelectOptions: EventTypeSelectOptionsReducer,

  EventVisibilityCreate: EventVisibilityCreateReducer,
  EventVisibilityDetails: EventVisibilityDetailsReducer,
  EventVisibilityDelete: EventVisibilityDeleteReducer,
  EventVisibilityUpdate: EventVisibilityUpdateReducer,
  EventVisibilityList: EventVisibilityListReducer,
  EventVisibilitySelectOptions: EventVisibilitySelectOptionsReducer,

  ContactsContactCreate: ContactsContactCreateReducer,
  ContactsContactDetails: ContactsContactDetailsReducer,
  ContactsContactSelectOptions: ContactsContactSelectOptionsReducer,
  ContactsContactDelete: ContactsContactDeleteReducer,
  ContactsContactUpdate: ContactsContactUpdateReducer,
  ContactsContactList: ContactsContactListReducer,

  ContactsGroupCreate: ContactsGroupCreateReducer,
  ContactsGroupDetails: ContactsGroupDetailsReducer,
  ContactsGroupSelectOptions: ContactsGroupSelectOptionsReducer,
  ContactsGroupDelete: ContactsGroupDeleteReducer,
  ContactsGroupUpdate: ContactsGroupUpdateReducer,
  ContactsGroupList: ContactsGroupListReducer,

  ContactsOrganisationCreate: ContactsOrganisationCreateReducer,
  ContactsOrganisationDetails: ContactsOrganisationDetailsReducer,
  ContactsOrganisationSelectOptions: ContactsOrganisationSelectOptionsReducer,
  ContactsOrganisationDelete: ContactsOrganisationDeleteReducer,
  ContactsOrganisationUpdate: ContactsOrganisationUpdateReducer,
  ContactsOrganisationList: ContactsOrganisationListReducer,

  ContactsPersonCreate: ContactsPersonCreateReducer,
  ContactsPersonDetails: ContactsPersonDetailsReducer,
  ContactsPersonSelectOptions: ContactsPersonSelectOptionsReducer,
  ContactsPersonDelete: ContactsPersonDeleteReducer,
  ContactsPersonUpdate: ContactsPersonUpdateReducer,
  ContactsPersonList: ContactsPersonListReducer,

  // ContactsStaffCreate: ContactsStaffCreateReducer,
  // ContactsStaffDetails: ContactsStaffDetailsReducer,
  // ContactsStaffSelectOptions: ContactsStaffSelectOptionsReducer,
  // ContactsStaffDelete: ContactsStaffDeleteReducer,
  // ContactsStaffUpdate: ContactsStaffUpdateReducer,
  // ContactsStaffList: ContactsStaffListReducer,

  InventoryItemCreate: InventoryItemCreateReducer,
  InventoryItemDetails: InventoryItemDetailsReducer,
  InventoryItemDelete: InventoryItemDeleteReducer,
  InventoryItemUpdate: InventoryItemUpdateReducer,
  InventoryItemList: InventoryItemListReducer,

  InventoryLocationCreate:InventoryLocationCreateReducer,
  InventoryLocationDetails:InventoryLocationDetailsReducer,
  InventoryLocationDelete:InventoryLocationDeleteReducer,
  InventoryLocationUpdate:InventoryLocationUpdateReducer,
  InventoryLocationList:InventoryLocationListReducer,

  InventoryChecklistListCreate: InventoryChecklistListCreateReducer,
  InventoryChecklistListDetails: InventoryChecklistListDetailsReducer,
  InventoryChecklistListUpdate: InventoryChecklistListUpdateReducer,
  InventoryChecklistListDelete: InventoryChecklistListDeleteReducer,
  InventoryChecklistListList: InventoryChecklistListListReducer,

  InventoryChecklistItemCreate: InventoryChecklistItemCreateReducer,
  InventoryChecklistItemDetails: InventoryChecklistItemDetailsReducer,
  InventoryChecklistItemUpdate: InventoryChecklistItemUpdateReducer,
  InventoryChecklistItemDelete: InventoryChecklistItemDeleteReducer,
  InventoryChecklistItemList: InventoryChecklistItemListReducer,

  InventoryChecklistCriteriaCreate: InventoryChecklistCriteriaCreateReducer,
  InventoryChecklistCriteriaDetails: InventoryChecklistCriteriaDetailsReducer,
  InventoryChecklistCriteriaUpdate: InventoryChecklistCriteriaUpdateReducer,
  InventoryChecklistCriteriaDelete: InventoryChecklistCriteriaDeleteReducer,
  InventoryChecklistCriteriaList: InventoryChecklistCriteriaListReducer,

  InventoryCategoryCreate: InventoryCategoryCreateReducer,
  InventoryCategoryDetails: InventoryCategoryDetailsReducer,
  InventoryCategoryUpdate: InventoryCategoryUpdateReducer,
  InventoryCategoryDelete: InventoryCategoryDeleteReducer,
  InventoryCategoryList: InventoryCategoryListReducer,

  AddressCreate: AddressCreateReducer,
  AddressList: AddressListReducer,
  AddressDetails: AddressDetailsReducer,
  AddressUpdate: AddressUpdateReducer,
  AddressDelete: AddressDeleteReducer,

  CategoryCreate: CategoryCreateReducer,
  CategoryList: CategoryListReducer,
  CategoryDetails: CategoryDetailsReducer,
  CategoryUpdate: CategoryUpdateReducer,
  CategoryDelete: CategoryDeleteReducer,

  CountryCodesList: CountryCodesListReducer,
  CountryCodesSelectOptions: CountryCodesSelectOptionsReducer,
  CurrencyCodeList: CurrencyCodeListReducer,
  CurrencyCodeSelectOptions: CurrencyCodeSelectOptionsReducer,

  SailingLogAcronymCreate: SailingLogAcronymCreateReducer,
  SailingLogAcronymList: SailingLogAcronymListReducer,
  SailingLogAcronymDetails: SailingLogAcronymDetailsReducer,
  SailingLogAcronymUpdate: SailingLogAcronymUpdateReducer,
  SailingLogAcronymDelete: SailingLogAcronymDeleteReducer,

  SailingLogBoatCreate: SailingLogBoatCreateReducer,
  SailingLogBoatList: SailingLogBoatListReducer,
  SailingLogBoatSelectOptions: SailingLogBoatSelectOptionsReducer,
  SailingLogBoatContactSummary: SailingLogBoatContactSummaryReducer,
  SailingLogBoatLocationSummary: SailingLogBoatLocationSummaryReducer,
  SailingLogBoatDetails: SailingLogBoatDetailsReducer,
  SailingLogBoatUpdate: SailingLogBoatUpdateReducer,
  SailingLogBoatDelete: SailingLogBoatDeleteReducer,

  SailingLogBoatTypeCreate: SailingLogBoatTypeCreateReducer,
  SailingLogBoatTypeList: SailingLogBoatTypeListReducer,
  SailingLogBoatTypeSelectOptions: SailingLogBoatTypeSelectOptionsReducer,
  SailingLogBoatTypeDetails: SailingLogBoatTypeDetailsReducer,
  SailingLogBoatTypeUpdate: SailingLogBoatTypeUpdateReducer,
  SailingLogBoatTypeDelete: SailingLogBoatTypeDeleteReducer,

  SailingLogContactCreate: SailingLogContactCreateReducer,
  SailingLogContactList: SailingLogContactListReducer,
  SailingLogContactSelectOptions: SailingLogContactSelectOptionsReducer,
  SailingLogContactDetails: SailingLogContactDetailsReducer,
  SailingLogContactUpdate: SailingLogContactUpdateReducer,
  SailingLogContactDelete: SailingLogContactDeleteReducer,

  SailingLogContactQualificationCreate: SailingLogContactQualificationCreateReducer,
  SailingLogContactQualificationList: SailingLogContactQualificationListReducer,
  SailingLogContactQualificationContactSummary: SailingLogContactQualificationContactSummaryReducer,
  SailingLogContactQualificationDetails: SailingLogContactQualificationDetailsReducer,
  SailingLogContactQualificationUpdate: SailingLogContactQualificationUpdateReducer,
  SailingLogContactQualificationDelete: SailingLogContactQualificationDeleteReducer,

  SailingLogCrewCreate: SailingLogCrewCreateReducer,
  SailingLogCrewList: SailingLogCrewListReducer,
  SailingLogCrewContactSummary: SailingLogCrewContactSummaryReducer,
  SailingLogCrewBoatSummary: SailingLogCrewBoatSummaryReducer,
  SailingLogCrewPassageSummary: SailingLogCrewPassageSummaryReducer,
  SailingLogCrewTripSummary: SailingLogCrewTripSummaryReducer,
  SailingLogCrewDetails: SailingLogCrewDetailsReducer,
  SailingLogCrewUpdate: SailingLogCrewUpdateReducer,
  SailingLogCrewDelete: SailingLogCrewDeleteReducer,

  SailingLogGlossaryCreate: SailingLogGlossaryCreateReducer,
  SailingLogGlossaryList: SailingLogGlossaryListReducer,
  SailingLogGlossaryDetails: SailingLogGlossaryDetailsReducer,
  SailingLogGlossaryUpdate: SailingLogGlossaryUpdateReducer,
  SailingLogGlossaryDelete: SailingLogGlossaryDeleteReducer,

  SailingLogLocationCreate: SailingLogLocationCreateReducer,
  SailingLogLocationList: SailingLogLocationListReducer,
  SailingLogLocationSelectOptions : SailingLogLocationSelectOptionsReducer,
  SailingLogLocationDetails: SailingLogLocationDetailsReducer,
  SailingLogLocationUpdate: SailingLogLocationUpdateReducer,
  SailingLogLocationDelete: SailingLogLocationDeleteReducer,

  SailingLogLogEntryCreate: SailingLogLogEntryCreateReducer,
  SailingLogLogEntryList: SailingLogLogEntryListReducer,
  SailingLogLogEntryTripSummary: SailingLogLogEntryTripSummaryReducer,
  SailingLogLogEntryPassageSummary: SailingLogLogEntryPassageSummaryReducer,
  SailingLogLogEntryDetails: SailingLogLogEntryDetailsReducer,
  SailingLogLogEntryUpdate: SailingLogLogEntryUpdateReducer,
  SailingLogLogEntryDelete: SailingLogLogEntryDeleteReducer,

  SailingLogQualificationCreate: SailingLogQualificationCreateReducer,
  SailingLogQualificationList: SailingLogQualificationListReducer,
  SailingLogQualificationSelectOptions: SailingLogQualificationSelectOptionsReducer,
  SailingLogQualificationDetails: SailingLogQualificationDetailsReducer,
  SailingLogQualificationUpdate: SailingLogQualificationUpdateReducer,
  SailingLogQualificationDelete: SailingLogQualificationDeleteReducer,

  SailingLogTripCreate: SailingLogTripCreateReducer,
  SailingLogTripList: SailingLogTripListReducer,
  SailingLogTripSelectOptions: SailingLogTripSelectOptionsReducer,
  // SailingLogTripSummary: SailingLogTripSummaryReducer,
  SailingLogTripBoatSummary: SailingLogTripBoatSummaryReducer,
  SailingLogTripSummaryByLocation : SailingLogTripSummaryByLocationReducer,
  SailingLogTripDetails: SailingLogTripDetailsReducer,
  SailingLogTripUpdate: SailingLogTripUpdateReducer,
  SailingLogTripDelete: SailingLogTripDeleteReducer,

  SailingLogTripTypeCreate: SailingLogTripTypeCreateReducer,
  SailingLogTripTypeList: SailingLogTripTypeListReducer,
  SailingLogTripTypeSelectOptions: SailingLogTripTypeSelectOptionsReducer,
  SailingLogTripTypeDetails: SailingLogTripTypeDetailsReducer,
  SailingLogTripTypeUpdate: SailingLogTripTypeUpdateReducer,
  SailingLogTripTypeDelete: SailingLogTripTypeDeleteReducer,

  // Sailing References
  SailingLogAlphabetList:SailingLogAlphabetListReducer,
  SailingLogAcronymList:SailingLogAcronymListReducer,
  SailingLogGlossaryList:SailingLogGlossaryListReducer,
  SailingLogSeaStateList: SailingLogSeaStateListReducer,
  SailingLogSoundList:SailingLogSoundListReducer,
  SailingLogVisibilityList: SailingLogVisibilityListReducer,
  SailingLogWindSpeedList: SailingLogWindSpeedListReducer,

  SailingLogPassageCreate: SailingLogPassageCreateReducer,
  SailingLogPassageList: SailingLogPassageListReducer,
  SailingLogPassageSelectOptions: SailingLogPassageSelectOptionsReducer,
  SailingLogPassageSummaryByTrip: SailingLogPassageSummaryByTripReducer,
  SailingLogPassageSummaryByLocation :SailingLogPassageSummaryByLocationReducer,
  SailingLogPassageDetails: SailingLogPassageDetailsReducer,
  SailingLogPassageUpdate: SailingLogPassageUpdateReducer,
  SailingLogPassageDelete: SailingLogPassageDeleteReducer,

  SailingLogRoleCreate: SailingLogRoleCreateReducer,
  SailingLogRoleList: SailingLogRoleListReducer,
  SailingLogRoleSelectOptions: SailingLogRoleSelectOptionsReducer,
  SailingLogRoleDetails: SailingLogRoleDetailsReducer,
  SailingLogRoleUpdate: SailingLogRoleUpdateReducer,
  SailingLogRoleDelete: SailingLogRoleDeleteReducer,

  SupportTicketCreate: SupportTicketCreateReducer,
  SupportTicketList: SupportTicketListReducer,
  SupportTicketDetails: SupportTicketDetailsReducer,
  SupportTicketUpdate: SupportTicketUpdateReducer,
  SupportTicketDelete: SupportTicketDeleteReducer,

  SupportTicketApplicationOptionsList: SupportTicketApplicationOptionsListReducer,
  SupportTicketBrowserOptionsList: SupportTicketBrowserOptionsListReducer,
  SupportTicketDeviceTypeList: SupportTicketDeviceTypeListReducer,
  SupportTicketMobileTypeList: SupportTicketMobileTypeListReducer,
  SupportTicketModuleOptionsList: SupportTicketModuleOptionsListReducer,
  SupportTicketStatusOptionsList: SupportTicketStatusOptionsListReducer,
  SupportTicketPriorityOptionsList: SupportTicketPriorityOptionsListReducer,
  SupportTicketTicketTypeList: SupportTicketTicketTypeListReducer,
});
// Get user login detail from local storage if user has already signed in
const userInfoFromStorage = localStorage.getItem('userInfo') ?
  JSON.parse(localStorage.getItem('userInfo')) : null

export const initialState = {
  user_Login: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = configureStore({
  reducer: reducer,
  preloadedState: initialState,
  middleware: middleware,
});

export default store
