//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'
import { SailingLogContactSelectOptionsAPI } from './contacts'
import { SailingLogQualificationSelectOptionsAPI } from '../settings/qualifications'

export const moduleDefinitions = {
  moduleName: 'Qualifications',
  moduleNameSingular: 'Qualification',
  moduleLink: '/fun/sailinglog/contacts/qualifications/',
}

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
    fieldErrorMessage = ""

  switch (fieldName) {

    case "qualification":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Qualification is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break


    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

///////////// API Config /////////////////////////

const APIVariablesCreate = {
  APIRequest: 'SailingLogContactQualificationCreateRequest',
  APISuccess: 'SailingLogContactQualificationCreateSuccess',
  APIFail: 'SailingLogContactQualificationCreateFail',
  APIEndPoint: 'sailinglog/v1/contactqualification/add',
}
const APIVariablesListAll = {
  APIRequest: 'SailingLogContactQualificationListRequest',
  APISuccess: 'SailingLogContactQualificationListSuccess',
  APIFail: 'SailingLogContactQualificationListFail',
  APIEndPoint: 'sailinglog/v1/contactqualification/list'
}
const APIVariablesSelectOptions = {
  APIRequest: 'SailingLogContactQualificationSelectOptionsRequest',
  APISuccess: 'SailingLogContactQualificationSelectOptionsSuccess',
  APIFail: 'SailingLogContactQualificationSelectOptionsFail',
  APIEndPoint: 'sailinglog/v1/contactqualification/selectoptions'
}
const APIVariablesSummaryByContact = {
  APIRequest: 'SailingLogContactQualificationContactSummaryRequest',
  APISuccess: 'SailingLogContactQualificationContactSummarySuccess',
  APIFail: 'SailingLogContactQualificationContactSummaryFail',
  APIEndPoint: 'sailinglog/v1/contactqualification/contactsummary'
}
const APIVariablesItemDetails = {
  APIRequest: 'SailingLogContactQualificationViewDetailsRequest',
  APISuccess: 'SailingLogContactQualificationViewDetailsSuccess',
  APIFail: 'SailingLogContactQualificationViewDetailsFail',
  APIEndPoint: 'sailinglog/v1/contactqualification/'
}
const APIVariablesUpdate = {
  APIRequest: 'SailingLogContactQualificationUpdateRequest',
  APISuccess: 'SailingLogContactQualificationUpdateSuccess',
  APISuccess2: 'SailingLogContactQualificationViewDetailsSuccess',
  APIFail: 'SailingLogContactQualificationUpdateFail',
  APIEndPoint: 'sailinglog/v1/contactqualification/'
}
const APIVariablesDelete = {
  APIRequest: 'SailingLogContactQualificationDeleteRequest',
  APISuccess: 'SailingLogContactQualificationDeleteSuccess',
  APIFail: 'SailingLogContactQualificationDeleteFail',
  APIEndPoint: 'sailinglog/v1/contactqualification/'
}

export const SailingLogContactQualificationCreateAPI = (item) => async (dispatch, getState) => {
  const cleanissuedate = item.issued_date === '' ? null : item.issued_date;
  const cleanexpirydate = item.expiry_date === '' ? null : item.expiry_date;
  const API_Body = {
    contact: item.contact,
    qualification: item.qualification,
    reference_no: item.reference_no,
    issued_date: cleanissuedate,
    issued_by: item.issued_by,
    expiry_date: cleanexpirydate,
    notes: item.notes,
  }
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const SailingLogContactQualificationListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const SailingLogContactQualificationSelectOptionsAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesSelectOptions, dispatch, getState)
}

export const SailingLogContactQualificationSummaryByContactAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesSummaryByContact, dispatch, getState, query)
}

export const SailingLogContactQualificationDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const SailingLogContactQualificationUpdateAPI = (item) => async (dispatch, getState) => {
  const cleanissuedate = item.issued_date === '' ? null : item.issued_date;
  const cleanexpirydate = item.expiry_date === '' ? null : item.expiry_date;
  const API_Body = {
    contact: item.contact,
    qualification: item.qualification,
    reference_no: item.reference_no,
    issued_date: cleanissuedate,
    issued_by: item.issued_by,
    expiry_date: cleanexpirydate,
    notes: item.notes,
  }
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const SailingLogContactQualificationDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

///////////// REDUCERS/////////////////////////
// State is an empty object
export const SailingLogContactQualificationCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SailingLogContactQualificationCreateRequest':
      return {
        loading: true
      }

    case 'SailingLogContactQualificationCreateSuccess':
      return {
        loading: false,
        success: true,
        SailingLogContactQualification: action.payload
      }

    case 'SailingLogContactQualificationCreateFail':
      return {
        loading: false,
        error: action.payload
      }

    case 'SailingLogContactQualificationCreateReset':
      return {}


    default:
      return state
  }
}

export const SailingLogContactQualificationUpdateReducer = (state = { SailingLogContactQualification: {} }, action) => {
  switch (action.type) {
    case 'SailingLogContactQualificationUpdateRequest':
      return { loading: true }

    case 'SailingLogContactQualificationUpdateSuccess':
      return { loading: false, success: true }

    case 'SailingLogContactQualificationUpdateFail':
      return { loading: false, error: action.payload }

    case 'SailingLogContactQualificationUpdateReset':
      return { SailingLogContactQualification: {} }

    default:
      return state
  }
}

export const SailingLogContactQualificationDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SailingLogContactQualificationDeleteRequest':
      return { loading: true }

    case 'SailingLogContactQualificationDeleteSuccess':
      return {
        loading: false,
        success: true
      }

    case 'SailingLogContactQualificationDeleteFail':
      return { loading: false, error: action.payload }

    case 'SailingLogContactQualificationDeleteReset':
      return {}

    default:
      return state
  }
}

export const SailingLogContactQualificationDetailsReducer = (state = { loading: true, SailingLogContactQualification: [] }, action) => {
  switch (action.type) {
    case 'SailingLogContactQualificationViewDetailsRequest':
      return {
        ...state,
        loading: true
      }

    case 'SailingLogContactQualificationViewDetailsSuccess':
      return {
        loading: false,
        SailingLogContactQualification: action.payload
      }

    case 'SailingLogContactQualificationViewDetailsFail':
      return {
        loading: false,
        error: action.payload
      }


    default:
      return state
  }
}


export const SailingLogContactQualificationListReducer = (state = { SailingLogContactQualification: [] }, action) => {
  switch (action.type) {
    case 'SailingLogContactQualificationListRequest':
      return {
        loading: true
      }

    case 'SailingLogContactQualificationListSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
        SailingLogContactQualification: action.payload
      }

    case 'SailingLogContactQualificationListFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}
           
export const SailingLogContactQualificationContactSummaryReducer = (state = { SailingLogContactQualification: [] }, action) => {
  switch (action.type) {
    case 'SailingLogContactQualificationContactSummaryRequest':
      return {
        loading: true
      }

    case 'SailingLogContactQualificationContactSummarySuccess':
      return {
        loading: false,
        SailingLogContactQualification: action.payload
      }

    case 'SailingLogContactQualificationContactSummaryFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const SailingLogContactQualificationSelectOptionsReducer = (state = { SailingLogContactQualification: [] }, action) => {
  switch (action.type) {
    case 'SailingLogContactQualificationSelectOptionsRequest':
      return {
        loading: true
      }

    case 'SailingLogContactQualificationSelectOptionsSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
      }

    case 'SailingLogContactQualificationSelectOptionsFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}



function SailingLogContactQualification(props) {
  const createAPI = SailingLogContactQualificationCreateAPI
  const listAPI = SailingLogContactQualificationListAllAPI
  const detailsAPI = SailingLogContactQualificationDetailsAPI
  const updateAPI = SailingLogContactQualificationUpdateAPI
  const deleteAPI = SailingLogContactQualificationDeleteAPI

  const resetCreate = 'SailingLogContactQualificationCreate_Reset'
  const resetUpdate = 'SailingLogContactQualificationUpdate_Reset'
  const resetViewDetails = 'SailingLogContactQualification_ViewDetails_Reset'
  const resetDelete = 'SailingLogContactQualificationDelete_Reset'

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()
  const paramsItemSplit = paramsItem.action.split(':')
  const paramsID = paramsItemSplit[1]

  const queryParameters = new URLSearchParams(window.location.search)
  const contact = queryParameters.get("contact")

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const SailingLogContactQualificationCreate = useSelector(state => state.SailingLogContactQualificationCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = SailingLogContactQualificationCreate

  const SailingLogContactQualificationList = useSelector(state => state.SailingLogContactQualificationList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    SailingLogContactQualification: dataList } = SailingLogContactQualificationList

  const SailingLogContactQualificationDetails = useSelector(state => state.SailingLogContactQualificationDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    SailingLogContactQualification: dataItem } = SailingLogContactQualificationDetails

  const SailingLogContactQualificationUpdate = useSelector(state => state.SailingLogContactQualificationUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = SailingLogContactQualificationUpdate

  const SailingLogContactQualificationDelete = useSelector(state => state.SailingLogContactQualificationDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = SailingLogContactQualificationDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingList || loadingDetails
    )
  )

   const moduleFields = [
    {
      label: 'Contact',
      fieldName: 'contact',
      fieldLongName: 'contact_name',
      fieldType: 'selectfromdb',
      options: 'SailingLogContactList',
      addFormOrder: '',
      addFormInitialValue: contact,
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '1',
      disabled: true
    }, {
      label: 'Qualification',
      fieldName: 'qualification',
      mandatory: "true",
      fieldLongName: 'qualification_name',
      // fieldType: 'autoCompleteText',
      fieldType: 'selectfromdb',
      options: 'SailingLogQualificationSelectOptions',
      addNewLink:'/fun/sailinglog/settings/qualifications/add/',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '1',
    }, {
      label: 'Reference No.',
      fieldName: 'reference_no',
      fieldType: 'text',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '2',
    }, {
      label: 'Issued Date',
      fieldName: 'issued_date',
      fieldType: 'date',
      fieldGroupPosition: 'Left',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '3',
    }, {
      label: 'Expiry Date',
      fieldName: 'expiry_date',
      fieldType: 'date',
      fieldGroupPosition: 'Right',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '4',
    }, {
      label: 'Issued By',
      fieldName: 'issued_by',
      fieldType: 'text',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '4',
    }, {
      label: 'Notes',
      fieldName: 'notes',
      fieldType: 'textarea',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '4',
    },
  ]
  
  useEffect(() => {
    dispatch(SailingLogContactQualificationListAllAPI())
    dispatch(SailingLogContactSelectOptionsAPI())
    dispatch(SailingLogQualificationSelectOptionsAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }

  }, [successDelete, successUpdate,
    errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete,paramsID])


  return (
    <ModuleHome
        formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
    moduleModalVisibility={props.moduleModalVisibility}
    setModuleModalVisibility={props.setModuleModalVisibility}
    
    paramsItem={paramsItem}
    moduleDefinitions={moduleDefinitions}
    moduleFields={moduleFields}

    //Table Data
    getDataList={listAPI}
    dataList={dataList}
    loadingList={loadingList}
    errorLoadingList={errorLoadingList}

    // rowOnClickAction={'view'} 
    view={(dataitem) => navigate(moduleDefinitions.moduleLink + 'view:' + dataitem.id)}
    add={true}
    edit={(dataitem) => navigate(moduleDefinitions.moduleLink + 'edit:' + dataitem.id)}
    delete={(id) => dispatch(deleteAPI(id))}

    //AddEditForm
    fieldValidation={fieldValidation}

    loading={loading}
    serverErrorMessage={serverErrorMessage}
    setServerErrorMessage={setServerErrorMessage}

    itemDetails={detailsAPI}
    dataItem={dataItem}
    itemDetailsReset={resetViewDetails}

    itemCreate={createAPI}
    successCreate={successCreate}
    resetCreate={resetCreate}

    itemUpdate={updateAPI}
    successUpdate={successUpdate}
    resetUpdate={resetUpdate}

    itemDelete={deleteAPI}
    successDelete={successDelete}
    resetDelete={resetDelete}

    //View Page
    // viewTabs={viewTabs} 
    // headingName={dataItem.name}
 
      /> 
  )
}

export default SailingLogContactQualification
