//  React
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
// Components
import Loader from '../loading/spinner';
import InputFields from '../fields/inputFields/inputFields';
import {moduleFields as person} from '../../modules/contacts/person'
import {moduleFields as organisation} from '../../modules/contacts/organisation'

const SubForm = (props) => {
  // console.log('SubForm - props' ,props)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [moduleFields, setModuleFields] = useState(person)
  const [nextaction, setNextaction] = useState('')
  const [fieldValues, setFieldValues] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false)

  // console.log('person' ,person)
  // console.log('moduleFields' ,moduleFields)

  useEffect(()=> {
    const a = person
    const b = organisation
    if (props.subFormFieldDefinitions == 'person') 
      setModuleFields(a)
    if (props.subFormFieldDefinitions == 'organisation') 
    setModuleFields(b)
  }, [props.subFormFieldDefinitions])
  // console.log('props.subFormFieldDefinitions',props.subFormFieldDefinitions)
  ///////////// Section 1 - Define fields////////////////////////////////
  const initialFieldValues = moduleFields
    .map((fields) => {

      // Set defualt value for new fields
      const value = (fields.addFormInitialValue === undefined ? '' : fields.addFormInitialValue)

      return {
        fieldName: fields.fieldName,
        value: value,
        // Form Validation
        touched: false,
        hasError: false,
        fieldErrorMessage: "",
      };
    })

  // Create initial field values when screen first loads
  useEffect(() => {
    setFieldValues(initialFieldValues)
  }, [])

  const fieldValue = (field) => {
    // console.log('AddEditForm - fieldValue', field)
    // console.log('AddEditForm - fieldValues',fieldValues)
    const fieldstate = fieldValues.find(e => e.fieldName === field)
    if (fieldstate === undefined) { return '' } else {
      // console.log('fieldstate.value',field, fieldstate.value)
      return fieldstate.value
    }
  }

  // Used for AutoComplete
  const fieldValueName = (field) => {
    // console.log('AddEditForm - fieldValueName', field)
    const fieldstate = fieldValues.find(e => e.fieldName === field)
    // console.log('fieldstate.fieldValueName',fieldstate.fieldValueName)
    if (fieldstate === undefined) { return '' } else { return fieldstate.fieldValueName }
  }

  const fieldTouched = (field) => {
    const fieldstate = fieldValues.find(e => e.fieldName === field)
    if (fieldstate === undefined) { return '' } else { return fieldstate.touched }
  }

  const fieldError = (field) => {
    const fieldstate = fieldValues.find(e => e.fieldName === field)
    if (fieldstate === undefined) { return '' } else { return fieldstate.fieldErrorMessage }
  }

  const formHasError = (field) => {
    const fieldstate = fieldValues.find(e => e.fieldName === field)
    if (fieldstate === undefined) { return '' } else { return fieldstate.hasError }
  }

  ///////////// Section 2 - Handle User Actions ////////////////////////////////

  const handleChange = (field, value) => {
    const getindex = fieldValues.findIndex(e => e.fieldName === field)
    return setFieldValues(
      fieldValues.map((currentData, index) => {
        if (index == getindex) {
          return { ...currentData, 'fieldName': field, 'value': value }
        } else {
          return { ...currentData }
        }
      }))
  }

  const handleMultiSelectAdd = (field, value) => {
    // console.log('handleMultiSelectChange1',field, value)
    const getindex = fieldValues.findIndex(e => e.fieldName === field)
    return setFieldValues(
      fieldValues.map((currentData, index) => {
        if (index == getindex) {
          // console.log('handleMultiSelectChange2',field, value)
          // console.log('currentData',currentData.value)
          const newData = [...currentData.value] // new array
          // console.log('newData1',newData)
          const selectedValueToNumber = parseInt(value)
          // console.log('selectedValueToNumber',selectedValueToNumber)
          newData.push(selectedValueToNumber) // add new object
          // console.log('newData2',newData)
          return { ...currentData, 'fieldName': field, 'value': newData }
          // return { ...currentData}
        } else {
          return { ...currentData }
        }
      }))
  }

  const handleMultiSelectDelete = (field, value) => {
    // console.log('handleMultiSelectDelete1',field, value)
    const getindex = fieldValues.findIndex(e => e.fieldName === field)
    return setFieldValues(
      fieldValues.map((currentData, index) => {
        if (index == getindex) {
          // console.log('handleMultiSelectDelete2',field, value)
          // console.log('currentData',currentData.value)
          const index = currentData.value.findIndex(e => e === value)
          // console.log('index',index)
          const newData = [...currentData.value] // new array
          // console.log('newData1',newData)
          newData.splice(index, 1); // remove old object
          // newData.push(selectedValueToNumber) // add new object
          // console.log('newData2',newData)
          return { ...currentData, 'fieldName': field, 'value': newData }
          // return { ...currentData}
        } else {
          return { ...currentData }
        }
      }))
  }


  const resetForm = () => {
    props.setServerErrorMessage('')
    dispatch({ type: props.resetCreate })
    dispatch({ type: props.resetUpdate })
  }

  const handleCancel = (e) => {
    e.preventDefault()
    resetForm()
    props.setFormModalVisibility ? props.setFormModalVisibility() : navigate(-1)
  }

  const handleSave = (e, action) => {
    e.preventDefault()
    resetForm()
    setFormSubmitted(true)
    if (action == 'Add') {
      setNextaction('Add')
    } else {
      setNextaction('Exit')
    }
    validateAllFields()
    if (isFormValid() === true) {
      sendDataToDB()
    // } else {
    //   setErrorMessage('Please check the fields below')
    }
  }

  const sendDataToDB = () => {
    if (props.formType === 'Add') {
      dispatch(props.itemCreate(dataForDB))
    } else {
      const insertID = { id: props.dataItem.id }
      const dataForDBWithID = { ...dataForDB, ...insertID }
      console.log('dataForDB',dataForDB,'insertID',insertID)
      dispatch(props.itemUpdate(dataForDBWithID))
    }
  }

  // Extract required data from the Array to be sent to the DB.
  const dataForDB = fieldValues.reduce(
    (obj, item) => Object.assign(obj, { [item.fieldName]: item.value }), {});

  // triggered with onBlur(user exits a field) and on form submission.
  const onBlur = (field, value) => {
    if (formSubmitted === true) {
      isFormValid()
    }
    validateOneField(field, value)
  }

  const validateOneField = (field, value) => {
    const { hasError, fieldErrorMessage } = props.fieldValidation(field, value)
    const getindex = fieldValues.findIndex(e => e.fieldName === field)
    return setFieldValues(
      fieldValues.map((currentData, index) => {
        if (index == getindex) {
          if (hasError) {
            return { ...currentData, touched: true, hasError: true, fieldErrorMessage: fieldErrorMessage }
          } else {
            return { ...currentData, touched: true, hasError: false, fieldErrorMessage: '' }
          }
        } else {
          return { ...currentData }
        }
      }))
  }

  const validateAllFields = () => {
    return setFieldValues(
      fieldValues.map((currentData, index) => {
        const { hasError, fieldErrorMessage } = props.fieldValidation(currentData.fieldName, currentData.value)
        const getindex = fieldValues.findIndex(i => i.fieldName === currentData.fieldName)
        if (index == getindex) {
          if (hasError) {
            return { ...currentData, touched: true, hasError: true, fieldErrorMessage: fieldErrorMessage }
          } else {
            return { ...currentData, hasError: false, fieldErrorMessage: '' }
          }
        } else {
          return { ...currentData }
        }
      }))
  }

  const isFormValid = () => {
    const result = fieldValues.map((currentData, index) => {
      const { hasError } = props.fieldValidation(currentData.fieldName, currentData.value)
      const getindex = fieldValues.findIndex(i => i.fieldName === currentData.fieldName)
      if (index == getindex) { if (hasError) { return false } else { return true } }
    })
    const resultSummary = result.every(bool => bool)
    // if (resultSummary === true) { setErrorMessage('') }
    return resultSummary
  }

  ///////////// Section 3 - Post save effects  ////////////////////////////////
  // console.log('addedit - itemId:',props.itemId)
  // console.log('addedit - item:',props.item)
  // When the form has succesfully saved to the DB
  useEffect(() => {
    if (props.successCreate) {
      dispatch({ type: props.resetCreate })
      dispatch(props.getDataList())
      if (nextaction === 'Exit') {
        resetForm()
        props.setFormModalVisibility ? props.setFormModalVisibility() : navigate(-1)
      }
      if (nextaction === 'Add') {
        // setSuccessMessage(props.moduleDefinitions.moduleNameSingular + ' successfully saved')
        setFieldValues(initialFieldValues)
      }
    }
    if (props.formType == 'Edit') {
      // New from edit form
      if (props.successUpdate) {
        dispatch({ type: props.resetUpdate })
        dispatch(props.getDataList())
        props.setFormModalVisibility ? props.setFormModalVisibility() : navigate(-1)
      }
      if (props.successDelete) {
        dispatch({ type: props.resetDelete })
        dispatch(props.getDataList())
        props.setFormModalVisibility ? props.setFormModalVisibility() : navigate(-1)
      }
      if (props.dataItem === undefined || props.dataItem.id !== Number(props.itemId)) {
        dispatch(props.itemDetails(props.itemId))
      }
      else {
        updateAllFieldValues(props.dataItem)
      }
    }

  }, [dispatch, navigate, props.dataItem, nextaction, props.successCreate, props.successUpdate, props.successDelete])

  ///////////// Section 4 - For Edit form  ////////////////////////////////

  const updateAllFieldValues = (item) => {
    // update fields with database values
    const dbFieldValues = moduleFields.map((fields) => {
      return {
        fieldName: fields.fieldName,
        fieldLongName: fields.fieldLongName,
        value: item[fields.fieldName],
        fieldValueName: item[fields.fieldLongName],
        touched: false,
        hasError: false,
        fieldErrorMessage: "",
        source: 'editform',
      };
    })
    setFieldValues(dbFieldValues)
  }

  const [morefields, setMorefields] = useState(false);

  const handleShowMorefields = (event) => {
    event.preventDefault();
    setMorefields(true)
  }

  const handleHideMorefields = (event) => {
    event.preventDefault();
    setMorefields(false)
  }

  const handleDelete = (e) => {
    e.preventDefault()
    dispatch(props.itemDelete(
      props.dataItem.id
    ))
  }

  const fieldIsChecked = (field) => {
    if (fieldValues.find(e => e.fieldName === field) !== undefined) {
      const fieldIndex = fieldValues.findIndex(e => e.fieldName === field)
      const fieldValue = fieldValues[fieldIndex].value
      return fieldValue
    }
  }

  function formatDate(string) {
    var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return new Date(string).toLocaleDateString([], options);
  }

  const displayFields = (field) => {
    return (
      <>
        {field.fieldGroupHeading && <p style={{ fontWeight: 'bold', marginBottom: '0px', marginTop: '5px' }}>{field.fieldGroupHeading}</p>}
        {/* {console.log('field.addFormConditionalRenderBasedOn',field.fieldName, field.addFormConditionalRenderBasedOn)} */}
        {/* {console.log('multipleSelectIndex',props.multipleSelectIndex)} */}
        <InputFields
          key={field.fieldName}
          label={field.label}
          formType={props.formType}

          fieldName={field.fieldName}
          fieldType={field.fieldType}
          fieldGroupHeading={field.fieldGroupHeading}
          fieldGroupPosition={field.fieldGroupPosition}
          fieldGroupSize={field.fieldGroupSize}
          value={fieldValue(field.fieldName)}
          fieldValueName={fieldValueName(field.fieldName)}
          onChange={e => handleChange(field.fieldName, e.target.value)}
          onBlur={e => onBlur(field.fieldName, e.target.value)}

          mandatory={field.mandatory}
          disabled={field.disabled}
          //Select
          options={field.options}
          addFormConditionalSelectBasedOn={field.addFormConditionalSelectBasedOn}
          addFormConditionalSelectLinkField={field.addFormConditionalSelectLinkField}
          conditionalSelectFieldValue={fieldValue(field.addFormConditionalSelectBasedOn)}

          multipleSelect={field.multipleSelect}
          handleMultiSelectAdd={handleMultiSelectAdd}
          handleMultiSelectDelete={handleMultiSelectDelete}

          // Select Add New
          addNewLink={field.addNewLink}
          openAddNewModule={field.openAddNewModule}
          ItemCreate={field.ItemCreate}

          //TextArea
          rows={field.rows}

          // Form Validation
          touched={fieldTouched(field.fieldName)}
          fieldErrorMessage={fieldError(field.fieldName)}
          hasError={formHasError(field.fieldName)}

          // Text 
          placeholder={field.placeholder}
          autoComplete={field.autoComplete}

          //AutoComplete
          handleChange={handleChange}

          //Checkbox
          fieldIsChecked={fieldIsChecked}

          //See Events / Attendees, handling multiple lines of additional data.
          relatedFields={field.relatedFields}
        />
      </>
    )
  }

  return (
    <>
          <div>
            {props.loading ? <Loader /> :
              <form>
                {moduleFields
                  .filter((field) => (props.formType == 'Add' ? field.addFormOrder !== 0 : field.editFormOrder !== 0))
                  .sort((a, b) => (props.formType === 'Add' ? a.addFormOrder - b.addFormOrder : a.editFormOrder - b.editFormOrder))
                  .map((field) =>
                  // Does the field have conditional logic?

                  (field.addFormConditionalRenderBasedOn ?
                    //  Logic for checkboxes true/false
                    field.addFormConditionalRenderValues === false || field.addFormConditionalRenderValues === true ? field.addFormConditionalRenderValues === fieldValue(field.addFormConditionalRenderBasedOn) && displayFields(field) :
                      //  Logic for 'selectfromdb' values
                      field.addFormConditionalRenderValues.includes(parseInt(fieldValue(field.addFormConditionalRenderBasedOn))) &&
                      // if yes display the field,
                      displayFields(field) :
                    // if no conditions then show the fields
                    displayFields(field))
                    //   // Console Logs for Conditional Rendering for select fields
                    //   console.log('field.addFormConditionalRenderValues',field.fieldName,field.addFormConditionalRenderValues)
                    //   // console.log('parseInt(fieldValue(field.addFormConditionalRenderBasedOn)',field.fieldName, parseInt(fieldValue(field.addFormConditionalRenderBasedOn)))
                    //   // console.log(field.addFormConditionalRenderValues.includes(parseInt(fieldValue(field.addFormConditionalRenderBasedOn))))

                    //   // Console Logs for Conditional Rendering for checkboxes
                    //   // console.log(field.addFormConditionalRenderValues === fieldValue(field.addFormConditionalRenderBasedOn))
                  )
                }
              </form>
            }
        </div >
    </>
  )
}

export default SubForm