import Square from '../components/square';
import { MdArrowBack,MdOutlineSailing,MdSunny, MdPeople, MdMenuBook, MdOutlineRoute, MdTimeline, MdOutlineWatchLater, MdOutlineLoop,MdOutlineWater, MdAnchor, MdBuild, MdSettings,MdWindPower,MdOutlineVisibility,MdVolumeUp,MdLightMode,MdAbc,MdOutlineFlag } from "react-icons/md";
import { WiDayRainMix } from "react-icons/wi";
import style from '../sailingLog.module.css'


const Reference = () => {
  const heading = 'Sailing Log'

  const squares = [
    {
      name: 'Back',
      icon: <MdArrowBack />,
      link: "/fun/sailinglog/",
    },{
      name: 'Acronyms',
      icon: <MdAbc/>,
      link: "/fun/sailinglog/reference/acronyms/list",
    },{
      name: 'Flags',
      icon: <MdOutlineFlag />,
      link: "/fun/sailinglog/reference/flags/list",
      disabled: true,
    },{
      name: 'Glossary',
      icon: <MdAbc />,
      link: "/fun/sailinglog/reference/glossary/list",
    },{
      name: 'Lights & Shapes',
      icon: <MdLightMode />,
      link: "/fun/sailinglog/reference/lights/list",
      disabled: true,
    },{
      name: 'Phonetic Alphabet',
      icon: <MdAbc />,
      link: "/fun/sailinglog/reference/alphabet/list",
    },{
      name: 'Sea State',
      icon: <MdOutlineWater />,
      link: "/fun/sailinglog/reference/seastate/list",
    },{
      name: 'Sound Signals',
      icon: <MdVolumeUp/>,
      link: "/fun/sailinglog/reference/sounds/list",
    },{
      name: 'Visibility',
      icon: <MdOutlineVisibility />,
      link: "/fun/sailinglog/reference/visibility/list",
    },{
      name: 'Weather',
      icon: <WiDayRainMix 
      // style={{fontSize:'50px' }}
      />,
      link: "/fun/sailinglog/reference/windspeed/list",
      disabled: true,
    },{
      name: 'Wind Speed',
      icon: <MdWindPower />,
      link: "/fun/sailinglog/reference/windspeed/list",
    },
  ]

  return (
    <div>
      <div className={style.gridContainer}>
        {squares.map((square) =>
          <Square
            key={square.name}
            name={square.name}
            icon={square.icon}
            link={square.link}
            disabled={square.disabled}
            hoverText={square.hoverText}
          />)}
      </div>
    </div>

  );
};
export default Reference;