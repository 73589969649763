import { useState, useEffect } from 'react';
import Loader from '../../../components/loading/spinner';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import {signin} from '../../../APIs/userAPIConfig';
import style from '../../../style/global.module.css';
import formstyle from '../../../style/form.module.css';
import mmcylogo from '../../../static/PrimaryColourTransparentNoWordsBorder.png';

const SignIn = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const redirect = location.search ? location.search.split('=')[1] :'/'

  const user_Login = useSelector(state => state.user_Login)
  const {error, loading, userInfo } = user_Login

  useEffect(() => {
    if(userInfo) {
        navigate(redirect)
    }
}, [navigate, userInfo, redirect])

const submitHandler = (e) => {
    e.preventDefault()
    dispatch(signin(email,password))
}

  return (
    <div className={style.section}>
      <div className={`${style.fixedcontainer} ${style.center}`}>
        <img
          className={style.mmcylogo}
          src={mmcylogo}
          alt="MMCY Business Solutions Logo" />
        <h2>Sign In</h2>
        {error && <div className={formstyle.errormessage}>{error}</div>}
        {!loading ? 
        <form
          onSubmit={submitHandler}
        >
          <div className={`${formstyle.input} ${formstyle.fullwidth}`}>
            <label htmlFor='Input email'>Email</label>
            <input
              type='email'
              id='inputemail'
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={`${formstyle.input} ${formstyle.fullwidth}`}>
            <label htmlFor='password'>Password</label>
            <input
              type='password'
              id='password'
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className={formstyle.actions}>
              <button className={formstyle.darkblue}>Sign In</button>
            <br></br>
            <NavLink to='/passwordreset'>Forgotten your password?</NavLink>
          </div>
        </form>
        :
        <Loader/>}
      </div>
    </div>
  );
};
export default SignIn;