import React,{useState} from 'react'   
import styles from '../../../style/form.module.css';


function RandomNo() {
  const[number,setNumber] = useState(0)

    return (    
        <div className={styles.center}>
            <h2>Random Number</h2>       
            <label>{number}</label><br></br>    
            <button className={styles.green} onClick={() => setNumber(Math.random)}>Generate</button> 
    
        </div>    
    )    
}   
  
export default RandomNo; 