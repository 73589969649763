import { MdAdd } from 'react-icons/md';
import GenericButton from './genericButton';

const AddButton = (props) => {
  return (
    <GenericButton 
      name='Add'
      onClick={() => {
        props.setFormTypeAction('Add');
        props.setFormModalVisibility(true);
        props.resetInitialValues && props.resetInitialValues()
      }}
      icon={MdAdd}
      iconSize={'30px'}
      className={`${'green'} ${'addEditButtons'}`}
    />
  )
}
export default AddButton