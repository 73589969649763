import style from '../../../../style/global.module.css';

const Template = () => {
  return (
    <div className={style.section}>
      <div className={style.fixedcontainer}>
        <h2>Disable 2FA</h2>
        <p>Enter Text</p>
      </div>
    </div>
  );  
};
export default Template;