const ViewFields = (props) => {
  // console.log('ViewFields',props)

  const currencyField = () => {
    if (props.fieldValue || props.fieldValueName) {
      // console.log('currencyField', field.fieldName, field.currencyCode)
      const getCurrencyCodeValue = props.fieldValueName(props.currencyCode)
      // console.log('getCurrencyCodeValue', getCurrencyCodeValue)
      if (getCurrencyCodeValue) {
        return (
          new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: getCurrencyCodeValue
          }).format(props.fieldValue(props.fieldName))
        )
      } else {
        return ''
      }
    } else {
      return props.data
    }
  }

  const checkboxField = () => {
    if (props.fieldValue || props.fieldValueName) {
      return (
        props.fieldValue(props.fieldName) === true ? 'Yes' : 'No'
      )
    } else {
      return props.data
    }
  }

  const dateFormat = (date) => {
    const jsDate = new Date(date)
    // console.log('jsDate', jsDate)
    return (
      new Intl.DateTimeFormat("en-GB").format(jsDate)
    )

  }

  const dateField = () => {
    // console.log('dateField', props)
    // const value = fieldValue(field.fieldName)
    // const dateArray = value.split('-')
    // console.log('dateArray',dateArray)
    // const year = dateArray[0]
    // const month = dateArray[1]
    // const day = dateArray[2]

    // console.log(day,'/',month,'/',year)
    if (props.fieldValue) {
      const date = props.fieldValue(props.fieldName)
      if (date) {
        dateFormat(date)
      } else {
        return 'not recorded'
      }
    } else {
      if (props.data) {
        if (props.data == '01/01/1970') {
          return ''
        } else {
      return dateFormat(props.data)
        }
      } 
    }
  }

  const timeField = () => {
    // console.log('timeField', props)
    if (props.fieldValue) {
      const time = props.fieldValue(props.fieldName)
      // console.log('time', time)

      if (time) {
        const timeArray = time.split(':')
        const hour = timeArray[0]
        const minutes = timeArray[1]
        return (hour + ':' + minutes)
      } else {
        return 'not recorded'
      }
    } else {
      if (props.data) {
        const time = props.data
        // console.log('time', time)

        if (time) {
          if (time == '00:00:00') {return ''} 
          else { 
          const timeArray = time.split(':')
          const hour = timeArray[0]
          const minutes = timeArray[1]
          return (hour + ':' + minutes)
          }
        } else {return ''}
      }
    }
  }

  const genericField = () => {
    // console.log('genericField',props)
    // console.log('fieldValueName',props.fieldValueName)
    // if (props.fieldValue || props.fieldValueName) {
    //   return (
    //     props.fieldValueName(props.fieldName) === undefined ? props.fieldValue(props.fieldName) : props.fieldValueName(props.fieldName)
    //   )
    // } else {
      return props.data
    // }
  }

  return (
    <>
      {props.fieldType === 'text' && genericField()}
      {props.fieldType === 'autoCompleteText' && genericField()}

      {props.fieldType === 'date' && dateField()}
      {props.fieldType === 'time' && timeField()}
      {props.fieldType === 'checkbox' && checkboxField()}
      {props.fieldType === 'currency' && currencyField()}
      {props.fieldType === 'number' && genericField()}

      {props.fieldType === 'select' && genericField()}
      {props.fieldType === 'selectnoid' && genericField()}
      {props.fieldType === 'selectfromdb' && genericField()}
      {props.fieldType === 'textarea' && genericField()}

    </>
  )
};
export default ViewFields;