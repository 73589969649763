import formstyle from '../../../style/form.module.css';
import { MdMarkEmailRead, MdDeleteForever, MdCancel, MdOutlineCancel } from 'react-icons/md';

const listOfSelectValues = (props) => {
  // console.log('listOfSelectValues - props', props)

  const getPrimaryField = (props) => {
    // console.log('getPrimaryField',props)
    const primaryField = props.relatedFields.find(e => e.fieldDisplay == 'primary') 
    // console.log('primaryField',)
    return(primaryField.fieldName)
  }

  const getIndicatorFlag = (props) => {
    // console.log('getIndicatorFlag',props)
    const primaryField = props.relatedFields.find(e => e.fieldDisplay == 'indicatorFlag') 
    // console.log('indicatorFlag',)
    return(primaryField.fieldName)
  }

  return (
    <>
      <div style={{ marginTop: '5px' }}>
        {Array.isArray(props.value) &&
          props.value.length > 0 ?
          // If there is data in the value array then map out each value.
          props.value.map((value) => <>
            {/* {console.log('listOfSelectValues - value1', value)} */}
            {/* If there are multiple items in the value array... */}
            {/* {console.log('listOfSelectValues - value2', Object.keys(value).length)} */}
            {Object.keys(value).length > 1 ? <>
              {/* {console.log('relatedFields', props.relatedFields)} */}
               <div className={formstyle.listOfSelectValuesValue}>
                <li style={{ marginLeft: '20px' }}>{props.fieldName(value[getPrimaryField(props)])}</li>
              </div>
              {getIndicatorFlag(props) && 
              <div className={formstyle.listOfSelectValuesFlag}>
                <span
                  // onClick={(e) => props.deleteMultiSelectFieldValue(e, value)}
                  title="Email Sent">
                  <i
                    style={{ color: 'green', fontSize: '20px', verticalAlign: 'text-top', cursor: 'default'}} //red
                  ><MdMarkEmailRead /></i>
                </span>
              </div >
}
              <div className={formstyle.listOfSelectValuesDelete}>
                <span
                  onClick={(e) => props.deleteMultiSelectFieldValue(e, value)}
                  title="Delete">
                  <i
                    style={{ color: '#d9534f', fontSize: '20px', verticalAlign: 'text-top' }} //red
                  ><MdCancel /></i>
                </span>
              </div >

            </> : <>
            {/* Fields to be displayed when only a single value is being returned */}
              <div className={formstyle.listOfSelectValuesValue}>
                <li style={{ marginLeft: '20px' }}>{props.fieldName(value)}</li>
              </div>
              <div className={formstyle.listOfSelectValuesDelete}>
                <span
                  onClick={(e) => props.deleteMultiSelectFieldValue(e, value)}
                  title="Delete">
                  <i
                    style={{ color: '#d9534f', fontSize: '20px', verticalAlign: 'text-top' }} //red
                  ><MdCancel /></i>
                </span>
              </div >
            </>
            }
          </>
          )
          // If there is no data in the value array then show this
          : ''
          // <p className={formstyle.warningmessage}>None selected.</p>
          }
      </div >
    </>
  )
}

export default listOfSelectValues