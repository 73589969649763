
//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'

export const moduleDefinitions = {
  moduleName: 'Glossary',
  moduleNameSingular: 'Glossary',
  moduleLink: '/fun/sailinglog/reference/glossary/',
  thOptionsWidth: '115px',
}

export const moduleFields = [
  {
    label: 'Term',
    mandatory: "true",
    fieldName: 'term',
    fieldType: 'text',
    addFormOrder: 1,
    editFormOrder: 1,
    tableDisplayOrder: 1,
    tableDisplayPriority: 1,
  }, {
    label: 'Definition',
    mandatory: "true",
    fieldName: 'definition',
    fieldType: 'textarea',
    rows: '4',
    addFormOrder: 2,
    editFormOrder: 2,
    tableDisplayOrder: 2,
    tableDisplayPriority: 2,
  }, {
    label: 'Link',
    fieldName: 'link',
    fieldType: 'text',
    addFormOrder: 3,
    editFormOrder: 3,
    tableDisplayOrder: 4,
    tableDisplayPriority: 4,
  }
]

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
    fieldErrorMessage = ""
  switch (fieldName) {
    case "name":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Term is required"
      } else if (value.length < 2) {
        hasError = true
        fieldErrorMessage = 'Term is too short'
      } else if (value.length > 50) {
        hasError = true
        fieldErrorMessage = 'Term is too long'
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break
      case "description":
        if (value.trim() === "") {
          hasError = true
          fieldErrorMessage = "Description is required"
        } else if (value.length < 2) {
          hasError = true
          fieldErrorMessage = 'Description is too short'
        } else if (value.length > 300) {
          hasError = true
          fieldErrorMessage = 'Description is too long'
        } else {
          hasError = false
          fieldErrorMessage = ""
        }
        break
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

///////////// Constants /////////////////////////
export const GlossaryCreateRequest = 'GlossaryCreateRequest'
export const GlossaryCreateSuccess = 'GlossaryCreateSuccess'
export const GlossaryCreateFail = 'GlossaryCreateFail'
export const GlossaryCreateReset = 'GlossaryCreateReset'

export const GlossaryDetailsRequest = 'GlossaryDetailsRequest'
export const GlossaryDetailsSuccess = 'GlossaryDetailsSuccess'
export const GlossaryDetailsFail = 'GlossaryDetailsFail'
export const GlossaryDetailsReset = 'GlossaryDetailsReset'

export const GlossaryUpdateRequest = 'GlossaryUpdateRequest'
export const GlossaryUpdateSuccess = 'GlossaryUpdateSuccess'
export const GlossaryUpdateFail = 'GlossaryUpdateFail'
export const GlossaryUpdateReset = 'GlossaryUpdateReset'

export const GlossaryDeleteRequest = 'GlossaryDeleteRequest'
export const GlossaryDeleteSuccess = 'GlossaryDeleteSuccess'
export const GlossaryDeleteFail = 'GlossaryDeleteFail'
export const GlossaryDeleteReset = 'GlossaryDeleteReset'

export const GlossaryList_MYRequest = 'GlossaryList_MYRequest'
export const GlossaryList_MYSuccess = 'GlossaryList_MYSuccess'
export const GlossaryList_MYFail = 'GlossaryList_MYFail'
export const GlossaryList_MYReset = 'GlossaryList_MYReset'

export const GlossaryListRequest = 'GlossaryListRequest'
export const GlossaryListSuccess = 'GlossaryListSuccess'
export const GlossaryListFail = 'GlossaryListFail'


///////////// API Config /////////////////////////


const APIVariablesCreate = {
  APIRequest: GlossaryCreateRequest,
  APISuccess: GlossaryCreateSuccess,
  APIFail: GlossaryCreateFail,
  APIEndPoint: 'sailinglog/v1/glossary/add',
}
const APIVariablesListAll = {
  APIRequest: GlossaryListRequest,
  APISuccess: GlossaryListSuccess,
  APIFail: GlossaryListFail,
  APIEndPoint: 'sailinglog/v1/glossary/list'
}
const APIVariablesItemDetails = {
  APIRequest: GlossaryDetailsRequest,
  APISuccess: GlossaryDetailsSuccess,
  APIFail: GlossaryDetailsFail,
  APIEndPoint: 'sailinglog/v1/glossary/'
}
const APIVariablesUpdate = {
  APIRequest: GlossaryUpdateRequest,
  APISuccess: GlossaryUpdateSuccess,
  APISuccess_2: GlossaryDetailsSuccess,
  APIFail: GlossaryUpdateFail,
  APIEndPoint: 'sailinglog/v1/glossary/'
}
const APIVariablesDelete = {
  APIRequest: GlossaryDeleteRequest,
  APISuccess: GlossaryDeleteSuccess,
  APIFail: GlossaryDeleteFail,
  APIEndPoint: 'sailinglog/v1/glossary/'
}

export const GlossaryCreateAPI = (item) => async (dispatch, getState) => {
  const API_Body = {
    term: item.term,
    definition: item.definition,
    link: item.link,
  }
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const GlossaryListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const GlossaryDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const GlossaryUpdateAPI = (item) => async (dispatch, getState) => {
  const API_Body = {
    term: item.term,
    definition: item.definition,
    link: item.link,
  }

  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}


export const GlossaryDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

///////////// REDUCERS/////////////////////////
// State is an empty object
export const SailingLogGlossaryCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case GlossaryCreateRequest:
      return {
        loading: true
      }

    case GlossaryCreateSuccess:
      return {
        loading: false,
        success: true,
        SailingLogGlossary: action.payload
      }

    case GlossaryCreateFail:
      return {
        loading: false,
        error: action.payload
      }

    case GlossaryCreateReset:
      return {}


    default:
      return state
  }
}

export const SailingLogGlossaryUpdateReducer = (state = { SailingLogGlossary: {} }, action) => {
  switch (action.type) {
    case GlossaryUpdateRequest:
      return { loading: true }

    case GlossaryUpdateSuccess:
      return { loading: false, success: true }

    case GlossaryUpdateFail:
      return { loading: false, error: action.payload }

    case GlossaryUpdateReset:
      return { SailingLogGlossary: {} }

    default:
      return state
  }
}

export const SailingLogGlossaryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case GlossaryDeleteRequest:
      return { loading: true }

    case GlossaryDeleteSuccess:
      return {
        loading: false,
        success: true
      }

    case GlossaryDeleteFail:
      return { loading: false, error: action.payload }

    case GlossaryDeleteReset:
      return {}

    default:
      return state
  }
}

export const SailingLogGlossaryDetailsReducer = (state = { loading: true, SailingLogGlossary: [] }, action) => {
  switch (action.type) {
    case GlossaryDetailsRequest:
      return {
        ...state,
        loading: true
      }

    case GlossaryDetailsSuccess:
      return {
        loading: false,
        success: true,
        SailingLogGlossary: action.payload
      }

    case GlossaryDetailsFail:
      return {
        loading: false,
        error: action.payload,
        SailingLogGlossary: 'error'
      }

    case GlossaryDetailsReset:
      return { SailingLogGlossary: {} }

    default:
      return state
  }
}

export const SailingLogGlossaryListReducer = (state = { SailingLogGlossary: [] }, action) => {
  switch (action.type) {
    case GlossaryListRequest:
      return {
        loading: true
      }

    case GlossaryListSuccess:
      return {
        loading: false,
        success: true,
        SailingLogGlossary: action.payload
      }

    case GlossaryListFail:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}


function SailingLogGlossary() {
  const createAPI = GlossaryCreateAPI
  const listAPI = GlossaryListAllAPI
  const detailsAPI = GlossaryDetailsAPI
  const updateAPI = GlossaryUpdateAPI
  const deleteAPI = GlossaryDeleteAPI

  const resetCreate = GlossaryCreateReset
  const resetUpdate = GlossaryUpdateReset
  const resetViewDetails = GlossaryDetailsReset
  const resetDelete = GlossaryDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const SailingLogGlossaryCreate = useSelector(state => state.SailingLogGlossaryCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = SailingLogGlossaryCreate

  const SailingLogGlossaryList = useSelector(state => state.SailingLogGlossaryList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    SailingLogGlossary: dataList } = SailingLogGlossaryList

  const SailingLogGlossaryDetails = useSelector(state => state.SailingLogGlossaryDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    SailingLogGlossary: dataItem } = SailingLogGlossaryDetails

  const SailingLogGlossaryUpdate = useSelector(state => state.SailingLogGlossaryUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = SailingLogGlossaryUpdate

  const SailingLogGlossaryDelete = useSelector(state => state.SailingLogGlossaryDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = SailingLogGlossaryDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate : loadingUpdate || loadingDelete || loadingDetails
    )
  )

  // const categoryList = useSelector(state => state.categoryList)
  // const { categories } = categoryList

  useEffect(() => {
    dispatch(listAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: GlossaryUpdateReset })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: GlossaryDeleteReset })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }
  }, [successDelete, successUpdate,
    errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete])


  return (
    <ModuleHome
    formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
      // Shared items
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      //Table Actions
      rowOnClickAction='edit'
      add={true}
      edit={true}
      view={false}
      delete={(id) => dispatch(deleteAPI(id))}

      inLineAdd={false}
      inLineEdit={false}

      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      getDataList={listAPI}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}

      //View Page
      // viewTabs={viewTabs} 
      // headingName={dataItem.name}

    />
  )
}

export default SailingLogGlossary