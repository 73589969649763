import ResponsiveContainer from '../../../components/containers/responsiveContatiner';
import Counter from './counter';
import Stopwatch from './stopwatch';
import Countdown from './countdown';
import RandomNo from './randomno';
import Time from './time';
import UOM from './UOM';
import Racetimer from './racetimer';

import "../../../hooks/tabs/tabs.css"
import Tabs from '../../../hooks/tabs/useTabs'; 

const Tools = () => {

  const heading = 'Tools'
  const contents = (<>
   <Tabs>
      <div label="Date & Time">
          <Time />
        </div>
        <div label="Countdown">
          <Countdown />
        </div>
        <div label="Stopwatch">
          <Stopwatch />
        </div>
        <div label="Counter">
          <Counter />
        </div>
        <div label="Random No.">
          <RandomNo />
        </div>
        <div label="UOM">
          <UOM />
        </div>
        {/* <div label="Race Timer">
        <Racetimer />
        </div> */}
      </Tabs>

    </>)

  return (
    <ResponsiveContainer
      heading={heading}
      contents={contents}
    />
  );
};
export default Tools;
