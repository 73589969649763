import { Link } from 'react-router-dom';
import FixedContainer from '../../components/containers/fixedContatiner';

const Settings = () => {
  const heading = 'System Settings'
  const contents = (<>
      <p> These settings affect all users in your organisation. </p>
      <p> For your settings go to <Link to={'/user/settings'}>User Settings</Link></p>
      <h3>General</h3>
      <Link to={'/system/settings/address/list'}>Locations</Link><br></br>
      <Link to={'/system/settings/categories/list'}>Categories</Link><br></br>
      <Link to={'/system/settings/countrycodes/'}>Country Codes</Link><br></br>
      <Link to={'/system/settings/currencycodes'}>Currency Codes</Link>
      <h3>Tasks</h3>
      <Link to={'/system/settings/tasks/status/list'}>Status Options</Link><br></br>
      <Link to={'/system/settings/tasks/priority/list'}>Priority Options</Link>
      <h3>Events</h3>
      <Link to={'/system/settings/events/status/list'}>Status Options</Link><br></br>
      <Link to={'/system/settings/events/types/list'}>Event Types</Link><br></br>
      {/* <Link to={'/system/settings/events/visibility/list'}>Visibility</Link><br></br> */}
      <h3>Contacts</h3>
      <Link to={'/system/settings/contacts/relationships/list'}>Relationships</Link><br></br>
      <h3>Inventory</h3>
      <Link to={'/system/settings/inventory/category/list'}>Categories</Link><br></br>
      <Link to={'/system/settings/inventory/checklist/criteria/list'}>Criteria Options</Link><br></br>
    </>
  )


  return (
    <FixedContainer
      heading={heading}
      contents={contents}
    />

  );
};
export default Settings;