//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../components/containers/moduleHome'
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../APIs/genericAPIs'

import { SailingLogBoatTypeSelectOptionsAPI } from './settings/boatTypes'
import { SailingLogContactSelectOptionsAPI } from './contacts/contacts'
import { SailingLogLocationSelectOptionsAPI } from './locations'
import { SailingLogTripSummaryByBoatAPI } from './trips'
import { SailingLogCrewSummaryByBoatAPI } from './passage/crew'

import {local_YYYY as currentYear} from '../../hooks/useDateTime'

// console.log('currentYear',currentYear)
const moduleDefinitions = {
  moduleName: 'Boats',
  moduleNameSingular: 'Boat',
  moduleLink: '/fun/sailinglog/boats/',
  thOptionsWidth: '115px',
}

const moduleFields = [
  {
    label: 'Boat Name',
    mandatory: 'true',
    fieldName: 'name',
    fieldType: 'text',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  }, {
    label: 'Boat Type',
    fieldName: 'boat_type',
    fieldLongName: 'boat_type_name',
    fieldType: 'selectfromdb',
    options: 'SailingLogBoatTypeSelectOptions',
    // addNewLink: '/fun/sailinglog/settings/boattypes/add/',
    openAddNewModule: 'sailinglog/settings/boattypes',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '3',
  }, {
    label: 'Make & Model',
    fieldName: 'boat_make_model',
    fieldType: 'text',
    addFormOrder: 0,
    editFormOrder: 0,
    filterOrder: 0,
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  }, {
    label: 'Make',
    fieldName: 'make',
    fieldType: 'text',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  }, {
    label: 'Model',
    fieldName: 'model',
    fieldType: 'text',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  }, {
    label: 'LOA (m)',
    fieldName: 'loa',
    fieldType: 'number',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '2',
  }, {
    label: 'Draft (m)',
    fieldName: 'draft',
    fieldType: 'number',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '2',
  }, {
    label: 'Height (m)',
    fieldName: 'height',
    fieldType: 'number',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  }, {
    label: 'Beam (m)',
    fieldName: 'beam',
    fieldType: 'number',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  }, {
    label: 'Year',
    fieldName: 'year',
    fieldType: 'number',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '3',
  }, {
    label: 'Home Marina',
    fieldName: 'home_marina',
    fieldLongName: 'home_marina_name',
    fieldType: 'autoCompleteText',
    options: 'SailingLogLocationSelectOptions',
    // addNewLink: '/fun/sailinglog/locations/add/',
    openAddNewModule: 'sailinglog/locations',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '3',
  }, {
    label: 'Owner',
    fieldName: 'owner',
    fieldLongName: 'owner_name',
    fieldType: 'autoCompleteText',
    options: 'SailingLogContactSelectOptions',
    // addNewLink: '/fun/sailinglog/contacts/add/',
    openAddNewModule: 'sailinglog/contacts',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '4',
  }, {
    label: 'IRC Rating',
    fieldName: 'irc_rating',
    fieldType: 'number',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  }, {
    label: 'Sail Number',
    fieldName: 'sail_number',
    fieldType: 'text',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  }, {
    label: 'MMSI',
    fieldName: 'MMSI',
    fieldType: 'number',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  }, {
    label: 'Link',
    fieldName: 'link',
    fieldType: 'text',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  }, {
    label: 'Notes',
    fieldName: 'notes',
    fieldType: 'textarea',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  },
]

const fieldValidation = (fieldName, value) => {
  let hasError = false,
    fieldErrorMessage = ""

  switch (fieldName) {

    case "name":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Name is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break
    
    case "year":
      if (value > currentYear) {
        hasError = true
        // console.log('value 1',value)
        fieldErrorMessage = "Boat can not be built in the future"
      } else if (value < 1900 ) {
        if (value === '') {
          hasError = false
          // console.log('value 3',value)
          fieldErrorMessage = ""
        }else {
        hasError = true
        // console.log('value 2',value)
        fieldErrorMessage = "Boat must be built after 1900"
      } 
    }
      break

    default:
      break
  }
  return { hasError, fieldErrorMessage }
}


///////////// Constants /////////////////////////
export const SailingLogBoatCreateRequest = 'SailingLogBoatCreateRequest'
export const SailingLogBoatCreateSuccess = 'SailingLogBoatCreateSuccess'
export const SailingLogBoatCreateFail = 'SailingLogBoatCreateFail'
export const SailingLogBoatCreateReset = 'SailingLogBoatCreateReset'

export const SailingLogBoatDetailsRequest = 'SailingLogBoatDetailsRequest'
export const SailingLogBoatDetailsSuccess = 'SailingLogBoatDetailsSuccess'
export const SailingLogBoatDetailsFail = 'SailingLogBoatDetailsFail'
export const SailingLogBoatDetailsReset = 'SailingLogBoatDetailsReset'

export const SailingLogBoatUpdateRequest = 'SailingLogBoatUpdateRequest'
export const SailingLogBoatUpdateSuccess = 'SailingLogBoatUpdateSuccess'
export const SailingLogBoatUpdateFail = 'SailingLogBoatUpdateFail'
export const SailingLogBoatUpdateReset = 'SailingLogBoatUpdateReset'

export const SailingLogBoatDeleteRequest = 'SailingLogBoatDeleteRequest'
export const SailingLogBoatDeleteSuccess = 'SailingLogBoatDeleteSuccess'
export const SailingLogBoatDeleteFail = 'SailingLogBoatDeleteFail'
export const SailingLogBoatDeleteReset = 'SailingLogBoatDeleteReset'

export const SailingLogBoatListRequest = 'SailingLogBoatListRequest'
export const SailingLogBoatListSuccess = 'SailingLogBoatListSuccess'
export const SailingLogBoatListFail = 'SailingLogBoatListFail'


///////////// API Config /////////////////////////

const APIVariablesCreate = {
  APIRequest: SailingLogBoatCreateRequest,
  APISuccess: SailingLogBoatCreateSuccess,
  APIFail: SailingLogBoatCreateFail,
  APIEndPoint: 'sailinglog/v1/boat/add',
}
const APIVariablesListAll = {
  APIRequest: SailingLogBoatListRequest,
  APISuccess: SailingLogBoatListSuccess,
  APIFail: SailingLogBoatListFail,
  APIEndPoint: 'sailinglog/v1/boat/list'
}
const APIVariablesSelectOptions = {
  APIRequest: 'SailingLogBoatSelectOptionsRequest',
  APISuccess: 'SailingLogBoatSelectOptionsSuccess',
  APIFail: 'SailingLogBoatSelectOptionsFail',
  APIEndPoint: 'sailinglog/v1/boat/selectoptions'
}
const APIVariablesSummaryByContact = {
  APIRequest: 'SailingLogBoatContactSummaryRequest',
  APISuccess: 'SailingLogBoatContactSummarySuccess',
  APIFail: 'SailingLogBoatContactSummaryFail',
  APIEndPoint: 'sailinglog/v1/boat/contactsummary'
}
const summaryByLocationAPIVariables = {
  APIRequest: 'SailingLogBoatLocationSummaryRequest',
  APISuccess: 'SailingLogBoatLocationSummarySuccess',
  APIFail: 'SailingLogBoatLocationSummaryFail',
  APIEndPoint: 'sailinglog/v1/boat/locationsummary'
}
const APIVariablesItemDetails = {
  APIRequest: SailingLogBoatDetailsRequest,
  APISuccess: SailingLogBoatDetailsSuccess,
  APIFail: SailingLogBoatDetailsFail,
  APIEndPoint: 'sailinglog/v1/boat/'
}
const APIVariablesUpdate = {
  APIRequest: SailingLogBoatUpdateRequest,
  APISuccess: SailingLogBoatUpdateSuccess,
  APISuccess_2: SailingLogBoatDetailsSuccess,
  APIFail: SailingLogBoatUpdateFail,
  APIEndPoint: 'sailinglog/v1/boat/'
}
const APIVariablesDelete = {
  APIRequest: SailingLogBoatDeleteRequest,
  APISuccess: SailingLogBoatDeleteSuccess,
  APIFail: SailingLogBoatDeleteFail,
  APIEndPoint: 'sailinglog/v1/boat/'
}

export const SailingLogBoatCreateAPI = (item) => async (dispatch, getState) => {

  const API_Body = {
    name: item.name,
    boat_type: item.boat_type,
    make: item.make,
    model: item.model,
    loa: item.loa,
    draft: item.draft,
    height: item.height,
    beam: item.beam,
    year: item.year,
    home_marina: item.home_marina,
    country: item.country,
    owner: item.owner,
    irc_rating: item.irc_rating,
    sail_number: item.sail_number,
    MMSI: item.MMSI,
    link: item.link,
    notes: item.notes,
  }
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const SailingLogBoatListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}
export const SailingLogBoatSelectOptionsAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesSelectOptions, dispatch, getState)
}
export const SailingLogBoatSummaryByContactAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesSummaryByContact, dispatch, getState, query)
}
export const SailingLogBoatSummaryByLocationAPI = (query) => async (dispatch, getState) => {
  ItemListAll(summaryByLocationAPIVariables, dispatch, getState, query)
}
export const getSailingLogBoatDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const SailingLogBoatUpdateAPI = (item) => async (dispatch, getState) => {

  const API_Body = {
    name: item.name,
    boat_type: item.boat_type,
    make: item.make,
    model: item.model,
    loa: item.loa,
    draft: item.draft,
    height: item.height,
    beam: item.beam,
    year: item.year = '' ? 'None' : item.year,
    home_marina: item.home_marina,
    country: item.country,
    owner: item.owner,
    irc_rating: item.irc_rating,
    sail_number: item.sail_number,
    MMSI: item.MMSI,
    link: item.link,
    notes: item.notes,

  }
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const SailingLogBoatDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

///////////// REDUCERS/////////////////////////
// State is an empty object
export const SailingLogBoatCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SailingLogBoatCreateRequest:
      return {
        loading: true
      }

    case SailingLogBoatCreateSuccess:
      return {
        loading: false,
        success: true,
        SailingLogBoat: action.payload
      }

    case SailingLogBoatCreateFail:
      return {
        loading: false,
        error: action.payload
      }

    case SailingLogBoatCreateReset:
      return {}


    default:
      return state
  }
}

export const SailingLogBoatUpdateReducer = (state = { SailingLogBoat: {} }, action) => {
  switch (action.type) {
    case SailingLogBoatUpdateRequest:
      return { loading: true }

    case SailingLogBoatUpdateSuccess:
      return { loading: false, success: true }

    case SailingLogBoatUpdateFail:
      return { loading: false, error: action.payload }

    case SailingLogBoatUpdateReset:
      return { SailingLogBoat: {} }

    default:
      return state
  }
}

export const SailingLogBoatDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SailingLogBoatDeleteRequest:
      return { loading: true }

    case SailingLogBoatDeleteSuccess:
      return {
        loading: false,
        success: true
      }

    case SailingLogBoatDeleteFail:
      return { loading: false, error: action.payload }

    case SailingLogBoatDeleteReset:
      return {}

    default:
      return state
  }
}

export const SailingLogBoatDetailsReducer = (state = { loading: true, SailingLogBoat: [] }, action) => {
  switch (action.type) {
    case SailingLogBoatDetailsRequest:
      return {
        ...state,
        loading: true
      }

    case SailingLogBoatDetailsSuccess:
      return {
        loading: false,
        SailingLogBoat: action.payload
      }

    case SailingLogBoatDetailsFail:
      return {
        loading: false,
        error: action.payload
      }

    default:
      return state
  }
}


export const SailingLogBoatListReducer = (state = { SailingLogBoat: [] }, action) => {
  switch (action.type) {
    case SailingLogBoatListRequest:
      return {
        loading: true
      }

    case SailingLogBoatListSuccess:
      return {
        loading: false,
        SailingLogBoat: action.payload
      }

    case SailingLogBoatListFail:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const SailingLogBoatSelectOptionsReducer = (state = { SailingLogBoat: [] }, action) => {
  switch (action.type) {
    case 'SailingLogBoatSelectOptionsRequest':
      return {
        loading: true
      }

    case 'SailingLogBoatSelectOptionsSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
      }

    case 'SailingLogBoatSelectOptionsFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const SailingLogBoatContactSummaryReducer = (state = { SailingLogBoat: [] }, action) => {
  switch (action.type) {
    case 'SailingLogBoatContactSummaryRequest':
      return {
        loading: true
      }

    case 'SailingLogBoatContactSummarySuccess':
      return {
        loading: false,
        SailingLogBoat: action.payload
      }

    case 'SailingLogBoatContactSummaryFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const SailingLogBoatLocationSummaryReducer = (state = { SailingLogBoat: [] }, action) => {
  switch (action.type) {
    case 'SailingLogBoatLocationSummaryRequest':
      return {
        loading: true
      }

    case 'SailingLogBoatLocationSummarySuccess':
      return {
        loading: false,
        SailingLogBoat: action.payload
      }

    case 'SailingLogBoatLocationSummaryFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function SailingLogBoatScreen(props) {
  const createAPI = SailingLogBoatCreateAPI
  const listAPI = SailingLogBoatListAllAPI
  const detailsAPI = getSailingLogBoatDetailsAPI
  const updateAPI = SailingLogBoatUpdateAPI
  const deleteAPI = SailingLogBoatDeleteAPI

  const resetCreate = SailingLogBoatCreateReset
  const resetUpdate = SailingLogBoatUpdateReset
  const resetViewDetails = SailingLogBoatDetailsReset
  const resetDelete = SailingLogBoatDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const paramsItem = useParams()
  const paramsItemSplit = paramsItem.action.split(':')
  const paramsID = paramsItemSplit[1]

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const SailingLogBoatCreate = useSelector(state => state.SailingLogBoatCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = SailingLogBoatCreate

  const SailingLogBoatList = useSelector(state => state.SailingLogBoatList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    SailingLogBoat: dataList } = SailingLogBoatList

  const SailingLogBoatDetails = useSelector(state => state.SailingLogBoatDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    SailingLogBoat: dataItem } = SailingLogBoatDetails

  const SailingLogBoatUpdate = useSelector(state => state.SailingLogBoatUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = SailingLogBoatUpdate

  const SailingLogBoatDelete = useSelector(state => state.SailingLogBoatDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = SailingLogBoatDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingList || loadingDetails
    )
  )

  const SailingLogCrewBoatSummary = useSelector(state => state.SailingLogCrewBoatSummary)
  const {
    loading: loadingCrewSummary,
    error: errorCrewSummary,
    SailingLogCrew: listCrewSummary } = SailingLogCrewBoatSummary

  const SailingLogTripBoatSummary = useSelector(state => state.SailingLogTripBoatSummary)
  const {
    loading: loadingTripSummary,
    error: errorTripSummary,
    SailingLogTrip: listTripSummary } = SailingLogTripBoatSummary

  const viewTabs = [
    {
      label: 'Trips',
      viewLink: '/fun/sailinglog/trips/',
      dataList: listTripSummary,
      loading: loadingTripSummary,
      error: errorTripSummary,
      tabContent: '',
      tableFields: [
        {
          label: 'Trip',
          fieldName: 'name',
          fieldType: 'text',
          tableDisplayOrder: '',
          tableDisplayPriority: '1',
        }, {
          label: 'Trip Type',
          fieldName: 'trip_type_name',
          fieldType: 'selectfromdb',
          tableDisplayOrder: '',
          tableDisplayPriority: '1',
        }
      ]
    },
    { 
      label: 'Crew',
      viewLink: '/fun/sailinglog/crew/',
      // tabContent: 'Coming soon: A count of each time a crew member is on the boat.',
      addLinkRef: 'name',
      dataList: listCrewSummary,
      loading: loadingCrewSummary,
      error: errorCrewSummary,
      tableFields: [
        {
          label: 'Name',
          fieldName: 'name',
          fieldLongName: 'name_name',
          fieldType: 'text',
          tableDisplayOrder: '',
          tableDisplayPriority: '1',
        // },   {
        //   label: 'Role',
        //   fieldName: 'role',
        //   fieldLongName: 'role_name',
        //   fieldType: 'text',
        //   tableDisplayOrder: '',
        //   tableDisplayPriority: '1',
          // },   {
          // label: 'Outings',
          // fieldName: 'outings',
          // fieldType: 'number',
          // tableDisplayOrder: '',
          // tableDisplayPriority: '1',
        }
      ]
    }
  ]

  useEffect(() => {
    dispatch(SailingLogBoatListAllAPI())
    dispatch(SailingLogBoatTypeSelectOptionsAPI())
    dispatch(SailingLogContactSelectOptionsAPI())
    dispatch(SailingLogLocationSelectOptionsAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }
    if (paramsID) {
      dispatch(SailingLogCrewSummaryByBoatAPI('?listid=' + paramsID))
      dispatch(SailingLogTripSummaryByBoatAPI('?listid=' + paramsID))
    }
  }, [successDelete, successUpdate,
    errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete, paramsID])


  return (
    <ModuleHome
          formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
    moduleModalVisibility={props.moduleModalVisibility}
    setModuleModalVisibility={props.setModuleModalVisibility}

      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      rowOnClickAction={'view'}
      view={(dataitem) => navigate(moduleDefinitions.moduleLink + 'view:' + dataitem.id)}
      add={true}
      edit={(dataitem) => navigate(moduleDefinitions.moduleLink + 'edit:' + dataitem.id)}
      delete={(id) => dispatch(deleteAPI(id))}

      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      getDataList={listAPI}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}

      //View Page
      viewTabs={viewTabs}
    />

  )
}

export default SailingLogBoatScreen