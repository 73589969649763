//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../components/containers/moduleHome'
// APIs
import {ItemCreate, ItemListAll,ItemDetails,ItemUpdate, ItemDelete} from '../../APIs/genericAPIs'

export const moduleDefinitions = {
  moduleName: 'Categories',
  moduleNameSingular: 'Category',
  moduleLink: '/system/settings/categories/',
}

export const moduleFields = [
 {
    label: 'Category',
    fieldName: 'name',
    // fieldLongName: 'category_name',
    fieldType: 'text',
    addFormOrder: 1,
    editFormOrder: 1,
    tableDisplayOrder: 1,
    tableDisplayPriority: 1,
  }
]

export const CategoryCreateRequest = 'CategoryCreateRequest'
export const CategoryCreateSuccess = 'CategoryCreateSuccess'
export const CategoryCreateFail = 'CategoryCreateFail'
export const CategoryCreateReset = 'CategoryCreateReset'

export const CategoryListRequest = 'CategoryListRequest'
export const CategoryListSuccess = 'CategoryListSuccess'
export const CategoryListFail = 'CategoryListFail'

export const CategoryDetailsRequest = 'CategoryDetailsRequest'
export const CategoryDetailsSuccess = 'CategoryDetailsSuccess'
export const CategoryDetailsFail = 'CategoryDetailsFail'
export const CategoryDetailsReset = 'CategoryDetailsReset'

export const CategoryUpdateRequest = 'CategoryUpdateRequest'
export const CategoryUpdateSuccess = 'CategoryUpdateSuccess'
export const CategoryUpdateFail = 'CategoryUpdateFail'
export const CategoryUpdateReset = 'CategoryUpdateReset'

export const CategoryDeleteRequest = 'CategoryDeleteRequest'
export const CategoryDeleteSuccess = 'CategoryDeleteSuccess'
export const CategoryDeleteFail = 'CategoryDeleteFail'
export const CategoryDeleteReset = 'CategoryDeleteReset'


export const fieldValidation = (fieldName, value) => {
  let hasError = false,
      fieldErrorMessage = ""
  switch (fieldName) {
    case "name":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Name is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break

    default:
      break
  }
  return { hasError, fieldErrorMessage }
}


export const CategoryCreateReducer = (state = {}, action) => {
  switch (action.type) {
      case CategoryCreateRequest:
          return {
              loading: true
          }

      case CategoryCreateSuccess:
          return {
              loading: false,
              success: true,
              Category: action.payload
          }

      case CategoryCreateFail:
          return {
              loading: false,
              error: action.payload
          }

      case CategoryCreateReset:
          return {}


      default:
          return state
  }
}

export const CategoryUpdateReducer = (state = { Category: {} }, action) => {
  switch (action.type) {
      case CategoryUpdateRequest:
          return { loading: true }

      case CategoryUpdateSuccess:
          return { loading: false, success: true }

      case CategoryUpdateFail:
          return { loading: false, error: action.payload }

      case CategoryUpdateReset:
          return { Category: {} }

      default:
          return state
  }
}

export const CategoryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
      case CategoryDeleteRequest:
          return { loading: true }

      case CategoryDeleteSuccess:
          return { 
            loading: false, 
            success: true }

      case CategoryDeleteFail:
          return { loading: false, error: action.payload }

      case CategoryDeleteReset:
            return { }

      default:
          return state
  }
}

export const CategoryDetailsReducer = (state = { loading: true, Category: [] }, action) => {
  switch (action.type) {
      case CategoryDetailsRequest:
          return {
              ...state,
              loading: true
          }

      case CategoryDetailsSuccess:
          return {
              loading: false,
              Category: action.payload
          }

      case CategoryDetailsFail:
          return {
              loading: false,
              error: action.payload
          }


      default:
          return state
  }
}


export const CategoryListReducer = (state = { Category: [], fieldOptionValues: [] }, action) => {
  switch (action.type) {
      case CategoryListRequest:
          return {
              loading: true
          }

      case CategoryListSuccess:
          // var obj = [{id: 999, name: 'Select...'}]
          return {
              loading: false,
              fieldOptionValues: [...action.payload],
              Category: action.payload
          }

      case CategoryListFail:
          return {
              loading: false,
              error: action.payload
          }
      default:
          return state
  }
}

const APIVariablesCreate = {
  APIRequest: CategoryCreateRequest ,
  APISuccess: CategoryCreateSuccess ,
  APIFail: CategoryCreateFail ,
  APIEndPoint: 'shared/v1/category/add'
}
const APIVariablesListAll = {
  APIRequest: CategoryListRequest ,
  APISuccess: CategoryListSuccess ,
  APIFail: CategoryListFail ,
  APIEndPoint: 'shared/v1/category/list'
}
const APIVariablesItemDetails = {
  APIRequest: CategoryDetailsRequest ,
  APISuccess: CategoryDetailsSuccess ,
  APIFail: CategoryDetailsFail ,
  APIEndPoint: 'shared/v1/category/'
}
const APIVariablesUpdate = {
  APIRequest: CategoryUpdateRequest ,
  APISuccess: CategoryUpdateSuccess ,
  APISuccess_2: CategoryDetailsSuccess,
  APIFail: CategoryUpdateFail ,
  APIEndPoint: 'shared/v1/category/'
}
const APIVariablesDelete = {
  APIRequest: CategoryDeleteRequest ,
  APISuccess: CategoryDeleteSuccess ,
  APIFail: CategoryDeleteFail ,
  APIEndPoint: 'shared/v1/category/'
}

//POST
export const CategoryCreateAPI = (item) => async (dispatch, getState) => {
  // console.log('CategoryCreateAPI',item)
  const API_Body = {
    status: 'Active',
    name: item.name,
      }
      ItemCreate(API_Body,APIVariablesCreate,dispatch,getState) 
}

export const CategoryListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll,dispatch,getState)
}

export const CategoryDetailsAPI = (itemId)  => async (dispatch, getState) => { 
  ItemDetails(itemId,APIVariablesItemDetails,dispatch,getState)
}

export const CategoryUpdateAPI = (item) => async (dispatch, getState) => {
  const API_Body = JSON.stringify({
    status: item.status,
    name: item.category,
  })
  ItemUpdate(item,API_Body,APIVariablesUpdate,dispatch, getState)
}

export const CategoryDeleteAPI = (itemId) => async (dispatch, getState) => { 
  ItemDelete(itemId,APIVariablesDelete,dispatch,getState) 
}


function CategoryModule(props) {
  const createAPI = CategoryCreateAPI
  const listAPI = CategoryListAllAPI
  const detailsAPI = CategoryDetailsAPI
  const updateAPI = CategoryUpdateAPI
  const deleteAPI = CategoryDeleteAPI

  const resetCreate = CategoryCreateReset
  const resetUpdate = CategoryUpdateReset
  const resetViewDetails = CategoryDetailsReset
  const resetDelete = CategoryDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const CategoryCreate = useSelector(state => state.CategoryCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = CategoryCreate

  const CategoryList = useSelector(state => state.CategoryList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    Category: dataList } = CategoryList

  const CategoryDetails = useSelector(state => state.CategoryDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    Category: dataItem } = CategoryDetails

  const CategoryUpdate = useSelector(state => state.CategoryUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = CategoryUpdate

  const CategoryDelete = useSelector(state => state.CategoryDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = CategoryDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingDetails
    )
  )

  useEffect(() => {
    dispatch(listAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
    if (errorLoadingList) {setServerErrorMessage(errorLoadingList)}
    if (errorCreate) {setServerErrorMessage(errorCreate)}
    if (errorUpdate) {setServerErrorMessage(errorUpdate)}
    if (errorGetDetails) {setServerErrorMessage(errorGetDetails)}
    if (errorDelete) {setServerErrorMessage(errorDelete)}
  }, [successDelete, successUpdate,
    errorLoadingList,errorCreate,errorUpdate,errorGetDetails, errorDelete])

    // const { state } = useLocation();
    // console.log('category - state.modal',state.modal)
    console.log('category - props.modal',props.modal)

  return (
   <ModuleHome
    // navigationState={state}
    formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
    moduleModalVisibility={props.moduleModalVisibility}
    setModuleModalVisibility={props.setModuleModalVisibility}
    // Shared items
    paramsItem={paramsItem}
    moduleDefinitions={moduleDefinitions}
    moduleFields={moduleFields}

    //Table Data
    dataList={dataList}
    loadingList={loadingList}
    errorLoadingList={errorLoadingList}

    rowOnClickAction='edit'
    add={true}
    edit={true}
    view={false}
    delete={(id) => dispatch(deleteAPI(id))}

    //AddEditForm
    fieldValidation={fieldValidation}

    loading={loading}
    serverErrorMessage={serverErrorMessage}
    setServerErrorMessage={setServerErrorMessage}

    getDataList={listAPI}

    itemDetails={detailsAPI}
    dataItem={dataItem}
    itemDetailsReset={resetViewDetails}

    itemCreate={createAPI}
    successCreate={successCreate}
    resetCreate={resetCreate}

    itemUpdate={updateAPI}
    successUpdate={successUpdate}
    resetUpdate={resetUpdate}

    itemDelete={deleteAPI}
    successDelete={successDelete}
    resetDelete={resetDelete}
/>
  )
}

export default CategoryModule