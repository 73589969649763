import FixedContainer from '../../components/containers/fixedContatiner';
import {Routes, Route, Link, useLocation  } from 'react-router-dom'

const TestPhoto = () => {
  let location = useLocation();
  // Deconstruct State from location
  const { state = {} } = location;
  const modal = (state === null ? false : state.modal)


  const heading = 'Template'
  const contents = (<>
      <div className={modal ? 'modal' : undefined}>
        {modal && <Link to='/test/'>Close</Link>}
        <div>
        <img alt='pic' src="https://source.unsplash.com/random" />
        </div>
    </div>
  </>)

  return (
    <FixedContainer
      heading={heading}
      contents={contents}
    />
  );
};
export default TestPhoto;