import style from '../../style/global.module.css';

const Template = () => {
  return (
    <div className={style.section}>
      <div className={style.fixedcontainer}>
        <h2>Relationships</h2>
        <p>Coming Soon</p>
      </div>
    </div>
  );  
};
export default Template;