import { useState} from 'react';

const HandleSort = (props) => {

const handleSorting = (sortField, sortOrder) => {
  if (sortField) {
    // console.log('props.filteredData',props.filteredData)
    const sorted = [...props.filteredData].sort((a, b) => {
      if (a[sortField] === null) return 1;
      if (b[sortField] === null) return -1;
      if (a[sortField] === null && b[sortField] === null) return 0;
      return (
        a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
          numeric: true,
        }) * (sortOrder === "asc" ? 1 : -1)
      );
    });
    // console.log('sorted',sorted)
    return props.setFilteredData(sorted);
  }
};

const [sortField, setSortField] = useState("");
const [order, setOrder] = useState("asc");

const handleSortingChange = (accessor) => {
  const sortOrder = accessor === sortField && order === "asc" ? "desc" : "asc";
  setSortField(accessor);
  setOrder(sortOrder);
  handleSorting(accessor, sortOrder);
};

const longFieldName = (fieldName) => {
  const moduleFields = props.moduleFields.find((item) => item.fieldName === fieldName)
  const fieldLongName = moduleFields.fieldLongName
  if (fieldLongName) {
    return fieldLongName
  } else {
    return fieldName
  }
}

return (
  <a
  href="#"
  onClick={() => {
    handleSortingChange(longFieldName(props.fieldName)); 
    props.setGroupBy(props.fieldName);
    props.setIsDropDownButtonActive(false)
    }}>
  {props.sortName === undefined ? 'Sort' : props.sortName}
</a>
)

}
export default HandleSort
