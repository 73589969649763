//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Components
import ModuleHome from '../../components/containers/moduleHome'
import { ItemListAll } from '../../APIs/genericAPIs'

const moduleDefinitions = {
  moduleName: 'Currency Codes',
  moduleNameSingular: 'Currency Code',
}

const moduleFields = [
  {
    label: 'Currency Code',
    fieldName: 'name',
    fieldType: 'text',
    tableDisplayOrder: 1,
    tableDisplayPriority: 1,
  }, {
    label: 'Alpha Code',
    fieldName: 'alpha_code',
    fieldType: 'text',
    tableDisplayOrder: 2,
    tableDisplayPriority: 1,
  }, {
    label: 'Numeric',
    fieldName: 'numeric',
    fieldType: 'text',
    tableDisplayOrder: 4,
    tableDisplayPriority: 1,
  }, {
    label: 'Minour Unit',
    fieldName: 'minor_unit',
    fieldType: 'text',
    tableDisplayOrder: 4,
    tableDisplayPriority: 1,
  }, {
    label: 'Symbol',
    fieldName: 'symbol',
    fieldType: 'text',
    tableDisplayOrder: 4,
    tableDisplayPriority: 1,
   }
]

const APIVariablesListAll = {
  APIRequest: 'CurrencyCodesListRequest',
  APISuccess: 'CurrencyCodesListSuccess',
  APIFail: 'CurrencyCodesListFail',
  APIEndPoint: 'shared/v1/currencycodes/list'
}

const APIVariablesSelectOptions = {
  APIRequest: 'CurrencyCodesSelectOptionsRequest',
  APISuccess: 'CurrencyCodesSelectOptionsSuccess',
  APIFail: 'CurrencyCodesSelectOptionsFail',
  APIEndPoint: 'shared/v1/currencycodes/selectoptions'
}

export const CurrencyCodesListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const CurrencyCodesSelectOptionsAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesSelectOptions, dispatch, getState)
}

export const CurrencyCodeListReducer = (state = { category: [], fieldOptionValues: [] }, action) => {
  switch (action.type) {
    case 'CurrencyCodesListRequest':
      return {
        loading: true
      }

    case 'CurrencyCodesListSuccess':
      return {
        loading: false,
        fieldOptionValues: action.payload
        .sort((a, b) => {
          if (a.alpha_code < b.alpha_code) { return -1; }
          if (a.alpha_code > b.alpha_code) { return 1;  }
          return 0;})
        .map((cur) => {return { id: cur.id, name: cur.alpha_code}}),
        currencycode: action.payload
      }

    case 'CurrencyCodesListFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const CurrencyCodeSelectOptionsReducer = (state = { category: [], fieldOptionValues: [] }, action) => {
  switch (action.type) {
    case 'CurrencyCodesSelectOptionsRequest':
      return {
        loading: true
      }

    case 'CurrencyCodesSelectOptionsSuccess':
      return {
        loading: false,
        fieldOptionValues: action.payload
        .sort((a, b) => {
          if (a.alpha_code < b.alpha_code) { return -1; }
          if (a.alpha_code > b.alpha_code) { return 1;  }
          return 0;})
        .map((cur) => {return { id: cur.id, name: cur.alpha_code}}),
        currencycode: action.payload
      }

    case 'CurrencyCodesSelectOptionsFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function CurrencyCodeListScreen() {
  const listAPI = CurrencyCodesListAllAPI

  const dispatch = useDispatch()

  const CurrencyCodeList = useSelector(state => state.CurrencyCodeList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    currencycode: dataList } = CurrencyCodeList


  useEffect(() => {
    dispatch(listAPI())
  }, [])

  return (
    <ModuleHome
    // Shared items
    paramsItem={{action:'list'}}
    moduleDefinitions={moduleDefinitions}
    moduleFields={moduleFields}

    //Table Data
    dataList={dataList}
    loadingList={loadingList}
    errorLoadingList={errorLoadingList}
        />
  )
}

export default CurrencyCodeListScreen


