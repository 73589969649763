import formstyle from '../../style/form.module.css';
import { useState } from 'react';
import { Modal } from '../modals/moduleModal';

export const Label = (props) => {
  const [moduleModalVisibility, setModuleModalVisibility] = useState(false);

  return (
    <>
      <label
        htmlFor={props.label}>
        {props.label}
        {props.mandatory && <span style={{ color: "red" }}>*</span>}

        {/* {props.filterMode &&
          props.fieldType === undefined &&
          <button
            style={{ display: 'inline', float: "right" }}
            className={`${formstyle.smallButton} ${formstyle.red} containter`}
            onClick={props.onClick}
          >Clear Filter</button>
        } */}
        {props.addNewLink &&
          <>
            <button
              className={`${formstyle.smallButton} ${formstyle.blue} containter`}
              onClick={props.onClick}
            >Add New</button>
          </>
        }
        {props.openAddNewModule &&
          <>
            <button
              className={`${formstyle.smallButton} ${formstyle.blue} containter`}
              onClick={(e) => { e.preventDefault(); setModuleModalVisibility(true) }}
            >Config</button>
          </>
        }
      </label>
      <Modal
        moduleModalVisibility={moduleModalVisibility}
        setModuleModalVisibility={setModuleModalVisibility}
        openAddNewModule={props.openAddNewModule}
      />
    </>
  )
}