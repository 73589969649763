import { useState, useEffect } from 'react';
import Loader from '../../../components/loading/spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getUserProfile, updateUserProfile, updateUserPassword } from '../../../APIs/userAPIConfig';
import { USERUpdate_PROFILEReset } from '../userConstants';
import Avatar from '../../../components/avatar'
import FixedContainer from '../../../components/containers/fixedContatiner';
import formstyle from '../../../style/form.module.css';
import style from '../../../style/global.module.css';


function ProfileScreen() {
  const [first_name, setFirst_name] = useState('')
  const [last_name, setLast_name] = useState('')
  const [email, setEmail] = useState('')
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [successmessage, setSuccessMessage] = useState('')
  const [errormessage, setErrorMessage] = useState('')
 
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [MFAStatus, setMFAStatus] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [changePassword, setChangePassword] = useState(false)

  const userProfile = useSelector(state => state.user_Profile)
  const { 
    error: usererrorProfile, 
    loading: userloadingProfile, 
    success: usersuccessProfile,
    user } = userProfile


  const userUpdateProfile = useSelector(state => state.userUpdateProfile)
  const { 
    error: usererrorUpdate, 
    loading: userloadingUpdate, 
    success: usersuccessUpdate } = userUpdateProfile
    
  const userUpdatePassword = useSelector(state => state.userUpdatePassword)

  const { 
    error: passworderrorUpdate, 
    loading: passwordloadingUpdate, 
    success: passwordsuccessUpdate } = userUpdatePassword

  const [spinner, setSpinner] = useState(false) 

  const setup2fa = (
    <div>
      <label>Secure your account with two factor authentication (2FA)</label><br></br>
      <Link to={'/user/2fa/setup'} ><button className={formstyle.green}>Setup 2FA</button></Link>
    </div>
  );

  const mfaenabled = (
    <div>
      <h3> 2FA Options</h3>
      <Link to={'/user/2fa/backupcodes'} ><button className={formstyle.lightblue}>2FA Backup Codes</button></Link>
      <Link to={'/user/2fa/disable'} ><button className={formstyle.red}>Disable 2FA</button></Link>
    </div>
  );


  // Make sure user is signed in
  const user_Login = useSelector(state => state.user_Login)
  const { userInfo } = user_Login

  useEffect(() => {
    // check if we have user details, if not get user details
    // If no user OR no first_name OR ?? OR User login does not = user ID then get user infro
    if (!user || !user.first_name || userInfo.userInfo !== user.id) {
      // Clear 
      dispatch({ type: USERUpdate_PROFILEReset })
      dispatch(getUserProfile(userInfo.user_id))
    } else {
      setFirst_name(user.first_name)
      setLast_name(user.last_name)
      setEmail(user.email)
    }
  }, [])


  useEffect(() => {
    // if (!user || !user.first_name || userInfo.userInfo !== user.id) {
    //     console.log('I have run')
    //     dispatch({ type: USERUpdate_PROFILEReset })
    //     dispatch(getUserProfile(userInfo.user_id))
    // } else {
    //   setFirst_name(user.first_name)
    //   setLast_name(user.last_name)
    //   setEmail(user.email)
    // }

    if (usersuccessProfile) {
      setFirst_name(user.first_name)
      setLast_name(user.last_name)
      setEmail(user.email)
    }
    else if (usersuccessUpdate ) {
      setSpinner(false)
      dispatch(getUserProfile(userInfo.user_id))
      dispatch({ type: USERUpdate_PROFILEReset })
      setEditMode(false)
      setSuccessMessage('Profile successfully updated')
    } else if (usererrorUpdate) {
      setSpinner(false)
    } else if (passworderrorUpdate) {
      setSpinner(false)
    } else if (passwordsuccessUpdate) {
      setSpinner(false)
      setChangePassword(false)
      setSuccessMessage('Password successfully changed')
    } 
  }, [dispatch, userInfo, usersuccessProfile,usersuccessUpdate, passwordsuccessUpdate, usererrorUpdate, passworderrorUpdate])


  const handleUpdateProfile = (e) => {
    setSuccessMessage('')
    setErrorMessage('')
    e.preventDefault()
    if (first_name.trim().length < 1) {
      setErrorMessage('Name fields can not be empty')
    } else if (email.trim().length < 1) {
      setErrorMessage('Email address is not valid')
    } else {
      setSpinner(true)
      dispatch(updateUserProfile({
        id: userInfo.user_id,
        first_name,
        last_name,
        email,
      }))
    }
  }

  const handleChangePassword = (e) => {
    e.preventDefault();
    setSuccessMessage('')
    setErrorMessage('')
    if (currentPassword.trim().length < 1) {
      setErrorMessage('Current Password can not be empty')
    } else if (newPassword.trim().length < 6) {
      setErrorMessage('New password must be at least 6 characters')
    } else if (newPassword !== confirmPassword) {
      setErrorMessage('Passwords do not match')
    } else {
      setSpinner(true)
      setErrorMessage('')
      dispatch(updateUserPassword({
        id: userInfo.user_id,
        currentPassword,
        newPassword,
        confirmPassword,
      }))
    }
  };

  const resetfunction = () => {
    dispatch(getUserProfile(userInfo.user_id))
    setEditMode(false)
  }

  const viewProfile = (
    <>
      <Avatar
        user={{ name: 'Mark', imageID: 'OKS67lh' }}
        size={{ height: 100, width: 100 }}
      />
      <div onClick={() => setEditMode(true)}>
        <div className={formstyle.input} style={{ textAlign: 'left' }}>
          <label htmlFor='firstname'>First Name: </label>
          <span >{first_name} </span>
        </div>
        <div className={formstyle.input} style={{ textAlign: 'left' }}>
          <label htmlFor='lastname'>Last Name: </label>
          <span >{last_name} </span>
        </div>
        <div className={formstyle.input} style={{ textAlign: 'left' }}>
          <label htmlFor='email'>Email: </label>
          <span >{email}  </span>
        </div>
      </div>
      <button className={formstyle.lightblue} onClick={() => setEditMode(true)}>Edit Profile</button>
      <button className={formstyle.lightblue} onClick={() => setChangePassword(true)}>Change Password</button>
      {/* <Link to={'/user/changepassword'} ><button className={formstyle.lightblue}>Change Password</button></Link> */}

      {!MFAStatus ? setup2fa : mfaenabled}
    </>
  )


  const editProfile = (
    <>
      <form>
        <Avatar
          user={{ name: 'Mark', imageID: 'OKS67lh' }}
          size={{ height: 100, width: 100 }}
        />
        <div className={`${formstyle.input} ${formstyle.fullwidth}`}>
          <label htmlFor='firstname'>First Name</label>
          <input
            type='text'
            id='firstname'
            value={first_name}
            onChange={(e) => setFirst_name(e.target.value)}
          />
        </div>
        <div className={`${formstyle.input} ${formstyle.fullwidth}`}>
          <label htmlFor='lastname'>Last Name</label>
          <input
            type='text'
            id='lastname'
            value={last_name}
            onChange={(e) => setLast_name(e.target.value)}
          />
        </div>
        <div className={`${formstyle.input} ${formstyle.fullwidth}`}>
          <label htmlFor='email'>Email</label>
          <input
            type='email'
            id='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button className={formstyle.orange} onClick={() => resetfunction} >Cancel</button>
        <button className={formstyle.green} onClick={handleUpdateProfile}>Update Profile</button>
      </form>
    </>
  )

  const changePasswordForm = (
    <>        <form>
      <h3>Change Password</h3>
      <div className={`${formstyle.input} ${formstyle.fullwidth}`}>
        <label htmlFor='current-password'>Current Password</label>
        <input
          type='password'
          id='current-password'
          minLength="7"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
      </div>
      <div className={`${formstyle.input} ${formstyle.fullwidth}`}>
        <label htmlFor='new-password1'>New Password</label>
        <input
          type='password'
          id='new-password1'
          minLength="7"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </div>
      <div className={`${formstyle.input} ${formstyle.fullwidth}`}>
        <label htmlFor='new-password2'>Confirm New Password</label>
        <input
          type='password'
          id='new-password2'
          minLength="7"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>
      <button className={formstyle.orange} onClick={() => resetfunction} >Cancel</button>
      <button className={formstyle.green} onClick={handleChangePassword}>Change Password</button>
    </form>
    </>
  )

  const heading = 'User Profile'
  const contents = ( <>
   {successmessage && <div className={formstyle.successmessage}>{successmessage}</div>}
        {errormessage && <div className={formstyle.errormessage}>{errormessage}</div>}
        {passworderrorUpdate && <div className={formstyle.errormessage}>{passworderrorUpdate}</div>}
        {usererrorUpdate && <div className={formstyle.errormessage}>{usererrorUpdate}</div>}
        {!spinner ?
          <div className={style.center}>
            {(!editMode && !changePassword) ?
              viewProfile
              :
              (editMode ? editProfile : changePasswordForm)
            }
          </div> :
          <Loader />
        }
</>)


  return (
    <FixedContainer
      heading={heading}
      contents={contents}
    />
  )
}
export default ProfileScreen