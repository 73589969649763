import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Layout from './layout/layout';
import Homepage from './screens/homeScreen'

// User Screens
import SignIn from './modules/user/userScreens/signInScreen'
import SignedOut from './modules/user/userScreens/signedOutScreen';
import UserProfile from './modules/user/userScreens/userProfileScreen';
import ChangePassword from './modules/user/userScreens/changePasswordScreen';
import PasswordReset from './modules/user/userScreens/resetPasswordScreen'
import Setup2FA from './modules/user/userScreens/2FA/setup';
import Disable2FA from './modules/user/userScreens/2FA/disable';
import BackupCodes2FA from './modules/user/userScreens/2FA/backupCodes';
import UserSettings from './modules/user/userScreens/userSettings';

// NavBar
import Dashboard from './screens/dashboard'
import Support from './modules/support/supportScreen'
import Support_Tickets from './modules/support/tickets'
import SystemSettings from './modules/systemSettings/settingsScreen'

// Modules
import Calendar_Event from './modules/calendar/events/events';
import Calendar_Event_Status from './modules/calendar/events/status';
import Calendar_Event_Types from './modules/calendar/events/types';
import Calendar_Event_Visibiity from './modules/calendar/events/visibility';
import Calendar_Task from './modules/calendar/tasks/tasks';
import Calendar_Task_Status from './modules/calendar/tasks/status';
import Calendar_Task_Priority from './modules/calendar/tasks/priority';

import Inventory_Item from './modules/inventory/items'
import Inventory_Location from './modules/inventory/locations'
import Inventory_Checklist_List from './modules/inventory/checklist/lists'
import Inventory_Checklist_Item from './modules/inventory/checklist/items'
import Inventory_Checklist_Criteria from './modules/inventory/checklist/criteria'
import Inventory_Category from './modules/inventory/settings/category'

import Contacts_New from './modules/contacts/contactNew';
import Contacts_Contacts from './modules/contacts/contact';
import Contacts_Person from './modules/contacts/person';
import Contacts_Organisation from './modules/contacts/organisation';
import Contacts_Groups from './modules/contacts/groups';
import Contacts_Relationships from './modules/contacts/relationships';

import SailingLog from './modules/sailingLog/home'
import SailingLog_Boats from './modules/sailingLog/boats'
import SailingLog_Contacts from './modules/sailingLog/contacts/contacts'
import SailingLog_Contact_Qualifications from './modules/sailingLog/contacts/qualifications'
import SailingLog_Locations from './modules/sailingLog/locations'
import SailingLog_Trips from './modules/sailingLog/trips'

import SailingLog_Passages from './modules/sailingLog/passage/passages'
import SailingLog_Crew from './modules/sailingLog/passage/crew'
import SailingLog_LogEntry from './modules/sailingLog/passage/logEntry'

import SailingLog_Reference from './modules/sailingLog/reference/reference'
import SailingLog_Reference_Alphabet from './modules/sailingLog/reference/alphabet'
import SailingLog_Reference_Acronym from './modules/sailingLog/reference/acronyms'
import SailingLog_Reference_Glossary from './modules/sailingLog/reference/glossary'
import SailingLog_Reference_Sea_State from './modules/sailingLog/reference/seaState'
import SailingLog_Reference_Sound from './modules/sailingLog/reference/sound'
import SailingLog_Reference_Visibility from './modules/sailingLog/reference/visibility'
import SailingLog_Reference_Wind_Speed from './modules/sailingLog/reference/windSpeed'

import SailingLog_Tools from './modules/sailingLog/tools/tools'
import SailingLog_Tools_RaceCountdown from './modules/sailingLog/tools/raceCountdown'
import SailingLog_Tools_RaceManager from './modules/sailingLog/tools/raceManager'
import SailingLog_Tools_Stopwatch from './modules/sailingLog/tools/stopwatch'

import SailingLog_Settings from './modules/sailingLog/settings/settings'
import SailingLog_Settings_Boat_Types from './modules/sailingLog/settings/boatTypes'
import SailingLog_Settings_Role from './modules/sailingLog/settings/role'
import SailingLog_Settings_Qualifications from './modules/sailingLog/settings/qualifications'
import SailingLog_Settings_Trip_Types from './modules/sailingLog/settings/tripTypes'



import Finance from './modules/finance/finance'

// System Settings
import Address from './modules/shared/address'
import Category from './modules/shared/category'

// System Static Data
import CountryCodes from './modules/shared/countryCodes'
import CurrencyCodes from './modules/shared/currencyCodes'

//Other
import FreeTools from './modules/tools/toolScreens/freeToolScreen'
import Tools from './modules/tools/toolScreens/toolScreen'
import Games from './modules/games/noughtsandcrosses/board'

// Testing
import Test from './test/test'
import TestPhoto from './test/navmodals/photo'
import Testscreen from './screens/testScreen'

import { sendRefreshToken } from './APIs/userAPIConfig';

export function App() {
  const user_Login = useSelector(state => state.user_Login)
  const { userInfo } = user_Login

  const dispatch = useDispatch()

  // Start on initial load
  useEffect(() => {

    // then at an intervanl of every x minutes get a new refresh token.
    const intervalID = setInterval(() => {
      console.log(localtime, 'Interval Refresh triggered.',)

      const userTokens = JSON.parse(localStorage.getItem('userInfo'))
      if (userTokens)
        dispatch(sendRefreshToken(userTokens.refresh))
    }, 1000 * 60 * 14 )  //14 Minutes = 1 second (1000)  * 60 Seconds * 14 minutes.  
    return () => clearInterval(intervalID)
  }, []);

  const accessTokenExpirationTime = JSON.parse(localStorage.getItem('accessTokenExpirationTime'))
  const dateTimeNow = Date.now() //js format
  const dateTimeNew = new Date() // human format
  const localtime = dateTimeNew.toLocaleString("en-GB", { timeStyle: "long" });

  const difference = accessTokenExpirationTime - dateTimeNow

  // console.log(localtime,'accessTokenExpirationTime',accessTokenExpirationTime)
  // console.log(localtime,'dateTimeNow',dateTimeNow)
  // console.log(localtime,'difference',difference)

  const checktokenlife = () => {
    // console.log('checktokenlife' )
    if (difference < 10000) {
      const userTokens = JSON.parse(localStorage.getItem('userInfo'))
      if (userTokens)
        dispatch(sendRefreshToken(userTokens.refresh))
      console.log(localtime, 'get a refresh token before using links', difference)
      return routes_signed_out
    } else {
      console.log(localtime, 'passed token life check', difference)
      return routes_signed_in
    }
  }



  const routes_signed_in = (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>

          <Route index element={<Dashboard />} />
          <Route path="/testscreen" element={<Testscreen />} />
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/calendar/task/:action?" element={<Calendar_Task />} />
          <Route path="/calendar/event/:action?" element={<Calendar_Event />} />

          <Route path="/contacts/contactnew/:action?" element={<Contacts_New />} />
          <Route path="/contacts/contact/:action?" element={<Contacts_Contacts />} />
          <Route path="/contacts/person/:action?" element={<Contacts_Person />} />
          <Route path="/contacts/organisation/:action?" element={<Contacts_Organisation />} />
          <Route path="/contacts/groups/:action?" element={<Contacts_Groups />} />

          <Route path="/inventory/item/:action?" element={<Inventory_Item />} />
          <Route path="/inventory/location/:action?" element={<Inventory_Location />} />
          <Route path="/inventory/checklist/list/:action?" element={<Inventory_Checklist_List />} />
          <Route path="/inventory/checklist/items/:action?" element={<Inventory_Checklist_Item />} />

          <Route path="/system/settings/inventory/category/:action?" element={<Inventory_Category />} />
          <Route path="/system/settings/inventory/checklist/criteria/:action?" element={<Inventory_Checklist_Criteria />} />

          <Route path="finance" element={<Finance />} />
          <Route path="tools" element={<Tools />} />
          <Route path="games" element={<Games />} />

          <Route path="/fun/sailinglog/" element={<SailingLog />} />
          <Route path="/fun/sailinglog/boats/:action?" element={<SailingLog_Boats />} />
          <Route path="/fun/sailinglog/contacts/:action?" element={<SailingLog_Contacts />} />
          <Route path="/fun/sailinglog/contacts/qualifications/:action?" element={<SailingLog_Contact_Qualifications />} />
          <Route path="/fun/sailinglog/crew/:action?" element={<SailingLog_Crew />} />
          <Route path="/fun/sailinglog/locations/:action?" element={<SailingLog_Locations />} />
          <Route path="/fun/sailinglog/logentry/:action?" element={<SailingLog_LogEntry />} />
          <Route path="/fun/sailinglog/passages/:action?" element={<SailingLog_Passages />} />
          <Route path="/fun/sailinglog/trips/:action?" element={<SailingLog_Trips />} />

          <Route path="/fun/sailinglog/tools" element={<SailingLog_Tools />} />
          <Route path="/fun/sailinglog/tools/racecountdown" element={<SailingLog_Tools_RaceCountdown />} />
          <Route path="/fun/sailinglog/tools/racemanager" element={<SailingLog_Tools_RaceManager />} />
          <Route path="/fun/sailinglog/tools/stopwatch" element={<SailingLog_Tools_Stopwatch />} />

          <Route path="/fun/sailinglog/settings" element={<SailingLog_Settings />} />
          <Route path="/fun/sailinglog/settings/boattypes/:action?" element={<SailingLog_Settings_Boat_Types />} />
          <Route path="/fun/sailinglog/settings/qualifications/:action?" element={<SailingLog_Settings_Qualifications />} />
          <Route path="/fun/sailinglog/settings/roles/:action?" element={<SailingLog_Settings_Role />} />
          <Route path="/fun/sailinglog/settings/triptypes/:action?" element={<SailingLog_Settings_Trip_Types />} />

          <Route path="/fun/sailinglog/reference/" element={<SailingLog_Reference />} />
          <Route path="/fun/sailinglog/reference/alphabet/:action?" element={<SailingLog_Reference_Alphabet />} />
          <Route path="/fun/sailinglog/reference/acronyms/:action?" element={<SailingLog_Reference_Acronym />} />
          <Route path="/fun/sailinglog/reference/glossary/:action?" element={<SailingLog_Reference_Glossary />} />
          <Route path="/fun/sailinglog/reference/seastate/:action?" element={<SailingLog_Reference_Sea_State />} />
          <Route path="/fun/sailinglog/reference/sounds/:action?" element={<SailingLog_Reference_Sound />} />
          <Route path="/fun/sailinglog/reference/visibility/:action?" element={<SailingLog_Reference_Visibility />} />
          <Route path="/fun/sailinglog/reference/windspeed/:action?" element={<SailingLog_Reference_Wind_Speed />} />

          {/* Testing */}
          <Route path="test" element={<Test />} />
          <Route path="test/photo" element={<TestPhoto />} />

          <Route path='/user/profile' element={<UserProfile />} />
          {/* <Route path='/user/profile/edit'    element={<UserProfileEdit/>  } /> */}
          <Route path='/user/changepassword' element={<ChangePassword />} />
          <Route path='/user/2fa/setup' element={<Setup2FA />} />
          <Route path='/user/2fa/disable' element={<Disable2FA />} />
          <Route path='/user/2fa/backupcodes' element={<BackupCodes2FA />} />
          <Route path='/user/settings' element={<UserSettings />} />


          <Route path='/support' element={<Support />} />
          <Route path='/support/tickets/:action?' element={<Support_Tickets />} />



          {/* These paths need to be protected once user roles have been set up  */}
          <Route path='/system/settings' element={<SystemSettings />} />
          <Route path='/system/settings/address/:action?' element={<Address />} />
          <Route path='/system/settings/categories/:action?' element={<Category />} />
          <Route path="/system/settings/tasks/status/:action?" element={<Calendar_Task_Status />} />
          <Route path="/system/settings/tasks/priority/:action?" element={<Calendar_Task_Priority />} />

          <Route path="/system/settings/events/status/:action?" element={<Calendar_Event_Status />} />
          <Route path="/system/settings/events/types/:action?" element={<Calendar_Event_Types />} />
          <Route path="/system/settings/events/visibility/:action?" element={<Calendar_Event_Visibiity />} />

          <Route path="/system/settings/contacts/relationship/:action?" element={<Contacts_Relationships />} />

          <Route path='/system/settings/countrycodes/' element={<CountryCodes />} />
          <Route path='/system/settings/currencycodes/' element={<CurrencyCodes />} />

          {/* <Route path='/system/settings/task/status/:action?' element={<Task_Status />} />
          <Route path='/system/settings/task/priority:action?' element={<Task_Priority />} /> */}


          <Route path="*" element={<Dashboard />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );

  const routes_signed_out = (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<SignIn />} />
          <Route path="passwordreset" element={<PasswordReset />} />
          <Route path="signedout" element={<SignedOut />} />
          <Route path="signin" element={<SignIn />} />
          {/* <Route path="signup" element={<SignUp />} /> */}
          {/* <Route path="tools" element={<FreeTools />} /> */}
          <Route path="*" element={<SignIn />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );

  return (
    <>
      {/* Original */}
      {/* {userInfo ? routes_signed_in : routes_signed_out} */}
      {/* Current */}
      {userInfo ? checktokenlife() : routes_signed_out}
      {/* Future */}
      {/* {tokenvalid ? routes_signed_in : routes_signed_out} */}
    </>
  );
}


export default App;
