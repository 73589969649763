import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useOnlineStatus } from '../../hooks/useOnlineStatus';
import { useDispatch, useSelector } from 'react-redux';
import style from '../../style/global.module.css';

import formstyle from '../../style/form.module.css';
import global from '../../style/global.module.css';

const Footer = () => {
  const user_Login = useSelector(state => state.user_Login)
  const { userInfo } = user_Login
  const isLoggedIn = userInfo;

  const userProfile = useSelector(state => state.user_Profile)
  const { error, loading, user } = userProfile

  const isOnline = useOnlineStatus();

  const footer_signed_out = (
    <>
      <p>© 2018-2023 MMCY Business Solutions Ltd </p>
      <p>This application is designed, built and hosted by MMCY Business Solutions Ltd</p>
    </>
  );
  // This should be replaced with a Websocket. 
  const footer_signed_in = (
    <>
      {error ?
        <div className={formstyle.errormessage}>{error}</div>
        :
        <>
        
          
          <span className={global.hideOnMobile}> 
          {user === undefined ? <p className={formstyle.errormessage}>Error getting user details </p> : 
             <span>Signed in as: {user.first_name + " " + user.last_name} </span>}
          <span> // Backend: {isOnline ? '✅' : '❌ Disconnected'}</span>
          <span> // Database: {isOnline ? '✅' : '❌ Disconnected'}</span>
          </span>
          <Link className={global.white} to='/support/tickets/list'>Beta Release: Report a bug or request a feature</Link>
       </>
      }

    </>
  );


  return (
    <footer className={style.center} >
      {isLoggedIn ? footer_signed_in : footer_signed_out}

    </footer>
  );
};

export default Footer;