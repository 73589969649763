import { Link } from 'react-router-dom';
import style from '../style/global.module.css';
import formstyle from '../style/form.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile } from '../APIs/userAPIConfig';
import { useEffect } from 'react';

const Dashboard = () => {
  const user_Login = useSelector(state => state.user_Login)
  const { userInfo } = user_Login

  const userProfile = useSelector(state => state.user_Profile)
  const { error, loading, user } = userProfile

  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(getUserProfile(userInfo.user_id))
  }, [dispatch, userInfo])

  // const isMFAenabled = user.mfa;

  const mfanotice = () => {
    // if (isMFAenabled === false) {
       return (
   
     <div className={formstyle.warningmessage}>
       <h3>Important 2FA Notice</h3>
       <p>Secure your account with two factor authentication (2FA)</p>
       <Link to={'/user/2fa/setup'} ><button className={formstyle.green}>Setup 2fA</button></Link>
     </div> );
    //  }
   };

   const eventsnotice = () => {
  //  if (isMFAenabled === false) {
    return (
  <div>
    <h3>Upcoming Events</h3>
    <p>Summary comming soon, for now go direct to the full list</p>
    <Link to={'/calendar/event/list'} ><button className={formstyle.lightblue}>Events</button></Link>
  </div> );
  // }
};

const tasksnotice = () => {

    return (
  <div>
    <h3>Upcoming Tasks</h3>
    <p>Summary comming soon, for now go direct to the full list</p>
    <Link to={'/calendar/task/list'} ><button className={formstyle.lightblue}>Tasks</button></Link>
  </div> );
  
};

  return (
    <div className={style.section}>
       <div className={`${style.responsivecontainer} ${style.center} containter`}>
      <h1>MMCY Dashboard</h1>
      { (error) ? 
       <div className={formstyle.errormessage}>{error}</div>
       :
       <>
      <p>Welcome {user.first_name}  </p>
      {mfanotice()}
      {eventsnotice()}
      {tasksnotice()}
      </>
      }
      </div>
    </div>
  );
};
export default Dashboard;
