import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import store, { initialState } from './store'
import App from './App';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store} serverState={initialState}>
        <App />
    </Provider>
  </React.StrictMode>
);
