import Square from '../components/square';
import { MdArrowBack,MdAnchor,MdOutlineTimer,MdDashboard, MdPeople, MdMenuBook, MdOutlineRoute, MdTimeline, MdOutlineWatchLater, MdOutlineLoop,  MdBuild, MdAccessTime } from "react-icons/md";
import style from '../sailingLog.module.css'


const Tools = () => {
  const squares = [
    {
      name: 'Back',
      icon: <MdArrowBack />,
      link: "/fun/sailinglog/",
    },
    {
      name: 'Anchour Alarm',
      icon: <MdAnchor />,
      link: "/fun/sailinglog/settings/boattypes/list",
      disabled: true,
    },
    {
      name: 'Race Countdown',
      icon: <MdAccessTime />,
      link: "/fun/sailinglog/tools/racecountdown",
    },
    {
      name: 'Stopwatch',
      icon: <MdOutlineTimer />,
      link: "/fun/sailinglog/tools/stopwatch",
    },
    {
      name: 'Race Manager',
      icon: <MdDashboard />,
      link: "/fun/sailinglog/tools/racemanager",
    },
  ]

  return (
    <div>
      <div className={style.gridContainer}>
        {squares.map((square) =>
          <Square
            key={square.name}
            name={square.name}
            icon={square.icon}
            link={square.link}
            disabled={square.disabled}
            hoverText={square.hoverText}
          />)}
      </div>
    </div>

  );
};
export default Tools;