//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../components/containers/moduleHome'
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../APIs/genericAPIs'
import { ContactsGroupSelectOptionsAPI } from './groups'

export const moduleDefinitions = {
  moduleName: 'Contacts New',
  moduleNameSingular: 'Contact',
  moduleLink: '/contacts/contact/',
}

const dataBaseFilterOptions = [
  {
    id: 1,
    name: 'Active',
    default: true,
  }, {
    id: 2,
    name: 'Archived',
  }, {
    id: 3,
    name: 'All',
  },
]

export const moduleFields = [
  {
    label: 'Name',
    fieldName: 'name',
    fieldType: 'text',
    addFormOrder: 0,
    editFormOrder: 0,
    viewOrder: 1,
    tableDisplayOrder: 1,
    tableDisplayPriority: 1,
  }, {
    label: 'Type',
    fieldName: 'contact_type',
    fieldLongName: 'contact_type_name',
    fieldType: 'select',
    fieldGroupPosition: 'Left',
    options: [{ id: '1', name: 'Person' }, { id: '2', name: 'Organisation' }],
    addFormInitialValue: 1,
    addFormOrder: 10,
    editFormOrder: 10,
    viewOrder: 10,
    tableDisplayOrder: 1,
    tableDisplayPriority: 1,
  }, {
    // label: 'Person',
    fieldName: 'person',
    fieldType: 'subForm',
    subFormFieldDefinitions: 'person',
    addFormOrder: 10,
    addFormConditionalRenderBasedOn: 'contact_type',
    addFormConditionalRenderValues: [1],
    editFormOrder: 10,
    editFormConditionalRenderBasedOn: 'contact_type',
    editFormConditionalRenderValues: [1],
    viewOrder: 10,
    viewConditionalRenderBasedOn: 'contact_type',
    viewConditionalRenderValues: [1],
  }, {
    // label: 'Organisation',
    fieldName: 'organisation',
    fieldType: 'subForm',
    subFormFieldDefinitions: 'organisation',
    addFormOrder: 10,
    addFormConditionalRenderBasedOn: 'contact_type',
    addFormConditionalRenderValues: [2],
    editFormOrder: 10,
    editFormConditionalRenderBasedOn: 'contact_type',
    editFormConditionalRenderValues: [2],
    viewOrder: 10,
    viewConditionalRenderBasedOn: 'contact_type',
    viewConditionalRenderValues: [2],
  }, {
    label: 'Status',
    fieldName: 'record_status',
    fieldType: 'select',
    fieldGroupPosition: 'Right',
    options: [{ id: 'Active', name: 'Active' }, { id: 'Archived', name: 'Archived' }],
    addFormOrder: 0,
    editFormOrder: 20,
    viewOrder: 20,
    tableDisplayOrder: '',
    tableDisplayPriority: 0,
  }, {
    label: 'Phone No.',
    fieldName: 'phone_no',
    fieldType: 'text',
    multipleAdd: true,
    addFormOrder: 30,
    editFormOrder: 30,
    tableDisplayOrder: 6,
    tableDisplayPriority: 3,
  }, {
    label: 'Groups',
    fieldName: 'group',
    fieldLongName: 'group_name',
    fieldType: 'autoCompleteText',
    options: 'ContactsGroupSelectOptions',
    multipleSelect: true,
    openAddNewModule: 'contacts/groups',
    addFormOrder: 71,
    editFormOrder: 71,
    viewOrder: 71,
    tableDisplayOrder: 4,
    tableDisplayPriority: 2,
  }, {
    label: 'Customer',
    fieldName: 'customer',
    fieldType: 'checkbox',
    fieldGroupPosition: 'Left',
    fieldGroupSize: '20%',
    addFormOrder: 120,
    editFormOrder: 120,
    tableDisplayOrder: '0',
    tableDisplayPriority: '0',
  }, {
    label: 'Supplier',
    fieldName: 'supplier',
    fieldType: 'checkbox',
    fieldGroupPosition: 'Right',
    fieldGroupSize: '20%',
    addFormOrder: 130,
    editFormOrder: 130,
    tableDisplayOrder: '0',
    tableDisplayPriority: '0',
  }, {
    label: 'Staff',
    fieldName: 'staff',
    fieldType: 'checkbox',
    fieldGroupPosition: 'Right',
    fieldGroupSize: '20%',
    addFormOrder: 140,
    addFormConditionalRenderBasedOn: 'contact_type',
    addFormConditionalRenderValues: [1],
    editFormOrder: 140,
    editFormConditionalRenderBasedOn: 'contact_type',
    editFormConditionalRenderValues: [1],
    viewOrder: 140,
    viewConditionalRenderBasedOn: 'contact_type',
    viewConditionalRenderValues: [1],
    tableDisplayOrder: '0',
    tableDisplayPriority: '0',
  }, {
    label: 'Member',
    fieldName: 'member',
    fieldType: 'checkbox',
    fieldGroupPosition: 'Right',
    fieldGroupSize: '20%',
    addFormOrder: 150,
    editFormOrder: 150,
    tableDisplayOrder: '0',
    tableDisplayPriority: '0',

  }, {
    //   label: 'Relationships',
    //   fieldName: 'Relationship',
    //   fieldType: 'autoCompleteText',
    //   options: 'ContactsContactSelectOptions',
    //   openAddNewModule: 'contacts/contact',
    //   multipleSelect: true,
    //   addFormOrder: 155,
    //   editFormOrder: 155,
    //   tableDisplayOrder: 7,
    //   tableDisplayPriority: 4,
    // }, {
    label: 'Notes',
    fieldName: 'notes',
    fieldType: 'textarea',
    rows: "4",
    addFormOrder: 160,
    editFormOrder: 160,
  },
]

export const fieldValidation = (fieldName, value, condition) => {
  let hasError = false,
    fieldErrorMessage = ""
  switch (fieldName) {
    case "first_name":
      if (condition === true) {
        if (value.trim() === "") {
          hasError = true
          fieldErrorMessage = "First name is required"
        } else if (value.length < 2) {
          hasError = true
          fieldErrorMessage = 'First name is too short'
        } else if (value.length > 50) {
          hasError = true
          fieldErrorMessage = 'First name is too long'
        } else {
          hasError = false
          fieldErrorMessage = ""
        }
        break
      }
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

export const ContactsContactCreateRequest = 'ContactsContactCreateRequest'
export const ContactsContactCreateSuccess = 'ContactsContactCreateSuccess'
export const ContactsContactCreateFail = 'ContactsContactCreateFail'
export const ContactsContactCreateReset = 'ContactsContactCreateReset'

export const ContactsContactDetailsRequest = 'ContactsContactDetailsRequest'
export const ContactsContactDetailsSuccess = 'ContactsContactDetailsSuccess'
export const ContactsContactDetailsFail = 'ContactsContactDetailsFail'
export const ContactsContactDetailsReset = 'ContactsContactDetailsReset'

export const ContactsContactUpdateRequest = 'ContactsContactUpdateRequest'
export const ContactsContactUpdateSuccess = 'ContactsContactUpdateSuccess'
export const ContactsContactUpdateFail = 'ContactsContactUpdateFail'
export const ContactsContactUpdateReset = 'ContactsContactUpdateReset'

export const ContactsContactDeleteRequest = 'ContactsContactDeleteRequest'
export const ContactsContactDeleteSuccess = 'ContactsContactDeleteSuccess'
export const ContactsContactDeleteFail = 'ContactsContactDeleteFail'
export const ContactsContactDeleteReset = 'ContactsContactDeleteReset'

export const ContactsContactListRequest = 'ContactsContactListRequest'
export const ContactsContactListSuccess = 'ContactsContactListSuccess'
export const ContactsContactListFail = 'ContactsContactListFail'


const APIVariablesCreate = {
  APIRequest: ContactsContactCreateRequest,
  APISuccess: ContactsContactCreateSuccess,
  APIFail: ContactsContactCreateFail,
  APIEndPoint: 'contacts/v1/contact/add',
}
const APIVariablesListAll = {
  APIRequest: ContactsContactListRequest,
  APISuccess: ContactsContactListSuccess,
  APIFail: ContactsContactListFail,
  APIEndPoint: 'contacts/v1/contact/list'
}
const APIVariablesSelectOptions = {
  APIRequest: 'ContactsContactSelectOptionsRequest',
  APISuccess: 'ContactsContactSelectOptionsSuccess',
  APIFail: 'ContactsContactSelectOptionsFail',
  APIEndPoint: 'contacts/v1/contact/selectoptions'
}
// const listAllSummaryAPIVariables = {
//   APIRequest: ContactsContactList_NAMESRequest,
//   APISuccess: ContactsContactList_NAMESSuccess,
//   APIFail: ContactsContactList_NAMESFail,
//   APIEndPoint: 'contacts/v1/person/listnames'
// }
const APIVariablesItemDetails = {
  APIRequest: ContactsContactDetailsRequest,
  APISuccess: ContactsContactDetailsSuccess,
  APIFail: ContactsContactDetailsFail,
  APIEndPoint: 'contacts/v1/contact/'
}
const APIVariablesUpdate = {
  APIRequest: ContactsContactUpdateRequest,
  APISuccess: ContactsContactUpdateSuccess,
  APISuccess_2: ContactsContactDetailsSuccess,
  APIFail: ContactsContactUpdateFail,
  APIEndPoint: 'contacts/v1/contact/'
}
const APIVariablesDelete = {
  APIRequest: ContactsContactDeleteRequest,
  APISuccess: ContactsContactDeleteSuccess,
  APIFail: ContactsContactDeleteFail,
  APIEndPoint: 'contacts/v1/contact/'
}

const API_Body_Contents = () => {
  return (
    ''
  )
}

export const ContactsContactCreateAPI = (item) => async (dispatch, getState) => {
  const cleandate = item.dob === '' ? null : item.dob;

  const API_Body = {
    contact_type: item.contact_type,
    record_status: "Active",

    email: item.email,
    phone_no: item.phone_no,
    group: item.group,
    notes: item.notes,
  }

  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}
export const ContactsContactListAllAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState, query)
}
export const ContactsContactListSelectOptionsAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesSelectOptions, dispatch, getState)
}
export const ContactsContactDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}
export const ContactsContactUpdateAPI = (item) => async (dispatch, getState) => {
  const cleandate = item.dob === '' ? null : item.dob;

  const API_Body = {
    contact_type: item.contact_type,
    record_status: item.record_status,
    title: item.title,
    status: item.status,
    first_name: item.first_name,
    middle_name: item.middle_name,
    last_name: item.last_name,
    org_name: item.org_name,
    known_as: item.known_as,
    email: item.email,
    phone_no: item.phone_no,
    group: item.group,
    location: item.location,
    gender: item.gender,
    dob: cleandate,
    notes: item.notes,
  }
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}
export const ContactsContactDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

export const ContactsContactCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ContactsContactCreateRequest:
      return {
        loading: true
      }

    case ContactsContactCreateSuccess:
      return {
        loading: false,
        success: true,
        ContactsContact: action.payload
      }

    case ContactsContactCreateFail:
      return {
        loading: false,
        error: action.payload
      }

    case ContactsContactCreateReset:
      return {}


    default:
      return state
  }
}

export const ContactsContactUpdateReducer = (state = { ContactsContact: {} }, action) => {
  switch (action.type) {
    case ContactsContactUpdateRequest:
      return { loading: true }

    case ContactsContactUpdateSuccess:
      return { loading: false, success: true }

    case ContactsContactUpdateFail:
      return { loading: false, error: action.payload }

    case ContactsContactUpdateReset:
      return { ContactsContact: {} }

    default:
      return state
  }
}

export const ContactsContactDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ContactsContactDeleteRequest:
      return { loading: true }

    case ContactsContactDeleteSuccess:
      return {
        loading: false,
        success: true
      }

    case ContactsContactDeleteFail:
      return { loading: false, error: action.payload }

    case ContactsContactDeleteReset:
      return {}

    default:
      return state
  }
}

export const ContactsContactDetailsReducer = (state = { loading: true, ContactsContact: [] }, action) => {
  switch (action.type) {
    case ContactsContactDetailsRequest:
      return {
        ...state,
        loading: true
      }

    case ContactsContactDetailsSuccess:
      return {
        loading: false,
        ContactsContact: action.payload
      }

    case ContactsContactDetailsFail:
      return {
        loading: false,
        error: action.payload
      }


    default:
      return state
  }
}


export const ContactsContactListReducer = (state = { ContactsContact: [] }, action) => {
  switch (action.type) {
    case ContactsContactListRequest:
      return {
        loading: true
      }

    case ContactsContactListSuccess:
      return {
        loading: false,
        ContactsContact: action.payload
      }

    case ContactsContactListFail:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const ContactsContactSelectOptionsReducer = (state = { ContactsContactSelectOptions: [] }, action) => {
  switch (action.type) {
    case 'ContactsContactSelectOptionsRequest':
      return {
        loading: true
      }

    case 'ContactsContactSelectOptionsSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
      }

    case 'ContactsContactSelectOptionsFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function ContactsContactListScreen(props) {
  const [dataBaseFilter, setDataBaseFilter] = useState('Active');
  const dataListParams = ('?listtype=' + dataBaseFilter)

  const createAPI = ContactsContactCreateAPI
  const listAPI = ContactsContactListAllAPI
  const detailsAPI = ContactsContactDetailsAPI
  const updateAPI = ContactsContactUpdateAPI
  const deleteAPI = ContactsContactDeleteAPI

  const resetCreate = ContactsContactCreateReset
  const resetUpdate = ContactsContactUpdateReset
  const resetViewDetails = ContactsContactDetailsReset
  const resetDelete = ContactsContactDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

  const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const ContactsContactCreate = useSelector(state => state.ContactsContactCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = ContactsContactCreate

  const ContactsContactList = useSelector(state => state.ContactsContactList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    ContactsContact: dataList } = ContactsContactList

  const ContactsContactDetails = useSelector(state => state.ContactsContactDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    ContactsContact: dataItem } = ContactsContactDetails

  const ContactsContactUpdate = useSelector(state => state.ContactsContactUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = ContactsContactUpdate

  const ContactsContactDelete = useSelector(state => state.ContactsContactDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = ContactsContactDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingDetails
    )
  )

  useEffect(() => {
    dispatch(ContactsGroupSelectOptionsAPI())
  }, [])

  useEffect(() => {
    dispatch(ContactsContactListAllAPI(dataListParams))
  }, [dataBaseFilter])

  useEffect(() => {
    if (successUpdate) {
      dispatch(ContactsContactListAllAPI(dataListParams))
      dispatch({ type: ContactsContactUpdateReset })
    }
    if (successDelete) {
      // dispatch(ContactsContactListAllAPI(dataListParams))
      dispatch({ type: ContactsContactDeleteReset })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }
  }, [successDelete, successUpdate,
    errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete])


  return (
    <ModuleHome
      formModalVisibility={formModalVisibility}
      setFormModalVisibility={setFormModalVisibility}
      moduleModalVisibility={props.moduleModalVisibility}
      setModuleModalVisibility={props.setModuleModalVisibility}

      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataListParams={dataListParams}
      getDataList={listAPI}
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      //Database filtering
      dataBaseFilterOptions={dataBaseFilterOptions}
      dataBaseFilter={dataBaseFilter}
      setDataBaseFilter={setDataBaseFilter}

      rowOnClickAction='edit'
      add={true}
      edit={true}
      view={false}
      delete={(id) => dispatch(ContactsContactDeleteAPI(id))}

      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}
    />
  )
}

export default ContactsContactListScreen