//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'

const moduleDefinitions = {
  moduleName: 'Trip Types',
  moduleNameSingular: 'Trip Type',
  moduleLink: '/fun/sailinglog/settings/triptypes/',
  thOptionsWidth: '115px',
}

const moduleFields = [
  {
    label: 'Name', 
    mandatory: 'true', 
    fieldName: 'name',
    fieldType: 'text',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  } 
]

const fieldValidation = (fieldName, value) => {
  let hasError = false,
      fieldErrorMessage = ""

  switch (fieldName) {

    case "name":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Name is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break
      
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}


  ///////////// Constants /////////////////////////
export const SailingLogTripTypeListCreateRequest = 'SailingLogTripTypeListCreateRequest'
export const SailingLogTripTypeListCreateSuccess = 'SailingLogTripTypeListCreateSuccess'
export const SailingLogTripTypeListCreateFail = 'SailingLogTripTypeListCreateFail'
export const SailingLogTripTypeListCreateReset = 'SailingLogTripTypeListCreateReset'

export const SailingLogTripTypeListDetailsRequest = 'SailingLogTripTypeListDetailsRequest'
export const SailingLogTripTypeListDetailsSuccess = 'SailingLogTripTypeListDetailsSuccess'
export const SailingLogTripTypeListDetailsFail = 'SailingLogTripTypeListDetailsFail'
export const SailingLogTripTypeListDetailsReset = 'SailingLogTripTypeListDetailsReset'

export const SailingLogTripTypeListUpdateRequest = 'SailingLogTripTypeListUpdateRequest'
export const SailingLogTripTypeListUpdateSuccess = 'SailingLogTripTypeListUpdateSuccess'
export const SailingLogTripTypeListUpdateFail = 'SailingLogTripTypeListUpdateFail'
export const SailingLogTripTypeListUpdateReset = 'SailingLogTripTypeListUpdateReset'

export const SailingLogTripTypeListDeleteRequest = 'SailingLogTripTypeListDeleteRequest'
export const SailingLogTripTypeListDeleteSuccess = 'SailingLogTripTypeListDeleteSuccess'
export const SailingLogTripTypeListDeleteFail = 'SailingLogTripTypeListDeleteFail'
export const SailingLogTripTypeListDeleteReset = 'SailingLogTripTypeListDeleteReset'

export const SailingLogTripTypeListListRequest = 'SailingLogTripTypeListListRequest'
export const SailingLogTripTypeListListSuccess = 'SailingLogTripTypeListListSuccess'
export const SailingLogTripTypeListListFail = 'SailingLogTripTypeListListFail'


  ///////////// API Config /////////////////////////

const APIVariablesCreate = {
  APIRequest: SailingLogTripTypeListCreateRequest,
  APISuccess: SailingLogTripTypeListCreateSuccess,
  APIFail: SailingLogTripTypeListCreateFail,
  APIEndPoint: 'sailinglog/v1/triptype/add',
}
const APIVariablesListAll = {
  APIRequest: SailingLogTripTypeListListRequest,
  APISuccess: SailingLogTripTypeListListSuccess,
  APIFail: SailingLogTripTypeListListFail,
  APIEndPoint: 'sailinglog/v1/triptype/list'
}
const APIVariablesSelectOptions = {
  APIRequest: 'SailingLogTripTypeSelectOptionsRquest',
  APISuccess: 'SailingLogTripTypeSelectOptionsSuccess',
  APIFail: 'SailingLogTripTypeSelectOptionsFail',
  APIEndPoint: 'sailinglog/v1/triptype/selectoptions'
}
const APIVariablesItemDetails = {
  APIRequest: SailingLogTripTypeListDetailsRequest,
  APISuccess: SailingLogTripTypeListDetailsSuccess,
  APIFail: SailingLogTripTypeListDetailsFail,
  APIEndPoint: 'sailinglog/v1/triptype/'
}
const APIVariablesUpdate = {
  APIRequest: SailingLogTripTypeListUpdateRequest,
  APISuccess: SailingLogTripTypeListUpdateSuccess,
  APISuccess_2: SailingLogTripTypeListDetailsSuccess,
  APIFail: SailingLogTripTypeListUpdateFail,
  APIEndPoint: 'sailinglog/v1/triptype/'
}
const APIVariablesDelete = {
  APIRequest: SailingLogTripTypeListDeleteRequest,
  APISuccess: SailingLogTripTypeListDeleteSuccess,
  APIFail: SailingLogTripTypeListDeleteFail,
  APIEndPoint: 'sailinglog/v1/triptype/'
}

export const SailingLogTripTypeCreateAPI = (item) => async (dispatch, getState) => {
  const API_Body = {
    name: item.name,
  }
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const SailingLogTripTypeListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const SailingLogTripTypeSelectOptionsAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesSelectOptions, dispatch, getState)
}

export const SailingLogTripTypeDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const SailingLogTripTypeUpdateAPI = (item) => async (dispatch, getState) => {
  const API_Body = {
    name: item.name,
  }
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const SailingLogTripTypeDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}
  
  ///////////// REDUCERS/////////////////////////
  // State is an empty object
  export const SailingLogTripTypeCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case SailingLogTripTypeListCreateRequest:
            return {
                loading: true
            }
  
        case SailingLogTripTypeListCreateSuccess:
            return {
                loading: false,
                success: true,
                SailingLogTripType: action.payload
            }
  
        case SailingLogTripTypeListCreateFail:
            return {
                loading: false,
                error: action.payload
            }
  
        case SailingLogTripTypeListCreateReset:
            return {}
  
  
        default:
            return state
    }
  }
  
  export const SailingLogTripTypeUpdateReducer = (state = { SailingLogTripType: {} }, action) => {
    switch (action.type) {
        case SailingLogTripTypeListUpdateRequest:
            return { loading: true }
  
        case SailingLogTripTypeListUpdateSuccess:
            return { loading: false, success: true }
  
        case SailingLogTripTypeListUpdateFail:
            return { loading: false, error: action.payload }
  
        case SailingLogTripTypeListUpdateReset:
            return { SailingLogTripType: {} }
  
        default:
            return state
    }
  }
  
  export const SailingLogTripTypeDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case SailingLogTripTypeListDeleteRequest:
            return { loading: true }
  
        case SailingLogTripTypeListDeleteSuccess:
            return { 
              loading: false, 
              success: true }
  
        case SailingLogTripTypeListDeleteFail:
            return { loading: false, error: action.payload }
  
        case SailingLogTripTypeListDeleteReset:
              return { }
  
        default:
            return state
    }
  }
  
  export const SailingLogTripTypeDetailsReducer = (state = { loading: true, SailingLogTripType: [] }, action) => {
    switch (action.type) {
        case SailingLogTripTypeListDetailsRequest:
            return {
                ...state,
                loading: true
            }
  
        case SailingLogTripTypeListDetailsSuccess:
            return {
                loading: false,
                SailingLogTripType: action.payload
            }
  
        case SailingLogTripTypeListDetailsFail:
            return {
                loading: false,
                error: action.payload
            }
  
  
        default:
            return state
    }
  }
   
  
  export const SailingLogTripTypeListReducer = (state = { SailingLogTripType: [] }, action) => {
    switch (action.type) {
        case SailingLogTripTypeListListRequest:
            return {
                loading: true
            }
  
        case SailingLogTripTypeListListSuccess:
            return {
                loading: false,
                fieldOptionValues: [...action.payload],
                SailingLogTripType: action.payload
            }
  
        case SailingLogTripTypeListListFail:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
  }

  export const SailingLogTripTypeSelectOptionsReducer = (state = { fieldOptionValues: [] }, action) => {
    switch (action.type) {
      case 'SailingLogTripTypeSelectOptionsRequest':
        return {
          loading: true
        }
  
      case 'SailingLogTripTypeSelectOptionsSuccess':
        return {
          loading: false,
          fieldOptionValues: [...action.payload],
        }
  
      case 'SailingLogTripTypeSelectOptionsFail':
        return {
          loading: false,
          error: action.payload
        }
      default:
        return state
    }
  }
  

function SailingLogTripTypeScreen(props) {
  const createAPI = SailingLogTripTypeCreateAPI
  const listAPI = SailingLogTripTypeListAllAPI
  const detailsAPI = SailingLogTripTypeDetailsAPI
  const updateAPI = SailingLogTripTypeUpdateAPI
  const deleteAPI = SailingLogTripTypeDeleteAPI

  const resetCreate = SailingLogTripTypeListCreateReset
  const resetUpdate = SailingLogTripTypeListUpdateReset
  const resetViewDetails = SailingLogTripTypeListDetailsReset
  const resetDelete = SailingLogTripTypeListDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const SailingLogTripTypeCreate = useSelector(state => state.SailingLogTripTypeCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = SailingLogTripTypeCreate

  const SailingLogTripTypeList = useSelector(state => state.SailingLogTripTypeList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    SailingLogTripType: dataList } = SailingLogTripTypeList

  const SailingLogTripTypeDetails = useSelector(state => state.SailingLogTripTypeDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    SailingLogTripType: dataItem } = SailingLogTripTypeDetails

  const SailingLogTripTypeUpdate = useSelector(state => state.SailingLogTripTypeUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = SailingLogTripTypeUpdate

  const SailingLogTripTypeDelete = useSelector(state => state.SailingLogTripTypeDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = SailingLogTripTypeDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingList || loadingDetails
    )
  )

  useEffect(() => {
    dispatch(SailingLogTripTypeListAllAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
     if (errorLoadingList) {setServerErrorMessage(errorLoadingList)}
    if (errorCreate) {setServerErrorMessage(errorCreate)}
    if (errorUpdate) {setServerErrorMessage(errorUpdate)}
    if (errorGetDetails) {setServerErrorMessage(errorGetDetails)}
    if (errorDelete) {setServerErrorMessage(errorDelete)}
  }, [successDelete, successUpdate,
    errorLoadingList,errorCreate,errorUpdate,errorGetDetails, errorDelete])


  return (
    <ModuleHome
        formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
    moduleModalVisibility={props.moduleModalVisibility}
    setModuleModalVisibility={props.setModuleModalVisibility}

    paramsItem={paramsItem}
    moduleDefinitions={moduleDefinitions}
    moduleFields={moduleFields}

    //Table Data
    dataList={dataList}
    loadingList={loadingList}
    errorLoadingList={errorLoadingList}

    rowOnClickAction={'edit'}
    add={true}
    edit={true}
    view={false}
    delete={(id) => dispatch(deleteAPI(id))}

    //AddEditForm
    fieldValidation={fieldValidation}

    loading={loading}
    serverErrorMessage={serverErrorMessage}
    setServerErrorMessage={setServerErrorMessage}

    getDataList={listAPI}

    itemDetails={detailsAPI}
    dataItem={dataItem}
    itemDetailsReset={resetViewDetails}

    itemCreate={createAPI}
    successCreate={successCreate}
    resetCreate={resetCreate}

    itemUpdate={updateAPI}
    successUpdate={successUpdate}
    resetUpdate={resetUpdate}

    itemDelete={deleteAPI}
    successDelete={successDelete}
    resetDelete={resetDelete}

    //View Page
    // viewTabs={viewTabs} 
    // headingName={dataItem.name}
 
      /> 
  )
}

export default SailingLogTripTypeScreen