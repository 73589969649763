// Old style detailed explanation : https://reactjs.org/docs/lifting-state-up.html
// Hooks video: https://www.youtube.com/watch?v=HF4o9KAZNxw
// Hooks code: https://codepen.io/codeSTACKr/pen/gOayRBK

import { useState } from "react";

  // shared
  const scaleNames = {
    c: 'Celsius',
    f: 'Fahrenheit',
    cm: 'Centimetre',
    in: 'Inch',
    mt: 'Meter',
    ft: 'Feet',
    km: 'Kilometer',
    mi:  'Mile',
  };

  function tryConvert(argument, convert) {
    const input = parseFloat(argument);
    if (Number.isNaN(input)) {
      return '';
    }
    const output = convert(input);
    const rounded = Math.round(output * 1000) / 1000;
    return rounded.toString();
  }

  // Temperature 
  function toCelsius(fahrenheit) {
    return (fahrenheit - 32) * 5 / 9;
  }

  function toFahrenheit(celsius) {
    return (celsius * 9 / 5) + 32;
  }

  function BoilingVerdict(props) {
    if (props.celsius >= 300) {
      return <p>Too hot</p>;
    }
    else if (props.celsius >= 220) {
      return <p>Oven is ready</p>;
    }
    else if (props.celsius >= 100) {
      return <p>Tea or coffee?</p>;
    }
    else if (props.celsius >= 25) {
      return <p>Put the air con on</p>;
    }
    return <p>Crank up the heat!</p>;
  }

  // Length
  function CMtoInch(centimetre) {
    return (centimetre * 0.3937008);
  }
  // function CMtoFeet(centimetre) {
  //   return (centimetre / 30.48);
  // }
  // function CMtoMile(centimetre) {
  //   return (centimetre * 0.000006213712);
  // }
  function INtoCentimetre(inch) {
    return (inch / 0.3937008);
  }
  // function INtoMeter(inch) {
  //   return (inch * 0.0254);
  // }
  // function INtoKilometer(inch) {
  //   return (inch * 0.0000254);
  // }
  
  const Input1 = ({tempscale, onScaleChange, temperature, length, onChange}) => {
    function handleChange(e) {
      onChange(e.target.value);
      onScaleChange(tempscale);
    }

    return (
      <div>
        <fieldset
          style={{width: "90%"}}   >
          <legend>{scaleNames[tempscale]}:</legend>
          <input
            style={{width: "90%"}}        
            value={temperature}          
            onChange={handleChange} />        
        </fieldset>
      </div>
    );
  };

  const Input2 = ({lengthscale, onScaleChange, temperature, length,label, onChange}) => {
    function handleChange(e) {
      onChange(e.target.value);
      onScaleChange(lengthscale);
    }

    return (
      <div>
        <fieldset
          style={{width: "90%"}}   >
          <legend>{scaleNames[label]}:</legend>
          <input
            style={{width: "90%"}}        
            value={length}          
            onChange={handleChange} />        
        </fieldset>
      </div>
    );
  };


const Calculator  = () => {
  const [tempscale, setTempScale] = useState("c");
  const [lengthscale, setLengthScale] = useState("cm");
  const [temperature, setTemperature] = useState("");
  const [length, setLength] = useState("");

  const celsius = tempscale === 'f' ? tryConvert(temperature, toCelsius) : temperature;
  const fahrenheit = tempscale === 'c' ? tryConvert(temperature, toFahrenheit) : temperature;

  const centimetre = lengthscale === 'in' ? tryConvert(length, INtoCentimetre) : length;
  const inch = lengthscale === 'cm' ? tryConvert(length, CMtoInch) : length;
  // const meter = lengthscale === 'in' ? tryConvert(length, INtoMeter) : length;
  // const feet = lengthscale === 'cm' ? tryConvert(length, CMtoFeet) : length;
  // const kilometer = lengthscale === 'in' ? tryConvert(length, INtoKilometer) : length;
  // const mile = lengthscale === 'cm' ? tryConvert(length, CMtoMile) : length;

  return (
    <div>
      {/* Result: {metre} */}
      <table>
        <thead></thead>
        <tbody>
          <tr><h3>Temperature</h3></tr>
          <tr>
            <td>      
              <Input1 
              tempscale="c"
              temperature={celsius}
              onScaleChange ={setTempScale}
              onChange={setTemperature} />  
            </td>
            <td>      
              <Input1 
              tempscale="f"
              temperature={fahrenheit}
              onScaleChange ={setTempScale}
              onChange={setTemperature} />
            </td>
          </tr>
          <tr>      
            <BoilingVerdict      
            celsius={parseFloat(celsius)} />  
          </tr>
          <tr><h3>Length</h3></tr>
          <tr>
            <td>      
              <Input2 
              label="cm"
              lengthscale="cm"
              length={centimetre}
              onScaleChange ={setLengthScale}
              onChange={setLength} />  
            </td>
            <td>      
              <Input2
              label="in"
              lengthscale="in"
              length={inch}
              onScaleChange ={setLengthScale}
              onChange={setLength} />
            </td>
          </tr>  
          {/* <tr>
            <td>      
              <Input2 
              label="mt"
              lengthscale="cm"
              length={meter}
              onScaleChange ={setLengthScale}
              onChange={setLength} />  
            </td>
            <td>      
              <Input2
              label="ft"
              lengthscale="in"
              length={feet}
              onScaleChange ={setLengthScale}
              onChange={setLength} />
            </td>
          </tr>
          <tr>
            <td>      
              <Input2 
              label="km"
              lengthscale="cm"
              length={kilometer}
              onScaleChange ={setLengthScale}
              onChange={setLength} />  
            </td>
            <td>      
              <Input2
              label="mi"
              lengthscale="in"
              length={mile}
              onScaleChange ={setLengthScale}
              onChange={setLength} />
            </td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
};

export default Calculator ;