//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
// APIs
import {ItemCreate, ItemListAll,ItemDetails,ItemUpdate, ItemDelete} from '../../../APIs/genericAPIs'

export const moduleDefinitions = {
  moduleName: 'Events: Status Options',
  moduleNameSingular: 'Events - Status Option',
  moduleLink: '/system/settings/events/status/',
  thOptionsWidth: '115px',
}

export const moduleFields = [
  {
    label: 'Select Order',
    fieldName: 'order',
    fieldType: 'text',
    addFormOrder: 2,
    editFormOrder: 2,
    tableDisplayOrder: 2,
    tableDisplayPriority: 2,
  },
{
    label: 'Status Name',
    fieldName: 'name',
    fieldType: 'text',
    addFormOrder: 1,
    editFormOrder: 1,
    tableDisplayOrder: 1,
    tableDisplayPriority: 1,
  }
]

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
      fieldErrorMessage = ""
  switch (fieldName) {
    case "name":
      break
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

export const EventStatusCreateRequest = 'EventStatusCreateRequest'
export const EventStatusCreateSuccess = 'EventStatusCreateSuccess'
export const EventStatusCreateFail = 'EventStatusCreateFail'
export const EventStatusCreateReset = 'EventStatusCreateReset'

export const EventStatusDetailsRequest = 'EventStatusDetailsRequest'
export const EventStatusDetailsSuccess = 'EventStatusDetailsSuccess'
export const EventStatusDetailsFail = 'EventStatusDetailsFail'
export const EventStatusDetailsReset = 'EventStatusDetailsReset'

export const EventStatusUpdateRequest = 'EventStatusUpdateRequest'
export const EventStatusUpdateSuccess = 'EventStatusUpdateSuccess'
export const EventStatusUpdateFail = 'EventStatusUpdateFail'
export const EventStatusUpdateReset = 'EventStatusUpdateReset'

export const EventStatusDeleteRequest = 'EventStatusDeleteRequest'
export const EventStatusDeleteSuccess = 'EventStatusDeleteSuccess'
export const EventStatusDeleteFail = 'EventStatusDeleteFail'
export const EventStatusDeleteReset = 'EventStatusDeleteReset'

export const EventStatusList_MYRequest = 'EventStatusList_MYRequest'
export const EventStatusList_MYSuccess = 'EventStatusList_MYSuccess'
export const EventStatusList_MYFail = 'EventStatusList_MYFail'
export const EventStatusList_MYReset = 'EventStatusList_MYReset'

export const EventStatusListRequest = 'EventStatusListRequest'
export const EventStatusListSuccess = 'EventStatusListSuccess'
export const EventStatusListFail = 'EventStatusListFail'

const APIVariablesCreate = {
  APIRequest: EventStatusCreateRequest ,
  APISuccess: EventStatusCreateSuccess ,
  APIFail: EventStatusCreateFail ,
  APIEndPoint: 'events/v1/status/add',
}
const APIVariablesListAll = {
  APIRequest: EventStatusListRequest ,
  APISuccess: EventStatusListSuccess ,
  APIFail: EventStatusListFail ,
  APIEndPoint: 'events/v1/status/list'
}
const APIVariablesItemDetails = {
  APIRequest: EventStatusDetailsRequest ,
  APISuccess: EventStatusDetailsSuccess ,
  APIFail: EventStatusDetailsFail ,
  APIEndPoint: 'events/v1/status/'
}
const APIVariablesUpdate = {
  APIRequest: EventStatusUpdateRequest ,
  APISuccess: EventStatusUpdateSuccess ,
  APISuccess_2: EventStatusDetailsSuccess,
  APIFail: EventStatusUpdateFail ,
  APIEndPoint: 'events/v1/status/'
}
const APIVariablesDelete = {
  APIRequest: EventStatusDeleteRequest ,
  APISuccess: EventStatusDeleteSuccess ,
  APIFail: EventStatusDeleteFail ,
  APIEndPoint: 'events/v1/status/'
}

export const EventStatusCreateAPI = (item) => async (dispatch, getState) => {  
  const API_Body = {
    order: item.order,
    name: item.name,
      }
  ItemCreate(API_Body,APIVariablesCreate,dispatch,getState)
} 

export const EventStatusListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll,dispatch,getState)
}

export const EventStatusDetailsAPI = (itemId)  => async (dispatch, getState) => { 
  ItemDetails(itemId,APIVariablesItemDetails,dispatch,getState)
}

export const EventStatusUpdateAPI = (item) => async (dispatch, getState) => {
  const API_Body = {
    order: item.order,
    name: item.name,
  }
  
  ItemUpdate(item,API_Body,APIVariablesUpdate,dispatch, getState)
}

export const EventStatusDeleteAPI = (itemId) => async (dispatch, getState) => { 
  ItemDelete(itemId,APIVariablesDelete,dispatch,getState) 
}


// State is an empty object
export const EventStatusCreateReducer = (state = {}, action) => {
  switch (action.type) {
      case EventStatusCreateRequest:
          return {
              loading: true
          }

      case EventStatusCreateSuccess:
          return {
              loading: false,
              success: true,
              EventStatus: action.payload
          }

      case EventStatusCreateFail:
          return {
              loading: false,
              error: action.payload
          }

      case EventStatusCreateReset:
          return {}


      default:
          return state
  }
}

export const EventStatusUpdateReducer = (state = { EventStatus: {} }, action) => {
  switch (action.type) {
      case EventStatusUpdateRequest:
          return { loading: true }

      case EventStatusUpdateSuccess:
          return { loading: false, success: true }

      case EventStatusUpdateFail:
          return { loading: false, error: action.payload }

      case EventStatusUpdateReset:
          return { EventStatus: {} }

      default:
          return state
  }
}

export const EventStatusDeleteReducer = (state = {}, action) => {
  switch (action.type) {
      case EventStatusDeleteRequest:
          return { loading: true }

      case EventStatusDeleteSuccess:
          return { 
            loading: false, 
            success: true }

      case EventStatusDeleteFail:
          return { loading: false, error: action.payload }

      case EventStatusDeleteReset:
            return { }

      default:
          return state
  }
}

export const EventStatusDetailsReducer = (state = { loading: true, EventStatus: [] }, action) => {
  switch (action.type) {
      case EventStatusDetailsRequest:
          return {
              ...state,
              loading: true
          }

      case EventStatusDetailsSuccess:
          return {
              loading: false,
              success: true,
              EventStatus: action.payload
          }

      case EventStatusDetailsFail:
          return {
              loading: false,
              error: action.payload,
              EventStatus: 'error'
          }

      case EventStatusDetailsReset:
        return { EventStatus: {} }

      default:
          return state
  }
}


export const EventStatusListReducer = (state = { EventStatus: [],fieldOptionValues:[] }, action) => {
  switch (action.type) {
      case EventStatusListRequest:
          return {
              loading: true
          }

      case EventStatusListSuccess:
          return {
              loading: false,
              fieldOptionValues: [...action.payload],
              success: true,
              EventStatus: action.payload
          }

      case EventStatusListFail:
          return {
              loading: false,
              error: action.payload
          }
      default:
          return state
  }
}

function EventStatusScreen() {

  const createAPI = EventStatusCreateAPI
  const listAPI = EventStatusListAllAPI
  const detailsAPI = EventStatusDetailsAPI
  const updateAPI = EventStatusUpdateAPI
  const deleteAPI = EventStatusDeleteAPI

  const resetCreate = EventStatusCreateReset
  const resetUpdate = EventStatusUpdateReset
  const resetViewDetails = EventStatusDetailsReset
  const resetDelete = EventStatusDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const EventStatusCreate = useSelector(state => state.EventStatusCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = EventStatusCreate

  const EventStatusList = useSelector(state => state.EventStatusList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    EventStatus: dataList } = EventStatusList

  const EventStatusDetails = useSelector(state => state.EventStatusDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    EventStatus: dataItem } = EventStatusDetails

  const EventStatusUpdate = useSelector(state => state.EventStatusUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = EventStatusUpdate

  const EventStatusDelete = useSelector(state => state.EventStatusDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = EventStatusDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingDetails
    )
  )


  useEffect(() => {
    dispatch(listAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
     if (errorLoadingList) {setServerErrorMessage(errorLoadingList)}
    if (errorCreate) {setServerErrorMessage(errorCreate)}
    if (errorUpdate) {setServerErrorMessage(errorUpdate)}
    if (errorGetDetails) {setServerErrorMessage(errorGetDetails)}
    if (errorDelete) {setServerErrorMessage(errorDelete)}
  }, [successDelete, successUpdate,
    errorLoadingList,errorCreate,errorUpdate,errorGetDetails, errorDelete])


  return (
    <ModuleHome
      // Shared items
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      rowOnClickAction='edit'
      add={true}
      edit={true}
      view={false}
      delete={(id) => dispatch(deleteAPI(id))}

      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      getDataList={listAPI}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}
    />
  )
}

export default EventStatusScreen