const GenericButton = (props) => {

  const icon = (
    <span className='buttonIcon'>
    <props.icon
      className={props.iconClassName}
      style={{
        verticalAlign: 'middle',
        fontSize: props.iconSize  ? props.iconSize : '30px' ,
      }} />
  </span>
  )

  return (
    <button
      className={`${'button'} ${props.className && props.className} `}
      style={props.style}
      onClick={props.onClick}
      disabled={props.disabled}
      >
      <div className='buttonDiv'>
        {props.iconPosition != 'Left' &&
          <>
            {icon}
            <span className='buttonText'>
              {props.name}
            </span>
          </>
        }
        {props.iconPosition === 'Left' &&
          <>
            <span className='buttonTextRight'>
              {props.name}
            </span>
            {icon}
          </>
        }
      </div>
    </button >
  )
}
export default GenericButton