import { useState } from 'react';
import tablestyle from '../../style/table.module.css';
import navstyle from '../../layout/layout.module.css'
import { MdExpandMore } from 'react-icons/md';

const DropDownMenu = (props) => {

  const [isActive, setIsActive] = useState(false);

  const handleClick = (e) => {
    setIsActive(current => !current);
  };

  return (
    <>
    <div className={navstyle.navDropDownMenu}>
      <div className={navstyle.navDropDownMenuButton}>
        {props.name}<MdExpandMore />
      </div>
      <div
        className={`
          ${navstyle.navDropDownContent} 
          ${navstyle[props.css]} 
          `}
          style={props.style}
      >
        {props.contents}
      </div>
      </div>
    </>
  );
};
export default DropDownMenu;

