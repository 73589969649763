import ResponsiveContainer from '../../../components/containers/responsiveContatiner';
import settingsStyle from './settings.module.css';
import ToggleSwitch from '../../../components/switches/toggleSwitch';
import SelectField from '../../../components/fields/inputFields/selectField';

import { useState } from "react";

const settingOptions = [
  {
    sectionHeading: 'Notifications',
    sectionDescription: '',
    sectionContents: [{
      label: 'Task Notifications',
      text: '',
      optionType: 'toggle',
      details: '',
    }, {
      label: 'Event Notifications',
      text: '',
      optionType: 'toggle',
      details: '',
    }]
  },
  {
    sectionHeading: 'Privacy',
    sectionDescription: 'We take your privacy sriosuly, choose how we share your data.',
    sectionContents: [{
      label: 'Marketing Consent',
      text: 'Allow us to contact you regarding new features and products',
      optionType: 'toggle',
      details: '',
    }, {
      label: 'Third Parties Marketing Consent',
      text: 'Allow select third parties to contact you about products you might be interested in.',
      optionType: 'toggle',
      details: 'Details',
    }]
  },
  {
    sectionHeading: 'Appearance',
    sectionDescription: '',
    sectionContents: [{
      label: 'Dark Mode',
      text: '',
      optionType: 'toggle',
      details: '',
    }]
  },
  {
    sectionHeading: 'Localisation',
    sectionDescription: 'Configure how your apps works.',
    sectionContents: [{
      label: 'Language',
      text: '',
      optionType: 'select',
      optionSource: [{ id: 1, name: 'English' }],
      details: '',
    }, {
      label: 'Currency',
      text: '',
      optionType: 'select',
      optionSource: [{ id: 1, name: 'GBP' }],
      details: '',
    }, {
      label: 'County/region',
      text: '',
      optionType: 'select',
      optionSource: [{ id: 1, name: 'UK' }],
      details: '',
    }, {
      label: 'Units of Measure',
      text: '',
      optionType: 'select',
      optionSource: [{ id: 1, name: 'Metric' }],
      details: '',
    }, {
      label: 'TimeZone',
      text: '',
      optionType: 'select',
      optionSource: [{ id: 1, name: 'London' }],
      details: '',
    }, {
      label: 'Date Format',
      text: '',
      optionType: 'select',
      optionSource: [{ id: 1, name: 'DD/MM/YYYY' }], //ISO: YYYY.MM.DD   American: MMM DD YYYY
      details: '',
    }, {
      label: 'Time Format',
      text: '',
      optionType: 'select',
      optionSource: [{ id: 1, name: '24 Hours' }], //24hr or AM / PM
      details: '',
    }
    ]
  },
]




  const Template = () => {

    const [toggleValue, setToggleValue] = useState(false);
    const toggleSwitch = () => {
    return (
      <ToggleSwitch 
       isChecked={toggleValue}
        // handleToggle={() => setToggleValue(!toggleValue)}
        handleToggle={() => setToggleValue(false)}
      />
        )
      }
    


    const heading = 'User Settings'
    const contents = (<>

      {settingOptions.map((sectionItem) =>

        <div className={settingsStyle.sectionHeading}>
          <h3> {sectionItem.sectionHeading}</h3>
          <p>{sectionItem.sectionDescription}</p>

          <div className={settingsStyle.sectionContents}>
            {sectionItem.sectionContents.map((sectionContent) =>
              <div className={settingsStyle.sectionContentsItem}>
                <div className={settingsStyle.sectionContentsLeft}>
                  {sectionContent.label}
                </div>
                <div className={settingsStyle.sectionContentsRight}>
                  <div className={settingsStyle.sectionContentsType}>
                    {sectionContent.optionType == 'toggle' && toggleSwitch()}
                    {sectionContent.optionType == 'select' && <SelectField fieldName={sectionContent.label} options={sectionContent.optionSource} width={'150px'}/>}
                  </div>
                  <div className={settingsStyle.sectionContentsDetailsOption} >
                    <p style={{ fontWeight: '600', margin: '0px' }}> {sectionContent.text}</p>
                    <p> {sectionContent.details}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>)}
    </>)

    return (
      <ResponsiveContainer
        heading={heading}
        contents={contents}
      />
    );
  };
  export default Template;