//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
import { ItemListAll } from '../../../APIs/genericAPIs'

const moduleDefinitions = {
  moduleName: 'Alphabet',
  moduleNameSingular: 'Alphabet',
  moduleLink: '/fun/sailinglog/alphabets/',
}

const moduleFields = [
  {
    label: 'Letter',
    fieldName: 'letter',
    fieldType: 'text',
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  }, {
    label: 'Name',
    fieldName: 'name',
    fieldType: 'text',
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  }, {
    label: 'Morse Code',
    fieldName: 'morse_code',
    fieldType: 'text',
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  }, {
    label: 'Phonic',
    fieldName: 'phonic',
    fieldType: 'text',
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  }
]

const APIVariablesListAll = {
  APIRequest: 'SailingLogAlphabetListRequest',
  APISuccess: 'SailingLogAlphabetListSuccess',
  APIFail: 'SailingLogAlphabetListFail',
  APIEndPoint: 'sailinglog/v1/alphabet/list'
}


export const SailingLogAlphabetListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const SailingLogAlphabetListReducer = (state = { SailingLogAlphabet: [] }, action) => {
  switch (action.type) {
    case 'SailingLogAlphabetListRequest':
      return {
        loading: true
      }

    case 'SailingLogAlphabetListSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
        SailingLogAlphabet: action.payload
      }

    case 'SailingLogAlphabetListFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function SailingLogAlphabetScreen() {

  const listAPI = SailingLogAlphabetListAllAPI

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()
  // const paramsItemSplit = paramsItem.action.split(':')
  // const paramsID = paramsItemSplit[1]
   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const SailingLogAlphabetList = useSelector(state => state.SailingLogAlphabetList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    SailingLogAlphabet: dataList } = SailingLogAlphabetList


  useEffect(() => {
    dispatch(SailingLogAlphabetListAllAPI())
  }, [])



  return (
    <ModuleHome
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}
/>
  )
}

export default SailingLogAlphabetScreen