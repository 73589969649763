//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../components/containers/moduleHome'
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../APIs/genericAPIs'
import { InventoryItemListAllAPI } from './items'
import { AddressListAllAPI } from '../shared/address'

export const moduleDefinitions = {
  moduleName: 'Items by Locations',
  moduleNameSingular: 'Inventory Location',
  moduleLink: '/inventory/location/',
}

export const statusOptions = [
  { id: "Active", name: "Active" },
  { id: "Proposed", name: "Proposed" },
  { id: "Disposed", name: "Disposed" },
]

export const moduleFields = [
  {
    label: 'Location',
    mandatory: 'true', 
    fieldName: 'address',
    fieldLongName: 'inventory_location',
    fieldType: 'selectfromdb',
    options: 'AddressList',
    addFormOrder: 2,
    editFormOrder: 2,
    tableDisplayOrder: 1,
    tableDisplayPriority: 2,
   }, {
    label: 'Item', 
    mandatory: 'true', 
    fieldName: 'item',
    fieldLongName: 'item_name',
    fieldType: 'autoCompleteText',
    options: 'InventoryItemList',
    addFormOrder: 1,
    editFormOrder: 1,
    tableDisplayOrder: 2,
    tableDisplayPriority: 1,

  },{
    label: 'Floor',
    fieldName: 'floor',
    fieldType: 'text',
    addFormOrder: 3,
    editFormOrder: 3,
    tableDisplayOrder: 4,
    tableDisplayPriority: 4,
  }, {
    label: 'Room',
    fieldName: 'room',
    fieldType: 'text',
    addFormOrder: 4,
    editFormOrder: 4,
    tableDisplayOrder: 3,
    tableDisplayPriority: 3,
  },{
    label: 'Shelf',
    fieldName: 'shelf',
    fieldType: 'text',
    addFormOrder: 5,
    editFormOrder: 5,
    tableDisplayOrder: 6,
    tableDisplayPriority: 5,
  },{
    label: 'Box',
    fieldName: 'box',
    fieldType: 'text',
    addFormOrder: 5,
    editFormOrder: 5,
    tableDisplayOrder: 6,
    tableDisplayPriority: 5,
  },{
    label: 'Notes',
    fieldName: 'notes',
    fieldType: 'textarea',
    rows:"4",
    addFormOrder: 8,
    editFormOrder: 8,
  },
]

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
      fieldErrorMessage = ""
  switch (fieldName) {
    case "item":
      if (value === "") {
        hasError = true
        fieldErrorMessage = "Item is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break
      case "address":
        if (value === "") {
          hasError = true
          fieldErrorMessage = "Address is required"
        } else {
          hasError = false
          fieldErrorMessage = ""
        }
        break
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

export const InventoryLocationCreateRequest = 'InventoryLocationCreateRequest'
export const InventoryLocationCreateSuccess = 'InventoryLocationCreateSuccess'
export const InventoryLocationCreateFail = 'InventoryLocationCreateFail'
export const InventoryLocationCreateReset = 'InventoryLocationCreateReset'

export const InventoryLocationDetailsRequest = 'InventoryLocationDetailsRequest'
export const InventoryLocationDetailsSuccess = 'InventoryLocationDetailsSuccess'
export const InventoryLocationDetailsFail = 'InventoryLocationDetailsFail'
export const InventoryLocationDetailsReset = 'InventoryLocationDetailsReset'

export const InventoryLocationUpdateRequest = 'InventoryLocationUpdateRequest'
export const InventoryLocationUpdateSuccess = 'InventoryLocationUpdateSuccess'
export const InventoryLocationUpdateFail = 'InventoryLocationUpdateFail'
export const InventoryLocationUpdateReset = 'InventoryLocationUpdateReset'

export const InventoryLocationDeleteRequest = 'InventoryLocationDeleteRequest'
export const InventoryLocationDeleteSuccess = 'InventoryLocationDeleteSuccess'
export const InventoryLocationDeleteFail = 'InventoryLocationDeleteFail'
export const InventoryLocationDeleteReset = 'InventoryLocationDeleteReset'

export const InventoryLocationList_MYRequest = 'InventoryLocationList_MYRequest'
export const InventoryLocationList_MYSuccess = 'InventoryLocationList_MYSuccess'
export const InventoryLocationList_MYFail = 'InventoryLocationList_MYFail'
export const InventoryLocationList_MYReset = 'InventoryLocationList_MYReset'

export const InventoryLocationListRequest = 'InventoryLocationListRequest'
export const InventoryLocationListSuccess = 'InventoryLocationListSuccess'
export const InventoryLocationListFail = 'InventoryLocationListFail'

const APIVariablesCreate = {
  APIRequest: InventoryLocationCreateRequest,
  APISuccess: InventoryLocationCreateSuccess,
  APIFail: InventoryLocationCreateFail,
  APIEndPoint: 'inventory/v1/location/add',
}
const APIVariablesListAll = {
  APIRequest: InventoryLocationListRequest,
  APISuccess: InventoryLocationListSuccess,
  APIFail: InventoryLocationListFail,
  APIEndPoint: 'inventory/v1/location/list'
}
const APIVariablesItemDetails = {
  APIRequest: InventoryLocationDetailsRequest,
  APISuccess: InventoryLocationDetailsSuccess,
  APIFail: InventoryLocationDetailsFail,
  APIEndPoint: 'inventory/v1/location/'
}
const APIVariablesUpdate = {
  APIRequest: InventoryLocationUpdateRequest,
  APISuccess: InventoryLocationUpdateSuccess,
  APISuccess_2: InventoryLocationDetailsSuccess,
  APIFail: InventoryLocationUpdateFail,
  APIEndPoint: 'inventory/v1/location/'
}
const APIVariablesDelete = {
  APIRequest: InventoryLocationDeleteRequest,
  APISuccess: InventoryLocationDeleteSuccess,
  APIFail: InventoryLocationDeleteFail,
  APIEndPoint: 'inventory/v1/location/'
}

export const InventoryLocationCreateAPI = (item) => async (dispatch, getState) => {

  const API_Body = {
    item: item.item,
    address: item.address,
    floor: item.floor,
    room: item.room,
    shelf: item.shelf,
    box: item.box,
    notes: item.notes,
  }
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const InventoryLocationListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const InventoryLocationDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const InventoryLocationUpdateAPI = (item) => async (dispatch, getState) => {

  const API_Body = {
    item: item.item,
    address: item.address,
    floor: item.floor,
    room: item.room,
    shelf: item.shelf,
    box: item.box,
    notes: item.notes,
  }
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const InventoryLocationDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

 // State is an empty object
 export const InventoryLocationCreateReducer = (state = {}, action) => {
  switch (action.type) {
      case InventoryLocationCreateRequest:
          return {
              loading: true
          }

      case InventoryLocationCreateSuccess:
          return {
              loading: false,
              success: true,
              InventoryLocation: action.payload
          }

      case InventoryLocationCreateFail:
          return {
              loading: false,
              error: action.payload
          }

      case InventoryLocationCreateReset:
          return {}


      default:
          return state
  }
}

export const InventoryLocationUpdateReducer = (state = { InventoryLocation: {} }, action) => {
  switch (action.type) {
      case InventoryLocationUpdateRequest:
          return { loading: true }

      case InventoryLocationUpdateSuccess:
          return { loading: false, success: true }

      case InventoryLocationUpdateFail:
          return { loading: false, error: action.payload }

      case InventoryLocationUpdateReset:
          return { InventoryLocation: {} }

      default:
          return state
  }
}

export const InventoryLocationDeleteReducer = (state = {}, action) => {
  switch (action.type) {
      case InventoryLocationDeleteRequest:
          return { loading: true }

      case InventoryLocationDeleteSuccess:
          return { 
            loading: false, 
            success: true }

      case InventoryLocationDeleteFail:
          return { loading: false, error: action.payload }

      case InventoryLocationDeleteReset:
            return { }

      default:
          return state
  }
}

export const InventoryLocationDetailsReducer = (state = { loading: true, InventoryLocation: [] }, action) => {
  switch (action.type) {
      case InventoryLocationDetailsRequest:
          return {
              ...state,
              loading: true
          }

      case InventoryLocationDetailsSuccess:
          return {
              loading: false,
              InventoryLocation: action.payload
          }

      case InventoryLocationDetailsFail:
          return {
              loading: false,
              error: action.payload
          }


      default:
          return state
  }
}

export const InventoryLocationListMyReducer = (state = { InventoryLocation: [] }, action) => {
  switch (action.type) {
      case InventoryLocationList_MYRequest:
          return {
              loading: true
          }

      case InventoryLocationList_MYSuccess:
          return {
              loading: false,
              InventoryLocation: action.payload
          }

      case InventoryLocationList_MYFail:
          return {
              loading: false,
              error: action.payload
          }

      case InventoryLocationList_MYReset:
          return {
              InventoryLocation: []
          }

      default:
          return state
  }
}



export const InventoryLocationListReducer = (state = { InventoryLocation: [] }, action) => {
  switch (action.type) {
      case InventoryLocationListRequest:
          return {
              loading: true
          }

      case InventoryLocationListSuccess:
          return {
              loading: false,
              InventoryLocation: action.payload
          }

      case InventoryLocationListFail:
          return {
              loading: false,
              error: action.payload
          }
      default:
          return state
  }
}

function InventoryLocationListScreen() {
  const createAPI = InventoryLocationCreateAPI
  const listAPI = InventoryLocationListAllAPI
  const detailsAPI = InventoryLocationDetailsAPI
  const updateAPI = InventoryLocationUpdateAPI
  const deleteAPI = InventoryLocationDeleteAPI

  const resetCreate = InventoryLocationCreateReset
  const resetUpdate = InventoryLocationUpdateReset
  const resetViewDetails = InventoryLocationDetailsReset
  const resetDelete = InventoryLocationDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const InventoryLocationCreate = useSelector(state => state.InventoryLocationCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = InventoryLocationCreate

  const InventoryLocationList = useSelector(state => state.InventoryLocationList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    InventoryLocation: dataList } = InventoryLocationList

  const InventoryLocationDetails = useSelector(state => state.InventoryLocationDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    InventoryLocation: dataItem } = InventoryLocationDetails

  const InventoryLocationUpdate = useSelector(state => state.InventoryLocationUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = InventoryLocationUpdate

  const InventoryLocationDelete = useSelector(state => state.InventoryLocationDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = InventoryLocationDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingList || loadingDetails
    )
  )

  useEffect(() => {
    dispatch(InventoryItemListAllAPI())
    dispatch(InventoryLocationListAllAPI())
    dispatch(AddressListAllAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
     if (errorLoadingList) {setServerErrorMessage(errorLoadingList)}
    if (errorCreate) {setServerErrorMessage(errorCreate)}
    if (errorUpdate) {setServerErrorMessage(errorUpdate)}
    if (errorGetDetails) {setServerErrorMessage(errorGetDetails)}
    if (errorDelete) {setServerErrorMessage(errorDelete)}
  }, [successDelete, successUpdate,
    errorLoadingList,errorCreate,errorUpdate,errorGetDetails, errorDelete])

  return (
    <ModuleHome
    formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      getDataList={listAPI}
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      rowOnClickAction='edit'
      add={true}
      edit={true}
      view={false}
      delete={(id) => dispatch(deleteAPI(id))}

       //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}
/>
  )
}

export default InventoryLocationListScreen