import React, { useState, useEffect } from "react";
import style from '../style/global.module.css';
import Autocomplete from './autocomplete/autocompleteScreen';
import NavModalTest from './navmodals/navmodaltest'
import FixedContainer from '../components/containers/fixedContatiner';
import { UseGEOLocationTest, Latitude, Longitude } from './location/geolocationv2'
// import LinkedFields1 from ".//linkedSelectFields/linkFieldsv1";
import LinkedFields2 from ".//linkedSelectFields/linkFieldsv2";
import LinkedFields3 from ".//linkedSelectFields/linkFieldsv3";
import LinkedFields4 from ".//linkedSelectFields/linkFieldsv4";
import { HandleOutsideClick } from '../hooks/handleOutsideClick';
import { Modal } from './modal/modal'
// import {ExportCSVButton} from './export/export1'
import FilterableProductTable from './tables/ExampleTable'
import ToggleSwitch from '../components/switches/toggleSwitch'
import { VerticalRadioSwitch } from '../components/switches/verticalRadioSwitch'
import HorizontalRadioSwitch from '../components/switches/horizontalRadioSwitch'
import CalendarTable from './calendar/CalendarTablev2'

const Test = () => {

  {/* <Autocomplete /> */ }
  {/* <NavModalTest /> */ }
  {/* <UseGEOLocationTest />
     <br></br>
     <Latitude/>
     <Longitude/> */}
  {/* <LinkedFields2></LinkedFields2> */ }
  {/* <LinkedFields3></LinkedFields3> */ }
  //  <LinkedFields4></LinkedFields4>


  // // Menu dropdown testing
  // const [showContent, setShowContent] = useState(false);
  // const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
  // console.log('showContent', showContent)
  // const contents2 = (
  //   <div style={{ backgroundColor: 'red' }}>
  //     <p>Some contents</p>
  //   </div>
  // )

  // Toggle Buttons
  const [toggleValue, setToggleValue] = useState(false);
  console.log('toggleValue', toggleValue)

  const [threewayTogglevalue, setThreewayToggleValue] = useState('');
  console.log('threewayTogglevalue', threewayTogglevalue)

  const switchValues = [
    {
      id: 1,
      name: 'Current'
    }, {
      id: 2,
      name: 'Past'
    }, {
      id: 3,
      name: 'All'
    },
  ]

  const [selected, setSelected] = useState("first");

  return (
    <>
      <div className={style.section}>
        <div className={style.fixedcontainer}>
          <h2> Test Page </h2>
          <p>This page is for development only.</p>

          {/* <CalendarTable/> */}
          <ToggleSwitch
            isChecked={toggleValue}
            handleToggle={() => setToggleValue(!toggleValue)}
          />
{/* <div style={{marginTop: '20px'}}> */}
{/* {switchValue.map((value) => */}


            <HorizontalRadioSwitch
              switchValues={switchValues}
              // value={value.name}
              selected={selected}
              // label={value.name}
              onChange={setSelected}
            />
          {/* )} */}
{/* </div> */}
          {switchValues.map((value) =>
            <VerticalRadioSwitch
              value={value.name}
              selected={selected}
              text={value.name}
              onChange={setSelected}
            />
          )}





          {/* <ExportCSVButton /> */}
          {/* <FilterableProductTable/> */}
          {/* Menu dropdown testing */}
          {/* <button onClick={() => setShowContent(current => !current)}>
            {showContent === false ? 'Show Contents' : 'Hide Contents'}</button> */}

          {/* <HandleOutsideClick
            show={showContent}
            onClickOutside={async event => { await delay(10); setShowContent(false) }}
            contents={contents2} /> */}
          <br></br>
          {/* <Modal /> */}


          <br></br>
          <br></br>
          <br></br>




          {/* Tables */}
          <table>
            <thead style={{ backgroundColor: 'pink' }}>
              <tr>
                <th colspan="2">ColA</th>
                <th>ColB</th>
                <th>ColC</th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: 'lightgreen' }}>
              <tr style={{ backgroundColor: 'blue' }}>
                <td>1</td>
                <td>2</td>
                <td>3</td>
              </tr>
              <tr style={{ backgroundColor: 'orange' }} rowspan="2">
                <td colspan="4" >This is a Group</td>
              </tr>
              <tr>
                <td>4</td>
                <td>5</td>
                <td>6</td>
              </tr>
            </tbody>
          </table>


        </div>
      </div>
    </>

  );
}
export default Test;