//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../components/containers/moduleHome'
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../APIs/genericAPIs'
import { SailingLogContactSelectOptionsAPI } from './contacts/contacts'
import { SailingLogTripTypeSelectOptionsAPI } from './settings/tripTypes'
import { SailingLogBoatSelectOptionsAPI } from './boats'

import { SailingLogPassageSummaryByTripAPI } from './passage/passages'
import { SailingLogLogEntrySummaryByTripAPI } from './passage/logEntry'
import { SailingLogCrewSummaryByTripAPI } from './passage/crew'

import { CurrencyCodesSelectOptionsAPI } from '../shared/currencyCodes'
// import { SailingLogCrewDelete } from './crew'

export const moduleDefinitions = {
  moduleName: 'Trips',
  moduleNameSingular: 'Trip',
  moduleLink: '/fun/sailinglog/trips/',
  thOptionsWidth: '115px',
}

export const moduleFields = [
  {
    label: 'Start Date',
    mandatory: 'true',
    fieldName: 'first_passage_date',
    fieldType: 'date',
    addFormOrder: 0,
    editFormOrder: 0,
    viewOrder: 0,
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  },
  {
    label: 'Trip Name',
    mandatory: 'true',
    fieldName: 'name',
    fieldType: 'text',
    addFormOrder: '',
    editFormOrder: '',
    viewOrder: 0,
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  },
  {
    label: 'Trip Type',
    mandatory: 'true',
    fieldName: 'trip_type',
    fieldLongName: 'trip_type_name',
    fieldType: 'selectfromdb',
    fieldGroupPosition: 'Left', // or 'Right' // Optional
    options: 'SailingLogTripTypeSelectOptions',
    // addNewLink:'/fun/sailinglog/settings/triptypes/add/',
    openAddNewModule: 'sailinglog/settings/triptypes',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '5',
  },
  {
    label: 'Boat',
    mandatory: 'true',
    fieldName: 'boat',
    fieldLongName: 'boat_name',
    fieldType: 'autoCompleteText',
    fieldGroupPosition: 'Right', // or 'Right' // Optional
    options: 'SailingLogBoatSelectOptions',
    // addNewLink:'/fun/sailinglog/boats/add/',
    openAddNewModule: 'sailinglog/boats',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '2',
  }, {
    label: 'Organiser',
    fieldName: 'organiser',
    fieldLongName: 'organiser_name',
    fieldType: 'autoCompleteText',
    options: 'SailingLogContactSelectOptions',
    // addNewLink:'/fun/sailinglog/contacts/add/',
    openAddNewModule: 'sailinglog/contacts',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '4',
  }, {
    label: 'Chartered From',
    fieldName: 'chartered_from',
    fieldLongName: 'chartered_from_name',
    fieldType: 'autoCompleteText',
    options: 'SailingLogContactSelectOptions',
    // addNewLink:'/fun/sailinglog/contacts/add/',
    openAddNewModule: 'sailinglog/contacts',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '4',
  }, {
    label: 'Currency',
    fieldName: 'chartered_cost_curr',
    fieldLongName: 'chartered_cost_curr_name',
    fieldType: 'selectfromdb',
    fieldGroupHeading: 'Trip Charter Costs',
    fieldGroupPosition: 'Left', // or 'Right' // Optional
    fieldGroupSize: '20%',
    options: 'CurrencyCodeSelectOptions',
    addFormOrder: '',
    editFormOrder: '',
    viewOrder: 0,
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  }, {
    label: 'Amount',
    fieldName: 'chartered_cost',
    fieldType: 'currency',
    currencyCode: 'chartered_cost_curr',
    // fieldGroupHeading: 'Charter Costs',
    fieldGroupPosition: 'Middle', // or 'Right' // Optional
    fieldGroupSize: '30%',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  }, {
    label: 'Per',
    fieldName: 'chartered_cost_options',
    fieldLongName: 'chartered_cost_current_choice',
    fieldType: 'select',
    // fieldGroupHeading: 'Charter Costs',
    fieldGroupPosition: 'Right', // or 'Right' // Optional
    fieldGroupSize: '40%',
    options: [{ id: '1', name: 'Person' }, { id: '2', name: 'Charter' }],
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  }, {
    label: 'Notes',
    fieldName: 'notes',
    fieldType: 'textarea',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
    rows: "4",
  }, {
    label: 'No. of passages',
    fieldName: 'total_no_of_passages',
    fieldType: 'number',
    addFormOrder: 0,
    editFormOrder: 0,
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  }, {
    label: 'Total Miles',
    fieldName: 'total_distance',
    fieldType: 'number',
    addFormOrder: 0,
    editFormOrder: 0,
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  }, {
    label: 'Total Days',
    fieldName: 'total_no_of_days',
    fieldType: 'number',
    addFormOrder: 0,
    editFormOrder: 0,
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  }, {
    label: 'Total Night Hours',
    fieldName: 'total_night_hours',
    fieldType: 'number',
    addFormOrder: 0,
    editFormOrder: 0,
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  },
]

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
    fieldErrorMessage = ""

  switch (fieldName) {

    case "name":
      if (value === "") {
        hasError = true
        fieldErrorMessage = "Name is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break

    case "trip_type":
      if (value === "") {
        hasError = true
        fieldErrorMessage = "Trip Type is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break

    case "boat":
      if (value === "") {
        hasError = true
        fieldErrorMessage = "Boat is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break

    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

///////////// API Config /////////////////////////

const APIVariablesCreate = {
  APIRequest: 'SailingLogTripCreateRequest',
  APISuccess: 'SailingLogTripCreateSuccess',
  APIFail: 'SailingLogTripCreateFail',
  APIEndPoint: 'sailinglog/v1/trip/add'
}
const APIVariablesListAll = {
  APIRequest: 'SailingLogTripListRequest',
  APISuccess: 'SailingLogTripListSuccess',
  APIFail: 'SailingLogTripListFail',
  APIEndPoint: 'sailinglog/v1/trip/list'
}
const APIVariablesSelectOptions = {
  APIRequest: 'SailingLogTripSelectOptionsRequest',
  APISuccess: 'SailingLogTripSelectOptionsSuccess',
  APIFail: 'SailingLogTripSelectOptionsFail',
  APIEndPoint: 'sailinglog/v1/trip/selectoptions'
}
const APIVariablesSummaryByBoat = {
  APIRequest: 'SailingLogTripBoatSummaryRequest',
  APISuccess: 'SailingLogTripBoatSummarySuccess',
  APIFail: 'SailingLogTripBoatSummaryFail',
  APIEndPoint: 'sailinglog/v1/trip/boatsummary'
}
const APIVariablesSummaryByContact = {
  APIRequest: 'SailingLogTripSummaryRequest',
  APISuccess: 'SailingLogTripSummarySuccess',
  APIFail: 'SailingLogTripSummaryFail',
  APIEndPoint: 'sailinglog/v1/trip/contactsummary'
}
const APIVariablesSummaryByLocation = {
  APIRequest: 'SailingLogTripSummaryByLocationRequest',
  APISuccess: 'SailingLogTripSummaryByLocationSuccess',
  APIFail: 'SailingLogTripSummaryByLocationFail',
  APIEndPoint: 'sailinglog/v1/trip/locationsummary'
}
const APIVariablesItemDetails = {
  APIRequest: 'SailingLogTripDetailsRequest',
  APISuccess: 'SailingLogTripDetailsSuccess',
  APIFail: 'SailingLogTripDetailsFail',
  APIEndPoint: 'sailinglog/v1/trip/'
}
const APIVariablesUpdate = {
  APIRequest: 'SailingLogTripUpdateRequest',
  APISuccess: 'SailingLogTripUpdateSuccess',
  APISuccess_2: 'SailingLogTripDetailsSuccess',
  APIFail: 'SailingLogTripUpdateFail',
  APIEndPoint: 'sailinglog/v1/trip/'
}
const APIVariablesDelete = {
  APIRequest: 'SailingLogTripDeleteRequest',
  APISuccess: 'SailingLogTripDeleteSuccess',
  APIFail: 'SailingLogTripDeleteFail',
  APIEndPoint: 'sailinglog/v1/trip/'
}

const API_Body_Contents = (item) => {
    return ({
    name: item.name,
    trip_type: item.trip_type,
    boat: item.boat,
    organiser: item.organiser,
    chartered_from: item.chartered_from,
    chartered_cost: item.chartered_cost,
    chartered_cost_curr: item.chartered_cost_curr,
    chartered_cost_options: item.chartered_cost_options,
    notes: item.notes,
  })}

export const SailingLogTripCreateAPI = (item) => async (dispatch, getState) => {
  const API_Body = API_Body_Contents(item)
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}
export const SailingLogTripListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}
export const SailingLogTripSelectOptionsAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesSelectOptions, dispatch, getState,query)
}
export const SailingLogTripSummaryByBoatAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesSummaryByBoat, dispatch, getState, query)
}
export const SailingLogTripSummaryByContactAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesSummaryByContact, dispatch, getState, query)
}
export const SailingLogTripSummaryByLocationAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesSummaryByLocation, dispatch, getState, query)
}
export const SailingLogTripDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}
export const SailingLogTripUpdateAPI = (item) => async (dispatch, getState) => {
  const API_Body = API_Body_Contents(item)
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}
export const SailingLogTripDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

///////////// REDUCERS/////////////////////////
// State is an empty object
export const SailingLogTripCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SailingLogTripCreateRequest':
      return {
        loading: true
      }

    case 'SailingLogTripCreateSuccess':
      return {
        loading: false,
        success: true,
        SailingLogTrip: action.payload
      }

    case 'SailingLogTripCreateFail':
      return {
        loading: false,
        error: action.payload
      }

    case 'SailingLogTripCreateReset':
      return {}


    default:
      return state
  }
}

export const SailingLogTripUpdateReducer = (state = { SailingLogTrip: {} }, action) => {
  switch (action.type) {
    case 'SailingLogTripUpdateRequest':
      return { loading: true }

    case 'SailingLogTripUpdateSuccess':
      return { loading: false, success: true }

    case 'SailingLogTripUpdateFail':
      return { loading: false, error: action.payload }

    case 'SailingLogTripUpdateReset':
      return { SailingLogTrip: {} }

    default:
      return state
  }
}

export const SailingLogTripDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SailingLogTripDeleteRequest':
      return { loading: true }

    case 'SailingLogTripDeleteSuccess':
      return {
        loading: false,
        success: true
      }

    case 'SailingLogTripDeleteFail':
      return { loading: false, error: action.payload }

    case 'SailingLogTripDeleteReset':
      return {}

    default:
      return state
  }
}

export const SailingLogTripDetailsReducer = (state = { loading: true, SailingLogTrip: [] }, action) => {
  switch (action.type) {
    case 'SailingLogTripDetailsRequest':
      return {
        ...state,
        loading: true
      }

    case 'SailingLogTripDetailsSuccess':
      return {
        loading: false,
        SailingLogTrip: action.payload
      }

    case 'SailingLogTripDetailsFail':
      return {
        loading: false,
        error: action.payload
      }


    default:
      return state
  }
}


export const SailingLogTripListReducer = (state = { SailingLogTrip: [] }, action) => {
  switch (action.type) {
    case 'SailingLogTripListRequest':
      return {
        loading: true
      }

    case 'SailingLogTripListSuccess':
      return {
        loading: false,
        SailingLogTrip: action.payload
      }

    case 'SailingLogTripListFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const SailingLogTripSelectOptionsReducer = (state = { SailingLogTrip: [] }, action) => {
  switch (action.type) {
    case 'SailingLogTripSelectOptionsRequest':
      return {
        loading: true
      }

    case 'SailingLogTripSelectOptionsSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
      }

    case 'SailingLogTripSelectOptionsFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}


// export const SailingLogTripSummaryReducer = (state = { SailingLogTrip: [] }, action) => {
//   switch (action.type) {
//     case 'SailingLogTripSummaryRequest':
//       return {
//         loading: true
//       }

//     case 'SailingLogTripSummarySuccess':
//       return {
//         loading: false,
//         fieldOptionValues: [...action.payload],
//         SailingLogTrip: action.payload
//       }

//     case 'SailingLogTripSummaryFail':
//       return {
//         loading: false,
//         error: action.payload
//       }
//     default:
//       return state
//   }
// }

export const SailingLogTripBoatSummaryReducer = (state = { SailingLogTrip: [] }, action) => {
  switch (action.type) {
    case 'SailingLogTripBoatSummaryRequest':
      return {
        loading: true
      }

    case 'SailingLogTripBoatSummarySuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
        SailingLogTrip: action.payload
      }

    case 'SailingLogTripBoatSummaryFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const SailingLogTripSummaryByLocationReducer = (state = { SailingLogTrip: [] }, action) => {
  switch (action.type) {
    case 'SailingLogTripSummaryByLocationRequest':
      return {
        loading: true
      }

    case 'SailingLogTripSummaryByLocationSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
        SailingLogTrip: action.payload
      }

    case 'SailingLogTripSummaryByLocationFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function SailingLogTripScreen(props) {
  const createAPI = SailingLogTripCreateAPI
  const listAPI = SailingLogTripListAllAPI
  const detailsAPI = SailingLogTripDetailsAPI
  const updateAPI = SailingLogTripUpdateAPI
  const deleteAPI = SailingLogTripDeleteAPI

  const resetCreate = 'SailingLogTripCreateReset'
  const resetUpdate = 'SailingLogTripUpdateReset'
  const resetViewDetails = 'SailingLogTripDetailsReset'
  const resetDelete = 'SailingLogTripDeleteReset'

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const paramsItem = useParams()
  const paramsItemSplit = paramsItem.action.split(':')
  const paramsID = paramsItemSplit[1]

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const SailingLogTripCreate = useSelector(state => state.SailingLogTripCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = SailingLogTripCreate

  const SailingLogTripList = useSelector(state => state.SailingLogTripList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    SailingLogTrip: dataList } = SailingLogTripList

  const SailingLogTripDetails = useSelector(state => state.SailingLogTripDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    SailingLogTrip: dataItem } = SailingLogTripDetails

  const SailingLogTripUpdate = useSelector(state => state.SailingLogTripUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = SailingLogTripUpdate

  const SailingLogTripDelete = useSelector(state => state.SailingLogTripDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = SailingLogTripDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingList || loadingDetails
    )
  )


  const SailingLogPassageSummaryByTrip = useSelector(state => state.SailingLogPassageSummaryByTrip)
  const {
    loading: loadingPassageSummary,
    error: errorPassageSummary,
    SailingLogPassage: listPassageSummary } = SailingLogPassageSummaryByTrip

  const SailingLogLogEntryTripSummary = useSelector(state => state.SailingLogLogEntryTripSummary)
  const {
    loading: loadingLogEntrySummary,
    error: errorLogEntrySummary,
    SailingLogLogEntry: listLogEntrySummary } = SailingLogLogEntryTripSummary

  const SailingLogCrewTripSummary = useSelector(state => state.SailingLogCrewTripSummary)
  const {
    loading: loadingCrewSummary,
    error: errorCrewSummary,
    SailingLogCrew: listCrewSummary } = SailingLogCrewTripSummary

  const viewTabs = [
    {
      label: 'Passages',
      addLink: '/fun/sailinglog/passages/',
      viewLink: '/fun/sailinglog/passages/',
      addLinkRef: 'trip_name',
      dataList: listPassageSummary,
      loading: loadingPassageSummary,
      error: errorPassageSummary,
      // tabContent: (''),
      tableFields: [
        {          
          label: 'Passage No.',
          fieldName: 'trip_passage_no',
          fieldType: 'number',
          tableDisplayOrder: '',
          tableDisplayPriority: '2',
        }, {
          label: 'Depature Date',
          fieldName: 'depature_date',
          fieldType: 'date',
          tableDisplayOrder: '',
          tableDisplayPriority: '1',
        }, {
          label: 'Depature From',
          fieldName: 'depature_from',
          fieldLongName: 'depature_from_name',
          fieldType: 'text',
          tableDisplayOrder: '',
          tableDisplayPriority: '1',
        }, {
          label: 'Arrival At',
          fieldName: 'arrival_at',
          fieldLongName: 'arrival_at_name',
          fieldType: 'text',
          tableDisplayOrder: '',
          tableDisplayPriority: '2',
        }
      ]
    },    {
      label: 'Crew',
      // addLink: '/fun/sailinglog/crew/',
      viewLink: '/fun/sailinglog/contacts/',
      viewLinkIDField: 'name',
      addLinkRef: 'trip_name',
      dataList: listCrewSummary,
      loading: loadingCrewSummary,
      error: errorCrewSummary,
      tabContent:(<> <p>Summary of crew from all passages.</p> <p>To add or edit crew, go to the relevant passage.</p> </> ),
      // edit: true,
      // delete: SailingLogCrewDelete,
      tableFields: [
        {          
          label: 'Passage No.',
          fieldName: 'trip_passage_no',
          fieldType: 'number',
          tableDisplayOrder: '',
          tableDisplayPriority: '2',
      }, {
          label: 'Name',
          fieldName: 'name',
          fieldLongName: 'name_name',
          fieldType: 'text',
          tableDisplayOrder: '',
          tableDisplayPriority: '1',
        }, {
          label: 'Role(s)',
          fieldName: 'role',
          fieldLongName: 'role_name',
          fieldType: 'text',
          tableDisplayOrder: '',
          tableDisplayPriority: '1',
        }
      ]
    },
    {
      label: 'Log Entries',
      viewLink: '/fun/sailinglog/logentry/',
      addLink: '/fun/sailinglog/logentry/',
      addLinkRef: 'trip_name',
      dataList: listLogEntrySummary,
      loading: loadingLogEntrySummary,
      error: errorLogEntrySummary,
      // tabContent: 'Logs',
      tableFields: [
        {
          label: 'Passage No.',
          fieldName: 'trip_passage_no',
          fieldType: 'text',
          tableDisplayOrder: '',
          tableDisplayPriority: '1',
        }, {
          label: 'Log No.',
          fieldName: 'log_entry_no',
          fieldType: 'text',
          tableDisplayOrder: '',
          tableDisplayPriority: '1',
        }, {
          label: 'Date',
          fieldName: 'date',
          fieldType: 'text',
          tableDisplayOrder: '',
          tableDisplayPriority: '1',
        }, {
          label: 'Time',
          fieldName: 'time',
          fieldType: 'text',
        }
      ]
    }
  ]


  useEffect(() => {
    dispatch(SailingLogTripListAllAPI())
    dispatch(SailingLogBoatSelectOptionsAPI())
    dispatch(SailingLogContactSelectOptionsAPI())
    dispatch(SailingLogTripTypeSelectOptionsAPI())
    dispatch(CurrencyCodesSelectOptionsAPI())
    dispatch(SailingLogTripSelectOptionsAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }
    if (paramsID) {
      dispatch(SailingLogPassageSummaryByTripAPI('?listid=' + paramsID))
      dispatch(SailingLogLogEntrySummaryByTripAPI('?listid=' + paramsID))
      dispatch(SailingLogCrewSummaryByTripAPI('?listid=' + paramsID))
    }
  }, [dataList,successDelete, successUpdate,errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete, paramsID])

    // console.log('modalMode',props.modalMode)
    // console.log('setFormModalVisibility',props.setFormModalVisibility)
  return (
    <ModuleHome
          formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
    moduleModalVisibility={props.moduleModalVisibility}
    setModuleModalVisibility={props.setModuleModalVisibility}

      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      rowOnClickAction={'view'}
      view={(dataitem) => navigate(moduleDefinitions.moduleLink + 'view:' + dataitem.id)}
      add={true}
      edit={true}
      delete={(id) => dispatch(deleteAPI(id))}

      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      getDataList={listAPI}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}

      //View Page
      viewTabs={viewTabs}
    // headingName={dataItem.name}

    />

  )
}

export default SailingLogTripScreen