import {useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import style from '../../../style/global.module.css';
import formstyle from '../../../style/form.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserPassword } from '../../../APIs/userAPIConfig';
import { USERUpdate_PASSWORDReset } from '../userConstants';

const UpdatePassword = () => {
  const [currentPassword, setCurrentPassword] = useState(''); 
  const [newPassword, setNewPassword] = useState(''); 
  const [confirmPassword, setConfirmPassword] = useState(''); 
  const [message, setMessage] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user_Login = useSelector(state => state.user_Login)
  const { userInfo } = user_Login

  const userUpdatePassword = useSelector(state => state.userUpdatePassword)
  const { error: errorUpdate, loading: loadingUpdate, success: successUpdate  } = userUpdatePassword

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: USERUpdate_PASSWORDReset })
      navigate('/user/profile')
    } 
  }, [dispatch, successUpdate])


  const submitHandler = (e) => {
    e.preventDefault();
    if (currentPassword.trim().length< 1) {
      setMessage('Current Password can not be empty')
    } else if (newPassword.trim().length< 6) {
      setMessage('New password must be at least 6 characters')
    } else if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match')
    } else {
      setMessage('')
      dispatch(updateUserPassword({
        id: userInfo.user_id,
        currentPassword,
        newPassword,
        confirmPassword,
      }))
    }
};

  return (
    <div className={style.section}>
      <div className={`${style.fixedcontainer} ${style.center} containter`}>
      <h2>Change Password</h2>
      {message && <div className={formstyle.errormessage}>{message}</div>}
      {errorUpdate && <div className={formstyle.errormessage}>{errorUpdate}</div>}

      <form className={formstyle.text} >
      <div className={`${formstyle.input} ${formstyle.fullwidth}`}>
          <label htmlFor='current-password'>Current Password</label>
          <input 
            type='password' 
            id='current-password' 
            minLength="7" 
            value={currentPassword} 
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
          />
        </div>
        <div className={`${formstyle.input} ${formstyle.fullwidth}`}>
          <label htmlFor='new-password1'>New Password</label>
          <input 
            type='password' 
            id='new-password1' 
            minLength="7" 
            value={newPassword} 
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className={`${formstyle.input} ${formstyle.fullwidth}`}>
          <label htmlFor='new-password2'>Confirm New Password</label>
          <input 
            type='password' 
            id='new-password2' 
            minLength="7" 
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <div className={formstyle.action}>
          {/* {!isLoading && ( */}
            <div>
          <Link to={`/user/profile` } ><button className={formstyle.orange}>Cancel</button></Link>
          <button className={formstyle.green} onClick={submitHandler}>Change Password</button>
              </div>
            {/* )} */}
            {/* {isLoading && <p>Sending request...</p>} */}
        </div>
      </form>
    </div>
    </div>
  );
};

export default UpdatePassword;
