import SpinnerCSS from './spinnerSmall.module.css';

function Spinner() {
  return (
    <div style={{  margin: 'auto' ,
    width: '50%'}} > 
    <div className={SpinnerCSS.circleSmall}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    </div>
  )

}
export default Spinner




