import { useNavigate } from 'react-router-dom'
import { MdAdd } from 'react-icons/md';
// Components 
import GenericButton from '../buttons/genericButton'
import Table from '../tables/table'

const Template = (props) => {
  console.log('addadditionalLinkRef', props.addadditionalLinkRef)
  console.log('fieldValue', props.fieldValue(props.addadditionalLinkRef))
  // console.log('viewTab - dataList',props.dataList )
  // console.log('viewTab - loading',props.loading )
  console.log('edit={props.edit}',props.edit)

  const navigate = useNavigate()

  const setQueryParameters = () => {
    if (props.addadditionalLinkRef) {
      return (
        '?' + props.addLinkRef + '=' + props.itemId + '&' + props.addadditionalLinkRef + '=' + props.fieldValue(props.addadditionalLinkRef)
      )
    } else {
      return (
        '?' + props.addLinkRef + '=' + props.itemId
      )
    }
  }

  return (
    <>
      {!props.addLink ? '' :
        <GenericButton
        name='Add'
        onClick={() => navigate(props.addLink + 'add' + setQueryParameters())}
        icon={MdAdd}
        iconSize={'30px'}
        className={`${'green'} ${'addEditButtons'}`}
      />

        // <AddButton
        //   // add={() => { props.setFormTypeAction('Add'); { props.setFormModalVisibility(true) } }}
        //   add={() => navigate(props.addLink + 'add' + setQueryParameters())}
        // />
      }
      {props.tabContent}
      {/* {console.log('props.viewLinkIDField',props.viewLinkIDField)} */}
      {/* <TabTable
        dataList={props.dataList}
        tableFields={props.tableFields}
        
        loading={props.loading}
        error={props.error}
        addOption={props.addOption}
      /> */}
      <Table
        {...props}
        // showModal={setFormModalVisibility}
        // setFormTypeAction={setFormTypeAction}
        // setTableRowClickItemID={setTableRowClickItemID}
        source='viewTab'
        rowOnClickAction={'view'}
        tableControls={false}
        moduleFields={props.tableFields}
        thOptionsWidth='115px'
        dataList={props.dataList}
        // errorLoadingList={props.loading}
        loadingList={props.loading}

        inLineAdd={props.inLineAdd}
        inLineEdit={props.inLineEdit}
        // rowOnClickAction={props.rowOnClickAction}
        // CRUD Settings
        view={(dataitem) => navigate(props.viewLink + 'view:' + (props.viewLinkIDField ? dataitem[props.viewLinkIDField] : dataitem.id))}
        edit={props.editLink && ((dataitem) => navigate(props.editLink + 'edit:' + dataitem.id + setQueryParameters()))}
        // edit={props.edit}
        delete={props.delete}
      />
    </>
  );
};
export default Template;