import style from '../style/global.module.css';
import markavatar from '../static/avatar-mark.png';

function getImageUrl(user) {
  return (
    'https://i.imgur.com/' +
    user.imageID +
    '.jpg'
  );
}

function Avatar({user, toggle}) {

  const handletoggle = (toggle)

  return (
    <img
      className={style.avatar}
      src= {markavatar}
      // src={getImageUrl(user)}
      alt={user.name}
      onClick={() => handletoggle()}
    />
  );
}

export default Avatar