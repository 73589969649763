//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'
import { SailingLogPassageSelectOptionsAPI } from './passages'
import { SailingLogTripSelectOptionsAPI } from '../trips'
import { SailingLogWindSpeedListAllAPI } from '../reference/windSpeed'
import { SailingLogSeaStateListAllAPI } from '../reference/seaState'
import { SailingLogVisibilityListAllAPI } from '../reference/visibility'
// Hooks
import { localTime_HH_MM_SS, UTCISODate } from '../../../hooks/useDateTime'
import { useGEOLocation } from '../../../hooks/useGEOLocation.js'

export const moduleDefinitions = {
  moduleName: 'Log Entries',
  moduleNameSingular: 'Log',
  moduleLink: '/fun/sailinglog/logentry/',
  // linktoView: '/sailinglog/log/list/items/?listid=',
  thOptionsWidth: '115px',
}
export const fieldValidation = (fieldName, value) => {
  let hasError = false,
    fieldErrorMessage = ""

  switch (fieldName) {

    case "name":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Name is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break

    case "boat_speed":
      if (value === '' || value === null) {
        break
      } else if (value.length > 4) {
        hasError = true
        fieldErrorMessage = "Boat Speed is too fast. Maximum speed is 99.9"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break

    case "air_temp":
      if (value === '' || value === null) {
        break
      } else if (value > 99) {
        hasError = true
        fieldErrorMessage = "Air Temp too high. Maximum temp 99"
      } else if (value < -99) {
        hasError = true
        fieldErrorMessage = "Air Temp too low. Minimum temp -99"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break

    case "aws":
    case "awa":
    case "course":
      if (value === '' || value === null) {
        break
      } else if (value > 360) {
        hasError = true
        fieldErrorMessage = "Can not be greater than 360 degrees"
      } else if (value < 0) {
        hasError = true
        fieldErrorMessage = "Can not be less than 0 degrees"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break

    case "barometric_pressure":
      if (value === '' || value === null) {
        break
      } else if (value > 1100) {
        hasError = true
        fieldErrorMessage = "Can not be greater than 1100 millibars"
      } else if (value < 800) {
        hasError = true
        fieldErrorMessage = "Can not be less than 800 millibars"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

///////////// API Config /////////////////////////

const APIVariablesCreate = {
  APIRequest: 'SailingLogLogEntryCreateRequest',
  APISuccess: 'SailingLogLogEntryCreateSuccess',
  APIFail: 'SailingLogLogEntryCreateFail',
  APIEndPoint: 'sailinglog/v1/logentry/add',
}
const APIVariablesListAll = {
  APIRequest: 'SailingLogLogEntryListRequest',
  APISuccess: 'SailingLogLogEntryListSuccess',
  APIFail: 'SailingLogLogEntryListFail',
  APIEndPoint: 'sailinglog/v1/logentry/list'
}
const APIVariablesSummaryByTrip = {
  APIRequest: 'SailingLogLogEntryTripSummaryRequest',
  APISuccess: 'SailingLogLogEntryTripSummarySuccess',
  APIFail: 'SailingLogLogEntryTripSummaryFail',
  APIEndPoint: 'sailinglog/v1/logentry/tripsummary'
}
const summaryByPassageAPIVariables = {
  APIRequest: 'SailingLogLogEntryPassageSummaryRequest',
  APISuccess: 'SailingLogLogEntryPassageSummarySuccess',
  APIFail: 'SailingLogLogEntryPassageSummaryFail',
  APIEndPoint: 'sailinglog/v1/logentry/passagesummary'
}
const APIVariablesItemDetails = {
  APIRequest: 'SailingLogLogEntryDetailsRequest',
  APISuccess: 'SailingLogLogEntryDetailsSuccess',
  APIFail: 'SailingLogLogEntryDetailsFail',
  APIEndPoint: 'sailinglog/v1/logentry/'
}
const APIVariablesUpdate = {
  APIRequest: 'SailingLogLogEntryUpdateRequest',
  APISuccess: 'SailingLogLogEntryUpdateSuccess',
  APISuccess_2: 'SailingLogLogEntryDetailsSuccess',
  APIFail: 'SailingLogLogEntryUpdateFail',
  APIEndPoint: 'sailinglog/v1/logentry/'
}
const APIVariablesDelete = {
  APIRequest: 'SailingLogLogEntryDeleteRequest',
  APISuccess: 'SailingLogLogEntryDeleteSuccess',
  APIFail: 'SailingLogLogEntryDeleteFail',
  APIEndPoint: 'sailinglog/v1/logentry/'
}

export const SailingLogLogEntryCreateAPI = (item) => async (dispatch, getState) => {
  const cleanDate = item.date === '' ? null : item.date;
  const cleanTime = item.time === '' ? null : item.time;

  const API_Body = {
    trip_name: item.trip_name,
    passage: item.passage,
    log_entry_no: 99,
    date: cleanDate,
    time: cleanTime,
    latitude: item.latitude,
    longitude: item.longitude,
    course: item.course,
    course_heading_type: item.course_heading_type,
    boat_speed: item.boat_speed,
    awa: item.awa,
    aws: item.aws,
    wind_speed: item.wind_speed,
    sea_state: item.sea_state,
    visibility: item.visibility,
    air_temp: item.air_temp,
    barometric_pressure: item.barometric_pressure,
    notes: item.notes,
  }
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const SailingLogLogEntryListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const SailingLogLogEntrySummaryByTripAPI  = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesSummaryByTrip, dispatch, getState, query)
}

export const SailingLogLogEntrySummaryByPassageAPI  = (query) => async (dispatch, getState) => {
  ItemListAll(summaryByPassageAPIVariables, dispatch, getState, query)
}

export const SailingLogLogEntryDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const SailingLogLogEntryUpdateAPI = (item) => async (dispatch, getState) => {
  const cleanDate = item.date === '' ? null : item.date;
  const cleanTime = item.time === '' ? null : item.time;

  const API_Body = {
    trip_name: item.trip_name,
    passage: item.passage,
    log_entry_no: item.log_entry_no,
    date: cleanDate,
    time: cleanTime,
    latitude: item.latitude,
    longitude: item.longitude,
    course: item.course,
    course_heading_type: item.course_heading_type,
    boat_speed: item.boat_speed,
    awa: item.awa,
    aws: item.aws,
    wind_speed: item.wind_speed,
    sea_state: item.sea_state,
    visibility: item.visibility,
    air_temp: item.air_temp,
    barometric_pressure: item.barometric_pressure,
    notes: item.notes,
  }
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const SailingLogLogEntryDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

///////////// REDUCERS/////////////////////////
// State is an empty object
export const SailingLogLogEntryCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SailingLogLogEntryCreateRequest':
      return {
        loading: true
      }

    case 'SailingLogLogEntryCreateSuccess':
      return {
        loading: false,
        success: true,
        SailingLogLogEntry: action.payload
      }

    case 'SailingLogLogEntryCreateFail':
      return {
        loading: false,
        error: action.payload
      }

    case 'SailingLogLogEntryCreateReset':
      return {}


    default:
      return state
  }
}

export const SailingLogLogEntryUpdateReducer = (state = { SailingLogLogEntry: {} }, action) => {
  switch (action.type) {
    case 'SailingLogLogEntryUpdateRequest':
      return { loading: true }

    case 'SailingLogLogEntryUpdateSuccess':
      return { loading: false, success: true }

    case 'SailingLogLogEntryUpdateFail':
      return { loading: false, error: action.payload }

    case 'SailingLogLogEntryUpdateReset':
      return { SailingLogLogEntry: {} }

    default:
      return state
  }
}

export const SailingLogLogEntryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SailingLogLogEntryDeleteRequest':
      return { loading: true }

    case 'SailingLogLogEntryDeleteSuccess':
      return {
        loading: false,
        success: true
      }

    case 'SailingLogLogEntryDeleteFail':
      return { loading: false, error: action.payload }

    case 'SailingLogLogEntryDeleteReset':
      return {}

    default:
      return state
  }
}

export const SailingLogLogEntryDetailsReducer = (state = { loading: true, SailingLogLogEntry: [] }, action) => {
  switch (action.type) {
    case 'SailingLogLogEntryDetailsRequest':
      return {
        ...state,
        loading: true
      }

    case 'SailingLogLogEntryDetailsSuccess':
      return {
        loading: false,
        SailingLogLogEntry: action.payload
      }

    case 'SailingLogLogEntryDetailsFail':
      return {
        loading: false,
        error: action.payload
      }


    default:
      return state
  }
}


export const SailingLogLogEntryListReducer = (state = { SailingLogLogEntry: [] }, action) => {
  switch (action.type) {
    case 'SailingLogLogEntryListRequest':
      return {
        loading: true
      }

    case 'SailingLogLogEntryListSuccess':
      return {
        loading: false,
        SailingLogLogEntry: action.payload
      }

    case 'SailingLogLogEntryListFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const SailingLogLogEntryTripSummaryReducer = (state = { SailingLogLogEntry: [] }, action) => {
  switch (action.type) {
    case 'SailingLogLogEntryTripSummaryRequest':
      return {
        loading: true
      }

    case 'SailingLogLogEntryTripSummarySuccess':
      return {
        loading: false,
        SailingLogLogEntry: action.payload
      }

    case 'SailingLogLogEntryTripSummaryFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const SailingLogLogEntryPassageSummaryReducer = (state = { SailingLogLogEntry: [] }, action) => {
  switch (action.type) {
    case 'SailingLogLogEntryPassageSummaryRequest':
      return {
        loading: true
      }

    case 'SailingLogLogEntryPassageSummarySuccess':
      return {
        loading: false,
        SailingLogLogEntry: action.payload
      }

    case 'SailingLogLogEntryPassageSummaryFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function SailingLogLogScreen(props) {
  const createAPI = SailingLogLogEntryCreateAPI
  const listAPI = SailingLogLogEntryListAllAPI
  const detailsAPI = SailingLogLogEntryDetailsAPI
  const updateAPI = SailingLogLogEntryUpdateAPI
  const deleteAPI = SailingLogLogEntryDeleteAPI

  const resetCreate = 'SailingLogLogEntryCreateReset'
  const resetUpdate = 'SailingLogLogEntryUpdateReset'
  const resetViewDetails = 'SailingLogLogEntryDetailsReset'
  const resetDelete = 'SailingLogLogEntryDeleteReset'

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const paramsItem = useParams()
  // const paramsItemSplit = paramsItem.action.split(':')
  // const paramsID = paramsItemSplit[1]

  const queryParameters = new URLSearchParams(window.location.search)
  const trip_name = queryParameters.get("trip_name")
  const passage = queryParameters.get("passage")

  // console.log('log entry-queryParameters',queryParameters)
  // console.log('log entry-queryParameters trip_name:',trip_name)
  // console.log('log entry-queryParameters passage:',passage)

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const SailingLogLogEntryCreate = useSelector(state => state.SailingLogLogEntryCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = SailingLogLogEntryCreate

  const SailingLogLogEntryList = useSelector(state => state.SailingLogLogEntryList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    SailingLogLogEntry: dataList } = SailingLogLogEntryList

  const SailingLogLogEntryDetails = useSelector(state => state.SailingLogLogEntryDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    SailingLogLogEntry: dataItem } = SailingLogLogEntryDetails

  const SailingLogLogEntryUpdate = useSelector(state => state.SailingLogLogEntryUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = SailingLogLogEntryUpdate

  const SailingLogLogEntryDelete = useSelector(state => state.SailingLogLogEntryDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = SailingLogLogEntryDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingList || loadingDetails
    )
  )

  useEffect(() => {
    dispatch(SailingLogLogEntryListAllAPI())
    dispatch(SailingLogTripSelectOptionsAPI())
    dispatch(SailingLogPassageSelectOptionsAPI())
    dispatch(SailingLogWindSpeedListAllAPI())
    dispatch(SailingLogSeaStateListAllAPI())
    dispatch(SailingLogVisibilityListAllAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }
  }, [successDelete, successUpdate,
    errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete])

  const { latitude, longitude, error } = useGEOLocation();

  const moduleFields = [
    {
      label: 'Trip Name',
      mandatory: 'true',
      fieldName: 'trip_name',
      fieldLongName: 'trip_name_name',
      fieldType: 'autoCompleteText',
      options: 'SailingLogTripSelectOptions',
      // addNewLink: '/fun/sailinglog/trips/add/',
      openAddNewModule: 'sailinglog/trips',
      addFormInitialValue: trip_name,
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '1',
    }, {
      label: 'Passage',
      mandatory: 'true',
      fieldName: 'passage',
      fieldLongName: 'passage_name',
      fieldType: 'selectfromdb',
      options: 'SailingLogPassageSelectOptions',
      addFormConditionalSelectBasedOn: 'trip_name',
      addFormConditionalSelectLinkField: 'trip_name',
      // addNewLink: '/fun/sailinglog/passages/add/',
      openAddNewModule: 'sailinglog/passages',
      addFormInitialValue: passage,
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
    }, {
      //   label: 'Passage Name.',
      //   // mandatory: 'true',
      //   disabled: 'disabled',
      //   fieldName: 'passage_name',
      //   fieldType: 'text',
      //   addFormOrder: '0',
      //   editFormOrder: 0,
      //   tableDisplayOrder: '',
      //   tableDisplayPriority: '1',
      // }, {
      //   mandatory: 'true',
      //   label: 'Log entry no.',
      //   fieldName: 'log_entry_no',
      //   fieldType: 'number',
      //   addFormOrder: '',
      //   editFormOrder: '',
      //   tableDisplayOrder: '',
      //   tableDisplayPriority: '1',
      // }, {
      label: 'Date',
      fieldName: 'date',
      fieldType: 'date',
      fieldGroupPosition: 'Left',
      addFormOrder: '',
      addFormInitialValue: UTCISODate,
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '1',
    }, {
      label: 'Time',
      fieldName: 'time',
      fieldType: 'time',
      fieldGroupPosition: 'Right',
      addFormOrder: '',
      addFormInitialValue: localTime_HH_MM_SS,
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '1',
    }, {
      label: 'Latitude',
      fieldName: 'latitude',
      fieldType: 'text',
      fieldGroupPosition: 'Left',
      addFormOrder: '',
      addFormInitialValue: latitude,
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
    }, {
      label: 'Longitude',
      fieldName: 'longitude',
      fieldType: 'text',
      fieldGroupPosition: 'Right',
      addFormOrder: '',
      addFormInitialValue: longitude,
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
    }, {
      label: 'Course',
      fieldName: 'course',
      fieldType: 'number',
      fieldGroupPosition: 'Left',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
    }, {
      label: 'Course Type',
      fieldName: 'course_type',
      fieldType: 'select',
      fieldGroupPosition: 'Right',
      options: [{ id: 'T', name: 'True' }, { id: 'C', name: 'Compass' }],
      addFormOrder: '',
      addFormInitialValue: 'C',  // Optional
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
    }, {
      label: 'Boat Speed (knts)',
      fieldName: 'boat_speed',
      fieldType: 'text',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
    }, {
      label: 'TWA',
      fieldName: 'TWA',
      fieldType: 'number',
      fieldGroupPosition: 'Left',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
    }, {
      label: 'TWS',
      fieldName: 'TWS',
      fieldType: 'number',
      fieldGroupPosition: 'Right',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
    }, {
      label: 'Wind Speed',
      fieldName: 'wind_speed',
      fieldLongName: 'wind_speed_name',
      fieldType: 'selectfromdb',
      options: 'SailingLogWindSpeedList',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
    }, {
      label: 'Sea State',
      fieldName: 'sea_state',
      fieldLongName: 'sea_state_name',
      fieldType: 'selectfromdb',
      options: 'SailingLogSeaStateList',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
    }, {
      label: 'Visibility',
      fieldName: 'visibility',
      fieldLongName: 'visibility_name',
      fieldType: 'selectfromdb',
      options: 'SailingLogVisibilityList',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
    }, {
      label: 'Air Temp (c)',
      fieldName: 'air_temp',
      fieldType: 'number',
      // fieldGroupPosition: 'Left',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
    }, {
      label: 'Barometric Pressure (millibars)',
      fieldName: 'barometric_pressure',
      fieldType: 'number',
      // fieldGroupPosition: 'Right',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
    }, {

      label: 'Notes',
      fieldName: 'notes',
      fieldType: 'textarea',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
    },
  ]

  return (
    <ModuleHome
          formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
    moduleModalVisibility={props.moduleModalVisibility}
    setModuleModalVisibility={props.setModuleModalVisibility}
    
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      rowOnClickAction='view'
      add={true}
      edit={true}
      // view={true}
      view={(dataitem) => navigate(moduleDefinitions.moduleLink + 'view:' + dataitem.id)}
      delete={(id) => dispatch(deleteAPI(id))}
      
      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      getDataList={listAPI}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}


      //View Page
      // viewTabs={viewTabs} 
      viewHeadingName={'Log Entry'}
         
        />
      
  )
}

export default SailingLogLogScreen