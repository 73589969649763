import tablestyle from '../../style/table.module.css';
import InputFields from '../fields/inputFields/inputFields';
import ViewFields from '../fields/viewFields/viewFields'

export const TableRowData = (props) => {
  // console.log('TableRowData',props)
  const colclass = ('colDisP' + (props.tableDisplayPriority))

  // This is an action check box. Triggers a backend action
  const clickableRow = (
    <td className={tablestyle[colclass]}>
      <div className={tablestyle.checkboxRow}>
        <input
          id={props.id}
          key={props.fieldName}
          type="checkbox"
          disabled={props.disabled}
          checked={props.fieldRowItemIsChecked(props.fieldName, props.id)}
          onClick={e => props.handleRowItemClick(props.fieldName, props.id)}
        />
        <span> {props.name}</span>
      </div>
    </td>
  )

  const editableField = (
    <td className={tablestyle[colclass]}>
      <InputFields
        {...props}
      // key={props.fieldName}
      // id={props.fieldName}
      // fieldType={props.fieldType}
      // fieldValueName={props.fieldValueName}
      // value={props.value}
      // onChange={props.onChange}
      // options={props.options}
      // //Auto complete
      // handleChange={props.handleChange}
      // openAddNewModule={props.openAddNewModule}
      // //checkbox
      // fieldIsChecked={props.fieldIsChecked}
      />
    </td>
  )

  const forLoopForDataArray = (data) => {
    // console.log('data', data)
    const ArrayLength = props.data.length
    // console.log('ArrayLength', ArrayLength)
    if (ArrayLength == 0) return []
    else {
      // console.log('else', data)
      const newArray = []
      for (let i = 0; i < data.length; i++) {
        const endLoopOn = props.data.length - 1
        if (i === endLoopOn) {
          const newData = data[i]
          // console.log('newData1',newData)
          newArray.push(...newData)
        } else {
          const newData = data[i] + ', '
          // console.log('newData2',newData)
          newArray.push(...newData)
        }
      }
      return newArray
    }
  }


  const tableData = (props) => {
    // console.log('props', props)
    return (
      <td
        style={{ cursor: "pointer" }}
        className={tablestyle[colclass]}
        onClick={props.onClick}
      >
        {Array.isArray(props.data) ?
          forLoopForDataArray(props.data)
          :
          <>
            {/* {props.data} */}
            <ViewFields
              {...props}
            />
          </>
        }

      </td>

    )
  }

  // Is this row in edit mode? (Based on the row ID ) 
  if (props.editRow === props.dataitemID) {
    // If yes, check if the field should be eddited. 
    if (props.tableRowEditField === true) {
      // If yes, is the field clickable (a select field)?
      if (props.tableRowFieldClickable === true) {
        // If yes, return checkbox
        return clickableRow
      } else {
        // If no, return an input field. 
        // console.log('Showing editable field for', props.fieldName, props.dataitemID)
        return editableField
      }
    } else {
      // If field should not be edited, then return a empty cell
      return (
        <td className={tablestyle[colclass]}></td>
      )
    }
  } else {
    // If row in view mode
    if (props.tableRowFieldClickable === true) {
      // and field is clickable return a checkbox
      return clickableRow
    } else {
      // otherwise show the field data.
      // console.log('Showing Row data for', props.fieldName, props.dataitemID)
      return tableData(props)
    }
  }

}
