//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'

const moduleDefinitions = {
  moduleName: 'Boat Types',
  moduleNameSingular: 'Boat Type',
  moduleLink: '/fun/sailinglog/settings/boattypes/',
  thOptionsWidth: '115px',
}

const moduleFields = [
  {
    label: 'Name', 
    mandatory: 'true', 
    fieldName: 'name',
    fieldType: 'text',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  } 
]

const fieldValidation = (fieldName, value) => {
  let hasError = false,
      fieldErrorMessage = ""

  switch (fieldName) {

    case "name":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Name is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break
      
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}


  ///////////// Constants /////////////////////////
export const SailingLogBoatTypeCreateRequest = 'SailingLogBoatTypeCreateRequest'
export const SailingLogBoatTypeCreateSuccess = 'SailingLogBoatTypeCreateSuccess'
export const SailingLogBoatTypeCreateFail = 'SailingLogBoatTypeCreateFail'
export const SailingLogBoatTypeCreateReset = 'SailingLogBoatTypeCreateReset'

export const SailingLogBoatTypeDetailsRequest = 'SailingLogBoatTypeDetailsRequest'
export const SailingLogBoatTypeDetailsSuccess = 'SailingLogBoatTypeDetailsSuccess'
export const SailingLogBoatTypeDetailsFail = 'SailingLogBoatTypeDetailsFail'
export const SailingLogBoatTypeDetailsReset = 'SailingLogBoatTypeDetailsReset'

export const SailingLogBoatTypeUpdateRequest = 'SailingLogBoatTypeUpdateRequest'
export const SailingLogBoatTypeUpdateSuccess = 'SailingLogBoatTypeUpdateSuccess'
export const SailingLogBoatTypeUpdateFail = 'SailingLogBoatTypeUpdateFail'
export const SailingLogBoatTypeUpdateReset = 'SailingLogBoatTypeUpdateReset'

export const SailingLogBoatTypeDeleteRequest = 'SailingLogBoatTypeDeleteRequest'
export const SailingLogBoatTypeDeleteSuccess = 'SailingLogBoatTypeDeleteSuccess'
export const SailingLogBoatTypeDeleteFail = 'SailingLogBoatTypeDeleteFail'
export const SailingLogBoatTypeDeleteReset = 'SailingLogBoatTypeDeleteReset'

export const SailingLogBoatTypeListRequest = 'SailingLogBoatTypeListRequest'
export const SailingLogBoatTypeListSuccess = 'SailingLogBoatTypeListSuccess'
export const SailingLogBoatTypeListFail = 'SailingLogBoatTypeListFail'


  ///////////// API Config /////////////////////////

const APIVariablesCreate = {
  APIRequest: SailingLogBoatTypeCreateRequest,
  APISuccess: SailingLogBoatTypeCreateSuccess,
  APIFail: SailingLogBoatTypeCreateFail,
  APIEndPoint: 'sailinglog/v1/boattype/add',
}
const APIVariablesListAll = {
  APIRequest: SailingLogBoatTypeListRequest,
  APISuccess: SailingLogBoatTypeListSuccess,
  APIFail: SailingLogBoatTypeListFail,
  APIEndPoint: 'sailinglog/v1/boattype/list'
}
const APIVariablesSelectOptions = {
  APIRequest: 'SailingLogBoatTypeSelectOptionsRquest',
  APISuccess: 'SailingLogBoatTypeSelectOptionsSuccess',
  APIFail: 'SailingLogBoatTypeSelectOptionsFail',
  APIEndPoint: 'sailinglog/v1/boattype/selectoptions'
}
const APIVariablesItemDetails = {
  APIRequest: SailingLogBoatTypeDetailsRequest,
  APISuccess: SailingLogBoatTypeDetailsSuccess,
  APIFail: SailingLogBoatTypeDetailsFail,
  APIEndPoint: 'sailinglog/v1/boattype/'
}
const APIVariablesUpdate = {
  APIRequest: SailingLogBoatTypeUpdateRequest,
  APISuccess: SailingLogBoatTypeUpdateSuccess,
  APISuccess_2: SailingLogBoatTypeDetailsSuccess,
  APIFail: SailingLogBoatTypeUpdateFail,
  APIEndPoint: 'sailinglog/v1/boattype/'
}
const APIVariablesDelete = {
  APIRequest: SailingLogBoatTypeDeleteRequest,
  APISuccess: SailingLogBoatTypeDeleteSuccess,
  APIFail: SailingLogBoatTypeDeleteFail,
  APIEndPoint: 'sailinglog/v1/boattype/'
}

export const SailingLogBoatTypeCreateAPI = (item) => async (dispatch, getState) => {

  const API_Body = {
    name: item.name,
  }
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const SailingLogBoatTypeListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const SailingLogBoatTypeSelectOptionsAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesSelectOptions, dispatch, getState)
}

export const SailingLogBoatTypeDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const SailingLogBoatTypeUpdateAPI = (item) => async (dispatch, getState) => {

  const API_Body = {
    name: item.name,
  }
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const SailingLogBoatTypeDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}
  
  ///////////// REDUCERS/////////////////////////
  // State is an empty object
  export const SailingLogBoatTypeCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case SailingLogBoatTypeCreateRequest:
            return {
                loading: true
            }
  
        case SailingLogBoatTypeCreateSuccess:
            return {
                loading: false,
                success: true,
                SailingLogBoatType: action.payload
            }
  
        case SailingLogBoatTypeCreateFail:
            return {
                loading: false,
                error: action.payload
            }
  
        case SailingLogBoatTypeCreateReset:
            return {}
  
  
        default:
            return state
    }
  }
  
  export const SailingLogBoatTypeUpdateReducer = (state = { SailingLogBoatType: {} }, action) => {
    switch (action.type) {
        case SailingLogBoatTypeUpdateRequest:
            return { loading: true }
  
        case SailingLogBoatTypeUpdateSuccess:
            return { loading: false, success: true }
  
        case SailingLogBoatTypeUpdateFail:
            return { loading: false, error: action.payload }
  
        case SailingLogBoatTypeUpdateReset:
            return { SailingLogBoatType: {} }
  
        default:
            return state
    }
  }
  
  export const SailingLogBoatTypeDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case SailingLogBoatTypeDeleteRequest:
            return { loading: true }
  
        case SailingLogBoatTypeDeleteSuccess:
            return { 
              loading: false, 
              success: true }
  
        case SailingLogBoatTypeDeleteFail:
            return { loading: false, error: action.payload }
  
        case SailingLogBoatTypeDeleteReset:
              return { }
  
        default:
            return state
    }
  }
  
  export const SailingLogBoatTypeDetailsReducer = (state = { loading: true, SailingLogBoatType: [] }, action) => {
    switch (action.type) {
        case SailingLogBoatTypeDetailsRequest:
            return {
                ...state,
                loading: true
            }
  
        case SailingLogBoatTypeDetailsSuccess:
            return {
                loading: false,
                SailingLogBoatType: action.payload
            }
  
        case SailingLogBoatTypeDetailsFail:
            return {
                loading: false,
                error: action.payload
            }
  
  
        default:
            return state
    }
  }
   
  
  export const SailingLogBoatTypeListReducer = (state = { SailingLogBoatType: [] }, action) => {
    switch (action.type) {
        case SailingLogBoatTypeListRequest:
            return {
                loading: true
            }
  
        case SailingLogBoatTypeListSuccess:
            return {
                loading: false,
                SailingLogBoatType: action.payload
            }
  
        case SailingLogBoatTypeListFail:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
  }

  export const SailingLogBoatTypeSelectOptionsReducer = (state = { fieldOptionValues: [] }, action) => {
    switch (action.type) {
      case 'SailingLogBoatTypeSelectOptionsRequest':
        return {
          loading: true
        }
  
      case 'SailingLogBoatTypeSelectOptionsSuccess':
        return {
          loading: false,
          fieldOptionValues: [...action.payload],
        }
  
      case 'SailingLogBoatTypeSelectOptionsFail':
        return {
          loading: false,
          error: action.payload
        }
      default:
        return state
    }
  }
  

function SailingLogBoatTypeScreen(props) {
  const createAPI = SailingLogBoatTypeCreateAPI
  const listAPI = SailingLogBoatTypeListAllAPI
  const detailsAPI = SailingLogBoatTypeDetailsAPI
  const updateAPI = SailingLogBoatTypeUpdateAPI
  const deleteAPI = SailingLogBoatTypeDeleteAPI

  const resetCreate = SailingLogBoatTypeCreateReset
  const resetUpdate = SailingLogBoatTypeUpdateReset
  const resetViewDetails = SailingLogBoatTypeDetailsReset
  const resetDelete = SailingLogBoatTypeDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const SailingLogBoatTypeCreate = useSelector(state => state.SailingLogBoatTypeCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = SailingLogBoatTypeCreate

  const SailingLogBoatTypeList = useSelector(state => state.SailingLogBoatTypeList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    SailingLogBoatType: dataList } = SailingLogBoatTypeList

  const SailingLogBoatTypeDetails = useSelector(state => state.SailingLogBoatTypeDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    SailingLogBoatType: dataItem } = SailingLogBoatTypeDetails

  const SailingLogBoatTypeUpdate = useSelector(state => state.SailingLogBoatTypeUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = SailingLogBoatTypeUpdate

  const SailingLogBoatTypeDelete = useSelector(state => state.SailingLogBoatTypeDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = SailingLogBoatTypeDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingList || loadingDetails
    )
  )

  useEffect(() => {
    dispatch(SailingLogBoatTypeListAllAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
     if (errorLoadingList) {setServerErrorMessage(errorLoadingList)}
    if (errorCreate) {setServerErrorMessage(errorCreate)}
    if (errorUpdate) {setServerErrorMessage(errorUpdate)}
    if (errorGetDetails) {setServerErrorMessage(errorGetDetails)}
    if (errorDelete) {setServerErrorMessage(errorDelete)}
  }, [successDelete, successUpdate,
    errorLoadingList,errorCreate,errorUpdate,errorGetDetails, errorDelete])


  return (
    <ModuleHome
        formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
    moduleModalVisibility={props.moduleModalVisibility}
    setModuleModalVisibility={props.setModuleModalVisibility}
    
    paramsItem={paramsItem}
    moduleDefinitions={moduleDefinitions}
    moduleFields={moduleFields}

    //Table Data
    dataList={dataList}
    loadingList={loadingList}
    errorLoadingList={errorLoadingList}
    
    rowOnClickAction={'edit'}
    add={true}
    edit={true}
    view={false}
    delete={(id) => dispatch(deleteAPI(id))}

    //AddEditForm
    fieldValidation={fieldValidation}

    loading={loading}
    serverErrorMessage={serverErrorMessage}
    setServerErrorMessage={setServerErrorMessage}

    getDataList={listAPI}

    itemDetails={detailsAPI}
    dataItem={dataItem}
    itemDetailsReset={resetViewDetails}

    itemCreate={createAPI}
    successCreate={successCreate}
    resetCreate={resetCreate}

    itemUpdate={updateAPI}
    successUpdate={successUpdate}
    resetUpdate={resetUpdate}

    itemDelete={deleteAPI}
    successDelete={successDelete}
    resetDelete={resetDelete}

    //View Page
    // viewTabs={viewTabs} 
    // headingName={dataItem.name}
 
      /> 
  )
}

export default SailingLogBoatTypeScreen