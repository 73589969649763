//source: https://www.digitalocean.com/community/tutorials/react-countdown-timer-react-hooks
//Custom countdown timer that can be paused and resumed

import { useState, useRef, useEffect } from "react";
import styles from '../../../style/form.module.css';

const Countdown = () => {
  const [isActive, setIsActive] = useState(false)
  const [isPaused, setIsPaused] = useState(true)
  const [coutdownTime, setCountdownTime] = useState(0)
  const [timeEntered, setTimeEntered] = useState(false)

  // const [days,setDays] = useState(0);
  const [hours,setHours] = useState('');
  const [minutes,setMinutes] = useState('');
  const [seconds,setSeconds] = useState('');

  useEffect(() => {
    let interval = null;

    if (isActive && isPaused === false) {
        interval = setInterval(() => {
          setCountdownTime((timer) => timer - 1);
        }, 1000);
    } else {
        clearInterval(interval);
    }
    return () => {
        clearInterval(interval);
    };
}, [isActive, isPaused]);

  
  const handleStart = () => {
    setIsActive(true)
    setIsPaused(false)

    setCountdownTime ((
      // +(days*(60*60*24)) 
      +(hours*(60*60)) 
      +(minutes*60) 
      +(seconds*1)
    ))

  }

  const handlePause = () => {
    // clearInterval(countRef.current)
    setIsPaused(true)
  }

  const handleResume = () => {
    setIsPaused(false)
  }

  const handleRestart = () => {
    // clearInterval(countRef.current)
    setIsActive(true)
    setIsPaused(false)

    setCountdownTime ((
      // +(days*(60*60*24)) 
      +(hours*(60*60)) 
      +(minutes*60) 
      +(seconds*1)
    ))
  }

  const handleReset = () => {
    setIsActive(false)
    setIsPaused(true)
    setTimeEntered(false)
    // setDays(0)
    setHours('')
    setMinutes('')
    setSeconds('')
  }

  const display = () => {
    if (coutdownTime > 0) 
      {
        const getSeconds = `0${(coutdownTime % 60)}`.slice(-2)
        const minutes = `${Math.floor(coutdownTime / 60)}`
        const getMinutes = `0${minutes % 60}`.slice(-2)
        const getHours = `0${Math.floor(coutdownTime / 3600)}`.slice(-2)
        
        return (
          <p style={{fontSize: '50px', margin: '10px'}}>  
          {getHours >0 && getHours}
          {getHours >0 && ':'}
          {getMinutes+getHours >0 && getMinutes}
          {getMinutes+getHours >0 && ':'}
          {getSeconds} </p>
      )}
    else 
      { 
        return  (
          <p style={{fontSize: '50px', margin: '10px'}}>  Time's Up </p>)
      }
  }

  const formfields = {
    width: "60px",
    }

  return (
    <div className={styles.center}>
      <h2>Countdown Timer</h2>

      <form>
      <table className={styles.center}>
        <tbody>
          <tr>
          {/* <th> Days    </th> */}
          <th> Hours   </th>            
          <th> Minutes </th>            
          <th> Seconds </th>
          </tr>
          <tr>
            {/* <td>
              <input 
                type='number'
                id='days' 
                max="365"
                style={formfields}
                value={days}
                onChange={e => setDays(e.target.value) }
                />
            </td> */}
            <td>
              <input 
                type='number'
                id='hours' 
                max="24" 
                style={formfields}
                value={hours}
                onChange={e => {setHours(e.target.value);setTimeEntered(true)} }
                />
            </td>
            <td>
              <input 
                type='number'
                id='minutes' 
                max="60" 
                style={formfields}
                value={minutes}
                onChange={e => {setMinutes(e.target.value);setTimeEntered(true)} }
                />
            </td>
            <td>
              <input 
                type='number'
                id='seconds' 
                max="60"
                style={formfields}
                value={seconds}
                onChange={e => {setSeconds(e.target.value);setTimeEntered(true)} }
                />
            </td>
          </tr>
        </tbody>
      </table>
      </form>
      {isActive &&
      <p>{display()}</p> }
      {/* {timeEntered &&  */}
      <button className={styles.red}  onClick={handleReset}  disabled={!timeEntered}>Reset Timer</button>
      {/* } */}
      {isActive && <button className={styles.orange} onClick={handleRestart} disabled={!isActive}>Restart Timer</button>
      }
      {!isActive && isPaused && 
      // timeEntered && 
      <button className={styles.green} onClick={handleStart} disabled={!timeEntered}>Start</button>
      }
      {isActive && !isPaused && <button className={styles.blue} onClick={handlePause}>Pause</button> }
      {isActive && isPaused && timeEntered && <button className={styles.green} onClick={handleResume}>Resume</button>} 

    </div>  
  );
}

export default Countdown;
