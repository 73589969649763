//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../components/containers/moduleHome'
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../APIs/genericAPIs'

export const moduleDefinitions = {
  moduleName: 'People',
  moduleNameSingular: 'Person',
  moduleLink: '/contacts/person/',
}

const dataBaseFilterOptions = [
  {
    id: 1,
    name: 'Active',
    default: true,
  }, {
    id: 2,
    name: 'Archived',
  }, {
    id: 3,
    name: 'All',
  },
]

export const moduleFields = [
{
    label: 'Title',
    fieldName: 'title',
    fieldType: 'text',
    fieldGroupPosition: 'Left',
    addFormOrder: 60,
    editFormOrder: 60,
    viewOrder: 60,
    tableDisplayOrder: 0,
    tableDisplayPriority: 0,
  }, {
    label: 'First Name',
    fieldName: 'first_name',
    mandatory: true,
    fieldType: 'text',
    fieldGroupPosition: 'Left',
    addFormOrder: 40,
    editFormOrder: 40,
    viewOrder: 40,
    tableDisplayOrder: 1,
    tableDisplayPriority: 1,
  }, {
    label: 'Last Name',
    fieldName: 'last_name',
    fieldType: 'text',
    fieldGroupPosition: 'Right',
    addFormOrder: 50,
    editFormOrder: 50,
    viewOrder: 50,
    tableDisplayOrder: 2,
    tableDisplayPriority: 1,
  }, {
    label: 'Known As',
    fieldName: 'known_as',
    fieldType: 'text',
    fieldGroupPosition: 'Right',
    addFormOrder: 70,
    editFormOrder: 70,
    viewOrder: 70,
    tableDisplayOrder: 5,
    tableDisplayPriority: 3,
  }, {
    label: 'Date of Birth',
    fieldName: 'dob',
    fieldType: 'date',
    fieldGroupPosition: 'Left',
    addFormOrder: 110,
    editFormOrder: 110,
    viewOrder: 110,
    tableDisplayOrder: 8,
    tableDisplayPriority: 4,
  }, {
    label: 'Gender',
    fieldName: 'gender',
    fieldType: 'select',
    options: [{ id: '1', name: 'Male' }, { id: '2', name: 'Female' }],
    fieldGroupPosition: 'Right',
    addFormOrder: 110,
    editFormOrder: 110,
    viewOrder: 110,
    tableDisplayOrder: 8,
    tableDisplayPriority: 4,
  }
]

export const fieldValidation = (fieldName, value, condition) => {
  let hasError = false,
    fieldErrorMessage = ""
  switch (fieldName) {
    case "first_name":
      if (condition === true) { 
        if (value.trim() === "") {
          hasError = true
          fieldErrorMessage = "First name is required"
        } else if (value.length < 2) {
          hasError = true
          fieldErrorMessage = 'First name is too short'
        } else if (value.length > 50) {
          hasError = true
          fieldErrorMessage = 'First name is too long'
        } else {
          hasError = false
          fieldErrorMessage = ""
        }
        break
      }
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

export const ContactsPersonCreateRequest = 'ContactsPersonCreateRequest'
export const ContactsPersonCreateSuccess = 'ContactsPersonCreateSuccess'
export const ContactsPersonCreateFail = 'ContactsPersonCreateFail'
export const ContactsPersonCreateReset = 'ContactsPersonCreateReset'

export const ContactsPersonDetailsRequest = 'ContactsPersonDetailsRequest'
export const ContactsPersonDetailsSuccess = 'ContactsPersonDetailsSuccess'
export const ContactsPersonDetailsFail = 'ContactsPersonDetailsFail'
export const ContactsPersonDetailsReset = 'ContactsPersonDetailsReset'

export const ContactsPersonUpdateRequest = 'ContactsPersonUpdateRequest'
export const ContactsPersonUpdateSuccess = 'ContactsPersonUpdateSuccess'
export const ContactsPersonUpdateFail = 'ContactsPersonUpdateFail'
export const ContactsPersonUpdateReset = 'ContactsPersonUpdateReset'

export const ContactsPersonDeleteRequest = 'ContactsPersonDeleteRequest'
export const ContactsPersonDeleteSuccess = 'ContactsPersonDeleteSuccess'
export const ContactsPersonDeleteFail = 'ContactsPersonDeleteFail'
export const ContactsPersonDeleteReset = 'ContactsPersonDeleteReset'

export const ContactsPersonListRequest = 'ContactsPersonListRequest'
export const ContactsPersonListSuccess = 'ContactsPersonListSuccess'
export const ContactsPersonListFail = 'ContactsPersonListFail'


const APIVariablesCreate = {
  APIRequest: ContactsPersonCreateRequest,
  APISuccess: ContactsPersonCreateSuccess,
  APIFail: ContactsPersonCreateFail,
  APIEndPoint: 'contacts/v1/person/add',
}
const APIVariablesListAll = {
  APIRequest: ContactsPersonListRequest,
  APISuccess: ContactsPersonListSuccess,
  APIFail: ContactsPersonListFail,
  APIEndPoint: 'contacts/v1/person/list'
}
const APIVariablesSelectOptions = {
  APIRequest: 'ContactsPersonSelectOptionsRequest',
  APISuccess: 'ContactsPersonSelectOptionsSuccess',
  APIFail: 'ContactsPersonSelectOptionsFail',
  APIEndPoint: 'contacts/v1/person/selectoptions'
}
const APIVariablesItemDetails = {
  APIRequest: ContactsPersonDetailsRequest,
  APISuccess: ContactsPersonDetailsSuccess,
  APIFail: ContactsPersonDetailsFail,
  APIEndPoint: 'contacts/v1/person/'
}
const APIVariablesUpdate = {
  APIRequest: ContactsPersonUpdateRequest,
  APISuccess: ContactsPersonUpdateSuccess,
  APISuccess_2: ContactsPersonDetailsSuccess,
  APIFail: ContactsPersonUpdateFail,
  APIEndPoint: 'contacts/v1/person/'
}
const APIVariablesDelete = {
  APIRequest: ContactsPersonDeleteRequest,
  APISuccess: ContactsPersonDeleteSuccess,
  APIFail: ContactsPersonDeleteFail,
  APIEndPoint: 'contacts/v1/person/'
}

const API_Body_Contents =(item) => {
  const cleandob = item.dob === '' ? null : item.dob;

  return ({
    title: item.title,
    first_name: item.first_name,
    middle_name: item.middle_name,
    last_name: item.last_name,
    known_as: item.known_as,
    gender: item.gender,
    dob: cleandob,
    email: item.email,
    phone_no: item.phone_no,
    group: item.group,
})}

export const ContactsPersonCreateAPI = (item) => async (dispatch, getState) => {
  const API_Body = API_Body_Contents(item)
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}
export const ContactsPersonListAllAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState, query)
}
export const ContactsPersonListSelectOptionsAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesSelectOptions, dispatch, getState)
}
export const ContactsPersonDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}
export const ContactsPersonUpdateAPI = (item) => async (dispatch, getState) => {
  const API_Body = API_Body_Contents(item)
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}
export const ContactsPersonDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

export const ContactsPersonCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ContactsPersonCreateRequest:
      return {
        loading: true
      }

    case ContactsPersonCreateSuccess:
      return {
        loading: false,
        success: true,
        ContactsPerson: action.payload
      }

    case ContactsPersonCreateFail:
      return {
        loading: false,
        error: action.payload
      }

    case ContactsPersonCreateReset:
      return {}


    default:
      return state
  }
}

export const ContactsPersonUpdateReducer = (state = { ContactsPerson: {} }, action) => {
  switch (action.type) {
    case ContactsPersonUpdateRequest:
      return { loading: true }

    case ContactsPersonUpdateSuccess:
      return { loading: false, success: true }

    case ContactsPersonUpdateFail:
      return { loading: false, error: action.payload }

    case ContactsPersonUpdateReset:
      return { ContactsPerson: {} }

    default:
      return state
  }
}

export const ContactsPersonDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ContactsPersonDeleteRequest:
      return { loading: true }

    case ContactsPersonDeleteSuccess:
      return {
        loading: false,
        success: true
      }

    case ContactsPersonDeleteFail:
      return { loading: false, error: action.payload }

    case ContactsPersonDeleteReset:
      return {}

    default:
      return state
  }
}

export const ContactsPersonDetailsReducer = (state = { loading: true, ContactsPerson: [] }, action) => {
  switch (action.type) {
    case ContactsPersonDetailsRequest:
      return {
        ...state,
        loading: true
      }

    case ContactsPersonDetailsSuccess:
      return {
        loading: false,
        ContactsPerson: action.payload
      }

    case ContactsPersonDetailsFail:
      return {
        loading: false,
        error: action.payload
      }


    default:
      return state
  }
}


export const ContactsPersonListReducer = (state = { ContactsPerson: [] }, action) => {
  switch (action.type) {
    case ContactsPersonListRequest:
      return {
        loading: true
      }

    case ContactsPersonListSuccess:
      return {
        loading: false,
        ContactsPerson: action.payload
      }

    case ContactsPersonListFail:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const ContactsPersonSelectOptionsReducer = (state = { ContactsPersonSelectOptions: [] }, action) => {
  switch (action.type) {
    case 'ContactsPersonSelectOptionsRequest':
      return {
        loading: true
      }

    case 'ContactsPersonSelectOptionsSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
      }

    case 'ContactsPersonSelectOptionsFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function ContactsPersonListScreen(props) {
  const [dataBaseFilter, setDataBaseFilter] = useState('Active');
  const dataListParams = ('?listtype=' + dataBaseFilter)

  const createAPI = ContactsPersonCreateAPI
  const listAPI = ContactsPersonListAllAPI
  const detailsAPI = ContactsPersonDetailsAPI
  const updateAPI = ContactsPersonUpdateAPI
  const deleteAPI = ContactsPersonDeleteAPI

  const resetCreate = ContactsPersonCreateReset
  const resetUpdate = ContactsPersonUpdateReset
  const resetViewDetails = ContactsPersonDetailsReset
  const resetDelete = ContactsPersonDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const ContactsPersonCreate = useSelector(state => state.ContactsPersonCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = ContactsPersonCreate

  const ContactsPersonList = useSelector(state => state.ContactsPersonList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    ContactsPerson: dataList } = ContactsPersonList

  const ContactsPersonDetails = useSelector(state => state.ContactsPersonDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    ContactsPerson: dataItem } = ContactsPersonDetails

  const ContactsPersonUpdate = useSelector(state => state.ContactsPersonUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = ContactsPersonUpdate

  const ContactsPersonDelete = useSelector(state => state.ContactsPersonDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = ContactsPersonDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingDetails
    )
  )
 
  useEffect(() => {
    dispatch(ContactsPersonListAllAPI(dataListParams))
  }, [dataBaseFilter])

  useEffect(() => {
    if (successUpdate) {
      dispatch(ContactsPersonListAllAPI(dataListParams))
      dispatch({ type: ContactsPersonUpdateReset })
    }
    if (successDelete) {
      // dispatch(ContactsPersonListAllAPI(dataListParams))
      dispatch({ type: ContactsPersonDeleteReset })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }
  }, [successDelete, successUpdate,
    errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete])


  return (
    <ModuleHome
      //     formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
    moduleModalVisibility={props.moduleModalVisibility}
    setModuleModalVisibility={props.setModuleModalVisibility}
      // setFormModalVisibility={props.setFormModalVisibility}

      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataListParams={dataListParams}
      getDataList={listAPI}
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      //Database filtering
      dataBaseFilterOptions={dataBaseFilterOptions}
      dataBaseFilter={dataBaseFilter}
      setDataBaseFilter={setDataBaseFilter}

      rowOnClickAction='edit'
      // add={true}
      edit={true}
      view={false}
      // delete={(id) => dispatch(ContactsPersonDeleteAPI(id))}

      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}
    />
  )
}

export default ContactsPersonListScreen