import React, { useState, useEffect } from "react";
import styles from '../../../style/global.module.css';
import table from '../../../style/table.module.css';
import * as useDateTime from "../../../hooks/useDateTime";

const Time = () => {
  const [clocktime, setClockTime] = useState(new Date()); // human format

  useEffect(() => {
    var timerID = setInterval(() => tick(), 1000);

    return () => clearInterval(timerID);
  });

  function tick() {
    console.log('tick')
    setClockTime(new Date());
  }

  return (
    <>
      <div className={styles.center}>
        <h2 >Clock</h2>
        <p style={{ fontSize: '30px', margin: '10px', textAlign: 'center', margin: 'auto' }}>
          {clocktime.toLocaleString("en-GB", { timeStyle: "long" })}</p>
      </div>
      <br></br>
      <div >
        <h2 className={styles.center}>Date & Time Formats</h2>
        <table className={table.table}>
          <tr>
            <th></th>
            <th>Local</th>
            <th>UTC</th>
          </tr>
          <tr style={{
            backgroundColor: "rgb(91,133,183)",
            color: 'white'
          }}>
            <td>Date & Time</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Local </td>
            <td>{useDateTime.toLocaleString}</td>
            <td>{useDateTime.toUTCString}</td>
          </tr>
          <tr>
            <td>ISO - Raw </td>
            <td>{useDateTime.toString}</td>
            <td>{useDateTime.toISOString}</td>
          </tr>
          <tr>
            <td>ISO - Clean </td>
            <td>{useDateTime.localCleanISODateTime}</td>
            <td>{useDateTime.UTCCleanISODateTime}</td>
          </tr>
          <tr style={{
            backgroundColor: "rgb(91,133,183)",
            color: 'white'
          }}>
            <td>Time</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Time</td>
            <td>{useDateTime.localTime_HH_MM_SS}</td>
            <td>{useDateTime.UTCTime_HH_MM_SS}</td>
          </tr>
          <tr>
            <td>Time - No seconds</td>
            <td>{useDateTime.localTime_HH_MM}</td>
            <td>{useDateTime.UTCTime_HH_MM}</td>
          </tr>
          <tr>
            <td>Hour</td>
            <td>{useDateTime.localHour}</td>
            <td>{useDateTime.UTCHour}</td>
          </tr>
          <tr>
            <td>Minutes</td>
            <td>{useDateTime.localMinute}</td>
            <td>{useDateTime.UTCMinute}</td>
          </tr>
          <tr>
            <td>Seconds</td>
            <td>{useDateTime.localSeconds}</td>
            <td>{useDateTime.UTCSeconds}</td>
          </tr>



          <tr style={{
            backgroundColor: "rgb(91,133,183)",
            color: 'white'
          }}>
            <td
            >Dates</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Full - Normal</td>
            <td>{useDateTime.localDate_DD_MM_YYYY}</td>
            <td>{useDateTime.UTC_DD_MM_YYYY} </td>
          </tr>
          <tr>
            <td>Full - ISO</td>
            <td>{useDateTime.localISODate}</td>
            <td>{useDateTime.UTCISODate}</td>
          </tr>
          <tr>
            <td>Day - Number</td>
            <td>{useDateTime.local_DD}</td>
            <td>{useDateTime.UTC_DD}</td>
          </tr>
          <tr>
            <td>Day - Short</td>
            <td>{useDateTime.local_DDD}</td>
            <td></td>
          </tr>
          <tr>
            <td>Day - Long</td>
            <td>{useDateTime.local_DDDD}</td>
            <td></td>
          </tr>
          <tr>
            <td>Month - Number</td>
            <td>{useDateTime.local_MM}</td>
            <td>{useDateTime.UTC_MM} </td>
          </tr>
          <tr>
            <td>Month - Short</td>
            <td>{useDateTime.local_MMM}</td>
            <td> </td>
          </tr>
          <tr>
            <td>Month - Long</td>
            <td>{useDateTime.local_MMMM}</td>
            <td> </td>
          </tr>
          <tr>
            <td>Year- Short</td>
            <td>{useDateTime.local_YY}</td>
            <td></td>
          </tr>
          <tr>
            <td>Year- Long</td>
            <td>{useDateTime.local_YYYY}</td>
            <td>{useDateTime.UTC_YYYY} </td>
          </tr>
        </table>
      </div >
      <br></br>
      <table className={`${table.table} ${table.contentsCenter} `} >
        <thead>
          <tr>

            <th>
              1 Minute has:
            </th>
            <th>
              1 Hour has:
            </th>
            <th>
              1 Day has:
            </th>
            <td>
            </td>
          </tr>
          <tr>

            <td>
              60
            </td>
            <td>
              3600
            </td>
            <td>
              86400
            </td>
            <td style={{
              backgroundColor: "rgb(91,133,183)",
              color: 'white'
            }}>
              Seconds
            </td>
          </tr>

          <tr>

            <td>

            </td>
            <td>
              60
            </td>
            <td>
              1440
            </td>
            <td style={{
              backgroundColor: "rgb(91,133,183)",
              color: 'white'
            }}>
              Minutes
            </td>
          </tr>


          <tr>

            <td>

            </td>
            <td>

            </td>
            <td>
              24
            </td>
            <td style={{
              backgroundColor: "rgb(91,133,183)",
              color: 'white'
            }}>
              Hours
            </td>
          </tr>
        </thead>
      </table>

    </>
  )
}

export default Time;
