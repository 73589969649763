//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../components/containers/moduleHome'
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../APIs/genericAPIs'

export const moduleDefinitions = {
  moduleName: 'Groups',
  moduleNameSingular: 'Group',
  moduleLink: '/contacts/groups/',
}

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
    fieldErrorMessage = ""

  switch (fieldName) {

    case "name":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Name is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break


    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

///////////// API Config /////////////////////////

const APIVariablesCreate = {
  APIRequest: 'ContactsGroupCreateRequest',
  APISuccess: 'ContactsGroupCreateSuccess',
  APIFail: 'ContactsGroupCreateFail',
  APIEndPoint: 'contacts/v1/group/add',
}
const APIVariablesListAll = {
  APIRequest: 'ContactsGroupListRequest',
  APISuccess: 'ContactsGroupListSuccess',
  APIFail: 'ContactsGroupListFail',
  APIEndPoint: 'contacts/v1/group/list'
}
const APIVariablesSelectOptions = {
  APIRequest: 'ContactsGroupSelectOptionsRequest',
  APISuccess: 'ContactsGroupSelectOptionsSuccess',
  APIFail: 'ContactsGroupSelectOptionsFail',
  APIEndPoint: 'contacts/v1/group/selectoptions'
}
const APIVariablesItemDetails = {
  APIRequest: 'ContactsGroupViewDetailsRequest',
  APISuccess: 'ContactsGroupViewDetailsSuccess',
  APIFail: 'ContactsGroupViewDetailsFail',
  APIEndPoint: 'contacts/v1/group/'
}
const APIVariablesUpdate = {
  APIRequest: 'ContactsGroupUpdateRequest',
  APISuccess: 'ContactsGroupUpdateSuccess',
  APISuccess2: 'ContactsGroupViewDetailsSuccess',
  APIFail: 'ContactsGroupUpdateFail',
  APIEndPoint: 'contacts/v1/group/'
}
const APIVariablesDelete = {
  APIRequest: 'ContactsGroupDeleteRequest',
  APISuccess: 'ContactsGroupDeleteSuccess',
  APIFail: 'ContactsGroupDeleteFail',
  APIEndPoint: 'contacts/v1/group/'
}

const API_Body_Contents = (item) => {
  return ({
    name: item.name,
    email_address: item.email_address,
    description: item.description,
})}

export const ContactsGroupCreateAPI = (item) => async (dispatch, getState) => {
  const API_Body = API_Body_Contents(item)
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const ContactsGroupListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const ContactsGroupSelectOptionsAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesSelectOptions, dispatch, getState)
}

export const ContactsGroupDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const ContactsGroupUpdateAPI = (item) => async (dispatch, getState) => {
  const API_Body = API_Body_Contents(item)
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const ContactsGroupDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

///////////// REDUCERS/////////////////////////
// State is an empty object
export const ContactsGroupCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case 'ContactsGroupCreateRequest':
      return {
        loading: true
      }

    case 'ContactsGroupCreateSuccess':
      return {
        loading: false,
        success: true,
        ContactsGroup: action.payload
      }

    case 'ContactsGroupCreateFail':
      return {
        loading: false,
        error: action.payload
      }

    case 'ContactsGroupCreateReset':
      return {}


    default:
      return state
  }
}

export const ContactsGroupUpdateReducer = (state = { ContactsGroup: {} }, action) => {
  switch (action.type) {
    case 'ContactsGroupUpdateRequest':
      return { loading: true }

    case 'ContactsGroupUpdateSuccess':
      return { loading: false, success: true }

    case 'ContactsGroupUpdateFail':
      return { loading: false, error: action.payload }

    case 'ContactsGroupUpdateReset':
      return { ContactsGroup: {} }

    default:
      return state
  }
}

export const ContactsGroupDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case 'ContactsGroupDeleteRequest':
      return { loading: true }

    case 'ContactsGroupDeleteSuccess':
      return {
        loading: false,
        success: true
      }

    case 'ContactsGroupDeleteFail':
      return { loading: false, error: action.payload }

    case 'ContactsGroupDeleteReset':
      return {}

    default:
      return state
  }
}

export const ContactsGroupDetailsReducer = (state = { loading: true, ContactsGroup: [] }, action) => {
  switch (action.type) {
    case 'ContactsGroupViewDetailsRequest':
      return {
        ...state,
        loading: true
      }

    case 'ContactsGroupViewDetailsSuccess':
      return {
        loading: false,
        ContactsGroup: action.payload
      }

    case 'ContactsGroupViewDetailsFail':
      return {
        loading: false,
        error: action.payload
      }


    default:
      return state
  }
}


export const ContactsGroupListReducer = (state = { ContactsGroup: [] }, action) => {
  switch (action.type) {
    case 'ContactsGroupListRequest':
      return {
        loading: true
      }

    case 'ContactsGroupListSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
        ContactsGroup: action.payload
      }

    case 'ContactsGroupListFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}
           
export const ContactsGroupSelectOptionsReducer = (state = { ContactsGroup: [] }, action) => {
  switch (action.type) {
    case 'ContactsGroupSelectOptionsRequest':
      return {
        loading: true
      }

    case 'ContactsGroupSelectOptionsSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
      }

    case 'ContactsGroupSelectOptionsFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function ContactsGroup(props) {
  const createAPI = ContactsGroupCreateAPI
  const listAPI = ContactsGroupListAllAPI
  const detailsAPI = ContactsGroupDetailsAPI
  const updateAPI = ContactsGroupUpdateAPI
  const deleteAPI = ContactsGroupDeleteAPI

  const resetCreate = 'ContactsGroupCreate_Reset'
  const resetUpdate = 'ContactsGroupUpdate_Reset'
  const resetViewDetails = 'ContactsGroup_ViewDetails_Reset'
  const resetDelete = 'ContactsGroupDelete_Reset'

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()
  const paramsItemSplit = paramsItem.action.split(':')
  const paramsID = paramsItemSplit[1]

  const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const ContactsGroupCreate = useSelector(state => state.ContactsGroupCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = ContactsGroupCreate

  const ContactsGroupList = useSelector(state => state.ContactsGroupList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    ContactsGroup: dataList } = ContactsGroupList

  const ContactsGroupDetails = useSelector(state => state.ContactsGroupDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    ContactsGroup: dataItem } = ContactsGroupDetails

  const ContactsGroupUpdate = useSelector(state => state.ContactsGroupUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = ContactsGroupUpdate

  const ContactsGroupDelete = useSelector(state => state.ContactsGroupDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = ContactsGroupDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingList || loadingDetails
    )
  )

   const moduleFields = [
    {
      label: 'Name',
      mandatory: true,
      fieldName: 'name',
      fieldType: 'text',
      addFormOrder: 1,
      editFormOrder: 1,
      tableDisplayOrder: 1,
      tableDisplayPriority: 1,
    }, {
      label: 'Email Address',
      fieldName: 'email_address',
      fieldType: 'email',
      addFormOrder: 2,
      editFormOrder: 2,
      tableDisplayOrder: 2,
      tableDisplayPriority: 2,
    }, {
      label: 'Description',
      fieldName: 'description',
      fieldType: 'textarea',
      addFormOrder: 3,
      editFormOrder: 3,
      tableDisplayOrder: 0,
      tableDisplayPriority:0,
    },
  ]
  
  useEffect(() => {
    dispatch(ContactsGroupListAllAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }

  }, [successDelete, successUpdate,
    errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete,paramsID])


  return (
    <ModuleHome
    formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
    moduleModalVisibility={props.moduleModalVisibility}
    setModuleModalVisibility={props.setModuleModalVisibility}
    
    paramsItem={paramsItem}
    moduleDefinitions={moduleDefinitions}
    moduleFields={moduleFields}

    //Table Data
    getDataList={listAPI}
    dataList={dataList}
    loadingList={loadingList}
    errorLoadingList={errorLoadingList}

    // rowOnClickAction={'view'} 
    view={(dataitem) => navigate(moduleDefinitions.moduleLink + 'view:' + dataitem.id)}
    add={true}
    edit={(dataitem) => navigate(moduleDefinitions.moduleLink + 'edit:' + dataitem.id)}
    delete={(id) => dispatch(deleteAPI(id))}

    //AddEditForm
    fieldValidation={fieldValidation}

    loading={loading}
    serverErrorMessage={serverErrorMessage}
    setServerErrorMessage={setServerErrorMessage}

    itemDetails={detailsAPI}
    dataItem={dataItem}
    itemDetailsReset={resetViewDetails}

    itemCreate={createAPI}
    successCreate={successCreate}
    resetCreate={resetCreate}

    itemUpdate={updateAPI}
    successUpdate={successUpdate}
    resetUpdate={resetUpdate}

    itemDelete={deleteAPI}
    successDelete={successDelete}
    resetDelete={resetDelete}

    //View Page
    // viewTabs={viewTabs} 
    // headingName={dataItem.name}
 
      /> 
  )
}

export default ContactsGroup
