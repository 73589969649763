//Source: https://dev.to/abdulbasit313/how-to-develop-a-stopwatch-in-react-js-with-custom-hook-561b
//  Updated based on : https://www.geeksforgeeks.org/create-a-stop-watch-using-reactjs/

import { useState, useEffect } from 'react';
import styles from '../../../style/form.module.css';

const App = () => {
  const [isActive, setIsActive] = useState(false)
  const [isPaused, setIsPaused] = useState(true)
  const [timer, setTimer] = useState(0)
  // const countRef = useRef(null)

  useEffect(() => {
    let interval = null;

    if (isActive && isPaused === false) {
        interval = setInterval(() => {
            setTimer((timer) => timer + 10);
        }, 10);
    } else {
        clearInterval(interval);
    }
    return () => {
        clearInterval(interval);
    };
}, [isActive, isPaused]);

  const handleStart = () => {
    setIsActive(true)
    setIsPaused(false)
    // countRef.current = setInterval(() => {
    //   setTimer((timer) => timer + 1)
    // }, 1000)
  }

  const handlePause = () => {
    // clearInterval(countRef.current)
    setIsPaused(true)
  }

  const handleResume = () => {
    setIsPaused(false)
    // countRef.current = setInterval(() => {
    //   setTimer((timer) => timer + 1)
    // }, 1000)
  }

  const handleReset = () => {
    // clearInterval(countRef.current)
    setIsActive(false)
    setIsPaused(true)
    setTimer(0)
  }

  const getHours = `0${Math.floor((timer / 6000000) % 60)}`.slice(-2)

  // const minutes = `${}`
  const getMinutes = `0${Math.floor(timer / 60000) % 60}`.slice(-2)
  const getSeconds = `0${Math.floor(timer / 1000) % 60}`.slice(-2)
  const getSplitSeconds = `0${((timer / 10) % 100)}`.slice(-2)


  const formatTime = () => {
    // math.floor = rounds down to an integer
    // 

    // const getHours = `0${Math.floor(timer / 3600)}`.slice(-2)

    // // const minutes = `${}`
    // const getMinutes = `0${Math.floor(timer / 60) % 60}`.slice(-2)
    // const getSeconds = `0${(timer % 60)}`.slice(-2)
    // const getSplitSeconds = `0${(timer % 3600)}`.slice(-2)

    
    // const getHours = `0${Math.floor((timer / 6000) % 60)}`.slice(-2)

    // // const minutes = `${}`
    // const getMinutes = `0${Math.floor(timer / 6000) % 60}`.slice(-2)
    // const getSeconds = `0${Math.floor(timer / 1000) % 60}`.slice(-2)
    // const getSplitSeconds = `0${((timer / 10) % 100)}`.slice(-2)



    return `${getHours} : ${getMinutes} : ${getSeconds} : ${getSplitSeconds}`
  }

  return (
    <div className={styles.center}>
      <h2>Stopwatch</h2>
      <div className='stopwatch-card' >
        <table style={{fontSize: '30px', margin: '10px',textAlign: 'center',margin: 'auto' }} className={styles.center} >
          <thead >
            <tr>
              <th>HH :</th>
              <th>MM :</th>
              <th>SS :</th>
              <th>MS</th>
            </tr>
          </thead>
          <tbody>
            <tr > 
              <td>{getHours} :</td>
              <td>{getMinutes} :</td>
              <td>{getSeconds} :</td>
              <td>{getSplitSeconds}</td>
            </tr>
          </tbody>
        </table>
        {/* <p>{formatTime()}</p>  */}
        <div>
        {isActive &&  <button className={styles.orange} onClick={handleReset} disabled={!isActive}>Reset</button>}
       
        {
            !isActive && isPaused ?
              <button className={styles.green} onClick={handleStart}>Start</button>
              : (
                !isPaused ? 
                <button 
                className={styles.lightblue} 
                onClick={handlePause}>
                  Pause
                  </button> :
                  <button 
                  className={styles.green} 
                  onClick={handleResume}>
                    Resume
                    </button>
              )
          }
        </div>
      </div>
    </div>
  );
}

export default App;
