//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'

export const moduleDefinitions = {
  moduleName: 'Checklists',
  moduleNameSingular: 'Checklist',
  moduleLink: '/inventory/checklist/list/',
  linktoView: '/inventory/checklist/items/',
}

export const moduleFields = [
  {
    label: 'Checklist Name', 
    mandatory: 'true', 
    fieldName: 'name',
    fieldType: 'text',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '',
  }
]

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
      fieldErrorMessage = ""

  switch (fieldName) {

    case "name":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Name is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break
      
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}


  ///////////// Constants /////////////////////////
export const InventoryChecklistListCreateRequest = 'InventoryChecklistListCreateRequest'
export const InventoryChecklistListCreateSuccess = 'InventoryChecklistListCreateSuccess'
export const InventoryChecklistListCreateFail = 'InventoryChecklistListCreateFail'
export const InventoryChecklistListCreateReset = 'InventoryChecklistListCreateReset'

export const InventoryChecklistListDetailsRequest = 'InventoryChecklistListDetailsRequest'
export const InventoryChecklistListDetailsSuccess = 'InventoryChecklistListDetailsSuccess'
export const InventoryChecklistListDetailsFail = 'InventoryChecklistListDetailsFail'
export const InventoryChecklistListDetailsReset = 'InventoryChecklistListDetailsReset'

export const InventoryChecklistListUpdateRequest = 'InventoryChecklistListUpdateRequest'
export const InventoryChecklistListUpdateSuccess = 'InventoryChecklistListUpdateSuccess'
export const InventoryChecklistListUpdateFail = 'InventoryChecklistListUpdateFail'
export const InventoryChecklistListUpdateReset = 'InventoryChecklistListUpdateReset'

export const InventoryChecklistListDeleteRequest = 'InventoryChecklistListDeleteRequest'
export const InventoryChecklistListDeleteSuccess = 'InventoryChecklistListDeleteSuccess'
export const InventoryChecklistListDeleteFail = 'InventoryChecklistListDeleteFail'
export const InventoryChecklistListDeleteReset = 'InventoryChecklistListDeleteReset'

export const InventoryChecklistListListRequest = 'InventoryChecklistListListRequest'
export const InventoryChecklistListListSuccess = 'InventoryChecklistListListSuccess'
export const InventoryChecklistListListFail = 'InventoryChecklistListListFail'


  ///////////// API Config /////////////////////////

const APIVariablesCreate = {
  APIRequest: InventoryChecklistListCreateRequest,
  APISuccess: InventoryChecklistListCreateSuccess,
  APIFail: InventoryChecklistListCreateFail,
  APIEndPoint: 'inventory/v1/checklist/list/add',
}
const APIVariablesListAll = {
  APIRequest: InventoryChecklistListListRequest,
  APISuccess: InventoryChecklistListListSuccess,
  APIFail: InventoryChecklistListListFail,
  APIEndPoint: 'inventory/v1/checklist/list/list'
}
const APIVariablesItemDetails = {
  APIRequest: InventoryChecklistListDetailsRequest,
  APISuccess: InventoryChecklistListDetailsSuccess,
  APIFail: InventoryChecklistListDetailsFail,
  APIEndPoint: 'inventory/v1/checklist/list/'
}
const APIVariablesUpdate = {
  APIRequest: InventoryChecklistListUpdateRequest,
  APISuccess: InventoryChecklistListUpdateSuccess,
  APISuccess_2: InventoryChecklistListDetailsSuccess,
  APIFail: InventoryChecklistListUpdateFail,
  APIEndPoint: 'inventory/v1/checklist/list/'
}
const APIVariablesDelete = {
  APIRequest: InventoryChecklistListDeleteRequest,
  APISuccess: InventoryChecklistListDeleteSuccess,
  APIFail: InventoryChecklistListDeleteFail,
  APIEndPoint: 'inventory/v1/checklist/list/'
}

export const InventoryChecklistCreateAPI = (item) => async (dispatch, getState) => {

  const API_Body = {
    name: item.name,
  }
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const InventoryChecklistListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const InventoryChecklistDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const InventoryChecklistUpdateAPI = (item) => async (dispatch, getState) => {

  const API_Body = {
    name: item.name,
  }
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const InventoryChecklistDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}
  
  ///////////// REDUCERS/////////////////////////
  // State is an empty object
  export const InventoryChecklistListCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case InventoryChecklistListCreateRequest:
            return {
                loading: true
            }
  
        case InventoryChecklistListCreateSuccess:
            return {
                loading: false,
                success: true,
                InventoryChecklistList: action.payload
            }
  
        case InventoryChecklistListCreateFail:
            return {
                loading: false,
                error: action.payload
            }
  
        case InventoryChecklistListCreateReset:
            return {}
  
  
        default:
            return state
    }
  }
  
  export const InventoryChecklistListUpdateReducer = (state = { InventoryChecklistList: {} }, action) => {
    switch (action.type) {
        case InventoryChecklistListUpdateRequest:
            return { loading: true }
  
        case InventoryChecklistListUpdateSuccess:
            return { loading: false, success: true }
  
        case InventoryChecklistListUpdateFail:
            return { loading: false, error: action.payload }
  
        case InventoryChecklistListUpdateReset:
            return { InventoryChecklistList: {} }
  
        default:
            return state
    }
  }
  
  export const InventoryChecklistListDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case InventoryChecklistListDeleteRequest:
            return { loading: true }
  
        case InventoryChecklistListDeleteSuccess:
            return { 
              loading: false, 
              success: true }
  
        case InventoryChecklistListDeleteFail:
            return { loading: false, error: action.payload }
  
        case InventoryChecklistListDeleteReset:
              return { }
  
        default:
            return state
    }
  }
  
  export const InventoryChecklistListDetailsReducer = (state = { loading: true, InventoryChecklistList: [] }, action) => {
    switch (action.type) {
        case InventoryChecklistListDetailsRequest:
            return {
                ...state,
                loading: true
            }
  
        case InventoryChecklistListDetailsSuccess:
            return {
                loading: false,
                InventoryChecklistList: action.payload
            }
  
        case InventoryChecklistListDetailsFail:
            return {
                loading: false,
                error: action.payload
            }
  
  
        default:
            return state
    }
  }
   
  
  export const InventoryChecklistListListReducer = (state = { InventoryChecklistList: [] }, action) => {
    switch (action.type) {
        case InventoryChecklistListListRequest:
            return {
                loading: true
            }
  
        case InventoryChecklistListListSuccess:
            return {
                loading: false,
                InventoryChecklistList: action.payload
            }
  
        case InventoryChecklistListListFail:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
  }

function InventoryChecklistListScreen() {
  const createAPI = InventoryChecklistCreateAPI
  const listAPI = InventoryChecklistListAllAPI
  const detailsAPI = InventoryChecklistDetailsAPI
  const updateAPI = InventoryChecklistUpdateAPI
  const deleteAPI = InventoryChecklistDeleteAPI

  const resetCreate = InventoryChecklistListCreateReset
  const resetUpdate = InventoryChecklistListUpdateReset
  const resetViewDetails = InventoryChecklistListDetailsReset
  const resetDelete = InventoryChecklistListDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const InventoryChecklistListCreate = useSelector(state => state.InventoryChecklistListCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = InventoryChecklistListCreate

  const InventoryChecklistListList = useSelector(state => state.InventoryChecklistListList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    InventoryChecklistList: dataList } = InventoryChecklistListList

  const InventoryChecklistListDetails = useSelector(state => state.InventoryChecklistListDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    InventoryChecklistList: dataItem } = InventoryChecklistListDetails

  const InventoryChecklistListUpdate = useSelector(state => state.InventoryChecklistListUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = InventoryChecklistListUpdate

  const InventoryChecklistListDelete = useSelector(state => state.InventoryChecklistListDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = InventoryChecklistListDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingList || loadingDetails
    )
  )

  useEffect(() => {
    dispatch(InventoryChecklistListAllAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
     if (errorLoadingList) {setServerErrorMessage(errorLoadingList)}
    if (errorCreate) {setServerErrorMessage(errorCreate)}
    if (errorUpdate) {setServerErrorMessage(errorUpdate)}
    if (errorGetDetails) {setServerErrorMessage(errorGetDetails)}
    if (errorDelete) {setServerErrorMessage(errorDelete)}
  }, [successDelete, successUpdate,
    errorLoadingList,errorCreate,errorUpdate,errorGetDetails, errorDelete])


  return (
    <ModuleHome
    formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
    paramsItem={paramsItem}
    moduleDefinitions={moduleDefinitions}
    moduleFields={moduleFields}

    //Table Data
    getDataList={listAPI}
    dataList={dataList}
    loadingList={loadingList}
    errorLoadingList={errorLoadingList}

    rowOnClickAction={'view'}
    add={true}
    edit={true}
    view={(dataitem) => navigate(moduleDefinitions.linktoView + 'list:' + dataitem.id)}
    // edit={(dataitem) => navigate(moduleDefinitions.moduleLink + 'edit:' + dataitem.id)}
    delete={(id) => dispatch(deleteAPI(id))}

    //AddEditForm
    fieldValidation={fieldValidation}

    loading={loading}
    serverErrorMessage={serverErrorMessage}
    setServerErrorMessage={setServerErrorMessage}

    itemDetails={detailsAPI}
    dataItem={dataItem}
    itemDetailsReset={resetViewDetails}

    itemCreate={createAPI}
    successCreate={successCreate}
    resetCreate={resetCreate}

    itemUpdate={updateAPI}
    successUpdate={successUpdate}
    resetUpdate={resetUpdate}

    itemDelete={deleteAPI}
    successDelete={successDelete}
    resetDelete={resetDelete}
/>
  )
}

export default InventoryChecklistListScreen