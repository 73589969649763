// Source: not exact but similar https://upmostly.com/tutorials/modal-components-react-custom-hooks

import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

import '../../style/modal.css';

const Modal = ({ show, hide, handle_logout }) => show ? ReactDOM.createPortal(
  <React.Fragment>
    <div className="modalOverlay" onClick={hide} />
    <div className="modalWrapper" aria-modal aria-hidden tabIndex={-1} role="User Modal" >
      <div className="userNavModalContent">
        <div className="userNavModalHeader">
          <span className="modalClose" id="user-modalClose" onClick={hide}>&times;</span>
        </div>
        <div className="userNavModalLinks">
          <Link to="/" onClick={() => { handle_logout(); hide(); }}> Sign Out </Link>
          <Link to="/user/profile" onClick={hide}> Profile </Link>
          <Link to="/user/settings" onClick={hide}> Settings </Link>
        </div>
      </div>
    </div>
  </React.Fragment>, document.body
) : null;

export default Modal;