//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { MdAdd } from 'react-icons/md';
// Components
import ModuleHome from '../../../components/containers/moduleHome'
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'

export const moduleDefinitions = {
  moduleName: 'Roles',
  moduleNameSingular: 'Role',
  moduleLink: '/fun/sailinglog/settings/roles/',
  thOptionsWidth: '115px',
}

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
    fieldErrorMessage = ""

  switch (fieldName) {

    case "name":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Name is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break

      case "depature_from":
        if (value.trim() === "") {
          hasError = true
          fieldErrorMessage = "Depature from is required"
        } else {
          hasError = false
          fieldErrorMessage = ""
        }
        break

    default:
      break
  }
  return { hasError, fieldErrorMessage }
}


///////////// Constants /////////////////////////
export const SailingLogRoleCreateRequest = 'SailingLogRoleCreateRequest'
export const SailingLogRoleCreateSuccess = 'SailingLogRoleCreateSuccess'
export const SailingLogRoleCreateFail = 'SailingLogRoleCreateFail'
export const SailingLogRoleCreateReset = 'SailingLogRoleCreateReset'

export const SailingLogRoleDetailsRequest = 'SailingLogRoleDetailsRequest'
export const SailingLogRoleDetailsSuccess = 'SailingLogRoleDetailsSuccess'
export const SailingLogRoleDetailsFail = 'SailingLogRoleDetailsFail'
export const SailingLogRoleDetailsReset = 'SailingLogRoleDetailsReset'

export const SailingLogRoleUpdateRequest = 'SailingLogRoleUpdateRequest'
export const SailingLogRoleUpdateSuccess = 'SailingLogRoleUpdateSuccess'
export const SailingLogRoleUpdateFail = 'SailingLogRoleUpdateFail'
export const SailingLogRoleUpdateReset = 'SailingLogRoleUpdateReset'

export const SailingLogRoleDeleteRequest = 'SailingLogRoleDeleteRequest'
export const SailingLogRoleDeleteSuccess = 'SailingLogRoleDeleteSuccess'
export const SailingLogRoleDeleteFail = 'SailingLogRoleDeleteFail'
export const SailingLogRoleDeleteReset = 'SailingLogRoleDeleteReset'

export const SailingLogRoleListRequest = 'SailingLogRoleListRequest'
export const SailingLogRoleListSuccess = 'SailingLogRoleListSuccess'
export const SailingLogRoleListFail = 'SailingLogRoleListFail'


///////////// API Config /////////////////////////

const APIVariablesCreate = {
  APIRequest: SailingLogRoleCreateRequest,
  APISuccess: SailingLogRoleCreateSuccess,
  APIFail: SailingLogRoleCreateFail,
  APIEndPoint: 'sailinglog/v1/role/add',
}
const APIVariablesListAll = {
  APIRequest: SailingLogRoleListRequest,
  APISuccess: SailingLogRoleListSuccess,
  APIFail: SailingLogRoleListFail,
  APIEndPoint: 'sailinglog/v1/role/list'
}
const APIVariablesSelectOptions = {
  APIRequest: 'SailingLogRoleSelectOptionsRequest',
  APISuccess: 'SailingLogRoleSelectOptionsSuccess',
  APIFail: 'SailingLogRoleSelectOptionsFail',
  APIEndPoint: 'sailinglog/v1/role/selectoptions'
}
const APIVariablesItemDetails = {
  APIRequest: SailingLogRoleDetailsRequest,
  APISuccess: SailingLogRoleDetailsSuccess,
  APIFail: SailingLogRoleDetailsFail,
  APIEndPoint: 'sailinglog/v1/role/'
}
const APIVariablesUpdate = {
  APIRequest: SailingLogRoleUpdateRequest,
  APISuccess: SailingLogRoleUpdateSuccess,
  APISuccess_2: SailingLogRoleDetailsSuccess,
  APIFail: SailingLogRoleUpdateFail,
  APIEndPoint: 'sailinglog/v1/role/'
}
const APIVariablesDelete = {
  APIRequest: SailingLogRoleDeleteRequest,
  APISuccess: SailingLogRoleDeleteSuccess,
  APIFail: SailingLogRoleDeleteFail,
  APIEndPoint: 'sailinglog/v1/role/'
}

export const SailingLogRoleCreateAPI = (item) => async (dispatch, getState) => {
  const API_Body = {
    name: item.name,
    notes: item.notes,
  }
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const SailingLogRoleListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const SailingLogRoleSelectOptionsAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesSelectOptions, dispatch, getState )
}

export const SailingLogRoleDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const SailingLogRoleUpdateAPI = (item) => async (dispatch, getState) => {
  const API_Body = {
    name: item.name,
    notes: item.notes,
  }
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const SailingLogRoleDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

///////////// REDUCERS/////////////////////////
// State is an empty object
export const SailingLogRoleCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SailingLogRoleCreateRequest:
      return {
        loading: true
      }

    case SailingLogRoleCreateSuccess:
      return {
        loading: false,
        success: true,
        SailingLogRole: action.payload
      }

    case SailingLogRoleCreateFail:
      return {
        loading: false,
        error: action.payload
      }

    case SailingLogRoleCreateReset:
      return {}


    default:
      return state
  }
}

export const SailingLogRoleUpdateReducer = (state = { SailingLogRole: {} }, action) => {
  switch (action.type) {
    case SailingLogRoleUpdateRequest:
      return { loading: true }

    case SailingLogRoleUpdateSuccess:
      return { loading: false, success: true }

    case SailingLogRoleUpdateFail:
      return { loading: false, error: action.payload }

    case SailingLogRoleUpdateReset:
      return { SailingLogRole: {} }

    default:
      return state
  }
}

export const SailingLogRoleDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SailingLogRoleDeleteRequest:
      return { loading: true }

    case SailingLogRoleDeleteSuccess:
      return {
        loading: false,
        success: true
      }

    case SailingLogRoleDeleteFail:
      return { loading: false, error: action.payload }

    case SailingLogRoleDeleteReset:
      return {}

    default:
      return state
  }
}

export const SailingLogRoleDetailsReducer = (state = { loading: true, SailingLogRole: [] }, action) => {
  switch (action.type) {
    case SailingLogRoleDetailsRequest:
      return {
        ...state,
        loading: true
      }

    case SailingLogRoleDetailsSuccess:
      return {
        loading: false,
        SailingLogRole: action.payload
      }

    case SailingLogRoleDetailsFail:
      return {
        loading: false,
        error: action.payload
      }


    default:
      return state
  }
}


export const SailingLogRoleListReducer = (state = { SailingLogRole: [] }, action) => {
  switch (action.type) {
    case SailingLogRoleListRequest:
      return {
        loading: true
      }

    case SailingLogRoleListSuccess:
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
        SailingLogRole: action.payload
      }

    case SailingLogRoleListFail:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const SailingLogRoleSelectOptionsReducer = (state = { fieldOptionValues: [] }, action) => {
  switch (action.type) {
    case 'SailingLogRoleSelectOptionsRequest':
      return {
        loading: true
      }

    case 'SailingLogRoleSelectOptionsSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
      }

    case 'SailingLogRoleSelectOptionsFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}


function SailingLogRoleScreen(props) {
  const createAPI = SailingLogRoleCreateAPI
  const listAPI = SailingLogRoleListAllAPI
  const detailsAPI = SailingLogRoleDetailsAPI
  const updateAPI = SailingLogRoleUpdateAPI
  const deleteAPI = SailingLogRoleDeleteAPI

  const resetCreate = SailingLogRoleCreateReset
  const resetUpdate = SailingLogRoleUpdateReset
  const resetViewDetails = SailingLogRoleDetailsReset
  const resetDelete = SailingLogRoleDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const SailingLogRoleCreate = useSelector(state => state.SailingLogRoleCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = SailingLogRoleCreate

  const SailingLogRoleList = useSelector(state => state.SailingLogRoleList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    SailingLogRole: dataList } = SailingLogRoleList

  const SailingLogRoleDetails = useSelector(state => state.SailingLogRoleDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    SailingLogRole: dataItem } = SailingLogRoleDetails

  const SailingLogRoleUpdate = useSelector(state => state.SailingLogRoleUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = SailingLogRoleUpdate

  const SailingLogRoleDelete = useSelector(state => state.SailingLogRoleDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = SailingLogRoleDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingList || loadingDetails
    )
  )

   const moduleFields = [
    {
      label: 'Role Name',
      mandatory: 'true',
      fieldName: 'name',
      fieldType: 'text',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '1', 
    }, {
      label: 'Notes',
      fieldName: 'notes',
      fieldType: 'textarea',
      rows:"4",
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
    },
  ]
  
  useEffect(() => {
    dispatch(SailingLogRoleListAllAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }
  }, [successDelete, successUpdate,
    errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete])


  return (
    <ModuleHome
        formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
    moduleModalVisibility={props.moduleModalVisibility}
    setModuleModalVisibility={props.setModuleModalVisibility}

    paramsItem={paramsItem}
    moduleDefinitions={moduleDefinitions}
    moduleFields={moduleFields}

    //Table Data
    dataList={dataList}
    loadingList={loadingList}
    errorLoadingList={errorLoadingList}

    rowOnClickAction={'edit'}
    add={true}
    edit={true}
    view={false}
    delete={(id) => dispatch(deleteAPI(id))}

    //AddEditForm
    fieldValidation={fieldValidation}

    loading={loading}
    serverErrorMessage={serverErrorMessage}
    setServerErrorMessage={setServerErrorMessage}

    getDataList={listAPI}

    itemDetails={detailsAPI}
    dataItem={dataItem}
    itemDetailsReset={resetViewDetails}

    itemCreate={createAPI}
    successCreate={successCreate}
    resetCreate={resetCreate}

    itemUpdate={updateAPI}
    successUpdate={successUpdate}
    resetUpdate={resetUpdate}

    itemDelete={deleteAPI}
    successDelete={successDelete}
    resetDelete={resetDelete}

    //View Page
    // viewTabs={viewTabs} 
    // headingName={dataItem.name}
 
      /> 
  )
}

export default SailingLogRoleScreen
