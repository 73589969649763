//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../components/containers/moduleHome'
// import 
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../APIs/genericAPIs'
import { listAllSupportTicketApplicationOptions } from './reference/applicationOptions'
import { listAllSupportTicketBrowserOptions} from './reference/browserOptions'
import { listAllSupportTicketDeviceType } from './reference/deviceType'
import { listAllSupportTicketMobileType } from './reference/mobileType'
import { listAllSupportTicketModuleOptions } from './reference/moduleOptions'
import { listAllSupportTicketPriorityOptions } from './reference/priorityOptions'
import { listAllSupportTicketStatusOptions } from './reference/statusOptions'
import { listAllSupportTicketTicketType } from './reference/ticketType'
import { ContactsContactListSelectOptionsAPI } from '../contacts/contact'

export const moduleDefinitions = {
  moduleName: 'Support - Tickets',
  moduleNameSingular: 'Ticket',
  moduleLink: '/support/tickets/',
  moduleDescription: (<p> Before raising a ticket, please search exisiting tickets.</p>),
}

const dataBaseFilterOptions = [
  {
    id: 1,
    name: 'Open',
    default: true,
  }, {
    id: 2,
    name: 'Closed',
  }, {
    id: 3,
    name: 'All',
  },
]

export const moduleFields = [
  {
    label: 'Short Summary',
    mandatory: 'true',
    fieldName: 'summary',
    fieldType: 'text',
    addFormOrder: 3,
    editFormOrder: 3,
    tableDisplayOrder: 1,
    tableDisplayPriority: 1,
  }, {
    label: 'Ticket Type',
    mandatory: 'true',
    fieldName: 'ticket_type',
    fieldLongName: 'ticket_type_name',
    fieldType: 'selectfromdb',
    fieldGroupPosition: 'Left',
    options: 'SupportTicketTicketTypeList',
    addFormOrder: 1,
    editFormOrder: 2,
    tableDisplayOrder: 2,
    tableDisplayPriority: 2,
    // tableDefaultFilter: [1,2], 
  }, {
    label: 'Priority',
    fieldName: 'priority',
    fieldLongName: 'priority_name',
    fieldType: 'selectfromdb',
    fieldGroupPosition: 'Right',
    options: 'SupportTicketPriorityOptionsList',
    addFormOrder: 2,
    addFormInitialValue: '3',
    addFormConditionalRenderBasedOn: 'ticket_type',
    addFormConditionalRenderValues: [1], //Bug
    editFormOrder: 2,
    tableDisplayOrder: 4,
    tableDisplayPriority: 4,
  }, {
    label: 'Status',
    fieldName: 'status',
    fieldLongName: 'status_name',
    fieldType: 'selectfromdb',
    fieldGroupPosition: 'Left',
    options: 'SupportTicketStatusOptionsList',
    addFormOrder: 0,
    addFormInitialValue: 1,
    editFormOrder: 1.1,
    tableDisplayOrder: 2,
    tableDisplayPriority: 2,
    // tableDefaultFilter: [1,2], //Open, WIP
  }, {
    label: 'Device Type',
    fieldName: 'device_type',
    fieldLongName: 'device_type_name',
    fieldType: 'selectfromdb',
    fieldGroupPosition: 'Left',
    options: 'SupportTicketDeviceTypeList',
    addFormOrder: 4,
    addFormConditionalRenderBasedOn: 'ticket_type',
    addFormConditionalRenderValues: [1], //Bug
    editFormOrder: 4,
    editFormConditionalRenderBasedOn: 'ticket_type',
    editFormConditionalRenderValues: [1], //Bug
    tableDisplayOrder: 3,
    tableDisplayPriority: 3,
  }, {
    label: 'Web Browser',
    fieldName: 'browser',
    fieldLongName: 'browser_name',
    fieldType: 'selectfromdb',
    fieldGroupPosition: 'Right',
    options: 'SupportTicketBrowserOptionsList',
    addFormOrder: 4,
    addFormConditionalRenderBasedOn: 'device_type',
    addFormConditionalRenderValues: [2,3], //Mobile Browser , Desktop Browser
    editFormOrder: 4,
    editFormConditionalRenderBasedOn: 'device_type',
    editFormConditionalRenderValues: [2,3], //Mobile Browser , Desktop 
    tableDisplayOrder: 3,
    tableDisplayPriority: 3,
  }, {
    label: 'Mobile Type',
    fieldName: 'mobile',
    fieldLongName: 'mobile_name',
    fieldType: 'selectfromdb',
    fieldGroupPosition: 'Right',
    options: 'SupportTicketMobileTypeList',
    addFormOrder: 4,
    addFormConditionalRenderBasedOn: 'device_type',
    addFormConditionalRenderValues: [1], //Mobile App
    editFormOrder: 4,
    editFormConditionalRenderBasedOn: 'device_type',
    editFormConditionalRenderValues: [1], //Mobile App
    tableDisplayOrder: 3,
    tableDisplayPriority: 3,
  }, {
    label: 'URL',
    fieldName: 'url',
    fieldType: 'text',
    addFormOrder: 5,
    addFormConditionalRenderBasedOn: 'device_type',
    addFormConditionalRenderValues: [2,3], //Mobile Browser , Desktop Browser
    editFormOrder: 5,
    editFormConditionalRenderBasedOn: 'device_type',
    editFormConditionalRenderValues: [2,3], //Mobile Browser , Desktop 
    tableDisplayOrder: 6,
    tableDisplayPriority: 5,
  }, {
    label: 'Application',
    fieldName: 'application',
    fieldLongName: 'application_name',
    fieldType: 'selectfromdb',
    fieldGroupPosition: 'Left',
    options: 'SupportTicketApplicationOptionsList',
    addFormOrder: 5,
    editFormOrder: 5,
    tableDisplayOrder: 6,
    tableDisplayPriority: 5,
  }, {
    label: 'Module',
    fieldName: 'module',
    fieldLongName: 'module_name',
    fieldType: 'selectfromdb',
    fieldGroupPosition: 'Right',
    options: 'SupportTicketModuleOptionsList',
    addFormConditionalSelectBasedOn: 'application',
    addFormConditionalSelectLinkField: 'app',
    addFormOrder: 5,
    editFormOrder: 5,
    tableDisplayOrder: 6,
    tableDisplayPriority: 5,
  }, {
    label: 'Description',
    fieldName: 'description',
    fieldType: 'textarea',
    rows: "4",
    addFormOrder: 8,
    editFormOrder: 8,
  }, {
    label: 'Resolution',
    fieldName: 'resolution',
    fieldType: 'textarea',
    rows: "4",
    addFormOrder: 0,
    editFormOrder: 10,
  }, {
    label: 'Resolved By',
    fieldName: 'resolved_by',
    fieldLongName: 'resolved_by_name',
    fieldType: 'selectfromdb',
    options: 'ContactsContactSelectOptions',
    fieldGroupPosition: 'Left',
    addFormOrder: 0,
    editFormOrder: 11,
  }, {
    label: 'Resolved Date',
    fieldName: 'resolved_date',
    fieldType: 'datetime-local',
    fieldGroupPosition: 'Right',
    addFormOrder: 0,
    editFormOrder: 12,
    // editFormInitialValue: new Date(),

  },
]

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
    fieldErrorMessage = ""

  switch (fieldName) {
    case "summary":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "A short summary is required"
      } else if (value.length < 10) {
        hasError = true
        fieldErrorMessage = 'Short summary is too short'
      } else if (value.length > 50) {
        hasError = true
        fieldErrorMessage = 'Short summary is too long'
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break

    case "ticket_type":
      if (value === "") {
        hasError = true
        fieldErrorMessage = "Ticket type is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

///////////// Constants /////////////////////////
export const TicketCreateRequest = 'TicketCreateRequest'
export const TicketCreateSuccess = 'TicketCreateSuccess'
export const TicketCreateFail = 'TicketCreateFail'
export const TicketCreateReset = 'TicketCreateReset'

export const TicketDetailsRequest = 'TicketDetailsRequest'
export const TicketDetailsSuccess = 'TicketDetailsSuccess'
export const TicketDetailsFail = 'TicketDetailsFail'
export const TicketDetailsReset = 'TicketDetailsReset'

export const TicketUpdateRequest = 'TicketUpdateRequest'
export const TicketUpdateSuccess = 'TicketUpdateSuccess'
export const TicketUpdateFail = 'TicketUpdateFail'
export const TicketUpdateReset = 'TicketUpdateReset'

export const TicketDeleteRequest = 'TicketDeleteRequest'
export const TicketDeleteSuccess = 'TicketDeleteSuccess'
export const TicketDeleteFail = 'TicketDeleteFail'
export const TicketDeleteReset = 'TicketDeleteReset'

export const TicketList_MYRequest = 'TicketList_MYRequest'
export const TicketList_MYSuccess = 'TicketList_MYSuccess'
export const TicketList_MYFail = 'TicketList_MYFail'
export const TicketList_MYReset = 'TicketList_MYReset'

export const TicketListRequest = 'TicketListRequest'
export const TicketListSuccess = 'TicketListSuccess'
export const TicketListFail = 'TicketListFail'


///////////// API Config /////////////////////////


const APIVariablesCreate = {
  APIRequest: TicketCreateRequest,
  APISuccess: TicketCreateSuccess,
  APIFail: TicketCreateFail,
  APIEndPoint: 'support/v1/ticket/add',
}
const APIVariablesListAll = {
  APIRequest: TicketListRequest,
  APISuccess: TicketListSuccess,
  APIFail: TicketListFail,
  APIEndPoint: 'support/v1/ticket/list'
}
const APIVariablesItemDetails = {
  APIRequest: TicketDetailsRequest,
  APISuccess: TicketDetailsSuccess,
  APIFail: TicketDetailsFail,
  APIEndPoint: 'support/v1/ticket/'
}
const APIVariablesUpdate = {
  APIRequest: TicketUpdateRequest,
  APISuccess: TicketUpdateSuccess,
  APISuccess_2: TicketDetailsSuccess,
  APIFail: TicketUpdateFail,
  APIEndPoint: 'support/v1/ticket/'
}
const APIVariablesDelete = {
  APIRequest: TicketDeleteRequest,
  APISuccess: TicketDeleteSuccess,
  APIFail: TicketDeleteFail,
  APIEndPoint: 'support/v1/ticket/'
}

export const TicketCreateAPI = (item) => async (dispatch, getState) => {


  const API_Body = {
    ticket_type: item.ticket_type,
    summary: item.summary,
    browser: item.browser,
    mobile: item.mobile,
    description: item.description,
    device_type: item.device_type,
    status: item.status,
    priority: item.priority,
    application: item.application,
    module: item.module,
    url: item.url,
    reported_by: item.reported_by,
  }
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const TicketListAllAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState, query)
}

export const TicketDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const TicketUpdateAPI = (item) => async (dispatch, getState) => {
  const cleandate = item.resolved_date === '' ? null : item.resolved_date;

  const API_Body = {
    ticket_type: item.ticket_type,
    summary: item.summary,
    browser: item.browser,
    mobile: item.mobile,
    description: item.description,
    device_type: item.device_type,
    status: item.status,
    priority: item.priority,
    application: item.application,
    module: item.module,
    url: item.url,
    reported_by: item.reported_by,
    assigned_to: item.assigned_to,

    resolution: item.resolution,
    resolved_by: item.resolved_by,
    resolved_date: cleandate,
  }

  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const TicketCompleteAPI = (item) => async (dispatch, getState) => {

  const API_Body = {
    ticket_name: item.ticket_name,
    status: 3
  }

  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const TicketDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

///////////// REDUCERS/////////////////////////
// State is an empty object
export const SupportTicketCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TicketCreateRequest:
      return {
        loading: true
      }

    case TicketCreateSuccess:
      return {
        loading: false,
        success: true,
        ticket: action.payload
      }

    case TicketCreateFail:
      return {
        loading: false,
        error: action.payload
      }

    case TicketCreateReset:
      return {}


    default:
      return state
  }
}

export const SupportTicketUpdateReducer = (state = { ticket: {} }, action) => {
  switch (action.type) {
    case TicketUpdateRequest:
      return { loading: true }

    case TicketUpdateSuccess:
      return { loading: false, success: true }

    case TicketUpdateFail:
      return { loading: false, error: action.payload }

    case TicketUpdateReset:
      return { ticket: {} }

    default:
      return state
  }
}

export const SupportTicketDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TicketDeleteRequest:
      return { loading: true }

    case TicketDeleteSuccess:
      return {
        loading: false,
        success: true
      }

    case TicketDeleteFail:
      return { loading: false, error: action.payload }

    case TicketDeleteReset:
      return {}

    default:
      return state
  }
}

export const SupportTicketDetailsReducer = (state = { loading: true, ticket: [] }, action) => {
  switch (action.type) {
    case TicketDetailsRequest:
      return {
        ...state,
        loading: true
      }

    case TicketDetailsSuccess:
      return {
        loading: false,
        success: true,
        ticket: action.payload
      }

    case TicketDetailsFail:
      return {
        loading: false,
        error: action.payload,
        ticket: 'error'
      }

    case TicketDetailsReset:
      return { ticket: {} }

    default:
      return state
  }
}

export const SupportTicketListReducer = (state = { tickets: [] }, action) => {
  switch (action.type) {
    case TicketListRequest:
      return {
        loading: true
      }

    case TicketListSuccess:
      return {
        loading: false,
        success: true,
        tickets: action.payload
      }

    case TicketListFail:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}


function TicketListScreen() {
  const [dataBaseFilter, setDataBaseFilter] = useState('Open');
  const dataListParams = ('?listtype=' + dataBaseFilter )
  // console.log('dataListParams',dataListParams)

  const createAPI = TicketCreateAPI
  const listAPI = TicketListAllAPI
  const detailsAPI = TicketDetailsAPI
  const updateAPI = TicketUpdateAPI
  const deleteAPI = TicketDeleteAPI
  const completeAPI = TicketCompleteAPI

  const resetCreate = TicketCreateReset
  const resetUpdate = TicketUpdateReset
  const resetViewDetails = TicketDetailsReset
  const resetDelete = TicketDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const SupportTicketCreate = useSelector(state => state.SupportTicketCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = SupportTicketCreate

  const SupportTicketList = useSelector(state => state.SupportTicketList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    tickets: dataList } = SupportTicketList

  const SupportTicketDetails = useSelector(state => state.SupportTicketDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    ticket: dataItem } = SupportTicketDetails

  const SupportTicketUpdate = useSelector(state => state.SupportTicketUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = SupportTicketUpdate

  const SupportTicketDelete = useSelector(state => state.SupportTicketDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = SupportTicketDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate : loadingUpdate || loadingDelete || loadingDetails
    )
  )


  const user_Login = useSelector(state => state.user_Login)
  const { userInfo } = user_Login   

  const customFormAction =() => {
    const updateValues= {
      status: 3 , 
      resolved_by: userInfo.user_id , 
      resolved_date: new Date().toISOString() }

    console.log('updateValues',updateValues) 
    return updateValues

  }

  useEffect(() => {
    dispatch(listAPI(dataListParams))
    dispatch(listAllSupportTicketApplicationOptions())
    dispatch(listAllSupportTicketBrowserOptions())
    dispatch(listAllSupportTicketDeviceType()) 
    dispatch(listAllSupportTicketMobileType()) 
    dispatch(listAllSupportTicketModuleOptions()) 
    dispatch(listAllSupportTicketPriorityOptions())
    dispatch(listAllSupportTicketStatusOptions()) 
    dispatch(listAllSupportTicketTicketType()) 
    dispatch(ContactsContactListSelectOptionsAPI()) 
  }, [])

  useEffect(() => {
    dispatch(listAPI(dataListParams))
  }, [dataBaseFilter])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI(dataListParams))
      dispatch({ type: TicketUpdateReset })
    }
    if (successDelete) {
      dispatch(listAPI(dataListParams))
      dispatch({ type: TicketDeleteReset })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }
  }, [successDelete, successUpdate,
    errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete])


  return (
    <ModuleHome
    formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataListParams={dataListParams}
      getDataList={listAPI}
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      //Database filtering
      dataBaseFilterOptions={dataBaseFilterOptions}
      dataBaseFilter={dataBaseFilter}
      setDataBaseFilter={setDataBaseFilter}

      //Table Actions
      rowOnClickAction='edit'
      add={true}
      // view={(dataitem) => navigate(moduleDefinitions.moduleLink + 'view:' + dataitem.id)}
      edit={(dataitem) => navigate(moduleDefinitions.moduleLink + 'edit:' + dataitem.id)}
      delete={(id) => dispatch(deleteAPI(id))}
      // save={(data)=> handleSave(data)}
      complete={(dataitem) => dispatch(completeAPI(dataitem))}

      inLineAdd={false}
      inLineEdit={false}

      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}

      customFormAction={customFormAction}

      //View Page
      // viewTabs={viewTabs} 
      // headingName={dataItem.name}

    />
  )
}

export default TicketListScreen