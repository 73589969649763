//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'

export const moduleDefinitions = {
  moduleName: 'EventAttendeess',
  moduleNameSingular: 'EventAttendees',
  moduleLink: '/event/',
  thOptionsWidth: '90px',
}

const dataBaseFilterOptions = [
  {
    id: 1,
    name: 'Current',
    default: true,
  }, {
    id: 2,
    name: 'Past',
  }, {
    id: 3,
    name: 'All',
  },
]

export const moduleFields = [
  {
    label: 'EventAttendees Name',
    mandatory: true,
    fieldName: 'name',
    fieldType: 'text',
    addFormOrder: 10,
    editFormOrder: 10,
    tableDisplayOrder: 10,
    tableDisplayPriority: 1,
  }, {
    label: 'EventAttendees Type',
    mandatory: true,
    fieldName: 'event_type',
    fieldLongName: 'event_type_name',
    fieldType: 'selectfromdb',
    options: 'EventAttendeesTypeSelectOptions',
    fieldGroupPosition: 'Left',
    addFormOrder: 20,
    addFormInitialValue: 1, //Meeting
    editFormOrder: 20,
    tableDisplayOrder: 20,
    tableDisplayPriority: 1,
  }, {
    label: 'Visibility',
    mandatory: true,
    fieldName: 'visibility',
    fieldLongName: 'visibility_name',
    fieldType: 'selectfromdb',
    options: 'EventAttendeesVisibilitySelectOptions',
    fieldGroupPosition: 'Right',
    addFormOrder: 21,
    addFormInitialValue: 1, //Private
    editFormOrder: 21,
    tableDisplayOrder: 21,
    tableDisplayPriority: 1,
  }, {
    label: 'Start Date',
    fieldName: 'start_date',
    mandatory: true,
    fieldType: 'date',
    fieldGroupPosition: 'Left',
    addFormOrder: 30,
    editFormOrder: 30,
    tableDisplayOrder: 30,
    tableDisplayPriority: 1,
  }, {
    label: 'Start Time',
    fieldName: 'start_time',
    fieldType: 'time',
    fieldGroupPosition: 'Right',
    addFormOrder: 40,
    editFormOrder: 40,
    tableDisplayOrder: 40,
    tableDisplayPriority: 2,
  }, {
    label: 'End Date',
    fieldName: 'end_date',
    fieldType: 'date',
    fieldGroupPosition: 'Left',
    addFormOrder: 50,
    editFormOrder: 50,
    tableDisplayOrder: 50,
    tableDisplayPriority: 4,
  }, {
    label: 'End Time',
    fieldName: 'end_time',
    fieldType: 'time',
    fieldGroupPosition: 'Right',
    addFormOrder: 60,
    editFormOrder: 60,
    tableDisplayOrder: 60,
    tableDisplayPriority: 4,
  }, {
    label: 'Location',
    fieldName: 'location',
    fieldLongName: 'location_name',
    fieldType: 'autoCompleteText',
    options: 'AddressList',
    openAddNewModule: 'shared/address',
    addFormOrder: 70,
    editFormOrder: 70,
    tableDisplayOrder: 70,
    tableDisplayPriority: 3,
  }, {
    label: 'Status',
    fieldName: 'status',
    fieldLongName: 'status_name',
    fieldType: 'selectfromdb',
    options: 'EventAttendeesStatusList',
    addFormOrder: 71,
    addFormInitialValue: 1, //Confirmed
    editFormOrder: 71,
    tableDisplayOrder: 71,
    tableDisplayPriority: 5,
  }, {
    label: 'Attendees',
    fieldName: 'attendees',
    fieldType: 'autoCompleteText',
    options: 'PersonSelectOptions',
    openAddNewModule: 'contacts/contact',
    addFormOrder: 80,
    // addFormInitialValue: 1, //Confirmed
    editFormOrder: 80,
    tableDisplayOrder: 80,
    tableDisplayPriority: 0,
    multipleSelect: true,
    relatedFields: [
      {
        fieldName: 'pk',
        fieldType: 'number',
        fieldDisplay: 'ref',
        addFormOrder: 0,
        editFormOrder: 0,
      },
      {
        fieldName: 'person_id',
        fieldType: 'text',
        fieldDisplay: 'primary',
        addFormOrder: 10,
        editFormOrder: 10,
        primaryField: true
      },
      {
        fieldName: 'date_invited',
        fieldType: 'datetime',
        fieldDisplay: 'indicatorFlag',
        addFormOrder: 0,
        editFormOrder: 0,
      }
    ]
  }, {
    label: 'Email All Attendees',
    fieldName: 'email_all',
    fieldType: 'toggle',
    addFormOrder: 90,
    // addFormInitialValue: 1, //Confirmed
    editFormOrder: 90,
    tableDisplayOrder: 0,
    tableDisplayPriority: 0,
  }, {
    label: 'Notes',
    fieldName: 'notes',
    fieldType: 'textarea',
    rows: "4",
    addFormOrder: 100,
    editFormOrder: 100,
    tableDisplayOrder: 0,
    tableDisplayPriority: 0,
  },
]


export const fieldValidation = (fieldName, value) => {
  let hasError = false,
    fieldErrorMessage = ""
  switch (fieldName) {
    case "name":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "EventAttendees name is required"
      } else if (value.length < 2) {
        hasError = true
        fieldErrorMessage = 'EventAttendees name is too short'
      } else if (value.length > 50) {
        hasError = true
        fieldErrorMessage = 'EventAttendees name is too long'
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break
    case "start_date":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Start date is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

export const EventAttendeesCreateRequest = 'EventAttendeesCreateRequest'
export const EventAttendeesCreateSuccess = 'EventAttendeesCreateSuccess'
export const EventAttendeesCreateFail = 'EventAttendeesCreateFail'
export const EventAttendeesCreateReset = 'EventAttendeesCreateReset'

export const EventAttendeesDetailsRequest = 'EventAttendeesDetailsRequest'
export const EventAttendeesDetailsSuccess = 'EventAttendeesDetailsSuccess'
export const EventAttendeesDetailsFail = 'EventAttendeesDetailsFail'
export const EventAttendeesDetailsReset = 'EventAttendeesDetailsReset'

export const EventAttendeesUpdateRequest = 'EventAttendeesUpdateRequest'
export const EventAttendeesUpdateSuccess = 'EventAttendeesUpdateSuccess'
export const EventAttendeesUpdateFail = 'EventAttendeesUpdateFail'
export const EventAttendeesUpdateReset = 'EventAttendeesUpdateReset'

export const EventAttendeesDeleteRequest = 'EventAttendeesDeleteRequest'
export const EventAttendeesDeleteSuccess = 'EventAttendeesDeleteSuccess'
export const EventAttendeesDeleteFail = 'EventAttendeesDeleteFail'
export const EventAttendeesDeleteReset = 'EventAttendeesDeleteReset'

export const EventAttendeesListRequest = 'EventAttendeesListRequest'
export const EventAttendeesListSuccess = 'EventAttendeesListSuccess'
export const EventAttendeesListFail = 'EventAttendeesListFail'

const APIVariablesCreate = {
  APIRequest: EventAttendeesCreateRequest,
  APISuccess: EventAttendeesCreateSuccess,
  APIFail: EventAttendeesCreateFail,
  APIEndPoint: 'events/v1/event/add',
}
const APIVariablesListAll = {
  APIRequest: EventAttendeesListRequest,
  APISuccess: EventAttendeesListSuccess,
  APIFail: EventAttendeesListFail,
  APIEndPoint: 'events/v1/event/list'
}
const APIVariablesItemDetails = {
  APIRequest: EventAttendeesDetailsRequest,
  APISuccess: EventAttendeesDetailsSuccess,
  APIFail: EventAttendeesDetailsFail,
  APIEndPoint: 'events/v1/event/'
}
const APIVariablesUpdate = {
  APIRequest: EventAttendeesUpdateRequest,
  APISuccess: EventAttendeesUpdateSuccess,
  APISuccess_2: EventAttendeesDetailsSuccess,
  APIFail: EventAttendeesUpdateFail,
  APIEndPoint: 'events/v1/event/'
}
const APIVariablesDelete = {
  APIRequest: EventAttendeesDeleteRequest,
  APISuccess: EventAttendeesDeleteSuccess,
  APIFail: EventAttendeesDeleteFail,
  APIEndPoint: 'events/v1/event/'
}

const APIBodyContentAdd = (item) => {
  const cleanStartDate = item.start_date === '' ? null : item.start_date;
  const cleanStartTime = item.start_time === '' ? null : item.start_time;
  const cleanEndDate = item.end_date === '' ? null : item.end_date;
  const cleanEndTime = item.end_time === '' ? null : item.end_time;

  return ({
    name: item.name,
    event_type: item.event_type,
    visibility: item.visibility,
    start_date: cleanStartDate,
    start_time: cleanStartTime,
    end_date: cleanEndDate,
    end_time: cleanEndTime,
    location: item.location,
    status: item.status,
    notes: item.notes,
    attendees: item.attendees,
    email_all: item.email_all,
  })
}

const APIBodyContentEdit = (item) => {
  const cleanStartDate = item.start_date === '' ? null : item.start_date;
  const cleanStartTime = item.start_time === '' ? null : item.start_time;
  const cleanEndDate = item.end_date === '' ? null : item.end_date;
  const cleanEndTime = item.end_time === '' ? null : item.end_time;

  return ({
    name: item.name,
    event_type: item.event_type,
    visibility: item.visibility,
    start_date: cleanStartDate,
    start_time: cleanStartTime,
    end_date: cleanEndDate,
    end_time: cleanEndTime,
    location: item.location,
    status: item.status,
    notes: item.notes,
    attendees: item.attendees,
    email_all: item.email_all,
  })
}

export const EventAttendeesCreateAPI = (item) => async (dispatch, getState) => {
  const API_Body = APIBodyContentAdd(item)
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}
export const EventAttendeessListAllAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState, query)
}
export const EventAttendeessDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}
export const EventAttendeessUpdateAPI = (item) => async (dispatch, getState) => {
  const API_Body = APIBodyContentEdit(item)
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}
export const EventAttendeessDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

// State is an empty object
export const EventAttendeesCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case EventAttendeesCreateRequest:
      return {
        loading: true
      }

    case EventAttendeesCreateSuccess:
      return {
        loading: false,
        success: true,
        EventAttendees: action.payload
      }

    case EventAttendeesCreateFail:
      return {
        loading: false,
        error: action.payload
      }

    case EventAttendeesCreateReset:
      return {}


    default:
      return state
  }
}

export const EventAttendeesUpdateReducer = (state = { EventAttendees: {} }, action) => {
  switch (action.type) {
    case EventAttendeesUpdateRequest:
      return { loading: true }

    case EventAttendeesUpdateSuccess:
      return { loading: false, success: true }

    case EventAttendeesUpdateFail:
      return { loading: false, error: action.payload }

    case EventAttendeesUpdateReset:
      return { EventAttendees: {} }

    default:
      return state
  }
}

export const EventAttendeesDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EventAttendeesDeleteRequest:
      return { loading: true }

    case EventAttendeesDeleteSuccess:
      return {
        loading: false,
        success: true
      }

    case EventAttendeesDeleteFail:
      return { loading: false, error: action.payload }

    case EventAttendeesDeleteReset:
      return {}

    default:
      return state
  }
}

export const EventAttendeesDetailsReducer = (state = { loading: true, EventAttendees: [] }, action) => {
  switch (action.type) {
    case EventAttendeesDetailsRequest:
      return {
        ...state,
        loading: true
      }

    case EventAttendeesDetailsSuccess:
      return {
        loading: false,
        EventAttendees: action.payload
      }

    case EventAttendeesDetailsFail:
      return {
        loading: false,
        error: action.payload
      }


    default:
      return state
  }
}


export const EventAttendeesListReducer = (state = { EventAttendeess: [] }, action) => {
  switch (action.type) {
    case EventAttendeesListRequest:
      return {
        loading: true
      }

    case EventAttendeesListSuccess:
      return {
        loading: false,
        success: true,
        EventAttendeess: action.payload
      }

    case EventAttendeesListFail:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function EventAttendeesListScreen() {
  const createAPI = EventAttendeesCreateAPI
  const listAPI = EventAttendeessListAllAPI
  const detailsAPI = EventAttendeessDetailsAPI
  const updateAPI = EventAttendeessUpdateAPI
  const deleteAPI = EventAttendeessDeleteAPI

  const resetCreate = EventAttendeesCreateReset
  const resetUpdate = EventAttendeesUpdateReset
  const resetViewDetails = EventAttendeesDetailsReset
  const resetDelete = EventAttendeesDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')
  const [dataBaseFilter, setDataBaseFilter] = useState("Current");
  // console.log('dataBaseFilter', dataBaseFilter)

  const EventAttendeesCreate = useSelector(state => state.EventAttendeesCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = EventAttendeesCreate

  const EventAttendeesList = useSelector(state => state.EventAttendeesList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    EventAttendeess: dataList } = EventAttendeesList

  const EventAttendeesDetails = useSelector(state => state.EventAttendeesDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    EventAttendees: dataItem } = EventAttendeesDetails

  const EventAttendeesUpdate = useSelector(state => state.EventAttendeesUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = EventAttendeesUpdate

  const EventAttendeesDelete = useSelector(state => state.EventAttendeesDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = EventAttendeesDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingDetails
    )
  )

  useEffect(() => {
    dispatch(EventAttendeessListAllAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(EventAttendeessListAllAPI())
      dispatch({ type: EventAttendeesUpdateReset })
    }
    if (successDelete) {
      // dispatch(EventAttendeessListAllAPI())
      dispatch({ type: EventAttendeesDeleteReset })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }
  }, [successDelete, successUpdate,
    errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete])

  return (
    <ModuleHome
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      //Database filtering
      dataBaseFilter={dataBaseFilter}
      setDataBaseFilter={setDataBaseFilter}
      dataBaseFilterOptions={dataBaseFilterOptions}

      //Table Actions
      rowOnClickAction='edit'
      add={true}
      edit={true}
      view={false}
      delete={(id) => dispatch(EventAttendeessDeleteAPI(id))}

      //Add Edit Form
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      getDataList={listAPI}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}

    />

  )
}

export default EventAttendeesListScreen