import tablestyle from '../../../style/table.module.css';

const FilterText = (props) => {

  return (
    <div className={props.setClassFor === 'tableControl' && `${tablestyle.filterModalFields} ${tablestyle.filterModalText}`}>
    <input
      type='text'
      placeholder='Type to filter...'
      value={props.filterValue(props.fieldName)}
      onChange={e => props.handleSetFilterValues(props.fieldName, props.fieldType, e.target.value)}
    >
    </input>
    </div>
  )
}

export default FilterText