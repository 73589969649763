const handleFilterDate = (filter, dbValue) => {
  if (dbValue != null){
  console.log('filter', filter)
  console.log('filter.filterValue', filter.filterValue)

  // console.log('filterLogic', filter.filterLogic)
  // console.log('filterLogic2', filter.filterLogic2)
  const filterYear = Number(filter.filterValue.split('-')[0])
  const filterMonth = Number(filter.filterValue.split('-')[1])
  const filterDay = Number(filter.filterValue.split('-')[2])
  const filterYear2 = filter.filterValue2 ? Number(filter.filterValue2.split('-')[0]) : '00'
  const filterMonth2 = filter.filterValue2 ? Number(filter.filterValue2.split('-')[1]) : '00'
  const filterDay2 = filter.filterValue2 ? Number(filter.filterValue2.split('-')[2]) : '00'

  console.log('dbValue',dbValue)
  const dbYear = Number(dbValue.split('-')[0])
  const dbMonth = Number(dbValue.split('-')[1])
  const dbDay = Number(dbValue.split('-')[2])

  const minfilterMonth = filterMonth == '00' ? '01' : filterMonth
  const minfilterDay = filterDay == '00' ? '01' : filterDay
  const mindate = filterYear + '-' + minfilterMonth + '-' + minfilterDay
  const filtervalueinJSFormat = new Date(mindate)
  // console.log('mindate', mindate)
  // console.log('filtervalueinJSFormat', filtervalueinJSFormat)
  // console.log('filterYear2', filterYear2)
  // console.log('filterMonth2', filterMonth2)
  // console.log('filterDay2', filterDay2)
  // console.log('smarter days', new Date(filterYear2, filterMonth2 + 1, 0).getDate())
  const maxfilterMonth = filterMonth2 == '00' ? '12' : filterMonth2
  const maxfilterDay =
    (filterDay2 == '00' || filterDay2 == 0) ?
      (filterMonth2 == 1 || filterMonth2 == 3 || filterMonth2 == 5 ||
        filterMonth2 == 7 || filterMonth2 == 8 || filterMonth2 == 10 || filterMonth2 == 12) ? '31' :
        filterMonth2 == 2 ? '28' :
          (filterMonth2 == 4 || filterMonth2 == 6 || filterMonth2 == 9 || filterMonth2 == 11) && '30'

      : filterDay2
  // console.log('maxfilterDay', maxfilterDay)
  const maxdate = filterYear2 + '-' + maxfilterMonth + '-' + maxfilterDay
  // const maxdateplus1 = maxdate.setDate(maxdate.getDate() + 1)
  const filtervalueinJSFormat2 = new Date(maxdate)
  // console.log('maxdate', maxdate)
  // console.log('filtervalueinJSFormat2', filtervalueinJSFormat2)

  // console.log('filtervalueinJSFormat2plus1',filtervalueinJSFormat2plus1)
  const dbValueinJSFormat = new Date(dbValue)
  // console.log('dbValueinJSFormat',dbValueinJSFormat)

  // console.log('filterDay',filterDay)
  // console.log('filterYear',filterYear)
  // console.log('filterMonth2', filterMonth2)
  // console.log('dbYear',dbYear)
  // console.log('dbMonth',dbMonth)
  // console.log('dbDay',dbDay)
  if (filter.filterLogic === 'eqaulTo' || filter.filterLogic === undefined) {
    if (filterYear > 1) {
      if (filterMonth > 1) {
        if (filterDay > 1) {
          // console.log('Year,Month,Day')  
          return dbYear == filterYear && dbMonth == filterMonth && dbDay == filterDay
        } else {
          // console.log('Year,Month')  
          return dbYear == filterYear && dbMonth == filterMonth
        }
      } else {
        // console.log('Year',dbYear, filterYear) 
        return dbYear == filterYear
      }
    } else {
      console.log('error - failed all logic', dbYear, filterYear)
    }
  }
  if (filter.filterLogic === 'notEqualTo' || filter.filterLogic === undefined) {
    if (filterYear > 1) {
      if (filterMonth > 1) {
        if (filterDay > 1) {
          return dbYear != filterYear && dbMonth != filterMonth && dbDay != filterDay
        } else {
          return dbYear != filterYear && dbMonth != filterMonth
        }
      } else {
        return dbYear != filterYear
      }
    } else {
      console.log('error - failed all logic', dbYear, filterYear)
    }
  }
  // if (filter.filterLogic === 'equalToGreaterThan') {
  //   return dbValueinJSFormat >= filtervalueinJSFormat
  // }
  // if (filter.filterLogic === 'greaterThan') {
  //   return dbValueinJSFormat > filtervalueinJSFormat
  // }
  if (filter.filterLogic === 'lessThan') {
    return dbValueinJSFormat < filtervalueinJSFormat
  }
  if (filter.filterLogic === 'equalToLessThan') {
    return dbValueinJSFormat <= filtervalueinJSFormat
  }
  if (filter.filterLogic === 'greaterThan') {
    if (filter.filterLogic2 === 'lessThan') {
      return dbValueinJSFormat > filtervalueinJSFormat && dbValueinJSFormat < filtervalueinJSFormat2
    } else if (filter.filterLogic2 === 'equalToLessThan') {
      return dbValueinJSFormat > filtervalueinJSFormat && dbValueinJSFormat <= filtervalueinJSFormat2
    } else {
      return dbValueinJSFormat > filtervalueinJSFormat
    }
  }
  if (filter.filterLogic === 'equalToGreaterThan') {
    if (filter.filterLogic2 === 'equalToLessThan') {
      console.log('1')
      return dbValueinJSFormat >= filtervalueinJSFormat && dbValueinJSFormat <= filtervalueinJSFormat2
    } else if (filter.filterLogic2 === 'lessThan') {
      console.log('2')
      return dbValueinJSFormat >= filtervalueinJSFormat && dbValueinJSFormat < filtervalueinJSFormat2
    } else {
      console.log('3')
      return dbValueinJSFormat >= filtervalueinJSFormat
    }
  }
  }
}
export default handleFilterDate