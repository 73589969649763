//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams,useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'
import { InventoryChecklistListAllAPI } from './lists'
import { InventoryChecklistCriteriaListAllAPI } from './criteria'
import { InventoryItemListAllAPI} from '../items'

export const moduleDefinitions = {
  moduleName: 'Checklist:',
  moduleNameSingular: 'Checklist Item',
  moduleLink: '/inventory/checklist/item/',
  // allowCopy: true,
}

export const moduleFields = [
  {
    label: 'Checklist Name', 
    mandatory: 'true', 
    fieldName: 'checklist_name',
    fieldType: 'text',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
    tableFilterHide: true,
  },  {
    label: 'Item', 
    mandatory: 'true', 
    fieldName: 'item',
    fieldLongName: 'item_name',
    fieldType: 'autoCompleteText',
    options: 'InventoryItemList',
    openAddNewModule: 'inventory/items',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '1',
    tableDisplayPriority: '1',
    addNewLink:'/inventory/item/add/',
    tableRowAddField: true,
    tableRowEditField: true,
  },  {
    label: 'Item Category', 
    fieldName: 'item_category',
    fieldType: 'selectnoid',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '2',
    tableDisplayPriority: '2',
  },  {
    label: 'Criteria', 
    fieldName: 'criteria',
    fieldLongName: 'criteria_name',
    fieldType: 'selectfromdb',
    options: 'InventoryChecklistCriteriaList',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '2',
    tableDisplayPriority: '1',
    tableRowAddField: true,
    tableRowEditField: true,
  },  {
    label: 'Checkout', 
    fieldName: 'checkedout_status',
    fieldType: 'checkbox',
    addFormOrder: 0,
    editFormOrder: '',
    tableDisplayOrder: '3',
    tableDisplayPriority: '1',
    tableRowFieldClickable: true,
    tableRowFieldClickAction: '123',
    tableRowAddField: false,
    tableRowEditField: false,
  }
]

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
      fieldErrorMessage = ""

  switch (fieldName) {

    case "name":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Name is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break

    case "item":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Item is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break
      
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}


  ///////////// Constants /////////////////////////
export const InventoryChecklistItemCreateRequest = 'InventoryChecklistItemCreateRequest'
export const InventoryChecklistItemCreateSuccess = 'InventoryChecklistItemCreateSuccess'
export const InventoryChecklistItemCreateFail = 'InventoryChecklistItemCreateFail'
export const InventoryChecklistItemCreateReset = 'InventoryChecklistItemCreateReset'

export const InventoryChecklistItemDetailsRequest = 'InventoryChecklistItemDetailsRequest'
export const InventoryChecklistItemDetailsSuccess = 'InventoryChecklistItemDetailsSuccess'
export const InventoryChecklistItemDetailsFail = 'InventoryChecklistItemDetailsFail'
export const InventoryChecklistItemDetailsReset = 'InventoryChecklistItemDetailsReset'

export const InventoryChecklistItemUpdateRequest = 'InventoryChecklistItemUpdateRequest'
export const InventoryChecklistItemUpdateSuccess = 'InventoryChecklistItemUpdateSuccess'
export const InventoryChecklistItemUpdateFail = 'InventoryChecklistItemUpdateFail'
export const InventoryChecklistItemUpdateReset = 'InventoryChecklistItemUpdateReset'

export const InventoryChecklistItemDeleteRequest = 'InventoryChecklistItemDeleteRequest'
export const InventoryChecklistItemDeleteSuccess = 'InventoryChecklistItemDeleteSuccess'
export const InventoryChecklistItemDeleteFail = 'InventoryChecklistItemDeleteFail'
export const InventoryChecklistItemDeleteReset = 'InventoryChecklistItemDeleteReset'

export const InventoryChecklistItemList_MYRequest = 'InventoryChecklistItemList_MYRequest'
export const InventoryChecklistItemList_MYSuccess = 'InventoryChecklistItemList_MYSuccess'
export const InventoryChecklistItemList_MYFail = 'InventoryChecklistItemList_MYFail'
export const InventoryChecklistItemList_MYReset = 'InventoryChecklistItemList_MYReset'

export const InventoryChecklistItemListRequest = 'InventoryChecklistItemListRequest'
export const InventoryChecklistItemListSuccess = 'InventoryChecklistItemListSuccess'
export const InventoryChecklistItemListFail = 'InventoryChecklistItemListFail'


  ///////////// API Config /////////////////////////

const APIVariablesCreate = {
  APIRequest: InventoryChecklistItemCreateRequest,
  APISuccess: InventoryChecklistItemCreateSuccess,
  APIFail: InventoryChecklistItemCreateFail,
  APIEndPoint: 'inventory/v1/checklist/items/add',
}
const APIVariablesListAll = {
  APIRequest: InventoryChecklistItemListRequest,
  APISuccess: InventoryChecklistItemListSuccess,
  APIFail: InventoryChecklistItemListFail,
  APIEndPoint: 'inventory/v1/checklist/items/list'
}
const APIVariablesItemDetails = {
  APIRequest: InventoryChecklistItemDetailsRequest,
  APISuccess: InventoryChecklistItemDetailsSuccess,
  APIFail: InventoryChecklistItemDetailsFail,
  APIEndPoint: 'inventory/v1/checklist/items/'
}
const APIVariablesUpdate = {
  APIRequest: InventoryChecklistItemUpdateRequest,
  APISuccess: InventoryChecklistItemUpdateSuccess,
  APISuccess_2: InventoryChecklistItemDetailsSuccess,
  APIFail: InventoryChecklistItemUpdateFail,
  APIEndPoint: 'inventory/v1/checklist/items/'
}
const APIVariablesDelete = {
  APIRequest: InventoryChecklistItemDeleteRequest,
  APISuccess: InventoryChecklistItemDeleteSuccess,
  APIFail: InventoryChecklistItemDeleteFail,
  APIEndPoint: 'inventory/v1/checklist/items/'
}

export const InventoryChecklistItemCreateAPI = (item) => async (dispatch, getState) => {

  const API_Body = {
    name: item.name,
    item: item.item,
    criteria: item.criteria,
  }
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const InventoryChecklistItemListAllAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState, query)
}

export const InventoryChecklistItemDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const InventoryChecklistItemUpdateAPI = (item) => async (dispatch, getState) => {

  const API_Body = {
    name: item.name,
    item: item.item,
    criteria: item.criteria,
  }
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const InventoryChecklistItemDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}
  
  ///////////// REDUCERS/////////////////////////
  // State is an empty object
  export const InventoryChecklistItemCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case InventoryChecklistItemCreateRequest:
            return {
                loading: true
            }
  
        case InventoryChecklistItemCreateSuccess:
            return {
                loading: false,
                success: true,
                InventoryChecklistItem: action.payload
            }
  
        case InventoryChecklistItemCreateFail:
            return {
                loading: false,
                error: action.payload
            }
  
        case InventoryChecklistItemCreateReset:
            return {}
  
  
        default:
            return state
    }
  }
  
  export const InventoryChecklistItemUpdateReducer = (state = { InventoryChecklistItem: {} }, action) => {
    switch (action.type) {
        case InventoryChecklistItemUpdateRequest:
            return { loading: true }
  
        case InventoryChecklistItemUpdateSuccess:
            return { loading: false, success: true }
  
        case InventoryChecklistItemUpdateFail:
            return { loading: false, error: action.payload }
  
        case InventoryChecklistItemUpdateReset:
            return { InventoryChecklistItem: {} }
  
        default:
            return state
    }
  }
  
  export const InventoryChecklistItemDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case InventoryChecklistItemDeleteRequest:
            return { loading: true }
  
        case InventoryChecklistItemDeleteSuccess:
            return { 
              loading: false, 
              success: true }
  
        case InventoryChecklistItemDeleteFail:
            return { loading: false, error: action.payload }
  
        case InventoryChecklistItemDeleteReset:
              return { }
  
        default:
            return state
    }
  }
  
  export const InventoryChecklistItemDetailsReducer = (state = { loading: true, InventoryChecklistItem: [] }, action) => {
    switch (action.type) {
        case InventoryChecklistItemDetailsRequest:
            return {
                ...state,
                loading: true
            }
  
        case InventoryChecklistItemDetailsSuccess:
            return {
                loading: false,
                InventoryChecklistItem: action.payload
            }
  
        case InventoryChecklistItemDetailsFail:
            return {
                loading: false,
                error: action.payload
            }
  
  
        default:
            return state
    }
  }
  
   
  export const InventoryChecklistItemListReducer = (state = { InventoryChecklistItem: [] }, action) => {
    switch (action.type) {
        case InventoryChecklistItemListRequest:
            return {
                loading: true
            }
  
        case InventoryChecklistItemListSuccess:
            return {
                loading: false,
                InventoryChecklistItem: action.payload
            }
  
        case InventoryChecklistItemListFail:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
  }

function InventoryChecklistItemListScreen() {
  const createAPI = InventoryChecklistItemCreateAPI
  const listAPI = InventoryChecklistItemListAllAPI
  const detailsAPI = InventoryChecklistItemDetailsAPI
  const updateAPI = InventoryChecklistItemUpdateAPI
  const deleteAPI = InventoryChecklistItemDeleteAPI

  const resetCreate = InventoryChecklistItemCreateReset
  const resetUpdate = InventoryChecklistItemUpdateReset
  const resetViewDetails = InventoryChecklistItemDetailsReset
  const resetDelete = InventoryChecklistItemDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const InventoryChecklistItemCreate = useSelector(state => state.InventoryChecklistItemCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = InventoryChecklistItemCreate

  const InventoryChecklistItemList = useSelector(state => state.InventoryChecklistItemList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    InventoryChecklistItem: dataList } = InventoryChecklistItemList

  const InventoryChecklistItemDetails = useSelector(state => state.InventoryChecklistItemDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    InventoryChecklistItem: dataItem } = InventoryChecklistItemDetails

  const InventoryChecklistItemUpdate = useSelector(state => state.InventoryChecklistItemUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = InventoryChecklistItemUpdate

  const InventoryChecklistItemDelete = useSelector(state => state.InventoryChecklistItemDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = InventoryChecklistItemDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingList || loadingDetails
    )
  )
  // Logic to retrieve list details from search query 
  const paramsItemSplit = paramsItem.action.split(':')
  const paramsID = paramsItemSplit[1]
  let query = '?listid=' + paramsID

  // console.log('items - paramsItemSplit',paramsItemSplit)
  // console.log('items - paramsID',paramsID)
  // console.log('items - query',query)

  const InventoryChecklistList = useSelector(state => state.InventoryChecklistListList)

  const getListName = (
    InventoryChecklistList === undefined || InventoryChecklistList === null || InventoryChecklistList.length < 1 ? '' : 
    InventoryChecklistList.loading === true ? 'Loading....' :  InventoryChecklistList.InventoryChecklistList.filter(x => x.id == paramsID).map(x => x.name)
  )

  useEffect(() => {
    dispatch(InventoryItemListAllAPI())
    dispatch(listAPI(query))
    dispatch(InventoryChecklistListAllAPI()) 
    dispatch(InventoryChecklistCriteriaListAllAPI())
  }, [])

  useEffect(() => {
    if (successCreate) {
      dispatch(listAPI(query))
      dispatch({ type: resetCreate })
    }
    if (successUpdate) {
      dispatch(listAPI(query))
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI(query))
      dispatch({ type: resetDelete })
    }
    if (errorLoadingList) {setServerErrorMessage(errorLoadingList)}
    if (errorCreate) {setServerErrorMessage(errorCreate)}
    if (errorUpdate) {setServerErrorMessage(errorUpdate)}
    if (errorGetDetails) {setServerErrorMessage(errorGetDetails)}
    if (errorDelete) {setServerErrorMessage(errorDelete)}
  }, [successCreate, successDelete, successUpdate,
    errorLoadingList,errorCreate,errorUpdate,errorGetDetails, errorDelete])


  const handleSave = (data) => {
    const newItem = { 'name' : paramsID }
    const newObject = Object.assign(newItem,data)
    dispatch(createAPI(newObject))
  }

  const handleEdit = (data) => {
    const updatedItem = { 'name' : paramsID }
    const newObject = Object.assign(updatedItem,data)
    console.log(newObject)
    dispatch(updateAPI(newObject))
  }

  return (
    <ModuleHome
    formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
    // Shared items
    paramsItem={paramsItem}
    moduleDefinitions={moduleDefinitions}
    moduleFields={moduleFields}
    customModuleName={'Checklist: ' + getListName} 

    //Table Data
    dataList={dataList}
    loadingList={loadingList}
    errorLoadingList={errorLoadingList}

    rowOnClickAction={'inLineEdit'}
    edit={(data)=> handleEdit(data)}
    delete={(id) => dispatch(deleteAPI(id))}

    save={(data)=> handleSave(data)}  
    inLineAdd={true}
    inLineEdit={true}

    //AddEditForm
    fieldValidation={fieldValidation}

    loading={loading}
    serverErrorMessage={serverErrorMessage}
    setServerErrorMessage={setServerErrorMessage}

    getDataList={listAPI}

    itemDetails={detailsAPI}
    dataItem={dataItem}
    itemDetailsReset={resetViewDetails}

    itemCreate={createAPI}
    successCreate={successCreate}
    resetCreate={resetCreate}

    itemUpdate={updateAPI}
    successUpdate={successUpdate}
    resetUpdate={resetUpdate}

    itemDelete={deleteAPI}
    successDelete={successDelete}
    resetDelete={resetDelete}
    />
  )
}

export default InventoryChecklistItemListScreen