import InputFields from '../fields/inputFields/inputFields';
import tablestyle from '../../style/table.module.css';

export const TableRowAdd = (props) => {
  const colclass = ('colDisP' + (props.tableDisplayPriority))

  const tableAddField = (
    <td className={tablestyle[colclass]} style={{ padding: '0px 4px', }}>
      <InputFields
        fieldType={props.fieldType}
        fieldName={props.fieldName}
        placeholder={'Add ' + props.fieldName + '...'}
        options={props.options}
        onChange={props.onChange}
        // State
        value={props.value}
        // fieldValueName={props.fieldValueName}

        //AutoComplete
        handleChange={props.handleChange}
        addNewLink={props.addNewLink}
        openAddNewModule={props.openAddNewModule}

        //Checkbox
        fieldIsChecked={props.fieldIsChecked}
      >
      </InputFields>
    </td>
  )


  if (props.tableRowAddField === true) {
    return (
      tableAddField
    )
  } else {
    return (
      <td className={tablestyle[colclass]}></td>
    )
  }

}
