//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
import { ItemListAll} from '../../../APIs/genericAPIs'

const moduleDefinitions = {
  moduleName: 'SeaStates',
  moduleNameSingular: 'SeaState',
}

const moduleFields = [
  {
    label: 'Sea State', 
    mandatory: 'true', 
    fieldName: 'name',
    fieldType: 'text',
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  } ,  {
    label: 'Min Height', 
    fieldName: 'height_min',
    fieldType: 'text',
    tableDisplayOrder: '',
    tableDisplayPriority: '2',
  } ,  {
    label: 'Max Height', 
    fieldName: 'height_max',
    fieldType: 'text',
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  } 
]

export const SailingLogSeaStateListListRequest = 'SailingLogSeaStateListListRequest'
export const SailingLogSeaStateListListSuccess = 'SailingLogSeaStateListListSuccess'
export const SailingLogSeaStateListListFail = 'SailingLogSeaStateListListFail'

const APIVariablesListAll = {
  APIRequest: SailingLogSeaStateListListRequest,
  APISuccess: SailingLogSeaStateListListSuccess,
  APIFail: SailingLogSeaStateListListFail,
  APIEndPoint: 'sailinglog/v1/seastate/list'
}

export const SailingLogSeaStateListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}
  
  export const SailingLogSeaStateListReducer = (state = { SailingLogSeaState: [] }, action) => {
    switch (action.type) {
        case SailingLogSeaStateListListRequest:
            return {
                loading: true
            }
  
        case SailingLogSeaStateListListSuccess:
            return {
                loading: false,
                fieldOptionValues: [...action.payload],
                SailingLogSeaState: action.payload
            }
  
        case SailingLogSeaStateListListFail:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
  }

function SailingLogSeaStateScreen() {

  const listAPI = SailingLogSeaStateListAllAPI

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

  const SailingLogSeaStateList = useSelector(state => state.SailingLogSeaStateList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    SailingLogSeaState: dataList } = SailingLogSeaStateList


  useEffect(() => {
    dispatch(SailingLogSeaStateListAllAPI())
  }, [])



  return (
    <ModuleHome
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}
/>
  )
}

export default SailingLogSeaStateScreen