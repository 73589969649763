//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../components/containers/moduleHome'
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../APIs/genericAPIs'

export const moduleDefinitions = {
  moduleName: 'Organisations',
  moduleNameSingular: 'Organisation',
  moduleLink: '/contacts/organisation/',
}

const dataBaseFilterOptions = [
  {
    id: 1,
    name: 'Active',
    default: true,
  }, {
    id: 2,
    name: 'Archived',
  }, {
    id: 3,
    name: 'All',
  },
]

export const moduleFields = [
{
    label: 'Organisation Name',
    mandatory: true,
    fieldName: 'name',
    fieldType: 'text',
    addFormOrder: 10,
    editFormOrder: 10,
    viewOrder: 10,
    tableDisplayOrder: 1,
    tableDisplayPriority: 1,
  }, {
    label: 'Organisation Type',
    mandatory: true,
    fieldName: 'org_type',
    fieldType: 'select',
    options: [{ id: '1', name: 'LTD' }, { id: '2', name: 'PLC' }, { id: '3', name: 'Partnership'},{ id: '4', name: 'Charity' }, { id: '4', name: 'Sole Trader' } ],
    addFormOrder: 20,
    editFormOrder: 20,
    viewOrder: 20,
    tableDisplayOrder: 2,
    tableDisplayPriority: 2,
  }, {
    label: 'Known As',
    fieldName: 'known_as',
    fieldType: 'text',
    addFormOrder: 30,
    editFormOrder: 30,
    viewOrder: 30,
    tableDisplayOrder: 3,
    tableDisplayPriority: 2,
  }, {
    label: 'Date of Incorporation',
    fieldName: 'doi',
    fieldType: 'date',
    fieldGroupPosition: 'Left',
    addFormOrder: 40,
    editFormOrder: 40,
    viewOrder: 40,
    tableDisplayOrder: 0,
    tableDisplayPriority: 0,
  }, {
    label: 'Company Reg No.',
    fieldName: 'crn',
    fieldType: 'number',
    fieldGroupPosition: 'Right',
    addFormOrder: 50,
    editFormOrder: 50,
    viewOrder: 50,
    tableDisplayOrder: 0,
    tableDisplayPriority: 0,
  }
]

export const fieldValidation = (fieldName, value, condition) => {
  let hasError = false,
    fieldErrorMessage = ""
  switch (fieldName) {
    case "org_name":
      if (condition === true) { 
        if (value.trim() === "") {
          hasError = true
          fieldErrorMessage = "Organisation name is required"
        } else if (value.length < 2) {
          hasError = true
          fieldErrorMessage = 'Organisation name is too short'
        } else if (value.length > 50) {
          hasError = true
          fieldErrorMessage = 'Organisation name is too long'
        } else {
          hasError = false
          fieldErrorMessage = ""
        }
        break
      }
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

export const ContactsOrganisationCreateRequest = 'ContactsOrganisationCreateRequest'
export const ContactsOrganisationCreateSuccess = 'ContactsOrganisationCreateSuccess'
export const ContactsOrganisationCreateFail = 'ContactsOrganisationCreateFail'
export const ContactsOrganisationCreateReset = 'ContactsOrganisationCreateReset'

export const ContactsOrganisationDetailsRequest = 'ContactsOrganisationDetailsRequest'
export const ContactsOrganisationDetailsSuccess = 'ContactsOrganisationDetailsSuccess'
export const ContactsOrganisationDetailsFail = 'ContactsOrganisationDetailsFail'
export const ContactsOrganisationDetailsReset = 'ContactsOrganisationDetailsReset'

export const ContactsOrganisationUpdateRequest = 'ContactsOrganisationUpdateRequest'
export const ContactsOrganisationUpdateSuccess = 'ContactsOrganisationUpdateSuccess'
export const ContactsOrganisationUpdateFail = 'ContactsOrganisationUpdateFail'
export const ContactsOrganisationUpdateReset = 'ContactsOrganisationUpdateReset'

export const ContactsOrganisationDeleteRequest = 'ContactsOrganisationDeleteRequest'
export const ContactsOrganisationDeleteSuccess = 'ContactsOrganisationDeleteSuccess'
export const ContactsOrganisationDeleteFail = 'ContactsOrganisationDeleteFail'
export const ContactsOrganisationDeleteReset = 'ContactsOrganisationDeleteReset'

export const ContactsOrganisationListRequest = 'ContactsOrganisationListRequest'
export const ContactsOrganisationListSuccess = 'ContactsOrganisationListSuccess'
export const ContactsOrganisationListFail = 'ContactsOrganisationListFail'


const APIVariablesCreate = {
  APIRequest: ContactsOrganisationCreateRequest,
  APISuccess: ContactsOrganisationCreateSuccess,
  APIFail: ContactsOrganisationCreateFail,
  APIEndPoint: 'contacts/v1/organisation/add',
}
const APIVariablesListAll = {
  APIRequest: ContactsOrganisationListRequest,
  APISuccess: ContactsOrganisationListSuccess,
  APIFail: ContactsOrganisationListFail,
  APIEndPoint: 'contacts/v1/organisation/list'
}
const APIVariablesSelectOptions = {
  APIRequest: 'ContactsOrganisationSelectOptionsRequest',
  APISuccess: 'ContactsOrganisationSelectOptionsSuccess',
  APIFail: 'ContactsOrganisationSelectOptionsFail',
  APIEndPoint: 'contacts/v1/organisation/selectoptions'
}
const APIVariablesItemDetails = {
  APIRequest: ContactsOrganisationDetailsRequest,
  APISuccess: ContactsOrganisationDetailsSuccess,
  APIFail: ContactsOrganisationDetailsFail,
  APIEndPoint: 'contacts/v1/organisation/'
}
const APIVariablesUpdate = {
  APIRequest: ContactsOrganisationUpdateRequest,
  APISuccess: ContactsOrganisationUpdateSuccess,
  APISuccess_2: ContactsOrganisationDetailsSuccess,
  APIFail: ContactsOrganisationUpdateFail,
  APIEndPoint: 'contacts/v1/organisation/'
}
const APIVariablesDelete = {
  APIRequest: ContactsOrganisationDeleteRequest,
  APISuccess: ContactsOrganisationDeleteSuccess,
  APIFail: ContactsOrganisationDeleteFail,
  APIEndPoint: 'contacts/v1/organisation/'
}

const API_Body_Contents =(item) => {
  const cleandoi = item.doi === '' ? null : item.doi;
  return (
    {
      org_name: item.org_name,
      known_as: item.known_as,
      crn: item.crn,
      doi: cleandoi,
    }
  )}

export const ContactsOrganisationCreateAPI = (item) => async (dispatch, getState) => {
  const cleandate = item.dob === '' ? null : item.dob;

  const API_Body = API_Body_Contents(item)

  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}
export const ContactsOrganisationListAllAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState, query)
}
export const ContactsOrganisationListSelectOptionsAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesSelectOptions, dispatch, getState)
}
export const ContactsOrganisationDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}
export const ContactsOrganisationUpdateAPI = (item) => async (dispatch, getState) => {
  const cleandate = item.dob === '' ? null : item.dob;

  const API_Body = API_Body_Contents(item)
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}
export const ContactsOrganisationDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

export const ContactsOrganisationCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ContactsOrganisationCreateRequest:
      return {
        loading: true
      }

    case ContactsOrganisationCreateSuccess:
      return {
        loading: false,
        success: true,
        ContactsOrganisation: action.payload
      }

    case ContactsOrganisationCreateFail:
      return {
        loading: false,
        error: action.payload
      }

    case ContactsOrganisationCreateReset:
      return {}


    default:
      return state
  }
}

export const ContactsOrganisationUpdateReducer = (state = { ContactsOrganisation: {} }, action) => {
  switch (action.type) {
    case ContactsOrganisationUpdateRequest:
      return { loading: true }

    case ContactsOrganisationUpdateSuccess:
      return { loading: false, success: true }

    case ContactsOrganisationUpdateFail:
      return { loading: false, error: action.payload }

    case ContactsOrganisationUpdateReset:
      return { ContactsOrganisation: {} }

    default:
      return state
  }
}

export const ContactsOrganisationDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ContactsOrganisationDeleteRequest:
      return { loading: true }

    case ContactsOrganisationDeleteSuccess:
      return {
        loading: false,
        success: true
      }

    case ContactsOrganisationDeleteFail:
      return { loading: false, error: action.payload }

    case ContactsOrganisationDeleteReset:
      return {}

    default:
      return state
  }
}

export const ContactsOrganisationDetailsReducer = (state = { loading: true, ContactsOrganisation: [] }, action) => {
  switch (action.type) {
    case ContactsOrganisationDetailsRequest:
      return {
        ...state,
        loading: true
      }

    case ContactsOrganisationDetailsSuccess:
      return {
        loading: false,
        ContactsOrganisation: action.payload
      }

    case ContactsOrganisationDetailsFail:
      return {
        loading: false,
        error: action.payload
      }


    default:
      return state
  }
}


export const ContactsOrganisationListReducer = (state = { ContactsOrganisation: [] }, action) => {
  switch (action.type) {
    case ContactsOrganisationListRequest:
      return {
        loading: true
      }

    case ContactsOrganisationListSuccess:
      return {
        loading: false,
        ContactsOrganisation: action.payload
      }

    case ContactsOrganisationListFail:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const ContactsOrganisationSelectOptionsReducer = (state = { ContactsOrganisationSelectOptions: [] }, action) => {
  switch (action.type) {
    case 'ContactsOrganisationSelectOptionsRequest':
      return {
        loading: true
      }

    case 'ContactsOrganisationSelectOptionsSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
      }

    case 'ContactsOrganisationSelectOptionsFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function ContactsOrganisationScreen(props) {
  const [dataBaseFilter, setDataBaseFilter] = useState('Active');
  const dataListParams = ('?listtype=' + dataBaseFilter)

  const createAPI = ContactsOrganisationCreateAPI
  const listAPI = ContactsOrganisationListAllAPI
  const detailsAPI = ContactsOrganisationDetailsAPI
  const updateAPI = ContactsOrganisationUpdateAPI
  const deleteAPI = ContactsOrganisationDeleteAPI

  const resetCreate = ContactsOrganisationCreateReset
  const resetUpdate = ContactsOrganisationUpdateReset
  const resetViewDetails = ContactsOrganisationDetailsReset
  const resetDelete = ContactsOrganisationDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const ContactsOrganisationCreate = useSelector(state => state.ContactsOrganisationCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = ContactsOrganisationCreate

  const ContactsOrganisationList = useSelector(state => state.ContactsOrganisationList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    ContactsOrganisation: dataList } = ContactsOrganisationList

  const ContactsOrganisationDetails = useSelector(state => state.ContactsOrganisationDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    ContactsOrganisation: dataItem } = ContactsOrganisationDetails

  const ContactsOrganisationUpdate = useSelector(state => state.ContactsOrganisationUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = ContactsOrganisationUpdate

  const ContactsOrganisationDelete = useSelector(state => state.ContactsOrganisationDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = ContactsOrganisationDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingDetails
    )
  )
  
  useEffect(() => {
    dispatch(ContactsOrganisationListAllAPI(dataListParams))
  }, [dataBaseFilter])

  useEffect(() => {
    if (successUpdate) {
      dispatch(ContactsOrganisationListAllAPI(dataListParams))
      dispatch({ type: ContactsOrganisationUpdateReset })
    }
    if (successDelete) {
      // dispatch(ContactsOrganisationListAllAPI(dataListParams))
      dispatch({ type: ContactsOrganisationDeleteReset })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }
  }, [successDelete, successUpdate,
    errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete])


  return (
    <ModuleHome
      //     formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
    moduleModalVisibility={props.moduleModalVisibility}
    setModuleModalVisibility={props.setModuleModalVisibility}
      // setFormModalVisibility={props.setFormModalVisibility}

      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataListParams={dataListParams}
      getDataList={listAPI}
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      //Database filtering
      dataBaseFilterOptions={dataBaseFilterOptions}
      dataBaseFilter={dataBaseFilter}
      setDataBaseFilter={setDataBaseFilter}

      rowOnClickAction='edit'
      // add={true}
      edit={true}
      view={false}
      // delete={(id) => dispatch(ContactsOrganisationDeleteAPI(id))}

      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}
    />
  )
}

export default ContactsOrganisationScreen