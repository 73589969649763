import style from '../../../style/global.module.css';
import formstyle from '../../../style/form.module.css';
import mmcylogo from '../../../static/PrimaryColourTransparentNoWordsBorder.png';
import { Link } from "react-router-dom";
import { useState } from 'react';

const PasswordReset = () => {

  const [resetstate, setResetState] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setResetState(true);
  }

  return (
    <div className={style.section}>
      <div className={`${style.fixedcontainer} ${style.center} containter`}>
        <img
          className={style.mmcylogo}
          src={mmcylogo}
          alt="MMCY Business Solutions Logo" />
        <h2>Password Reset</h2>
        {!resetstate && (
        <form className={style.text} >
          <div className={`${formstyle.input} ${formstyle.fullwidth}`}>
            <p>Enter your email address to reset your password. </p>
            <label htmlFor='email'>Email</label>
            <input
              type='email'
              id='email'
              // value={enteredEmail}
              required
            />
          </div>
          <div className={formstyle.action}>
            <Link to={`/signin`} ><button className={formstyle.orange}>Cancel</button></Link>
            <button className={formstyle.darkblue} 
            onClick={handleSubmit}
            >Reset Password</button>
          </div>
        </form>
        )}
        {resetstate && (
          <>
        <p>If you entered a valid email address, a reset link will be sent to it.</p> 
          <Link to={`/signin`} ><button className={formstyle.darkblue}>Sign In</button></Link>
          </>
        )}
      </div>
    </div>
  );
};
export default PasswordReset;