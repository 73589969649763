//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
import { ItemListAll } from '../../../APIs/genericAPIs'

const moduleDefinitions = {
  moduleName: 'Sound',
  moduleNameSingular: 'Sound',
  moduleLink: '/fun/sailinglog/sounds/',
}

const moduleFields = [
  {
    label: 'Sound',
    fieldName: 'sound',
    fieldType: 'text',
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  }, {
    label: 'Description',
    fieldName: 'description',
    fieldType: 'text',
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  }, {
    label: 'Frequency',
    fieldName: 'frequency',
    fieldType: 'text',
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  }, {
    label: 'notes',
    fieldName: 'notes',
    fieldType: 'text',
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
  }
]

const APIVariablesListAll = {
  APIRequest: 'SailingLogSoundListRequest',
  APISuccess: 'SailingLogSoundListSuccess',
  APIFail: 'SailingLogSoundListFail',
  APIEndPoint: 'sailinglog/v1/sound/list'
}


export const SailingLogSoundListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const SailingLogSoundListReducer = (state = { SailingLogSound: [] }, action) => {
  switch (action.type) {
    case 'SailingLogSoundListRequest':
      return {
        loading: true
      }

    case 'SailingLogSoundListSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
        SailingLogSound: action.payload
      }

    case 'SailingLogSoundListFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function SailingLogSoundScreen() {

  const listAPI = SailingLogSoundListAllAPI

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

  const SailingLogSoundList = useSelector(state => state.SailingLogSoundList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    SailingLogSound: dataList } = SailingLogSoundList


  useEffect(() => {
    dispatch(SailingLogSoundListAllAPI())
  }, [])

  return (
    <ModuleHome
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}
/>
  )
}

export default SailingLogSoundScreen