 
 import formstyle from '../../../style/form.module.css'

 const SelectField = (props) => {
    console.log('props',props)
    return (
      <div className={formstyle.input}>
        <select
          style={{width: props.width}}
          
          // Standard
          key={props.fieldName}
          id={props.fieldName}
          name={props.fieldName}
          type='select'
          value={props.value}

          // Optional
          mandatory={props.mandatory}
          disabled={props.disabled}
>
          {props.options.map((option) => (
            <option key={option.id}
              value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    )
  }

export default SelectField;