const handleFilterTime = (filter, dbValue) => {
  if (dbValue == '00:00:00' || dbValue == '00:00') return false
  else {
    console.log('handleFilterTime', filter, dbValue)
    const filterHour = Number(filter.filterValue.split(':')[0])
    const filterMinute = Number(filter.filterValue.split(':')[1])
    const dbHour = dbValue ? Number(dbValue.split(':')[0]) : '00'
    const dbMinute = dbValue ? Number(dbValue.split(':')[1]) : '00'

    console.log('filterHour', filterHour)
    console.log('filterMinute', filterMinute)

    console.log('dbHour', dbHour)
    console.log('dbMinute', dbMinute)

    if (filter.filterLogic === 'eqaulTo' || filter.filterLogic === undefined) {
      return dbHour == filterHour && dbMinute == filterMinute
    }
    if (filter.filterLogic === 'lessThan') {
      return dbValue < filter.filterValue
    }
    if (filter.filterLogic === 'equalToLessThan') {
      return dbValue <= filter.filterValue
    }
    if (filter.filterLogic === 'notEqualTo') {
      return dbValue != filter.filterValue
    }
    if (filter.filterLogic === 'greaterThan') {
      if (filter.filterLogic2 === 'lessThan') {
        return dbValue > filter.filterValue && dbValue < filter.filterValue2
      } else if (filter.filterLogic2 === 'equalToLessThan') {
        return dbValue > filter.filterValue && dbValue <= filter.filterValue2
      } else {
        return dbValue > filter.filterValue
      }
    }
    if (filter.filterLogic === 'equalToGreaterThan') {
      if (filter.filterLogic2 === 'equalToLessThan') {
        return dbValue >= filter.filterValue && dbValue <= filter.filterValue2
      } else if (filter.filterLogic2 === 'lessThan') {
        return dbValue >= filter.filterValue && dbValue < filter.filterValue2
      } else {
        return dbValue >= filter.filterValue
      }
    }
  }
}
export default handleFilterTime