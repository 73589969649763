import {
  USER_LOGINRequest,
  USER_LOGINSuccess,
  USER_LOGINFail,

  USER_REFRESHRequest,
  USER_REFRESHSuccess,
  USER_REFRESHFail,

  USER_LOGOUT,

  USER_REGISTERRequest,
  USER_REGISTERSuccess,
  USER_REGISTERFail,

  USER_PROFILERequest,
  USER_PROFILESuccess,
  USER_PROFILEFail,
  USER_PROFILEReset,

  USERUpdate_PROFILERequest,
  USERUpdate_PROFILESuccess,
  USERUpdate_PROFILEFail,
  USERUpdate_PROFILEReset,

  USERUpdate_PASSWORDRequest,
  USERUpdate_PASSWORDSuccess,
  USERUpdate_PASSWORDFail,
  USERUpdate_PASSWORDReset,

  USERListRequest,
  USERListSuccess,
  USERListFail,
  USERListReset,

  USERDeleteRequest,
  USERDeleteSuccess,
  USERDeleteFail,

  USER_DETAILSRequest,
  USER_DETAILSSuccess,
  USER_DETAILSFail,
  USER_DETAILSReset,

  USERUpdateRequest,
  USERUpdateSuccess,
  USERUpdateFail,
  USERUpdateReset,
} from './userConstants'

export const user_LoginReducer = (state={}, action) => {
  switch(action.type) {
      case USER_LOGINRequest:
          return {loading:true, }
      case USER_LOGINSuccess:
          return {loading:false, userInfo: action.payload}
      case USER_LOGINFail:
          return {loading:false, error: action.payload}
      case USER_LOGOUT:  
          return {}
      default:
          return state
  }
}

export const user_RefreshReducer = (state={}, action) => {
  switch(action.type) {
      case USER_REFRESHRequest:
          return {loading:true, }
      case USER_REFRESHSuccess:
        return {loading:false}
      case USER_REFRESHFail:
          return {loading:false, error: action.payload}
      default:
          return state
  }
}

export const user_RegisterReducer = (state={}, action) => {
  switch(action.type) {
      case USER_REGISTERRequest:
          return {loading:true, }
      
      case USER_REGISTERSuccess:
          return {loading:false, userInfo: action.payload}
      
      case USER_REGISTERFail:
          return {loading:false, error: action.payload}
      
      case USER_LOGOUT:  
          return {}
      default:
          return state
  }
}

export const user_ProfileReducer = (state={ user:{}}, action) => {
  switch(action.type) { 
      case USER_PROFILERequest:
          return {...state, loading:true, }
      
      case USER_PROFILESuccess:
          return {loading:false, success: true, user: action.payload}
      
      case USER_PROFILEFail:
          return {loading:false, error: action.payload}
      
      case USER_PROFILEReset:
          return { user:{}}
      
      default:
          return state
  }
}

// export const userDetailsReducer = (state={ user:{}}, action) => {
//   switch(action.type) { 
//       case USER_DETAILSRequest:
//           return {...state, loading:true, }
      
//       case USER_DETAILSSuccess:
//           return {loading:false, user: action.payload}
      
//       case USER_DETAILSFail:
//           return {loading:false, error: action.payload}
      
//       case USER_DETAILSReset:
//           return { user:{}}
      
//       default:
//           return state
//   }
// }

export const userUpdateProfileReducer = (state={}, action) => {
  switch(action.type) {
      case USERUpdate_PROFILERequest:
          return {loading:true }
      
      case USERUpdate_PROFILESuccess:
          return {loading:false, success:true, userInfo: action.payload}
      
      case USERUpdate_PROFILEFail:
          return {loading:false, error: action.payload}

      case USERUpdate_PROFILEReset:
          return {}
      
      default:
          return state
  }
}

export const userUpdatePasswordReducer = (state={}, action) => {
  switch(action.type) {
      case USERUpdate_PASSWORDRequest:
          return {loading:true }
      
      case USERUpdate_PASSWORDSuccess:
          return {loading:false, success:true, userInfo: action.payload}
      
      case USERUpdate_PASSWORDFail:
          return {loading:false, error: action.payload}

      case USERUpdate_PASSWORDReset:
          return {}
      
      default:
          return state
  }
}

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
      case USERListRequest:
          return { loading: true }

      case USERListSuccess:
          return { loading: false, users: action.payload }

      case USERListFail:
          return { loading: false, error: action.payload }

      case USERListReset:
          return { users: [] }

      default:
          return state
  }
}


export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
      case USERDeleteRequest:
          return { loading: true }

      case USERDeleteSuccess:
          return { loading: false, success: true }

      case USERDeleteFail:
          return { loading: false, error: action.payload }

      default:
          return state
  }
}


export const userUpdateReducer = (state = { user: {} }, action) => {
  switch (action.type) {
      case USERUpdateRequest:
          return { loading: true }

      case USERUpdateSuccess:
          return { loading: false, success: true }

      case USERUpdateFail:
          return { loading: false, error: action.payload }

      case USERUpdateReset:
          return { user: {} }

      default:
          return state
  }
}