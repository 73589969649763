import React from 'react';
import ReactDOM from 'react-dom';
import '../../style/modal.css';
import AddressModule from '../../modules/shared/address'
import CategoryModule from '../../modules/shared/category'
import ContactsContactModule from '../../modules/contacts/contact'
import ContactsGroupModule from '../../modules/contacts/groups'

import Inventory_Item_Module from '../../modules/inventory/items'

import SailingLog_Boats from '../../modules/sailingLog/boats'
import SailingLog_Contacts from '../../modules/sailingLog/contacts/contacts'
import SailingLog_Contact_Qualifications from '../../modules/sailingLog/contacts/qualifications'
import SailingLog_Locations from '../../modules/sailingLog/locations'
import SailingLog_Trips from '../../modules/sailingLog/trips'

import SailingLog_Passages from '../../modules/sailingLog/passage/passages'
import SailingLog_Crew from '../../modules/sailingLog/passage/crew'
import SailingLog_LogEntry from '../../modules/sailingLog/passage/logEntry'

import SailingLog_Settings_Boat_Types from '../../modules/sailingLog/settings/boatTypes'
import SailingLog_Settings_Qualifications from '../../modules/sailingLog/settings/qualifications'
import SailingLog_Settings_Role from '../../modules/sailingLog/settings/role'
import SailingLog_Settings_Trip_Types from '../../modules/sailingLog/settings/tripTypes'

export const Modal = (props) => props.moduleModalVisibility ? ReactDOM.createPortal(
  <React.Fragment>
    <div className="modalOverlay">
      {console.log('props', props)}
      <div className="moduleModalContent">
        {props.openAddNewModule === 'shared/address' && <AddressModule {...props} />}
        {props.openAddNewModule === 'shared/category' && <CategoryModule {...props} />}

        {props.openAddNewModule === 'contacts/contacts' && <ContactsContactModule {...props} />}
        {props.openAddNewModule === 'contacts/groups' && <ContactsGroupModule {...props} />}

        {props.openAddNewModule === 'inventory/items' && <Inventory_Item_Module {...props} />}

        {props.openAddNewModule === 'sailinglog/boats' && <SailingLog_Boats {...props} />}
        {props.openAddNewModule === 'sailinglog/contacts' && <SailingLog_Contacts {...props} />}
        {props.openAddNewModule === 'sailinglog/contacts/qualifications' && <SailingLog_Contact_Qualifications {...props} />}
        {props.openAddNewModule === 'sailinglog/crew' && <SailingLog_Crew {...props} />}
        {props.openAddNewModule === 'sailinglog/locations' && <SailingLog_Locations {...props} />}
        {props.openAddNewModule === 'sailinglog/logentry' && <SailingLog_LogEntry {...props} />}
        {props.openAddNewModule === 'sailinglog/passages' && <SailingLog_Passages {...props} />}
        {props.openAddNewModule === 'sailinglog/trips' && <SailingLog_Trips {...props} />}

        {props.openAddNewModule === 'sailinglog/settings/boattypes' && <SailingLog_Settings_Boat_Types {...props} />}
        {props.openAddNewModule === 'sailinglog/settings/qualifications' && <SailingLog_Settings_Qualifications {...props} />}
        {props.openAddNewModule === 'sailinglog/settings/roles' && <SailingLog_Settings_Role {...props} />}
        {props.openAddNewModule === 'sailinglog/settings/triptypes' && <SailingLog_Settings_Trip_Types {...props} />}
      </div>



    </div>
  </React.Fragment>, document.body
) : null;
