import './toggleSwitch.css';

const ToggleSwitch = ({isChecked, handleToggle}) => {
  return (
    <>
      <input
        checked={isChecked}
        onChange={handleToggle}
        className="toggleSwitchCheckbox"
        id={`toggleSwitchNew`}
        type="checkbox"
      />
      <label
        style={{ background: isChecked && '#06D6A0' }}
        className="toggleSwitchLabel"
        htmlFor={`toggleSwitchNew`}
      > 
        <span 
        className={'toggleSwitchText'}
        style={{ display: isChecked && 'block' ,
        marginLeft: '10px'}}
        
        > On </span>
        <span 
        className={'toggleSwitchText'}
        style={{ 
          display: !isChecked && 'block',
          marginLeft: '28px'
         }}
        
        > Off </span>
        <span className={`toggleSwitchButton`} />
      </label>
    </>
  );
};
export default ToggleSwitch;