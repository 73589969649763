import {localCleanISODateTime} from '../hooks/useDateTime'

export const convertToCSV = (data) => {
  const headers = Object.keys(data[0]).join(',') + '\n';
  const csvRows = data.map((row) =>
    Object.values(row)
      .map((value) => (typeof value === 'string' ? `"${value}"` : value))
      .join(',')
  );
  return headers + csvRows.join('\n');
};

export const handleExport = (data,fileName) => {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = localCleanISODateTime + ' ' + fileName + '.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };






