//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
import AddEditForm from '../../../components/forms/AddEditForm'
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'

const moduleDefinitions = {
  moduleName: 'Wind Speed',
  moduleNameSingular: 'Wind Speed',
  linktoView: '/fun/sailinglog/reference/windspeeds/',
  // thOptionsWidth: '115px',
}

const moduleFields = [
  {
    label: 'Code',
    mandatory: 'true',
    fieldName: 'code',
    fieldType: 'text',
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  }, {
    label: 'Name',
    fieldName: 'name',
    fieldType: 'text',
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  }, {
    label: 'Sea Conditions',
    fieldName: 'sea_conditions',
    fieldType: 'text',
    tableDisplayOrder: '',
    tableDisplayPriority: '4',
  }, {
    label: 'Min wind speed (knts)',
    fieldName: 'wind_speed_min',
    fieldType: 'text',
    tableDisplayOrder: '',
    tableDisplayPriority: '3',
  }, {
    label: 'Max wind speed (knts)',
    fieldName: 'wind_speed_max',
    fieldType: 'text',
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  }
]



export const SailingLogWindSpeedListListRequest = 'SailingLogWindSpeedListListRequest'
export const SailingLogWindSpeedListListSuccess = 'SailingLogWindSpeedListListSuccess'
export const SailingLogWindSpeedListListFail = 'SailingLogWindSpeedListListFail'


const APIVariablesListAll = {
  APIRequest: SailingLogWindSpeedListListRequest,
  APISuccess: SailingLogWindSpeedListListSuccess,
  APIFail: SailingLogWindSpeedListListFail,
  APIEndPoint: 'sailinglog/v1/windspeed/list'
}

export const SailingLogWindSpeedListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}


export const SailingLogWindSpeedListReducer = (state = { SailingLogWindSpeed: [] }, action) => {
  switch (action.type) {
    case SailingLogWindSpeedListListRequest:
      return {
        loading: true
      }

    case SailingLogWindSpeedListListSuccess:
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
        SailingLogWindSpeed: action.payload
      }

    case SailingLogWindSpeedListListFail:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function SailingLogWindSpeedScreen() {

  const listAPI = SailingLogWindSpeedListAllAPI

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

  const SailingLogWindSpeedList = useSelector(state => state.SailingLogWindSpeedList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    SailingLogWindSpeed: dataList } = SailingLogWindSpeedList


  useEffect(() => {
    dispatch(SailingLogWindSpeedListAllAPI())
  }, [])



  return (
    <ModuleHome
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}
/>
  )
}

export default SailingLogWindSpeedScreen