//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'
import { AddressListAllAPI } from '../../shared/address'
import { CategoryListAllAPI } from '../../shared/category'
import { TaskStatusListAllAPI } from './status'
import { TaskPriorityListAllAPI } from './priority'
import { ContactsContactListSelectOptionsAPI } from '../../contacts/contact'

export const moduleDefinitions = {
  moduleName: 'Tasks',
  moduleNameSingular: 'Task',
  moduleLink: '/task/',
}

const dataBaseFilterOptions = [
  {
    id: 1,
    name: 'Open',
    default: true,
  }, {
    id: 2,
    name: 'Closed',
  }, {
    id: 3,
    name: 'All',
  },
]

export const moduleFields = [
  {
    label: 'Task name',
    mandatory: "true",
    fieldName: 'task_name',
    fieldType: 'text',
    addFormOrder: 1,
    editFormOrder: 1,
    tableDisplayOrder: 1,
    tableDisplayPriority: 1,
  }, {
    label: 'Status',
    fieldName: 'status',
    fieldLongName: 'status_name',
    fieldType: 'selectfromdb',
    fieldGroupPosition: 'Left',
    options: 'TaskStatusList',
    addFormOrder: 2,
    addFormInitialValue: 1,
    editFormOrder: 2,
    tableDisplayOrder: 2,
    tableDisplayPriority: 2,
    // tableDefaultFilter: [1, 2], //Open & WIP
  }, {
    label: 'Priority',
    fieldName: 'priority',
    fieldLongName: 'priority_name',
    fieldType: 'selectfromdb',
    fieldGroupPosition: 'Right',
    options: 'TaskPriorityList',
    addFormOrder: 3,
    addFormInitialValue: '3',
    editFormOrder: 3,
    tableDisplayOrder: 4,
    tableDisplayPriority: 4,
  }, {
    label: 'Category',
    fieldName: 'category',
    fieldLongName: 'category_name',
    fieldType: 'selectfromdb',
    options: 'CategoryList',
    ItemCreate: 'categoryCreate',
    addFormOrder: 4,
    editFormOrder: 4,
    tableDisplayOrder: 3,
    tableDisplayPriority: 3,
    // addNewLink: '/system/settings/categories/add/',
    openAddNewModule: 'shared/category',
  }, {
    label: 'Requested By',
    fieldName: 'requested_by',
    fieldLongName: 'requested_by_name',
    fieldType: 'autoCompleteText',
    options: 'ContactsContactSelectOptions',
    addFormOrder: 5,
    editFormOrder: 5,
    tableDisplayOrder: 6,
    tableDisplayPriority: 5,
    autoComplete: 'yes',
    // addNewLink: '/contacts/contact/add/',
    openAddNewModule: 'contacts/contact',
  }, {
    label: 'Due Date',
    fieldName: 'due_date',
    fieldType: 'date',
    fieldGroupPosition: 'Left',
    addFormOrder: 6.1,
    editFormOrder: 6.1,
    tableDisplayOrder: 5,
    tableDisplayPriority: 4,
  }, {
    label: 'Time',
    fieldName: 'due_time',
    fieldType: 'time',
    fieldGroupPosition: 'Right',
    addFormOrder: 6.2,
    editFormOrder: 6.2,
  }, {
    label: 'Task Location',
    fieldName: 'task_location',
    fieldLongName: 'task_location_name',
    fieldType: 'selectfromdb',
    options: 'AddressList',
    ItemCreate: 'addressCreate',
    addFormOrder: 7,
    editFormOrder: 7,
    tableDisplayOrder: 7,
    tableDisplayPriority: 5,
    // addNewLink: '/system/settings/address/add/',
    openAddNewModule: 'shared/address',
  }, {
    label: 'Description',
    fieldName: 'description',
    fieldType: 'textarea',
    rows: "4",
    addFormOrder: 8,
    editFormOrder: 8,
  },
]

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
    fieldErrorMessage = ""
  switch (fieldName) {
    case "task_name":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Task name is required"
      } else if (value.length < 2) {
        hasError = true
        fieldErrorMessage = 'Task name is too short'
      } else if (value.length > 50) {
        hasError = true
        fieldErrorMessage = 'Task name is too long'
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

///////////// Constants /////////////////////////
export const TaskCreateRequest = 'TaskCreateRequest'
export const TaskCreateSuccess = 'TaskCreateSuccess'
export const TaskCreateFail = 'TaskCreateFail'
export const TaskCreateReset = 'TaskCreateReset'

export const TaskDetailsRequest = 'TaskDetailsRequest'
export const TaskDetailsSuccess = 'TaskDetailsSuccess'
export const TaskDetailsFail = 'TaskDetailsFail'
export const TaskDetailsReset = 'TaskDetailsReset'

export const TaskUpdateRequest = 'TaskUpdateRequest'
export const TaskUpdateSuccess = 'TaskUpdateSuccess'
export const TaskUpdateFail = 'TaskUpdateFail'
export const TaskUpdateReset = 'TaskUpdateReset'

export const TaskDeleteRequest = 'TaskDeleteRequest'
export const TaskDeleteSuccess = 'TaskDeleteSuccess'
export const TaskDeleteFail = 'TaskDeleteFail'
export const TaskDeleteReset = 'TaskDeleteReset'

export const TaskList_MYRequest = 'TaskList_MYRequest'
export const TaskList_MYSuccess = 'TaskList_MYSuccess'
export const TaskList_MYFail = 'TaskList_MYFail'
export const TaskList_MYReset = 'TaskList_MYReset'

export const TaskListRequest = 'TaskListRequest'
export const TaskListSuccess = 'TaskListSuccess'
export const TaskListFail = 'TaskListFail'


///////////// API Config /////////////////////////


const APIVariablesCreate = {
  APIRequest: TaskCreateRequest,
  APISuccess: TaskCreateSuccess,
  APIFail: TaskCreateFail,
  APIEndPoint: 'tasks/v1/add',
}
const APIVariablesListAll = {
  APIRequest: TaskListRequest,
  APISuccess: TaskListSuccess,
  APIFail: TaskListFail,
  APIEndPoint: 'tasks/v1/list'
}
const APIVariablesItemDetails = {
  APIRequest: TaskDetailsRequest,
  APISuccess: TaskDetailsSuccess,
  APIFail: TaskDetailsFail,
  APIEndPoint: 'tasks/v1/'
}
const APIVariablesUpdate = {
  APIRequest: TaskUpdateRequest,
  APISuccess: TaskUpdateSuccess,
  APISuccess_2: TaskDetailsSuccess,
  APIFail: TaskUpdateFail,
  APIEndPoint: 'tasks/v1/'
}
const APIVariablesDelete = {
  APIRequest: TaskDeleteRequest,
  APISuccess: TaskDeleteSuccess,
  APIFail: TaskDeleteFail,
  APIEndPoint: 'tasks/v1/'
}

export const TaskCreateAPI = (item) => async (dispatch, getState) => {
  const cleandate = item.due_date === '' ? null : item.due_date;
  const cleantime = item.due_time === '' ? null : item.due_time;

  const API_Body = {
    task_name: item.task_name,
    status: item.status,
    priority: item.priority,
    due_date: cleandate,
    due_time: cleantime,
    category: item.category,
    description: item.description,
    requested_by: item.requested_by,
    task_location: item.task_location,
  }
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const TaskListAllAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState, query)
}

export const TaskGetDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const TaskUpdateAPI = (item) => async (dispatch, getState) => {
  const cleandate = item.due_date === '' ? null : item.due_date;
  const cleantime = item.due_time === '' ? null : item.due_time;

  const API_Body = {
    task_name: item.task_name,
    status: item.status,
    priority: item.priority,
    due_date: cleandate,
    due_time: cleantime,
    category: item.category,
    description: item.description,
    requested_by: item.requested_by,
    task_location: item.task_location,
  }

  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const TaskCompleteAPI = (item) => async (dispatch, getState) => {

  const API_Body = {
    task_name: item.task_name,
    status: 3
  }

  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const TaskDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

///////////// REDUCERS/////////////////////////
// State is an empty object
export const TaskCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TaskCreateRequest:
      return {
        loading: true
      }

    case TaskCreateSuccess:
      return {
        loading: false,
        success: true,
        task: action.payload
      }

    case TaskCreateFail:
      return {
        loading: false,
        error: action.payload
      }

    case TaskCreateReset:
      return {}


    default:
      return state
  }
}

export const TaskUpdateReducer = (state = { task: {} }, action) => {
  switch (action.type) {
    case TaskUpdateRequest:
      return { loading: true }

    case TaskUpdateSuccess:
      return { loading: false, success: true }

    case TaskUpdateFail:
      return { loading: false, error: action.payload }

    case TaskUpdateReset:
      return { task: {} }

    default:
      return state
  }
}

export const TaskDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TaskDeleteRequest:
      return { loading: true }

    case TaskDeleteSuccess:
      return {
        loading: false,
        success: true
      }

    case TaskDeleteFail:
      return { 
        loading: false, 
        error: action.payload }

    case TaskDeleteReset:
      return {}

    default:
      return state
  }
}

export const TaskDetailsReducer = (state = { loading: true, task: [] }, action) => {
  switch (action.type) {
    case TaskDetailsRequest:
      return {
        ...state,
        loading: true
      }

    case TaskDetailsSuccess:
      return {
        loading: false,
        success: true,
        task: action.payload
      }

    case TaskDetailsFail:
      return {
        loading: false,
        error: action.payload,
        task: 'error'
      }

    case TaskDetailsReset:
      return { task: {} }

    default:
      return state
  }
}

export const TaskListReducer = (state = { tasks: [] }, action) => {
  switch (action.type) {
    case TaskListRequest:
      return {
        loading: true
      }

    case TaskListSuccess:
      return {
        loading: false,
        success: true,
        tasks: action.payload
      }

    case TaskListFail:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}


function TaskListScreen() {
  const [dataBaseFilter, setDataBaseFilter] = useState('Open');
  const dataListParams = ('?listtype=' + dataBaseFilter )
  // console.log('dataListParams',dataListParams)

  const createAPI = TaskCreateAPI
  const listAPI = TaskListAllAPI
  const detailsAPI = TaskGetDetailsAPI
  const updateAPI = TaskUpdateAPI
  const deleteAPI = TaskDeleteAPI
  const completeAPI = TaskCompleteAPI

  const resetCreate = TaskCreateReset
  const resetUpdate = TaskUpdateReset
  const resetViewDetails = TaskDetailsReset
  const resetDelete = TaskDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const TaskCreate = useSelector(state => state.TaskCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = TaskCreate

  const TaskList = useSelector(state => state.TaskList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    tasks: dataList } = TaskList

  const TaskDetails = useSelector(state => state.TaskDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    task: dataItem } = TaskDetails

  const TaskUpdate = useSelector(state => state.TaskUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = TaskUpdate

  const TaskDelete = useSelector(state => state.TaskDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = TaskDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate : 
      loadingUpdate 
      || loadingDelete 
      || loadingDetails
    )
  )

  // const categoryList = useSelector(state => state.categoryList)
  // const { categories } = categoryList

  useEffect(() => {
    dispatch(TaskListAllAPI(dataListParams))
    dispatch(CategoryListAllAPI())
    dispatch(AddressListAllAPI())
    dispatch(TaskStatusListAllAPI())
    dispatch(TaskPriorityListAllAPI())
    dispatch(ContactsContactListSelectOptionsAPI())
  }, [])
  
  useEffect(() => {
    dispatch(listAPI(dataListParams))
  }, [dataBaseFilter])

  useEffect(() => {
    if (successUpdate) {
      // dispatch(listAPI())
      dispatch({ type: TaskUpdateReset })
    }
    if (successDelete) {
      // dispatch(listAPI())
      dispatch({ type: TaskDeleteReset })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }
  }, [successDelete, successUpdate,
    errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete])


  return (
    <ModuleHome
    formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataListParams={dataListParams}
      dataList={dataList}
      getDataList={listAPI}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      //Database filtering
      dataBaseFilterOptions={dataBaseFilterOptions}
      dataBaseFilter={dataBaseFilter}
      setDataBaseFilter={setDataBaseFilter}

      //Table Actions
      rowOnClickAction='edit'
      add={true}
      edit={true}
      view={false}
      // view={(dataitem) => navigate(moduleDefinitions.moduleLink + 'view:' + dataitem.id)}
      // edit={(dataitem) => navigate(moduleDefinitions.moduleLink + 'edit:' + dataitem.id)}
      delete={(id) => dispatch(deleteAPI(id))}
      // save={(data)=> handleSave(data)}
      complete={(dataitem) => dispatch(completeAPI(dataitem))}

      inLineAdd={false}
      inLineEdit={false}

      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}

      //View Page
      // viewTabs={viewTabs} 
      // headingName={dataItem.name}

    />
  )
}

export default TaskListScreen