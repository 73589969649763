//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'

export const moduleDefinitions = {
  moduleName: 'Acronyms',
  moduleNameSingular: 'Acronym',
  moduleLink: '/fun/sailinglog/reference/acronyms/',
  thOptionsWidth: '115px',
}

export const moduleFields = [
  {
    label: 'Acronym',
    mandatory: "true",
    fieldName: 'acronym',
    fieldType: 'text',
    addFormOrder: 1,
    editFormOrder: 1,
    tableDisplayOrder: 1,
    tableDisplayPriority: 1,
  }, {
    label: 'Definition',
    mandatory: "true",
    fieldName: 'definition',
    fieldType: 'text',
    addFormOrder: 2,
    editFormOrder: 2,
    tableDisplayOrder: 2,
    tableDisplayPriority: 2,
  // }, {
  //   label: 'Glossary',
  //   fieldName: 'glossary',
  //   fieldLongName: 'priority_name',
  //   fieldType: 'selectfromdb',
  //   fieldGroupPosition: 'Right',
  //   options: 'acronym_priorityList',
  //   addFormOrder: 3,
  //   addFormInitialValue: '3',
  //   editFormOrder: 3,
  //   tableDisplayOrder: 4,
  //   tableDisplayPriority: 4,
  }
]

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
    fieldErrorMessage = ""
  switch (fieldName) {
    case "acronym":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Acronym is required"
      } else if (value.length < 2) {
        hasError = true
        fieldErrorMessage = 'Acronym is too short'
      } else if (value.length > 50) {
        hasError = true
        fieldErrorMessage = 'Acronym is too long'
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break
      case "definition":
        if (value.trim() === "") {
          hasError = true
          fieldErrorMessage = "Definition is required"
        } else if (value.length < 2) {
          hasError = true
          fieldErrorMessage = 'Definition is too short'
        } else if (value.length > 300) {
          hasError = true
          fieldErrorMessage = 'Definition is too long'
        } else {
          hasError = false
          fieldErrorMessage = ""
        }
        break
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

///////////// Constants /////////////////////////
export const AcronymCreateRequest = 'AcronymCreateRequest'
export const AcronymCreateSuccess = 'AcronymCreateSuccess'
export const AcronymCreateFail = 'AcronymCreateFail'
export const AcronymCreateReset = 'AcronymCreateReset'

export const AcronymDetailsRequest = 'AcronymDetailsRequest'
export const AcronymDetailsSuccess = 'AcronymDetailsSuccess'
export const AcronymDetailsFail = 'AcronymDetailsFail'
export const AcronymDetailsReset = 'AcronymDetailsReset'

export const AcronymUpdateRequest = 'AcronymUpdateRequest'
export const AcronymUpdateSuccess = 'AcronymUpdateSuccess'
export const AcronymUpdateFail = 'AcronymUpdateFail'
export const AcronymUpdateReset = 'AcronymUpdateReset'

export const AcronymDeleteRequest = 'AcronymDeleteRequest'
export const AcronymDeleteSuccess = 'AcronymDeleteSuccess'
export const AcronymDeleteFail = 'AcronymDeleteFail'
export const AcronymDeleteReset = 'AcronymDeleteReset'

export const AcronymListRequest = 'AcronymListRequest'
export const AcronymListSuccess = 'AcronymListSuccess'
export const AcronymListFail = 'AcronymListFail'


///////////// API Config /////////////////////////


const APIVariablesCreate = {
  APIRequest: AcronymCreateRequest,
  APISuccess: AcronymCreateSuccess,
  APIFail: AcronymCreateFail,
  APIEndPoint: 'sailinglog/v1/acronym/add',
}
const APIVariablesListAll = {
  APIRequest: AcronymListRequest,
  APISuccess: AcronymListSuccess,
  APIFail: AcronymListFail,
  APIEndPoint: 'sailinglog/v1/acronym/list'
}
const APIVariablesItemDetails = {
  APIRequest: AcronymDetailsRequest,
  APISuccess: AcronymDetailsSuccess,
  APIFail: AcronymDetailsFail,
  APIEndPoint: 'sailinglog/v1/acronym/'
}
const APIVariablesUpdate = {
  APIRequest: AcronymUpdateRequest,
  APISuccess: AcronymUpdateSuccess,
  APISuccess_2: AcronymDetailsSuccess,
  APIFail: AcronymUpdateFail,
  APIEndPoint: 'sailinglog/v1/acronym/'
}
const APIVariablesDelete = {
  APIRequest: AcronymDeleteRequest,
  APISuccess: AcronymDeleteSuccess,
  APIFail: AcronymDeleteFail,
  APIEndPoint: 'sailinglog/v1/acronym/'
}

export const AcronymCreateAPI = (item) => async (dispatch, getState) => {
  const API_Body = {
    acronym: item.acronym,
    definition: item.definition,
    glossary: item.glossary,
  }
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const AcronymListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const AcronymDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const AcronymUpdateAPI = (item) => async (dispatch, getState) => {
  const API_Body = {
    acronym: item.acronym,
    definition: item.definition,
    glossary: item.glossary,
  }

  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}


export const AcronymDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

///////////// REDUCERS/////////////////////////
// State is an empty object
export const SailingLogAcronymCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case AcronymCreateRequest:
      return {
        loading: true
      }

    case AcronymCreateSuccess:
      return {
        loading: false,
        success: true,
        Acronym: action.payload
      }

    case AcronymCreateFail:
      return {
        loading: false,
        error: action.payload
      }

    case AcronymCreateReset:
      return {}


    default:
      return state
  }
}

export const SailingLogAcronymUpdateReducer = (state = { Acronym: {} }, action) => {
  switch (action.type) {
    case AcronymUpdateRequest:
      return { loading: true }

    case AcronymUpdateSuccess:
      return { loading: false, 
        success: true,
        Acronym: action.payload }

    case AcronymUpdateFail:
      return { loading: false, error: action.payload }

    case AcronymUpdateReset:
      return { acronym: {} }

    default:
      return state
  }
}

export const SailingLogAcronymDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case AcronymDeleteRequest:
      return { loading: true }

    case AcronymDeleteSuccess:
      return {
        loading: false,
        success: true
      }

    case AcronymDeleteFail:
      return { loading: false, error: action.payload }

    case AcronymDeleteReset:
      return {}

    default:
      return state
  }
}

export const SailingLogAcronymDetailsReducer = (state = { loading: true, Acronym: [] }, action) => {
  switch (action.type) {
    case AcronymDetailsRequest:
      return {
        ...state,
        loading: true
      }

    case AcronymDetailsSuccess:
      return {
        loading: false,
        success: true,
        Acronym: action.payload
      }

    case AcronymDetailsFail:
      return {
        loading: false,
        error: action.payload,
        Acronym: 'error'
      }

    case AcronymDetailsReset:
      return { acronym: {} }

    default:
      return state
  }
}

export const SailingLogAcronymListReducer = (state = { Acronym: [] }, action) => {
  switch (action.type) {
    case AcronymListRequest:
      return {
        loading: true
      }

    case AcronymListSuccess:
      return {
        loading: false,
        success: true,
        Acronym: action.payload
      }

    case AcronymListFail:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}


function SailingLogAcronym() {
  const createAPI = AcronymCreateAPI
  const listAPI = AcronymListAllAPI
  const detailsAPI = AcronymDetailsAPI
  const updateAPI = AcronymUpdateAPI
  const deleteAPI = AcronymDeleteAPI

  const resetCreate = AcronymCreateReset
  const resetUpdate = AcronymUpdateReset
  const resetViewDetails = AcronymDetailsReset
  const resetDelete = AcronymDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const SailingLogAcronymCreate = useSelector(state => state.SailingLogAcronymCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = SailingLogAcronymCreate

  const SailingLogAcronymList = useSelector(state => state.SailingLogAcronymList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    Acronym: dataList } = SailingLogAcronymList

  const SailingLogAcronymDetails = useSelector(state => state.SailingLogAcronymDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    Acronym: dataItem } = SailingLogAcronymDetails

  const SailingLogAcronymUpdate = useSelector(state => state.SailingLogAcronymUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = SailingLogAcronymUpdate

  const SailingLogAcronymDelete = useSelector(state => state.SailingLogAcronymDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = SailingLogAcronymDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate : loadingUpdate || loadingDelete || loadingDetails
    )
  )

  // const categoryList = useSelector(state => state.categoryList)
  // const { categories } = categoryList

  useEffect(() => {
    dispatch(listAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: AcronymUpdateReset })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: AcronymDeleteReset })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }
  }, [successDelete, successUpdate,
    errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete])


  return (
    <ModuleHome
    formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
      // Shared items
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      //Table Actions
      rowOnClickAction='edit'
      add={true}
      edit={true}
      view={false}
      delete={(id) => dispatch(deleteAPI(id))}
      // save={(data)=> handleSave(data)}

      inLineAdd={false}
      inLineEdit={false}

      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      getDataList={listAPI}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}

      //View Page
      // viewTabs={viewTabs} 
      // headingName={dataItem.name}

    />
  )
}

export default SailingLogAcronym