// Inventory Items Config
//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../components/containers/moduleHome'
import {InventoryCategoryListAllAPI} from './settings/category'
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../APIs/genericAPIs'

export const moduleDefinitions = {
  moduleName: 'Inventory - Items',
  moduleNameSingular: 'Inventory Item',
  moduleLink: '/inventory/item/',
  thOptionsWidth: '120px',
}

export const statusOptions = [
  { id: "Active", name: "Active" },
  { id: "Proposed", name: "Proposed" },
  { id: "Disposed", name: "Disposed" },
]

export const moduleFields = [
  {
    label: 'Item Name', 
    mandatory: 'true', 
    fieldName: 'name',
    fieldType: 'text',
    addFormOrder: 1,
    editFormOrder: 1,
    tableDisplayOrder: 1,
    tableDisplayPriority: 1,
  }, {
    label: 'Make',
    fieldName: 'make',
    fieldType: 'text',
    fieldGroupPosition: 'Left',
    addFormOrder: 2,
    editFormOrder: 2,
    tableDisplayOrder: 2,
    tableDisplayPriority: 2,
  },{
    label: 'Model',
    fieldName: 'model',
    fieldType: 'text',
    fieldGroupPosition: 'Right',
    addFormOrder: 3,
    editFormOrder: 3,
    tableDisplayOrder: 4,
    tableDisplayPriority: 4,
  }, {
    label: 'Category',
    fieldName: 'category',
    fieldLongName: 'category_name',
    fieldType: 'selectfromdb',
    options: 'InventoryCategoryList',
    addFormOrder: 3,
    editFormOrder: 3,
    tableDisplayOrder: 4,
    tableDisplayPriority: 4,
  }, {
    label: 'Colour',
    fieldName: 'colour',
    fieldType: 'text',
    fieldGroupPosition: 'Left',
    addFormOrder: 40,
    editFormOrder: 40,
    tableDisplayOrder: 6,
    tableDisplayPriority: 5,
  },{
    label: 'Size',
    fieldName: 'size',
    fieldType: 'text',
    fieldGroupPosition: 'Right',
    addFormOrder: 50,
    editFormOrder: 50,
    tableDisplayOrder: 6,
    tableDisplayPriority: 5,
  },{
    label: 'Part number',
    fieldName: 'part_number',
    fieldType: 'text',
    fieldGroupPosition: 'Left',
    addFormOrder: 60,
    editFormOrder: 60,
    tableDisplayOrder: 3,
    tableDisplayPriority: 3,
  },{
    label: 'Serial No.',
    fieldName: 'serial_no',
    fieldGroupPosition: 'Right',
    fieldType: 'text',
    addFormOrder: 70,
    editFormOrder:70,
    tableDisplayOrder: 6,
    tableDisplayPriority: 5,
  },{
    label: 'Purchased Date',
    fieldName: 'purchased_date',
    fieldType: 'date',
    fieldGroupPosition: 'Left',
    addFormOrder: 80,
    editFormOrder: 80,
    tableDisplayOrder: 5,
    tableDisplayPriority: 4,
  },{
  //   label: 'Purchased Currency',
  //   fieldName: 'purchased_cost',
  //   fieldGroupPosition: 'Right',
  //   fieldType: 'currency',
  //   addFormOrder: 90,
  //   editFormOrder: 90,
  // },{
    label: 'Purchased Cost',
    fieldName: 'purchased_cost',
    fieldGroupPosition: 'Right',
    fieldType: 'currency',
    addFormOrder: 90,
    editFormOrder: 90,
  },{
    label: 'Purchased From',
    fieldName: 'purchased_from',
    fieldType: 'text',
    addFormOrder: 100,
    editFormOrder: 100,
  },{
    label: 'Notes',
    fieldName: 'notes',
    fieldType: 'textarea',
    rows:"4",
    addFormOrder:110,
    editFormOrder: 110,
  },
]

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
      fieldErrorMessage = ""
  switch (fieldName) {
    case "name":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Name is required"
      } else if (value.length<2) {
        hasError = true
        fieldErrorMessage = 'Name is too short'    
      } else if (value.length>50) {
        hasError = true
        fieldErrorMessage = 'Name is too long'   
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

export const InventoryItemCreateRequest = 'InventoryItemCreateRequest'
export const InventoryItemCreateSuccess = 'InventoryItemCreateSuccess'
export const InventoryItemCreateFail = 'InventoryItemCreateFail'
export const InventoryItemCreateReset = 'InventoryItemCreateReset'

export const InventoryItemDetailsRequest = 'InventoryItemDetailsRequest'
export const InventoryItemDetailsSuccess = 'InventoryItemDetailsSuccess'
export const InventoryItemDetailsFail = 'InventoryItemDetailsFail'
export const InventoryItemDetailsReset = 'InventoryItemDetailsReset'

export const InventoryItemUpdateRequest = 'InventoryItemUpdateRequest'
export const InventoryItemUpdateSuccess = 'InventoryItemUpdateSuccess'
export const InventoryItemUpdateFail = 'InventoryItemUpdateFail'
export const InventoryItemUpdateReset = 'InventoryItemUpdateReset'

export const InventoryItemDeleteRequest = 'InventoryItemDeleteRequest'
export const InventoryItemDeleteSuccess = 'InventoryItemDeleteSuccess'
export const InventoryItemDeleteFail = 'InventoryItemDeleteFail'
export const InventoryItemDeleteReset = 'InventoryItemDeleteReset'

export const InventoryItemList_MYRequest = 'InventoryItemList_MYRequest'
export const InventoryItemList_MYSuccess = 'InventoryItemList_MYSuccess'
export const InventoryItemList_MYFail = 'InventoryItemList_MYFail'
export const InventoryItemList_MYReset = 'InventoryItemList_MYReset'

export const InventoryItemListRequest = 'InventoryItemListRequest'
export const InventoryItemListSuccess = 'InventoryItemListSuccess'
export const InventoryItemListFail = 'InventoryItemListFail'

const APIVariablesCreate = {
  APIRequest: InventoryItemCreateRequest,
  APISuccess: InventoryItemCreateSuccess,
  APIFail: InventoryItemCreateFail,
  APIEndPoint: 'inventory/v1/item/add',
}
const APIVariablesListAll = {
  APIRequest: InventoryItemListRequest,
  APISuccess: InventoryItemListSuccess,
  APIFail: InventoryItemListFail,
  APIEndPoint: 'inventory/v1/item/list'
}
const APIVariablesItemDetails = {
  APIRequest: InventoryItemDetailsRequest,
  APISuccess: InventoryItemDetailsSuccess,
  APIFail: InventoryItemDetailsFail,
  APIEndPoint: 'inventory/v1/item/'
}
const APIVariablesUpdate = {
  APIRequest: InventoryItemUpdateRequest,
  APISuccess: InventoryItemUpdateSuccess,
  APISuccess_2: InventoryItemDetailsSuccess,
  APIFail: InventoryItemUpdateFail,
  APIEndPoint: 'inventory/v1/item/'
}
const APIVariablesDelete = {
  APIRequest: InventoryItemDeleteRequest,
  APISuccess: InventoryItemDeleteSuccess,
  APIFail: InventoryItemDeleteFail,
  APIEndPoint: 'inventory/v1/item/'
}

export const InventoryItemCreateAPI = (item) => async (dispatch, getState) => {

  const cleandate = item.purchased_date === '' ? null : item.purchased_date;

  const API_Body = {
    status: 'Active',
    name: item.name,
    make: item.make,
    model: item.model,
    part_number: item.part_number,
    colour: item.colour,
    size: item.size,
    notes: item.notes,
    serial_no: item.serial_no,
    purchased_date: cleandate,
    purchased_from: item.purchased_from,
    purchased_cost: item.purchased_cost
  }
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const InventoryItemListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const InventoryItemDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const InventoryItemUpdateAPI = (item) => async (dispatch, getState) => {

  const cleandate = item.purchased_date === '' ? null : item.purchased_date;

  const API_Body = {
    status: item.status,
    name: item.name,
    make: item.make,
    model: item.model,
    part_number: item.part_number,
    colour: item.colour,
    size: item.size,
    notes: item.notes,
    serial_no: item.serial_no,
    purchased_date: cleandate,
    purchased_from: item.purchased_from,
    purchased_cost: item.purchased_cost
  }
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const InventoryItemDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

  
  // State is an empty object
  export const InventoryItemCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case InventoryItemCreateRequest:
            return {
                loading: true
            }
  
        case InventoryItemCreateSuccess:
            return {
                loading: false,
                success: true,
                InventoryItem: action.payload
            }
  
        case InventoryItemCreateFail:
            return {
                loading: false,
                error: action.payload
            }
  
        case InventoryItemCreateReset:
            return {}
  
  
        default:
            return state
    }
  }
  
  export const InventoryItemUpdateReducer = (state = { InventoryItem: {} }, action) => {
    switch (action.type) {
        case InventoryItemUpdateRequest:
            return { loading: true }
  
        case InventoryItemUpdateSuccess:
            return { loading: false, success: true }
  
        case InventoryItemUpdateFail:
            return { loading: false, error: action.payload }
  
        case InventoryItemUpdateReset:
            return { InventoryItem: {} }
  
        default:
            return state
    }
  }
  
  export const InventoryItemDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case InventoryItemDeleteRequest:
            return { loading: true }
  
        case InventoryItemDeleteSuccess:
            return { 
              loading: false, 
              success: true }
  
        case InventoryItemDeleteFail:
            return { loading: false, error: action.payload }
  
        case InventoryItemDeleteReset:
              return { }
  
        default:
            return state
    }
  }
  
  export const InventoryItemDetailsReducer = (state = { loading: true, InventoryItem: [] }, action) => {
    switch (action.type) {
        case InventoryItemDetailsRequest:
            return {
                ...state,
                loading: true
            }
  
        case InventoryItemDetailsSuccess:
            return {
                loading: false,
                InventoryItem: action.payload
            }
  
        case InventoryItemDetailsFail:
            return {
                loading: false,
                error: action.payload
            }
  
  
        default:
            return state
    }
  }
    
  export const InventoryItemListReducer = (state = { InventoryItem: [] }, action) => {
    switch (action.type) {
        case InventoryItemListRequest:
            return {
                loading: true
            }
  
        case InventoryItemListSuccess:
            return {
                loading: false,
                fieldOptionValues: [...action.payload],
                InventoryItem: action.payload
            }
  
        case InventoryItemListFail:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
  }

function InventoryItemListScreen() {
  const createAPI = InventoryItemCreateAPI
  const listAPI = InventoryItemListAllAPI
  const detailsAPI = InventoryItemDetailsAPI
  const updateAPI = InventoryItemUpdateAPI
  const deleteAPI = InventoryItemDeleteAPI

  const resetCreate = InventoryItemCreateReset
  const resetUpdate = InventoryItemUpdateReset
  const resetViewDetails = InventoryItemDetailsReset
  const resetDelete = InventoryItemDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const InventoryItemCreate = useSelector(state => state.InventoryItemCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = InventoryItemCreate

  const InventoryItemList = useSelector(state => state.InventoryItemList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    InventoryItem: dataList } = InventoryItemList

  const InventoryItemDetails = useSelector(state => state.InventoryItemDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    InventoryItem: dataItem } = InventoryItemDetails

  const InventoryItemUpdate = useSelector(state => state.InventoryItemUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = InventoryItemUpdate

  const InventoryItemDelete = useSelector(state => state.InventoryItemDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = InventoryItemDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingList || loadingDetails
    )
  )

  useEffect(() => {
    dispatch(listAPI())
    dispatch(InventoryCategoryListAllAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
     if (errorLoadingList) {setServerErrorMessage(errorLoadingList)}
    if (errorCreate) {setServerErrorMessage(errorCreate)}
    if (errorUpdate) {setServerErrorMessage(errorUpdate)}
    if (errorGetDetails) {setServerErrorMessage(errorGetDetails)}
    if (errorDelete) {setServerErrorMessage(errorDelete)}
  }, [successDelete, successUpdate,
    errorLoadingList,errorCreate,errorUpdate,errorGetDetails, errorDelete])


  return (
    <ModuleHome
    formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
      // Shared items
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      rowOnClickAction='edit'
      add={true}
      edit={true}
      view={false}
      delete={(id) => dispatch(deleteAPI(id))}

       //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      getDataList={listAPI}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}
  
        />

  )
}

export default InventoryItemListScreen