import FixedContainer from '../../components/containers/fixedContatiner';
import { Link } from 'react-router-dom';

const Help = () => {
  const heading = 'Help & Support'
  const contents = (<>
    <h3>Support Centre</h3>
    <br></br>
    <Link to='/support/tickets/list'>View exisiting tickets, report a bug or request a feature</Link>
    <br></br>

    <h3>Knowledge Library</h3>
    <ul>
      <li>Glossary</li>
      <li>FAQs</li>
      <li>How to Guides</li>
    </ul>
  </>)

  return (
    <FixedContainer
      heading={heading}
      contents={contents}
    />
  );
};
export default Help;