import style from '../sailingLog.module.css'
import { useNavigate } from 'react-router-dom'
import { MdInfoOutline } from "react-icons/md";


const Square = (props) => {
  const navigate = useNavigate()

  return (
    <>
      {props.disabled ?
        <div className={`${style.gridItem} ${style.disabled} container`} >
          <div className={style.squareIcon}>
            {props.icon}
          </div>
          <div className={style.squareFont}>
            {props.name}
          </div>
        </div> :
        <div className={style.gridItem}
          onClick={() => navigate(props.link)}
        >
          {props.hoverText &&
            <div className={style.squareHelpTextContainer}>
              <div className={style.squareHelpTextIcon}>
                <MdInfoOutline />
              </div>
              <span class={style.squareHelpText}>{props.hoverText}</span>
            </div>
          }
          <div className={style.squareIcon}>
            {props.icon}
          </div>
          <div className={style.squareFont}>
            {props.name}
          </div>
        </div>
      }

    </>
  );
};
export default Square;

