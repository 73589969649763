import { useEffect, useState } from 'react';
// style
import calendarStyle from '../../style/calendar.module.css';
// components
import GenericButton from '../buttons/genericButton'

//hooks
import { datetime, ISOtoJSDate, padLeadingZeros } from '../../hooks/useDateTime'
import { datesGenerator } from '../../hooks/useDateTime'
import { MdKeyboardDoubleArrowLeft, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowRight, MdToday } from 'react-icons/md';

const CalendarTable = (props) => {
  // console.log('props', props)
  // console.log('filteredData', props.filteredData)
  // const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat','Sun']
  // const days = ['M' ,'T', 'W', 'T', 'F', 'S','S']
  const days = [
    { id: 0, digit: 'M', short: 'Mon', full: 'Monday' },
    { id: 1, digit: 'T', short: 'Tue', full: 'Tuesday' },
    { id: 2, digit: 'W', short: 'Wed', full: 'Wednesday' },
    { id: 3, digit: 'T', short: 'Thu', full: 'Thursday' },
    { id: 4, digit: 'F', short: 'Fri', full: 'Friday' },
    { id: 5, digit: 'S', short: 'Sat', full: 'Saturday' },
    { id: 6, digit: 'S', short: 'Sun', full: 'Sunday' },
  ]
  const monthPairs = [
    { id: 0, name: 'Jan' },
    { id: 1, name: 'Feb' },
    { id: 2, name: 'Mar' },
    { id: 3, name: 'Apr' },
    { id: 4, name: 'May' },
    { id: 5, name: 'Jun' },
    { id: 6, name: 'Jul' },
    { id: 7, name: 'Aug' },
    { id: 8, name: 'Sep' },
    { id: 9, name: 'Oct' },
    { id: 10, name: 'Nov' },
    { id: 11, name: 'Dec' },
  ]
  const years = [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028];

  const [selectedDate, setSelectedDate] = useState(new Date().toLocaleDateString());
  const [todaysDate, setTodaysDate] = useState(new Date().toLocaleDateString());

  const [dates, setDates] = useState([]);

  useEffect(() => {
    const body = {
      month: props.calendar.month,
      year: props.calendar.year
    };

    const { dates, nextMonth, nextYear, previousMonth, previousYear } = datesGenerator(body);

    setDates([...dates]);
    props.setCalendar({
      ...props.calendar,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear,
    });
    // props.setFilterRangeStart(previousYear + '-' + padLeadingZeros(previousMonth) + '-01')
    // props.setFilterRangeEnd(nextYear + '-' + padLeadingZeros(nextMonth) + '-28')
  }, [])

  const calculateFilterRangeStart = (previousYear, previousMonth) => {
    // console.log('calculateFilterRangeStart', previousYear, previousMonth)
    const month = previousMonth === 0 ? 12 : previousMonth
    const year = previousMonth === 0 ? previousYear - 1 : previousYear
    // console.log('calculateFilterRangeStart', year, '-', month)
    props.setFilterRangeStart(year + '-' + padLeadingZeros(month) + '-01')
  }

  const calculateFilterRangeEnd = (nextYear, nextMonth) => {
    const month = nextMonth === 0 ? 1 : nextMonth
    // const year = nextMonth === 0 ? nextYear +1 : nextYear
    // console.log('calculateFilterRangeEnd', nextYear, '-', month)
    props.setFilterRangeEnd(nextYear + '-' + padLeadingZeros(month) + '-28')
  }

  const onClickNext = () => {
    const body = { month: props.calendar.nextMonth, year: props.calendar.nextYear };
    const { dates, nextMonth, nextYear, previousMonth, previousYear } = datesGenerator(body);

    setDates([...dates]);
    props.setCalendar({
      ...props.calendar,
      month: props.calendar.nextMonth,
      year: props.calendar.nextYear,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear,
    });
    calculateFilterRangeStart(previousYear, previousMonth)
    calculateFilterRangeEnd(nextYear, nextMonth)
  }

  const onClickPrevious = () => {
    const body = { month: props.calendar.previousMonth, year: props.calendar.previousYear };
    const { dates, nextMonth, nextYear, previousMonth, previousYear } = datesGenerator(body);

    setDates([...dates]);
    props.setCalendar({
      ...props.calendar,
      month: props.calendar.previousMonth,
      year: props.calendar.previousYear,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear
    });
    calculateFilterRangeStart(previousYear, previousMonth)
    calculateFilterRangeEnd(nextYear, nextMonth)
  }

  const onClickToday = () => {
    const todaysDate = new Date()
    const monthToday = todaysDate.getMonth()
    const yearToday = todaysDate.getFullYear()
    const body = { month: monthToday, year: yearToday };
    const { dates, nextMonth, nextYear, previousMonth, previousYear } = datesGenerator(body);
    setSelectedDate(todaysDate.toLocaleDateString())
    setDates([...dates]);
    props.setCalendar({
      ...props.calendar,
      month: monthToday,
      year: yearToday,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear
    });
    calculateFilterRangeStart(previousYear, previousMonth - 1)
    calculateFilterRangeEnd(nextYear, nextMonth)
  }

  const onSelectMonth = (value) => {
    const selectedMonth = parseInt(value)
    const body = { month: selectedMonth, year: props.calendar.year };
    const { dates, nextMonth, nextYear, previousMonth, previousYear } = datesGenerator(body);
    setSelectedDate('')
    setDates([...dates]);
    props.setCalendar({
      ...props.calendar,
      month: selectedMonth,
      year: props.calendar.year,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear
    });
    calculateFilterRangeStart(previousYear, previousMonth)
    calculateFilterRangeEnd(nextYear, nextMonth)
  }

  const onSelectYear = (value) => {
    const selectedYear = parseInt(value)
    const body = { month: props.calendar.month, year: selectedYear };
    const { dates, nextMonth, nextYear, previousMonth, previousYear } = datesGenerator(body);
    setSelectedDate('')
    setDates([...dates]);
    props.setCalendar({
      ...props.calendar,
      month: props.calendar.month,
      year: selectedYear,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear
    });
    calculateFilterRangeStart(previousYear, previousMonth)
    calculateFilterRangeEnd(nextYear, nextMonth)
  }

  // const onSelectDate = (date) => {
  //   setSelectedDate(new Date(date.year, date.month, date.date))
  // }

  // Moved to useDateTime Hook
  // const convertToDateObject = (ISODateString) => {
  //   if (ISODateString === undefined || ISODateString === null) {
  //     return ''
  //   } else {
  //     const year = ISODateString.substring(0, 4)
  //     const month = ISODateString.substring(5, 7)
  //     const day = ISODateString.substring(8, 10)
  //     const dateObject = new Date(year, month - 1, day)
  //     // console.log('convertToDateObject - Before:', ISODateString, 'After:', dateObject)
  //     return dateObject
  //   }
  // }

  const calculateColumnSpan = (endDate, startDate) => {
    const dateDiff = ISOtoJSDate(endDate) - ISOtoJSDate(startDate)
    const numberOfDays = dateDiff / 86400000
    if (numberOfDays === 0 || numberOfDays < 1)
      return 1
    else {
      return numberOfDays
    }
  }



  const CalendarControls = () => {
    return (
      <>
        {/* // style={{ float: 'left', textAlign: 'left' }} */}

        <GenericButton
          name='Previous'
          style={{ float: 'left' }}
          onClick={onClickPrevious}
          icon={MdKeyboardArrowLeft}
        />
        <GenericButton
          name='Next'
          style={{ float: 'right' }}
          onClick={onClickNext}
          icon={MdKeyboardArrowRight}
          iconPosition='Left'
        />
        {props.calendar.month != parseInt(new Date().toLocaleDateString().substring(3, 5)) - 1 &&
          <GenericButton
            name='Today'
            className={calendarStyle.todayButton}
            style={{ float: 'right', }}
            onClick={onClickToday}
            icon={MdToday}
            iconSize={'20px'}
            // iconClassName={calendarStyle.TodayButton}
            // iconPadding={'6px'} 
          />
        }



        {/* <div
            className={`${calendarStyle.button} ${calendarStyle.Left}`}
            onClick={onClickNext}
            style={{ float: 'right', textAlign: 'right' }}

          >                         <span
            className={calendarStyle.buttonText}
          >Next
            </span>                 <MdKeyboardArrowRight
              style={{
                verticalAlign: 'middle',
                fontSize: '30px'
              }}
            />

        </div > */}


        <div className={calendarStyle.select}>
          <select
            value={props.calendar.month}
            onChange={(e) => onSelectMonth(e.target.value)}
          >
            {monthPairs.map((option) => (
              <>
                <option key={option.id}
                  value={option.id}
                  className={calendarStyle.selectOptions}
                >
                  {option.name}
                </option>
              </>
            ))}
          </select>

          <select
            value={props.calendar.year}
            onChange={(e) => onSelectYear(e.target.value)}
          >
            {years.map((option) => (
              <>
                <option key={option}
                  value={option}
                  className={calendarStyle.selectOptions}
                >
                  {option}
                </option>
              </>
            ))}
          </select>

          {/* {years[props.calendar.year]} */}
        </div>

      </>
    )
  }

  const calendarDataItem = (day) => {
    const itemHtml = (item) => {
      return (
        <div
          className={calendarStyle.item}
          // colspan={calculateColumnSpan(item.end_date, item.start_date)}
          onClick={(e) => {
            e.stopPropagation(); //stops the parent onClick event
            props.setStartDate(''); //stops default start date from populating the edit form.
            props.onClickAction === 'edit' ? props.editActions(item) :
              props.onClickAction === 'view' && props.view(item)
          }}
        >
          {item.name}
        </div>
      )
    }

    return (
      props.filteredData
        // .filter((item) => item.start_date == day.ISODate)
        .map((item) => {

          const JSStartDate = ISOtoJSDate(item.start_date)
          const JSEndDate = ISOtoJSDate(item.end_date)
          const datecalc = ISOtoJSDate(item.start_date) >= day.jsDate && ISOtoJSDate(item.end_date) <= day.jsDate
          // console.log(item.name, 'start date:',JSStartDate, ' end date:', JSEndDate, 'cell date:', day.jsDate, 'calc:', datecalc)
          if (item.start_date == day.ISODate) { //If startdate of event equal to cell date return the calendar item
            return itemHtml(item)
          } else if (JSStartDate < day.jsDate) { //If the event has started before today  
            if (JSEndDate >= day.jsDate) {// check if the event finishs after the cells date. 
              return itemHtml(item)
            }
          }
        }
        )
    )
  }

  const calendarDayCell = (day) => {
    return (
      <td
        key={JSON.stringify(day)}
        className={calendarStyle.day}
        style={{ backgroundColor: day.localDate == todaysDate && 'lightgreen' }}
        onClick={() => {
          setSelectedDate(day.localDate);
          props.setStartDate(day.ISODate)
          props.setFormTypeAction('Add');
          props.setFormModalVisibility(true)
        }
        }
      >
        {day.date}
        {calendarDataItem(day)}
      </td>
    )
  }
  // console.log('dates',dates)
  return (
    <div style={{ width: '100%' }}>
      <div className={calendarStyle.container}>
        <CalendarControls
        />
        <br></br>
        <div>
          <table className={calendarStyle.table}>
            <tbody>
              <tr className={calendarStyle.tr}>
                {days.map((day) => (
                  <th 
                  key={day.id}
                  className={calendarStyle.th}
                  >
                    {day.short}
                  </th>
                ))}
              </tr>
              {dates.length > 0 && dates.map((week) => (
                <tr key={JSON.stringify(week[0])}
                  className={calendarStyle.tr}>
                  {week.map((day) => calendarDayCell(day))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CalendarTable;