// React
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux'
import { MdDeleteOutline, MdDeleteForever, MdCancel, MdOutlineCancel } from 'react-icons/md';
// Style
import formstyle from '../../../style/form.module.css';
// import tablestyle from '../../style/table.module.css';
//Components
import Loader from '../../loading/spinnerSmall';
import ListOfSelectValues from './listOfSelectValues'

function SelectFromDBField(props) {
  const dataList = useSelector(state => state[props.options])
  // const [newSelectValue, setNewSelectValue] = useState('')
  // console.log(' dataList', dataList)
  const [fieldsLoading, setFieldsLoading] = useState(true)

  if (dataList !== undefined) {
    var {
      loading: loading,
      error: error, } = dataList
  }
  // console.log('dataList.fieldOptionValues',props.fieldName,dataList.fieldOptionValues)
  // console.log(' props.value', props.value)
  // console.log('props.addFormConditionalSelectBasedOn',props.addFormConditionalSelectBasedOn)
  // console.log('props.conditionalSelectFieldValue',props.conditionalSelectFieldValue)

  //  Used for multi select options
  const displayValues = (
    dataList === undefined ? '' :
    dataList.fieldOptionValues === undefined ? '' :
      props.addFormConditionalSelectBasedOn ?
        dataList.fieldOptionValues
          .filter((field) => field[props.addFormConditionalSelectLinkField] == props.conditionalSelectFieldValue)
          .map((field => ({ id: field.id, name: field.name })))
        : dataList.fieldOptionValues
  )

  // console.log('object values',Object.values(props.value))
  // console.log('testing',
  // dataList.fieldOptionValues === undefined ? '' :
  //   Array.isArray(props.value) && dataList.fieldOptionValues
  //     .filter(item =>
  //       props.value.includes(item.id)
  //     )
  //   )

  // console.log('testing2',
  // dataList.fieldOptionValues
  // .filter((item) => item.id==2))

  const selectValues = (
    dataList === undefined ? '' :
    dataList.fieldOptionValues === undefined ? '' :
      props.addFormConditionalSelectBasedOn ?
        dataList.fieldOptionValues
          .filter((field) => field[props.addFormConditionalSelectLinkField] == props.conditionalSelectFieldValue)
          .map((field => ({ id: field.id, name: field.name })))
        :
        Array.isArray(props.value) ?
          dataList.fieldOptionValues
            .filter(item =>
              !props.value.includes(item.id)
            )
          :
          dataList.fieldOptionValues
  )

  useEffect(() => {
    setFieldsLoading(false)
  }, [props.value])

  const selectField = (selectValues) => {
    return (
      <>
        <select
          // Standard
          key={props.fieldName}
          id={props.id}
          name={props.name}
          type={props.type}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          disabled={props.disabled}

          // Optional
          mandatory={props.mandatory}

        // Type Specifics 
        // multiple={props.multiple}
        >
          <option
            key='0'
            hidden>
            Select...
          </option>
          {selectValues.map((option) => (
            <>
              {/* {console.log('option.id',props.fieldName,option.id,option.name)} */}
              <option key={option.id}
                value={option.id}>
                {option.name}
              </option>
            </>
          ))}
        </select>
      </>
    )
  }

  const selectMulitpleField = (selectValues) => {
    return (
      <>
        {/* {console.log('index', props.fieldName, props.value)} */}
        <select
          // Standard
          key={props.fieldName}
          id={props.id}
          name={props.name}
          type={props.type}
          // value={newSelectValue}
          // onChange={e => setNewSelectValue(e.target.value)}
          onChange={(e) => addMultiSelectFieldValue(e.target.value, props.fieldName)}
          onBlur={props.onBlur}
          disabled={props.disabled}

          // Optional
          mandatory={props.mandatory}
        >
          <option
            key='0'
            hidden>
            Select...
          </option>
          {selectValues.map((option) => (
            <>
              <option key={option.id}
                value={option.id}>
                {option.name}
              </option>
            </>
          ))}
        </select>
      </>
    )
  }

  const fieldName = (value) => {
    if (displayValues === undefined) { return 'No select values defined' }
    else {
      const selectValueItem = displayValues.find(e => e.id === value)
      // console.log('name', selectValueItem)
      // console.log('selectValues', selectValues)
      if (selectValueItem === undefined) { return '' } else { return selectValueItem.name }
    }
  }

  const addMultiSelectFieldValue = (e, field) => {
    // e.preventDefault();
    // console.log('addMultiSelectFieldValue', field, newSelectValue)
    props.handleMultiSelectAdd(field, e)
    // setNewSelectValue('')

  }

  const deleteMultiSelectFieldValue = (e, value) => {
    e.preventDefault();
    console.log('deleteMultiSelectFieldValue', props.fieldName, value)
    props.handleMultiSelectDelete(props.fieldName, value)
  }

  return (
    dataList === undefined ?
      <p className={formstyle.errormessage}
        style={{ margin: '0px' }}>
        System Error: Not able to get select values</p> : loading ? <Loader /> :
        <>
          {!displayValues.length ?
            props.addFormConditionalSelectBasedOn ?
              <p style={{ margin: '0px' }}>
                Nothing to select</p> :

              <p className={formstyle.warningmessage}
                style={{ margin: '0px' }}
              >
                Warning: No Select Values</p> :
            <>
              {props.multipleSelect ?
                <>
                  <div>
                    {props.multipleSelect ?
                      selectMulitpleField(selectValues) :
                      selectField(selectValues)}
                    {fieldsLoading ? <Loader /> :
                      <>
                        <ListOfSelectValues
                          deleteMultiSelectFieldValue={deleteMultiSelectFieldValue}
                          {...props}
                          fieldName={fieldName} />
                        {/* {listOfSelectValues(props.value)} */}
                      </>
                    }
                  </div>
                </>
                :
                selectField(selectValues)
              }
            </>
          }
        </>
  )
}

export default SelectFromDBField