//  React
import { useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdHelpOutline, MdOutlineSettings } from 'react-icons/md';
//Style
import navstyle from './../layout.module.css';
//Hooks
import useModal from '../../hooks/useModal';
//Components
import Avatar from '../../components/avatar';
import NavModal from '../../components/modals/userNavModal';
import DropDownMenu from '../../components/nav/dropDownMenu';
// Static Data
import whiteavatar from './whiteavatar.png';
import greyavatar from './greyavatar.png';
import mmcynavlogo from '../../static/PrimaryWhiteNoWordsBorder.png';
import mmcynavlogoonhover from '../../static/PrimaryColourTransparentNoWordsBorder.png';
// Other
import { signout } from '../../APIs/userAPIConfig';

const TopNav = () => {
  // User authentication
  const user_Login = useSelector(state => state.user_Login)
  const { userInfo } = user_Login
  const isLoggedIn = userInfo;

  const dispatch = useDispatch()
  const logoutHandler = () => {
    const source = 'user'
    dispatch(signout(source))
  };

  // User Modal
  const { show, toggle } = useModal();
  const chip = (
    <div className={navstyle.chip}>
      <img className={navstyle.show} id="userImgGrey" src={greyavatar} alt="user avatar" onClick={toggle} />
      <img className={navstyle.hide} id="userImgWhite" src={whiteavatar} alt="user avatar" onClick={toggle} />
      <Avatar
        user={{ name: 'Mark', imageID: 'OKS67lh' }}
        toggle={toggle}
      />
    </div>
  );

  //main nav menu
  const [menuToggle, setMenuToggle] = useState(false);
  const closeMenu = () => setMenuToggle(false);
  const handleNavMenuClick = () => {
    setMenuToggle(!menuToggle)
  }

  // MMCY Logo Hover over.
  const [isLogoShown, setIsLogoShown] = useState(false);

  const nav_menu_signed_out = (
    <>
      <div className={navstyle.navLogoDiv}>
        <a href='https://mmcy.uk'
          onClick={closeMenu}
          onMouseEnter={() => setIsLogoShown(true)}
          onMouseLeave={() => setIsLogoShown(false)}>
          {isLogoShown ?
            <img
              className={navstyle.navLogo}
              src={mmcynavlogoonhover}
              alt="MMCY Business Solutions Logo" />
            :
            <img
              className={navstyle.navLogo}
              src={mmcynavlogo}
              alt="MMCY Business Solutions Logo" />
          }
        </a>
      </div>
      <div className={navstyle.navLinksDiv}>
        {/* <Link to='/tools' onClick={closeMenu}>Free Tools</Link> */}
        {/* <Link to='/signin' onClick={closeMenu}>Sign In</Link> */}
      </div>
    </>
  );

  const calendar_dropdownmenu = (
    <>
      <Link to="/calendar/event/list" onClick={closeMenu}>Events </Link>
      <Link to="/calendar/task/list" onClick={closeMenu}>Tasks </Link>
    </>
  )

  const contacts_dropdownmenu = (
    <>
      <Link to="/contacts/contactnew/list" onClick={closeMenu}>Contacts New</Link>
      <Link to="/contacts/contact/list" onClick={closeMenu}>All Contacts </Link>
      <Link to="/contacts/person/list" onClick={closeMenu}>People </Link>
      <Link to="/contacts/organisation/list" onClick={closeMenu}>Organisations </Link>
      <Link to="/contacts/groups/list" onClick={closeMenu}>Groups </Link>
      {/* <Link to="/contacts/organisation/list" onClick={closeMenu}>Organisations </Link>
      <Link to="/contacts/relationship/list" onClick={closeMenu}>Relationships </Link> */}
    </>
  )

  const inventory_dropdownmenu = (
    <>
      <Link to="/inventory/item/list" onClick={closeMenu}>Items </Link>
      <Link to="/inventory/location/list" onClick={closeMenu}>Locations </Link>
      <Link to="/inventory/checklist/list/list" onClick={closeMenu}>Checklists </Link>
    </>
  )

  const fun_dropdownmenu = (
    <>
      {/* <Link to="/fun/games/list" onClick={closeMenu}>Games </Link> */}
      <Link to="/fun/sailinglog" onClick={closeMenu}>Sailing Log </Link>
    </>
  )

  const nav_menu_signed_in = (
    <div className={navstyle.navLinksDiv}>
      <div className={navstyle.navlinksleft}>
        <Link to="/dashboard" onClick={closeMenu}>Dashboard </Link>


        <DropDownMenu
          contents={calendar_dropdownmenu}
          name='Calendar'
          menuToggle={menuToggle}
          css='calendarmenu'
        />

        <DropDownMenu
          contents={contacts_dropdownmenu}
          name='Contacts'
          menuToggle={menuToggle}
          css='contactmenu'
        />
        <DropDownMenu
          contents={inventory_dropdownmenu}
          name='Inventory'
          menuToggle={menuToggle}
          css='inventorymenu'
        />

        <DropDownMenu
          contents={fun_dropdownmenu}
          name='Fun'
          menuToggle={menuToggle}
          css='funmenu'
        />

        <Link to="/tools" onClick={closeMenu}>Tools </Link>
      </div>
      <div className={navstyle.navlinksright}>
        <Link
          to="/support"
          title="Help & Support"
          onClick={closeMenu}>
          <MdHelpOutline />
        </Link>
        <Link
          to="/system/settings"
          title="System Settings"
          onClick={closeMenu}>
          <MdOutlineSettings />
        </Link>
      </div>
      {/* <Link to="/test" onClick={closeMenu}>Testing </Link> */}
      <NavModal show={show} hide={toggle} handle_logout={logoutHandler} />
    </div>
  );


  return (
    <div className={navstyle.topNavBar} id="myTopnav">
      <div className={menuToggle ? navstyle.topnavresponsive : ''}>
        <a className={navstyle.hamMenu}
          onClick={handleNavMenuClick}>
          <div className={navstyle.bar1}></div>
          <div className={navstyle.bar2}></div>
          <div className={navstyle.bar3}></div>
        </a>
        <div className={navstyle.navLinks} >
          {isLoggedIn ? nav_menu_signed_in : nav_menu_signed_out}
        </div>
        <div className={navstyle.userMenu}>
          {isLoggedIn ? chip : (<Link
            to="/signin"
            className={navstyle.userMenu}
            onClick={closeMenu}><button className={navstyle.button}>Sign In</button></Link>)}
        </div>
      </div>
    </div>


  );
};

export default TopNav;
