import Square from '../components/square';
import { MdArrowBack,MdOutlineSailing, MdPeople, MdMenuBook, MdOutlineRoute, MdTimeline, MdOutlineWatchLater, MdOutlineLoop, MdAnchor, MdBuild, MdSettings,MdOutlineGroups,MdSchool } from "react-icons/md";
import style from '../sailingLog.module.css'


const Settings = () => {
  const heading = 'Sailing Log'

  const squares = [
    {
      name: 'Back',
      icon: <MdArrowBack />,
      link: "/fun/sailinglog/",
    },{
      name: 'Boat Types',
      icon: <MdOutlineSailing />,
      hoverText: 'A holiday or several days of sailing',
      link: "/fun/sailinglog/settings/boattypes/list",
    },{
      name: 'Qualifications',
      icon: <MdSchool />,
      link: "/fun/sailinglog/settings/qualifications/list",
      hoverText: 'Marinas, Ports, Anchorages',
      // disabled: true,
    }, {
      name: 'Trip Types',
      icon: <MdOutlineLoop />,
      hoverText: 'A holiday or several days of sailing',
      link: "/fun/sailinglog/settings/triptypes/list",
    }, {
      name: 'Roles',
      icon: <MdOutlineGroups />,
      link: "/fun/sailinglog/settings/roles/list",
    },

  ]

  return (
    <div>
      <div className={style.gridContainer}>
        {squares.map((square) =>
          <Square
            key={square.name}
            name={square.name}
            icon={square.icon}
            link={square.link}
            disabled={square.disabled}
            hoverText={square.hoverText}
          />)}
      </div>
    </div>

  );
};
export default Settings;