//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'

const moduleDefinitions = {
  moduleName: 'Inventory Categories',
  moduleNameSingular: 'Category',
  moduleLink: '/system/settings/inventory/category/',
  thOptionsWidth: '115px',
}

const moduleFields = [
  {
    label: 'Name', 
    mandatory: 'true', 
    fieldName: 'name',
    fieldType: 'text',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  } 
]

const fieldValidation = (fieldName, value) => {
  let hasError = false,
      fieldErrorMessage = ""

  switch (fieldName) {

    case "name":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Name is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break
      
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}


  ///////////// Constants /////////////////////////
export const InventoryCategoryCreateRequest = 'InventoryCategoryCreateRequest'
export const InventoryCategoryCreateSuccess = 'InventoryCategoryCreateSuccess'
export const InventoryCategoryCreateFail = 'InventoryCategoryCreateFail'
export const InventoryCategoryCreateReset = 'InventoryCategoryCreateReset'

export const InventoryCategoryDetailsRequest = 'InventoryCategoryDetailsRequest'
export const InventoryCategoryDetailsSuccess = 'InventoryCategoryDetailsSuccess'
export const InventoryCategoryDetailsFail = 'InventoryCategoryDetailsFail'
export const InventoryCategoryDetailsReset = 'InventoryCategoryDetailsReset'

export const InventoryCategoryUpdateRequest = 'InventoryCategoryUpdateRequest'
export const InventoryCategoryUpdateSuccess = 'InventoryCategoryUpdateSuccess'
export const InventoryCategoryUpdateFail = 'InventoryCategoryUpdateFail'
export const InventoryCategoryUpdateReset = 'InventoryCategoryUpdateReset'

export const InventoryCategoryDeleteRequest = 'InventoryCategoryDeleteRequest'
export const InventoryCategoryDeleteSuccess = 'InventoryCategoryDeleteSuccess'
export const InventoryCategoryDeleteFail = 'InventoryCategoryDeleteFail'
export const InventoryCategoryDeleteReset = 'InventoryCategoryDeleteReset'

export const InventoryCategoryListRequest = 'InventoryCategoryListRequest'
export const InventoryCategoryListSuccess = 'InventoryCategoryListSuccess'
export const InventoryCategoryListFail = 'InventoryCategoryListFail'


  ///////////// API Config /////////////////////////

const APIVariablesCreate = {
  APIRequest: InventoryCategoryCreateRequest,
  APISuccess: InventoryCategoryCreateSuccess,
  APIFail: InventoryCategoryCreateFail,
  APIEndPoint: 'inventory/v1/category/add',
}
const APIVariablesListAll = {
  APIRequest: InventoryCategoryListRequest,
  APISuccess: InventoryCategoryListSuccess,
  APIFail: InventoryCategoryListFail,
  APIEndPoint: 'inventory/v1/category/list'
}
const APIVariablesItemDetails = {
  APIRequest: InventoryCategoryDetailsRequest,
  APISuccess: InventoryCategoryDetailsSuccess,
  APIFail: InventoryCategoryDetailsFail,
  APIEndPoint: 'inventory/v1/category/'
}
const APIVariablesUpdate = {
  APIRequest: InventoryCategoryUpdateRequest,
  APISuccess: InventoryCategoryUpdateSuccess,
  APISuccess_2: InventoryCategoryDetailsSuccess,
  APIFail: InventoryCategoryUpdateFail,
  APIEndPoint: 'inventory/v1/category/'
}
const APIVariablesDelete = {
  APIRequest: InventoryCategoryDeleteRequest,
  APISuccess: InventoryCategoryDeleteSuccess,
  APIFail: InventoryCategoryDeleteFail,
  APIEndPoint: 'inventory/v1/category/'
}

export const InventoryCategoryCreateAPI = (item) => async (dispatch, getState) => {

  const API_Body = {
    name: item.name,
  }
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const InventoryCategoryListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const InventoryCategoryDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const InventoryCategoryUpdateAPI = (item) => async (dispatch, getState) => {

  const API_Body = {
    name: item.name,
  }
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const InventoryCategoryDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}
  
  ///////////// REDUCERS/////////////////////////
  // State is an empty object
  export const InventoryCategoryCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case InventoryCategoryCreateRequest:
            return {
                loading: true
            }
  
        case InventoryCategoryCreateSuccess:
            return {
                loading: false,
                success: true,
                InventoryCategory: action.payload
            }
  
        case InventoryCategoryCreateFail:
            return {
                loading: false,
                error: action.payload
            }
  
        case InventoryCategoryCreateReset:
            return {}
  
  
        default:
            return state
    }
  }
  
  export const InventoryCategoryUpdateReducer = (state = { InventoryCategory: {} }, action) => {
    switch (action.type) {
        case InventoryCategoryUpdateRequest:
            return { loading: true }
  
        case InventoryCategoryUpdateSuccess:
            return { loading: false, success: true }
  
        case InventoryCategoryUpdateFail:
            return { loading: false, error: action.payload }
  
        case InventoryCategoryUpdateReset:
            return { InventoryCategory: {} }
  
        default:
            return state
    }
  }
  
  export const InventoryCategoryDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case InventoryCategoryDeleteRequest:
            return { loading: true }
  
        case InventoryCategoryDeleteSuccess:
            return { 
              loading: false, 
              success: true }
  
        case InventoryCategoryDeleteFail:
            return { loading: false, error: action.payload }
  
        case InventoryCategoryDeleteReset:
              return { }
  
        default:
            return state
    }
  }
  
  export const InventoryCategoryDetailsReducer = (state = { loading: true, InventoryCategory: [] }, action) => {
    switch (action.type) {
        case InventoryCategoryDetailsRequest:
            return {
                ...state,
                loading: true
            }
  
        case InventoryCategoryDetailsSuccess:
            return {
                loading: false,
                InventoryCategory: action.payload
            }
  
        case InventoryCategoryDetailsFail:
            return {
                loading: false,
                error: action.payload
            }
  
  
        default:
            return state
    }
  }
   
  
  export const InventoryCategoryListReducer = (state = { InventoryCategory: [] }, action) => {
    switch (action.type) {
        case InventoryCategoryListRequest:
            return {
                loading: true
            }
  
        case InventoryCategoryListSuccess:
            return {
                loading: false,
                fieldOptionValues: [...action.payload],
                InventoryCategory: action.payload
            }
  
        case InventoryCategoryListFail:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
  }

function InventoryCategoryScreen() {
  const createAPI = InventoryCategoryCreateAPI
  const listAPI = InventoryCategoryListAllAPI
  const detailsAPI = InventoryCategoryDetailsAPI
  const updateAPI = InventoryCategoryUpdateAPI
  const deleteAPI = InventoryCategoryDeleteAPI

  const resetCreate = InventoryCategoryCreateReset
  const resetUpdate = InventoryCategoryUpdateReset
  const resetViewDetails = InventoryCategoryDetailsReset
  const resetDelete = InventoryCategoryDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const InventoryCategoryCreate = useSelector(state => state.InventoryCategoryCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = InventoryCategoryCreate

  const InventoryCategoryList = useSelector(state => state.InventoryCategoryList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    InventoryCategory: dataList } = InventoryCategoryList

  const InventoryCategoryDetails = useSelector(state => state.InventoryCategoryDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    InventoryCategory: dataItem } = InventoryCategoryDetails

  const InventoryCategoryUpdate = useSelector(state => state.InventoryCategoryUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = InventoryCategoryUpdate

  const InventoryCategoryDelete = useSelector(state => state.InventoryCategoryDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = InventoryCategoryDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingList || loadingDetails
    )
  )

  useEffect(() => {
    dispatch(InventoryCategoryListAllAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
     if (errorLoadingList) {setServerErrorMessage(errorLoadingList)}
    if (errorCreate) {setServerErrorMessage(errorCreate)}
    if (errorUpdate) {setServerErrorMessage(errorUpdate)}
    if (errorGetDetails) {setServerErrorMessage(errorGetDetails)}
    if (errorDelete) {setServerErrorMessage(errorDelete)}
  }, [successDelete, successUpdate,
    errorLoadingList,errorCreate,errorUpdate,errorGetDetails, errorDelete])

  return (
    <ModuleHome
    formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
      // Shared items
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      rowOnClickAction='edit'
      add={true}
      edit={true}
      view={false}
      delete={(id) => dispatch(deleteAPI(id))}

      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      getDataList={listAPI}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}
    />
  )
}

export default InventoryCategoryScreen