//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

// Components
import ModuleHome from '../../../components/containers/moduleHome'

import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'
import { SailingLogTripSelectOptionsAPI } from '../trips'
import { SailingLogContactSelectOptionsAPI } from '../contacts/contacts'
import { SailingLogRoleSelectOptionsAPI } from '../settings/role'
import { SailingLogPassageSelectOptionsAPI } from './passages'

export const moduleDefinitions = {
  moduleName: 'Crews',
  moduleNameSingular: 'Crew',
  moduleLink: '/fun/sailinglog/crews/',
  // linktoView: '/sailinglog/crew/list/items/?listid=',
  thOptionsWidth: '115px',
}

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
    fieldErrorMessage = ""

  switch (fieldName) {

    case "name":
      if (value === "") {
        hasError = true
        fieldErrorMessage = "Name is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break

    case "depature_from":
      if (value === "") {
        hasError = true
        fieldErrorMessage = "Depature from is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break

    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

///////////// API Config /////////////////////////

const APIVariablesCreate = {
  APIRequest: 'SailingLogCrewCreateRequest',
  APISuccess: 'SailingLogCrewCreateSuccess',
  APIFail: 'SailingLogCrewCreateFail',
  APIEndPoint: 'sailinglog/v1/crew/add',
}
const APIVariablesListAll = {
  APIRequest: 'SailingLogCrewListRequest',
  APISuccess: 'SailingLogCrewListSuccess',
  APIFail: 'SailingLogCrewListFail',
  APIEndPoint: 'sailinglog/v1/crew/list'
}
const summaryByBoatAPIVariables = {
  APIRequest: 'SailingLogCrewBoatSummaryRequest',
  APISuccess: 'SailingLogCrewBoatSummarySuccess',
  APIFail: 'SailingLogCrewBoatSummaryFail',
  APIEndPoint: 'sailinglog/v1/crew/boatsummary'
}
const APIVariablesSummaryByContact = {
  APIRequest: 'SailingLogCrewContactSummaryRequest',
  APISuccess: 'SailingLogCrewContactSummarySuccess',
  APIFail: 'SailingLogCrewContactSummaryFail',
  APIEndPoint: 'sailinglog/v1/crew/contactsummary'
}
const summaryByPassageAPIVariables = {
  APIRequest: 'SailingLogCrewPassageSummaryRequest',
  APISuccess: 'SailingLogCrewPassageSummarySuccess',
  APIFail: 'SailingLogCrewPassageSummaryFail',
  APIEndPoint: 'sailinglog/v1/crew/passagesummary'
}
const APIVariablesSummaryByTrip = {
  APIRequest: 'SailingLogCrewTripSummaryRequest',
  APISuccess: 'SailingLogCrewTripSummarySuccess',
  APIFail: 'SailingLogCrewTripSummaryFail',
  APIEndPoint: 'sailinglog/v1/crew/tripsummary'
}
const APIVariablesItemDetails = {
  APIRequest: 'SailingLogCrewDetailsRequest',
  APISuccess: 'SailingLogCrewDetailsSuccess',
  APIFail: 'SailingLogCrewDetailsFail',
  APIEndPoint: 'sailinglog/v1/crew/'
}
const APIVariablesUpdate = {
  APIRequest: 'SailingLogCrewUpdateRequest',
  APISuccess: 'SailingLogCrewUpdateSuccess',
  APISuccess_2: 'SailingLogCrewDetailsSuccess',
  APIFail: 'SailingLogCrewUpdateFail',
  APIEndPoint: 'sailinglog/v1/crew/'
}
const APIVariablesDelete = {
  APIRequest: 'SailingLogCrewDeleteRequest',
  APISuccess: 'SailingLogCrewDeleteSuccess',
  APIFail: 'SailingLogCrewDeleteFail',
  APIEndPoint: 'sailinglog/v1/crew/'
}

const APIBodyContents = (item) => {
  if (item.role === '') {
    return (
      {
        trip_name: item.trip_name,
        passage_id: item.passage_id,
        passage_no: item.passage_no,
        name: item.name,
        notes: item.notes,
      })
  } else {
    return ({
      trip_name: item.trip_name,
      passage_id: item.passage_id,
      passage_no: item.passage_no,
      name: item.name,
      role: item.role,
      notes: item.notes,
    })
  }
}

export const SailingLogCrewCreateAPI = (item) => async (dispatch, getState) => {
  const API_Body = APIBodyContents(item)
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const SailingLogCrewListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}
export const SailingLogCrewSummaryByBoatAPI = (query) => async (dispatch, getState) => {
  ItemListAll(summaryByBoatAPIVariables, dispatch, getState, query)
}
export const SailingLogCrewSummaryByContactAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesSummaryByContact, dispatch, getState, query)
}
export const SailingLogCrewSummaryByPassageAPI = (query) => async (dispatch, getState) => {
  ItemListAll(summaryByPassageAPIVariables, dispatch, getState, query)
}

export const SailingLogCrewSummaryByTripAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesSummaryByTrip, dispatch, getState, query)
}

export const SailingLogCrewDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const SailingLogCrewUpdateAPI = (item) => async (dispatch, getState) => {
  const API_Body = APIBodyContents
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const SailingLogCrewDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

///////////// REDUCERS/////////////////////////
// State is an empty object
export const SailingLogCrewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SailingLogCrewCreateRequest':
      return {
        loading: true
      }

    case 'SailingLogCrewCreateSuccess':
      return {
        loading: false,
        success: true,
        SailingLogCrew: action.payload
      }

    case 'SailingLogCrewCreateFail':
      return {
        loading: false,
        error: action.payload
      }

    case 'SailingLogCrewCreateReset':
      return {}


    default:
      return state
  }
}

export const SailingLogCrewUpdateReducer = (state = { SailingLogCrew: {} }, action) => {
  switch (action.type) {
    case 'SailingLogCrewUpdateRequest':
      return { loading: true }

    case 'SailingLogCrewUpdateSuccess':
      return { loading: false, success: true }

    case 'SailingLogCrewUpdateFail':
      return { loading: false, error: action.payload }

    case 'SailingLogCrewUpdateReset':
      return { SailingLogCrew: {} }

    default:
      return state
  }
}

export const SailingLogCrewDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SailingLogCrewDeleteRequest':
      return { loading: true }

    case 'SailingLogCrewDeleteSuccess':
      return {
        loading: false,
        success: true
      }

    case 'SailingLogCrewDeleteFail':
      return { loading: false, error: action.payload }

    case 'SailingLogCrewDeleteReset':
      return {}

    default:
      return state
  }
}

export const SailingLogCrewDetailsReducer = (state = { loading: true, SailingLogCrew: [] }, action) => {
  switch (action.type) {
    case 'SailingLogCrewDetailsRequest':
      return {
        ...state,
        loading: true
      }

    case 'SailingLogCrewDetailsSuccess':
      return {
        loading: false,
        SailingLogCrew: action.payload
      }

    case 'SailingLogCrewDetailsFail':
      return {
        loading: false,
        error: action.payload
      }


    default:
      return state
  }
}


export const SailingLogCrewListReducer = (state = { SailingLogCrew: [] }, action) => {
  switch (action.type) {
    case 'SailingLogCrewListRequest':
      return {
        loading: true
      }

    case 'SailingLogCrewListSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
        SailingLogCrew: action.payload
      }

    case 'SailingLogCrewListFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const SailingLogCrewBoatSummaryReducer = (state = { SailingLogCrew: [] }, action) => {
  switch (action.type) {
    case 'SailingLogCrewBoatSummaryRequest':
      return {
        loading: true
      }

    case 'SailingLogCrewBoatSummarySuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
        SailingLogCrew: action.payload
      }

    case 'SailingLogCrewBoatSummaryFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const SailingLogCrewContactSummaryReducer = (state = { SailingLogCrew: [] }, action) => {
  switch (action.type) {
    case 'SailingLogCrewContactSummaryRequest':
      return {
        loading: true
      }

    case 'SailingLogCrewContactSummarySuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
        SailingLogCrew: action.payload
      }

    case 'SailingLogCrewContactSummaryFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const SailingLogCrewPassageSummaryReducer = (state = { SailingLogCrew: [] }, action) => {
  switch (action.type) {
    case 'SailingLogCrewPassageSummaryRequest':
      return {
        loading: true
      }

    case 'SailingLogCrewPassageSummarySuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
        SailingLogCrew: action.payload
      }

    case 'SailingLogCrewPassageSummaryFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const SailingLogCrewTripSummaryReducer = (state = { SailingLogCrew: [] }, action) => {
  switch (action.type) {
    case 'SailingLogCrewTripSummaryRequest':
      return {
        loading: true
      }

    case 'SailingLogCrewTripSummarySuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
        SailingLogCrew: action.payload
      }

    case 'SailingLogCrewTripSummaryFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}



function SailingLogCrewScreen(props) {
  const createAPI = SailingLogCrewCreateAPI
  const listAPI = SailingLogCrewListAllAPI
  const detailsAPI = SailingLogCrewDetailsAPI
  const updateAPI = SailingLogCrewUpdateAPI
  const deleteAPI = SailingLogCrewDeleteAPI

  const resetCreate = 'SailingLogCrewCreateReset'
  const resetUpdate = 'SailingLogCrewUpdateReset'
  const resetViewDetails = 'SailingLogCrewDetailsReset'
  const resetDelete = 'SailingLogCrewDeleteReset'

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()
  const paramsItemSplit = paramsItem.action.split(':')
  const paramsID = paramsItemSplit[1]

  const queryParameters = new URLSearchParams(window.location.search)
  const trip_name = queryParameters.get("trip_name")
  const passage = queryParameters.get("passage")

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const SailingLogCrewCreate = useSelector(state => state.SailingLogCrewCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = SailingLogCrewCreate

  const SailingLogCrewList = useSelector(state => state.SailingLogCrewList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    SailingLogCrew: dataList } = SailingLogCrewList

  const SailingLogCrewDetails = useSelector(state => state.SailingLogCrewDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    SailingLogCrew: dataItem } = SailingLogCrewDetails

  const SailingLogCrewUpdate = useSelector(state => state.SailingLogCrewUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = SailingLogCrewUpdate

  const SailingLogCrewDelete = useSelector(state => state.SailingLogCrewDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = SailingLogCrewDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingList || loadingDetails
    )
  )

  const moduleFields = [
    {
      label: 'Trip Name',
      mandatory: 'true',
      fieldName: 'trip_name',
      fieldLongName: 'trip_name_name',
      fieldType: 'selectfromdb',
      options: 'SailingLogTripSelectOptions',
      // addNewLink: '/fun/sailinglog/trips/add/',
      openAddNewModule: 'sailinglog/trips',
      addFormInitialValue: trip_name,
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '1',
      disabled: true
    }, {
      label: 'Passage',
      mandatory: 'true',
      fieldName: 'passage_id',
      fieldLongName: 'passage_name',
      fieldType: 'selectfromdb',
      options: 'SailingLogPassageSelectOptions',
      // addNewLink: '/fun/sailinglog/trips/add/',
      openAddNewModule: 'sailinglog/passages',
      addFormInitialValue: passage,
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '1',
      disabled: true
    }, {
      label: 'Passage',
      fieldName: 'passage_no',
      addFormOrder: 0,
      editFormOrder: 0,
      tableDisplayOrder: 0,
    }, {
      label: 'Name',
      mandatory: 'true',
      fieldName: 'name',
      // fieldLongName: 'name',
      fieldType: 'autoCompleteText',
      options: 'SailingLogContactSelectOptions',
      // addNewLink: '/fun/sailinglog/contacts/add/',
      openAddNewModule: 'sailinglog/contacts',
      // addFormInitialValue: trip_name,
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '1',
    }, {
      label: 'Role(s)',
      fieldName: 'role',
      fieldType: 'selectfromdb',
      // fieldType: 'autoCompleteText',
      options: 'SailingLogRoleSelectOptions',
      openAddNewModule: 'sailinglog/settings/roles',
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '1',
      multipleSelect: true,
    }, {
      //   label: 'Same for all passages?',
      //   fieldName: 'passages',
      //   fieldType: 'checkbox',
      //   fieldGroupPosition: 'Left',
      //   addFormOrder: '',
      //   addFormInitialValue: true,
      //   editFormOrder: '',
      //   tableDisplayOrder: '',
      //   tableDisplayPriority: '4',
      // }, {
      //   label: 'Passages crewed on',
      //   fieldName: 'allpassages',
      //   fieldType: 'text',
      //   fieldGroupPosition: 'Right',
      //   addFormOrder: '',
      //   addFormConditionalRenderBasedOn: 'passages',
      //   addFormConditionalRenderValues: false, 
      //   editFormOrder: '',
      //   tableDisplayOrder: '',
      //   tableDisplayPriority: '4',
      // }, {
      label: 'Notes',
      fieldName: 'notes',
      fieldType: 'textarea',
      rows: "4",
      addFormOrder: '',
      editFormOrder: '',
      tableDisplayOrder: '',
      tableDisplayPriority: '0',
    },
  ]

  useEffect(() => {
    // dispatch(SailingLogCrewListAll())
    dispatch(SailingLogTripSelectOptionsAPI('?itemid=' + trip_name ))
    dispatch(SailingLogContactSelectOptionsAPI('?listtype=' + 1))
    dispatch(SailingLogRoleSelectOptionsAPI())
    dispatch(SailingLogPassageSelectOptionsAPI('?itemid=' + passage ))

  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }

  }, [successDelete, successUpdate,
    errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete, paramsID])


  return (
    <ModuleHome
          formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
    moduleModalVisibility={props.moduleModalVisibility}
    setModuleModalVisibility={props.setModuleModalVisibility}

      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      rowOnClickAction={'view'}
      view={(dataitem) => navigate(moduleDefinitions.moduleLink + 'view:' + dataitem.id)}
      add={true}
      edit={(dataitem) => navigate(moduleDefinitions.moduleLink + 'edit:' + dataitem.id)}
      delete={(id) => dispatch(deleteAPI(id))}

      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      getDataList={listAPI}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}

    //View Page
    // viewTabs={viewTabs} 
    // headingName={dataItem.name}

    />
  )
}

export default SailingLogCrewScreen
