//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'
import { AddressListAllAPI } from '../../shared/address'
import { EventStatusListAllAPI } from './status'
import { ContactsContactListSelectOptionsAPI } from '../../contacts/contact'
import { EventTypeSelectOptionsAPI } from './types'
import { EventVisibilitySelectOptionsAPI } from './visibility'
import { EventAttendeesUpdateAPI } from './attendess'
// Hooks
import {localISODate} from '../../../hooks/useDateTime'

export const moduleDefinitions = {
  moduleName: 'Events',
  moduleNameSingular: 'Event',
  moduleLink: '/event/',
  viewOptions: ['Month','List' ],
  viewDefault: 'Month',
}

const dataBaseFilterOptions = [
  {
    id: 1,
    name: 'Current',
    default: true,
  }, {
    id: 2,
    name: 'Past',
  }, {
    id: 3,
    name: 'All',
  },
]

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
    fieldErrorMessage = ""
  switch (fieldName) {
    case "name":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Event name is required"
      } else if (value.length < 2) {
        hasError = true
        fieldErrorMessage = 'Event name is too short'
      } else if (value.length > 50) {
        hasError = true
        fieldErrorMessage = 'Event name is too long'
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break
    case "start_date":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Start date is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

export const EventCreateRequest = 'EventCreateRequest'
export const EventCreateSuccess = 'EventCreateSuccess'
export const EventCreateFail = 'EventCreateFail'
export const EventCreateReset = 'EventCreateReset'

export const EventDetailsRequest = 'EventDetailsRequest'
export const EventDetailsSuccess = 'EventDetailsSuccess'
export const EventDetailsFail = 'EventDetailsFail'
export const EventDetailsReset = 'EventDetailsReset'

export const EventUpdateRequest = 'EventUpdateRequest'
export const EventUpdateSuccess = 'EventUpdateSuccess'
export const EventUpdateFail = 'EventUpdateFail'
export const EventUpdateReset = 'EventUpdateReset'

export const EventDeleteRequest = 'EventDeleteRequest'
export const EventDeleteSuccess = 'EventDeleteSuccess'
export const EventDeleteFail = 'EventDeleteFail'
export const EventDeleteReset = 'EventDeleteReset'

export const EventListRequest = 'EventListRequest'
export const EventListSuccess = 'EventListSuccess'
export const EventListFail = 'EventListFail'

const APIVariablesCreate = {
  APIRequest: EventCreateRequest,
  APISuccess: EventCreateSuccess,
  APIFail: EventCreateFail,
  APIEndPoint: 'events/v1/event/add',
}
const APIVariablesListAll = {
  APIRequest: EventListRequest,
  APISuccess: EventListSuccess,
  APIFail: EventListFail,
  APIEndPoint: 'events/v1/event/list'
}
const APIVariablesItemDetails = {
  APIRequest: EventDetailsRequest,
  APISuccess: EventDetailsSuccess,
  APIFail: EventDetailsFail,
  APIEndPoint: 'events/v1/event/'
}
const APIVariablesUpdate = {
  APIRequest: EventUpdateRequest,
  APISuccess: EventUpdateSuccess,
  APISuccess_2: EventDetailsSuccess,
  APIFail: EventUpdateFail,
  APIEndPoint: 'events/v1/event/'
}
const APIVariablesDelete = {
  APIRequest: EventDeleteRequest,
  APISuccess: EventDeleteSuccess,
  APIFail: EventDeleteFail,
  APIEndPoint: 'events/v1/event/'
}

const APIBodyContentAdd = (item) => {
  const cleanStartDate = item.start_date === '' ? null : item.start_date;
  const cleanStartTime = item.start_time === '' ? null : item.start_time;
  const cleanEndDate = item.end_date === '' ? null : item.end_date;
  const cleanEndTime = item.end_time === '' ? null : item.end_time;

  return ({
    name: item.name,
    event_type: item.event_type,
    visibility: item.visibility,
    start_date: cleanStartDate,
    start_time: cleanStartTime,
    end_date: cleanEndDate,
    end_time: cleanEndTime,
    location: item.location,
    status: item.status,
    notes: item.notes,
    attendees: item.attendees,
    email_all: item.email_all,
  })
}

const APIBodyContentEdit = (item) => {
  const cleanStartDate = item.start_date === '' ? null : item.start_date;
  const cleanStartTime = item.start_time === '' ? null : item.start_time;
  const cleanEndDate = item.end_date === '' ? null : item.end_date;
  const cleanEndTime = item.end_time === '' ? null : item.end_time;

  return ({
    name: item.name,
    event_type: item.event_type,
    visibility: item.visibility,
    start_date: cleanStartDate,
    start_time: cleanStartTime,
    end_date: cleanEndDate,
    end_time: cleanEndTime,
    location: item.location,
    status: item.status,
    notes: item.notes,
    attendees: item.attendees,
    email_all: item.email_all,
  })
}

export const EventCreateAPI = (item) => async (dispatch, getState) => {
  const API_Body = APIBodyContentAdd(item)
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}
export const EventsListAllAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState, query)
}
export const EventsDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}
export const EventsUpdateAPI = (item) => async (dispatch, getState) => {
  const API_Body = APIBodyContentEdit(item)
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}
export const EventsDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

// State is an empty object
export const EventCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case EventCreateRequest:
      return {
        loading: true
      }

    case EventCreateSuccess:
      return {
        loading: false,
        success: true,
        Event: action.payload
      }

    case EventCreateFail:
      return {
        loading: false,
        error: action.payload
      }

    case EventCreateReset:
      return {}


    default:
      return state
  }
}

export const EventUpdateReducer = (state = { Event: {} }, action) => {
  switch (action.type) {
    case EventUpdateRequest:
      return { loading: true }

    case EventUpdateSuccess:
      return { loading: false, success: true }

    case EventUpdateFail:
      return { loading: false, error: action.payload }

    case EventUpdateReset:
      return { Event: {} }

    default:
      return state
  }
}

export const EventDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EventDeleteRequest:
      return { loading: true }

    case EventDeleteSuccess:
      return {
        loading: false,
        success: true
      }

    case EventDeleteFail:
      return { loading: false, error: action.payload }

    case EventDeleteReset:
      return {}

    default:
      return state
  }
}

export const EventDetailsReducer = (state = { loading: true, Event: [] }, action) => {
  switch (action.type) {
    case EventDetailsRequest:
      return {
        ...state,
        loading: true
      }

    case EventDetailsSuccess:
      return {
        loading: false,
        Event: action.payload
      }

    case EventDetailsFail:
      return {
        loading: false,
        error: action.payload
      }


    default:
      return state
  }
}


export const EventListReducer = (state = { Events: [] }, action) => {
  switch (action.type) {
    case EventListRequest:
      return {
        loading: true
      }

    case EventListSuccess:
      return {
        loading: false,
        success: true,
        Events: action.payload
      }

    case EventListFail:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function EventListScreen() {
  const resetCreate = EventCreateReset
  const resetUpdate = EventUpdateReset
  const resetViewDetails = EventDetailsReset
  const resetDelete = EventDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')
  const [dataBaseFilter, setDataBaseFilter] = useState('Month');

  //Calendar specific 
  const [calendar, setCalendar] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  // console.log('calendar',calendar.month, calendar.year)
  const [filterRangeStart, setFilterRangeStart] = useState(calendar.year + '-' + (calendar.month-1) + '-01');
  const [filterRangeEnd, setFilterRangeEnd] = useState(calendar.year + '-' + (calendar.month+1) + '-28');
  const [startDate, setStartDate] = useState(localISODate(new Date()));
  // console.log('dataBaseFilter', dataBaseFilter)
  // console.log('filterRangeStart', filterRangeStart)
  // console.log('filterRangeEnd', filterRangeEnd)

  const dataListParams = (
    '?listtype=' + dataBaseFilter + 
    '&filterRangeStart=' + filterRangeStart +
    '&filterRangeEnd=' + filterRangeEnd
  )

  const createAPI = EventCreateAPI
  const listAPI = EventsListAllAPI
  const detailsAPI = EventsDetailsAPI
  const updateAPI = EventsUpdateAPI
  const deleteAPI = EventsDeleteAPI

  const resetInitialValues = () => {
    setStartDate(localISODate(new Date()))
  }

  const EventCreate = useSelector(state => state.EventCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = EventCreate

  const EventList = useSelector(state => state.EventList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    Events: dataList } = EventList

  const EventDetails = useSelector(state => state.EventDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    Event: dataItem } = EventDetails

  const EventUpdate = useSelector(state => state.EventUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = EventUpdate

  const EventDelete = useSelector(state => state.EventDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = EventDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingDetails
    )
  )



  useEffect(() => {
    dispatch(EventsListAllAPI(dataListParams))
    dispatch(AddressListAllAPI())
    dispatch(EventStatusListAllAPI())
    dispatch(ContactsContactListSelectOptionsAPI())
    dispatch(EventTypeSelectOptionsAPI())
    dispatch(EventVisibilitySelectOptionsAPI())
  }, [])

  useEffect(() => {
    dispatch(EventsListAllAPI(dataListParams))
  }, [dataBaseFilter,filterRangeStart])


  useEffect(() => {
    if (successUpdate) {
      dispatch(EventsListAllAPI(dataListParams))
      dispatch({ type: EventUpdateReset })
    }
    if (successDelete) {
      // dispatch(EventsListAllAPI())
      dispatch({ type: EventDeleteReset })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }
  }, [successDelete, successUpdate,
    errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete])

  const moduleFields = [
      {
        label: 'Event Name',
        mandatory: true,
        fieldName: 'name',
        fieldType: 'text',
        addFormOrder: 10,
        editFormOrder: 10,
        tableDisplayOrder: 10,
        tableDisplayPriority: 1,
      }, {
        label: 'Event Type',
        mandatory: true,
        fieldName: 'event_type',
        fieldLongName: 'event_type_name',
        fieldType: 'selectfromdb',
        options: 'EventTypeSelectOptions',
        fieldGroupPosition: 'Left',
        addFormOrder: 20,
        addFormInitialValue: 1, //Meeting
        editFormOrder: 20,
        tableDisplayOrder: 20,
        tableDisplayPriority: 3,
      }, {
        label: 'Visibility',
        mandatory: true,
        fieldName: 'visibility',
        fieldLongName: 'visibility_name',
        fieldType: 'selectfromdb',
        options: 'EventVisibilitySelectOptions',
        fieldGroupPosition: 'Right',
        addFormOrder: 21,
        addFormInitialValue: 1, //Private
        editFormOrder: 21,
        tableDisplayOrder: 21,
        tableDisplayPriority: 4,
      }, {
        label: 'Start Date',
        fieldName: 'start_date',
        mandatory: true,
        fieldType: 'date',
        fieldGroupPosition: 'Left',
        addFormInitialValue: startDate, 
        addFormOrder: 30,
        editFormOrder: 30,
        tableDisplayOrder: 30,
        tableDisplayPriority: 1,
      }, {
        label: 'Start Time',
        fieldName: 'start_time',
        fieldType: 'time',
        fieldGroupPosition: 'Right',
        addFormOrder: 40,
        editFormOrder: 40,
        tableDisplayOrder: 40,
        tableDisplayPriority: 2,
      }, {
        label: 'End Date',
        mandatory: true,
        fieldName: 'end_date',
        fieldType: 'date',
        fieldGroupPosition: 'Left',
        addFormInitialValue: startDate, 
        addFormOrder: 50,
        editFormOrder: 50,
        tableDisplayOrder: 50,
        tableDisplayPriority: 4,
      }, {
        label: 'End Time',
        fieldName: 'end_time',
        fieldType: 'time',
        fieldGroupPosition: 'Right',
        addFormOrder: 60,
        editFormOrder: 60,
        tableDisplayOrder: 60,
        tableDisplayPriority: 4,
      }, {
        label: 'Location',
        fieldName: 'location',
        fieldLongName: 'location_name',
        fieldType: 'autoCompleteText',
        options: 'AddressList',
        openAddNewModule: 'shared/address',
        addFormOrder: 70,
        editFormOrder: 70,
        tableDisplayOrder: 70,
        tableDisplayPriority: 3,
      }, {
        label: 'Status',
        fieldName: 'status',
        fieldLongName: 'status_name',
        fieldType: 'selectfromdb',
        options: 'EventStatusList',
        addFormOrder: 71,
        addFormInitialValue: 1, //Confirmed
        editFormOrder: 71,
        tableDisplayOrder: 71,
        tableDisplayPriority: 5,
      }, {
        label: 'Attendees',
        fieldName: 'attendees',
        fieldType: 'autoCompleteText',
        options: 'ContactsContactSelectOptions',
        openAddNewModule: 'contacts/contact',
        addFormOrder: 80,
        // addFormInitialValue: 1, //Confirmed
        editFormOrder: 80,
        tableDisplayOrder: 80,
        tableDisplayPriority: 0,
        multipleSelect: true,
        // subForm: 'attendees'
        tableFilterHide: true,
        relatedFields: [
          {
            fieldName: 'pk',
            fieldType: 'number',
            fieldDisplay: 'ref',
            addFormOrder: 0,
            editFormOrder: 0,
          },
          {
            fieldName: 'person_id',
            fieldType: 'text',
            fieldDisplay: 'primary',
            addFormOrder: 10,
            editFormOrder: 10,
            primaryField: true
          },
          {
            fieldName: 'date_invited',
            fieldType: 'datetime',
            fieldDisplay: 'indicatorFlag',
            addFormOrder: 0,
            editFormOrder: 0,
          }
        ]
      }, {
        label: 'Email All Attendees',
        fieldName: 'email_all',
        fieldType: 'toggle',
        addFormOrder: 90,
        // addFormInitialValue: 1, //Confirmed
        editFormOrder: 90,
        tableDisplayOrder: 0,
        tableDisplayPriority: 0,
      }, {
        label: 'Notes',
        fieldName: 'notes',
        fieldType: 'textarea',
        rows: "4",
        addFormOrder: 100,
        editFormOrder: 100,
        tableDisplayOrder: 0,
        tableDisplayPriority: 0,
      },
    ]

  return (
    <ModuleHome
    formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}

      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataListParams={dataListParams}
      getDataList={listAPI}
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      //Database filtering
      dataBaseFilterOptions={dataBaseFilterOptions}
      dataBaseFilter={dataBaseFilter}
      setDataBaseFilter={setDataBaseFilter}
      filterRangeStart={filterRangeStart}
      setFilterRangeStart={setFilterRangeStart}
      filterRangeEnd={filterRangeEnd}
      setFilterRangeEnd={setFilterRangeEnd}


      //Table Actions
      rowOnClickAction='edit'
      add={true}
      edit={true}
      view={false}
      delete={(id) => dispatch(EventsDeleteAPI(id))}

      //Add Edit Form
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}

     //Calendar
     calendar={calendar}
     setCalendar={setCalendar}
     setStartDate={setStartDate}
     resetInitialValues={resetInitialValues}

    />

  )
}

export default EventListScreen