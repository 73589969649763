//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'

export const moduleDefinitions = {
  moduleName: 'Tasks: Status Options',
  moduleNameSingular: 'Task - Status Option',
  moduleLink: '/system/settings/tasks/status/',
  thOptionsWidth: '115px',
}

export const moduleFields = [
  {
    label: 'Select Order',
    fieldName: 'order',
    fieldType: 'text',
    addFormOrder: 2,
    editFormOrder: 2,
    tableDisplayOrder: 2,
    tableDisplayPriority: 2,
  },
  {
    label: 'Status Name',
    fieldName: 'name',
    fieldType: 'text',
    addFormOrder: 1,
    editFormOrder: 1,
    tableDisplayOrder: 1,
    tableDisplayPriority: 1,
  }
]

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
    fieldErrorMessage = ""
  switch (fieldName) {
    case "name":
      break
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

export const TaskStatusCreateRequest = 'TaskStatusCreateRequest'
export const TaskStatusCreateSuccess = 'TaskStatusCreateSuccess'
export const TaskStatusCreateFail = 'TaskStatusCreateFail'
export const TaskStatusCreateReset = 'TaskStatusCreateReset'

export const TaskStatusDetailsRequest = 'TaskStatusDetailsRequest'
export const TaskStatusDetailsSuccess = 'TaskStatusDetailsSuccess'
export const TaskStatusDetailsFail = 'TaskStatusDetailsFail'
export const TaskStatusDetailsReset = 'TaskStatusDetailsReset'

export const TaskStatusUpdateRequest = 'TaskStatusUpdateRequest'
export const TaskStatusUpdateSuccess = 'TaskStatusUpdateSuccess'
export const TaskStatusUpdateFail = 'TaskStatusUpdateFail'
export const TaskStatusUpdateReset = 'TaskStatusUpdateReset'

export const TaskStatusDeleteRequest = 'TaskStatusDeleteRequest'
export const TaskStatusDeleteSuccess = 'TaskStatusDeleteSuccess'
export const TaskStatusDeleteFail = 'TaskStatusDeleteFail'
export const TaskStatusDeleteReset = 'TaskStatusDeleteReset'

export const TaskStatusList_MYRequest = 'TaskStatusList_MYRequest'
export const TaskStatusList_MYSuccess = 'TaskStatusList_MYSuccess'
export const TaskStatusList_MYFail = 'TaskStatusList_MYFail'
export const TaskStatusList_MYReset = 'TaskStatusList_MYReset'

export const TaskStatusListRequest = 'TaskStatusListRequest'
export const TaskStatusListSuccess = 'TaskStatusListSuccess'
export const TaskStatusListFail = 'TaskStatusListFail'


// State is an empty object
export const TaskStatusCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TaskStatusCreateRequest:
      return {
        loading: true
      }

    case TaskStatusCreateSuccess:
      return {
        loading: false,
        success: true,
        TaskStatus: action.payload
      }

    case TaskStatusCreateFail:
      return {
        loading: false,
        error: action.payload
      }

    case TaskStatusCreateReset:
      return {}


    default:
      return state
  }
}

export const TaskStatusUpdateReducer = (state = { TaskStatus: {} }, action) => {
  switch (action.type) {
    case TaskStatusUpdateRequest:
      return { loading: true }

    case TaskStatusUpdateSuccess:
      return { loading: false, success: true }

    case TaskStatusUpdateFail:
      return { loading: false, error: action.payload }

    case TaskStatusUpdateReset:
      return { TaskStatus: {} }

    default:
      return state
  }
}

export const TaskStatusDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TaskStatusDeleteRequest:
      return { loading: true }

    case TaskStatusDeleteSuccess:
      return {
        loading: false,
        success: true
      }

    case TaskStatusDeleteFail:
      return { loading: false, error: action.payload }

    case TaskStatusDeleteReset:
      return {}

    default:
      return state
  }
}

export const TaskStatusDetailsReducer = (state = { loading: true, TaskStatus: [] }, action) => {
  switch (action.type) {
    case TaskStatusDetailsRequest:
      return {
        ...state,
        loading: true
      }

    case TaskStatusDetailsSuccess:
      return {
        loading: false,
        success: true,
        TaskStatus: action.payload
      }

    case TaskStatusDetailsFail:
      return {
        loading: false,
        error: action.payload,
        TaskStatus: 'error'
      }

    case TaskStatusDetailsReset:
      return { TaskStatus: {} }

    default:
      return state
  }
}

export const TaskStatusListReducer = (state = { TaskStatus: [], fieldOptionValues: [] }, action) => {
  switch (action.type) {
    case TaskStatusListRequest:
      return {
        loading: true
      }

    case TaskStatusListSuccess:
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
        success: true,
        TaskStatus: action.payload
      }

    case TaskStatusListFail:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

const APIVariablesCreate = {
  APIRequest: TaskStatusCreateRequest,
  APISuccess: TaskStatusCreateSuccess,
  APIFail: TaskStatusCreateFail,
  APIEndPoint: 'tasks/v1/status/add',
}
const APIVariablesListAll = {
  APIRequest: TaskStatusListRequest,
  APISuccess: TaskStatusListSuccess,
  APIFail: TaskStatusListFail,
  APIEndPoint: 'tasks/v1/status/list'
}
const APIVariablesItemDetails = {
  APIRequest: TaskStatusDetailsRequest,
  APISuccess: TaskStatusDetailsSuccess,
  APIFail: TaskStatusDetailsFail,
  APIEndPoint: 'tasks/v1/status/'
}
const APIVariablesUpdate = {
  APIRequest: TaskStatusUpdateRequest,
  APISuccess: TaskStatusUpdateSuccess,
  APISuccess_2: TaskStatusDetailsSuccess,
  APIFail: TaskStatusUpdateFail,
  APIEndPoint: 'tasks/v1/status/'
}
const APIVariablesDelete = {
  APIRequest: TaskStatusDeleteRequest,
  APISuccess: TaskStatusDeleteSuccess,
  APIFail: TaskStatusDeleteFail,
  APIEndPoint: 'tasks/v1/status/'
}

export const TaskStatusCreateAPI = (item) => async (dispatch, getState) => {
  const API_Body = {
    order: item.order,
    name: item.name,
  }
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const TaskStatusListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const TaskStatusDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const TaskStatusUpdateAPI = (item) => async (dispatch, getState) => {
  const API_Body = {
    order: item.order,
    name: item.name,
  }

  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const TaskStatusDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

function StatusListScreen() {

  const createAPI = TaskStatusCreateAPI
  const listAPI = TaskStatusListAllAPI
  const detailsAPI = TaskStatusDetailsAPI
  const updateAPI = TaskStatusUpdateAPI
  const deleteAPI = TaskStatusDeleteAPI

  const resetCreate = TaskStatusCreateReset
  const resetUpdate = TaskStatusUpdateReset
  const resetViewDetails = TaskStatusDetailsReset
  const resetDelete = TaskStatusDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const TaskStatusCreate = useSelector(state => state.TaskStatusCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = TaskStatusCreate

  const TaskStatusList = useSelector(state => state.TaskStatusList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    TaskStatus: dataList } = TaskStatusList

  const TaskStatusDetails = useSelector(state => state.TaskStatusDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    TaskStatus: dataItem } = TaskStatusDetails

  const TaskStatusUpdate = useSelector(state => state.TaskStatusUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = TaskStatusUpdate

  const TaskStatusDelete = useSelector(state => state.TaskStatusDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = TaskStatusDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingDetails
    )
  )

  useEffect(() => {
    dispatch(listAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }
  }, [successDelete, successUpdate,
    errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete])


  return (
    <ModuleHome
      // Shared items
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      //Table Actions
      rowOnClickAction='edit'
      add={true}
      edit={true}
      view={false}
      delete={(id) => dispatch(deleteAPI(id))}

      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      getDataList={listAPI}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}
    />
  )
}

export default StatusListScreen