import React from 'react';
import ReactDOM from 'react-dom';

import '../../style/modal.css';
import formstyle from '../../style/form.module.css';
import tablestyle from '../../style/table.module.css';

export const FilterModal = ({ hide, show, ref, contents, actionName, handleResetAllFilters, handleSave }) => show ? ReactDOM.createPortal(
  <React.Fragment>
    <div className="modalOverlay" onClick={hide} />
    <div className="modalWrapper" aria-modal aria-hidden tabIndex={-1} role="Filter Modal" >
      <div ref={ref} className="filterModalBody">
        <div className="filterModalHeader">
          <button
            onClick={() => { hide(); handleResetAllFilters(); }}
            className={formstyle.orange}>
            Reset all filters
          </button>
          <button
            className={formstyle.green}
            onClick={() => { hide(); handleSave(); }}>
            {(actionName ? actionName : 'Save')}
          </button>
          <span className="modalClose" id="modalClose" onClick={hide}>&times;</span>
        </div>
        <div className='filterModalContent'>
          <div className={tablestyle.filterModalContent}>
            {contents}
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>, document.body
) : null;
