import React from 'react';
import ReactDOM from 'react-dom';
import '../../style/modal.css';
import AddEditForm from '../forms/AddEditForm';

export const AddEditFormModal = (props) =>  props.formModalVisibility ? ReactDOM.createPortal(
  <React.Fragment>   
    <div className="modalOverlay">
        <AddEditForm {...props}/>
    </div>
</React.Fragment>, document.body
) : null ;
