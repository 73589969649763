import * as API from './APISettings';
import { sendRefreshToken } from './userAPIConfig';
import { useDispatch } from 'react-redux';

function RefreshToken() {
  console.log('access token expires, attempting to get new access Token')
  const dispatch = useDispatch()
  const refreshToken = JSON.parse(localStorage.getItem('userInfo'))
  dispatch(sendRefreshToken(refreshToken.refresh))
}

async function IsTokenValid(headers, signal) {
  const dateTimeNew = new Date()
  const localtime = dateTimeNew.toLocaleString("en-GB", { timeStyle: "long" });
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const user_id = userInfo.user_id
  const accessTokenExpirationTime = JSON.parse(localStorage.getItem('accessTokenExpirationTime'))
  const dateTimeNow = Date.now() //js format
  const difference = accessTokenExpirationTime - dateTimeNow

  try {
    console.log(localtime, '1. Checking token valid on backend', difference);
    // const response = await fetch(API.SERVER + 'validationcheck', {
    const response = await fetch(API.SERVER + 'auth/user/profile/view/' + user_id, {
      // const response = await fetch(API.SERVER + 'tasks/v1/list', {
      headers: headers,
      signal: signal
    });
    console.log('response', response);
    console.log('response.status', response.status);
    if (response.status === 200 ) { 
      return response.status === 200;
    } else if (response.status === 401) {
      console.log('response.status 401', response.status);

    }
    // If the response status is 200, the token is valid; otherwise, it's not valid
    // console.log(localtime ,'response.status', response.status);
    return response.status === 200;
  } catch (error) {
    console.log(localtime, '2. Failed to validate token on backend');
    // If error when checking token validity, then try a token refresh. 
    try {
      console.log(localtime, '3. Trying to get refresh token');
      const response = await RefreshToken()
      return response.status === 200;
    } catch (error) {
      console.log(localtime, '4. Failed to get refresh token');
      // Handle network errors or other issues
      console.log(localtime, 'Token validation error:', error);
      return false;
    }
  }
}

  // Function to handle API requests
  async function apiCall(url, options = {}) {
    // Get the access token from your storage (localStorage, sessionStorage, etc.)
    const accessToken = localStorage.getItem('userInfo');
    console.log('url', url);
    console.log('options', options);
    const headers = options.headers
    const signal = options.signal
    // console.log('headers 1', headers);
    const dateTimeNew = new Date()
    const localtime = dateTimeNew.toLocaleString("en-GB", { timeStyle: "long" });
    const accessTokenExpirationTime = JSON.parse(localStorage.getItem('accessTokenExpirationTime'))
    const dateTimeNow = Date.now() //js format
    const difference = accessTokenExpirationTime - dateTimeNow

    // If there's no access token or it's not valid, handle accordingly
    if (!accessToken || !(await IsTokenValid(headers, signal))) {
      // Redirect to login page or show an error message
      throw new Error(localtime + 'Token not currently valid- time left' + difference);
    }
    try {
      // Make the API request using fetch
      const response = await fetch(url, options);

      // Check for error status codes and handle accordingly
      if (!response.ok) {
        throw new Error('Request failed');
      }

      // Parse and return the response data (assuming it's JSON)
      // console.log('response', response);
      return response;
    } catch (error) {
      // Handle other types of errors (e.g., network errors)
      console.error('API Error:', error);
      throw error;
    }
  }

  export default apiCall;
