export const fieldStatusOptions = [
  {
    name: 'Active',
    value: 'Active',
  },
  {
    name: 'Archived',
    value: 'Archived',
  },
]
