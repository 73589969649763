import { signout } from '../APIs/userAPIConfig';

export const SERVER = process.env.REACT_APP_API_SERVER;
export const AbortSignalTimeOut = 10000

export const GenericError = 'An error had occured, please contact customer support with the following. Error '
export const Error400 = 'Please contact customer support with the error: Bad Request code 400'
export const Error401 = 'The details you entered are not valid, please try again.'
export const Error404 = 'An error has occured. Please contact customer support with the Error code 404 to resolve this issue.'
export const Error500 = 'A system error has occured. Please contact customer support with the Error code 500 to resolve this issue.'
export const ErrorUnkown = 'An unknown error has occured. Please contact customer support.'
export const Error5_1 = 'Unable to connect to Server, please try again later or contact customer support with the reason Code: SyntaxError'
export const Error5_2 = 'Unable to connect to Server, please try again later or contact customer support with the reason Code: API Time Out'
export const Error5_3 = 'Unable to connect to Server, please try again later or contact customer support with the reason Code: API (fetch) failed to connect to backend Server'

export const CatchErrorHandler= (error, dispatch, APIFail) => {
  console.log('Error 5', error);
  if (error.message.includes('Token is invalid or expired')) {
    // console.log('Yes message is expired', error);
    const source = 'system'
    dispatch(signout(source))
  }
  if (error.message.includes('Unexpected token')) {
    var UserErrorMessage = Error5_1;
  } else if (error.message === "The user aborted a request.") {
    var UserErrorMessage = Error5_2
  } else if (error.message === "Failed to fetch") {
    var UserErrorMessage = Error5_3;
  } else {
    var UserErrorMessage = error.message
  }
  dispatch({
    type: APIFail,
    payload: UserErrorMessage
  })
}
