// import { useState } from 'react';
import tablestyle from '../../style/table.module.css';
import { HandleOutsideClick } from '../../hooks/handleOutsideClick';
import GenericButton from './genericButton';

const DropDownButton = (props) => {

  // const [isDropDownButtonActive, setIsDropDownButtonActive] = useState(false);
  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  const handleClick = event => {
    props.setIsDropDownButtonActive(current => !current);
  };

  return (
    <div className={tablestyle.dropdownButton}>
      <GenericButton 
        name={props.name}
        onClick={handleClick}
        icon={props.icon}
        iconSize={'25px'}
        disabled={props.disabled || props.loadingList}
      />

      {/* <button
        className={props.className}
        disabled={props.disabled || props.loadingList}
        onClick={handleClick}
      >
        <props.icon />         
        <span className='buttonText'>
          {props.name}
        </span>
      </button> */}
      <div
        className={`${tablestyle.dropdowncontent} ${props.isDropDownButtonActive ? tablestyle.show : ''}`}
        style={{ textAlign: 'left' }}
      >
        <HandleOutsideClick
          show={props.isDropDownButtonActive}
          onClickOutside={async event => { await delay(10); props.setIsDropDownButtonActive(false) }}
          contents={props.contents} />
      </div>
    </div>
  );
};
export default DropDownButton;

