//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'

export const moduleDefinitions = {
  moduleName: 'Tasks: Priority Options',
  moduleNameSingular: 'Task - Status Option',
  moduleLink: '/system/settings/tasks/priority/',
  thOptionsWidth: '115px',
}

export const moduleFields = [
  {
    label: 'Select Order',
    fieldName: 'order',
    fieldType: 'text',
    addFormOrder: 2,
    editFormOrder: 2,
    tableDisplayOrder: 1,
    tableDisplayPriority: 1,
  },
  {
    label: 'Priority',
    fieldName: 'name',
    fieldType: 'text',
    addFormOrder: 1,
    editFormOrder: 1,
    tableDisplayOrder: 2,
    tableDisplayPriority: 2,
  }
]

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
    fieldErrorMessage = ""
  switch (fieldName) {
    case "name":
      break
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

export const TaskPriorityCreateRequest = 'TaskPriorityCreateRequest'
export const TaskPriorityCreateSuccess = 'TaskPriorityCreateSuccess'
export const TaskPriorityCreateFail = 'TaskPriorityCreateFail'
export const TaskPriorityCreateReset = 'TaskPriorityCreateReset'

export const TaskPriorityDetailsRequest = 'TaskPriorityDetailsRequest'
export const TaskPriorityDetailsSuccess = 'TaskPriorityDetailsSuccess'
export const TaskPriorityDetailsFail = 'TaskPriorityDetailsFail'
export const TaskPriorityDetailsReset = 'TaskPriorityDetailsReset'

export const TaskPriorityUpdateRequest = 'TaskPriorityUpdateRequest'
export const TaskPriorityUpdateSuccess = 'TaskPriorityUpdateSuccess'
export const TaskPriorityUpdateFail = 'TaskPriorityUpdateFail'
export const TaskPriorityUpdateReset = 'TaskPriorityUpdateReset'

export const TaskPriorityDeleteRequest = 'TaskPriorityDeleteRequest'
export const TaskPriorityDeleteSuccess = 'TaskPriorityDeleteSuccess'
export const TaskPriorityDeleteFail = 'TaskPriorityDeleteFail'
export const TaskPriorityDeleteReset = 'TaskPriorityDeleteReset'

export const TaskPriorityList_MYRequest = 'TaskPriorityList_MYRequest'
export const TaskPriorityList_MYSuccess = 'TaskPriorityList_MYSuccess'
export const TaskPriorityList_MYFail = 'TaskPriorityList_MYFail'
export const TaskPriorityList_MYReset = 'TaskPriorityList_MYReset'

export const TaskPriorityListRequest = 'TaskPriorityListRequest'
export const TaskPriorityListSuccess = 'TaskPriorityListSuccess'
export const TaskPriorityListFail = 'TaskPriorityListFail'



const APIVariablesCreate = {
  APIRequest: TaskPriorityCreateRequest,
  APISuccess: TaskPriorityCreateSuccess,
  APIFail: TaskPriorityCreateFail,
  APIEndPoint: 'tasks/v1/priority/add',
}
const APIVariablesListAll = {
  APIRequest: TaskPriorityListRequest,
  APISuccess: TaskPriorityListSuccess,
  APIFail: TaskPriorityListFail,
  APIEndPoint: 'tasks/v1/priority/list'
}
const APIVariablesItemDetails = {
  APIRequest: TaskPriorityDetailsRequest,
  APISuccess: TaskPriorityDetailsSuccess,
  APIFail: TaskPriorityDetailsFail,
  APIEndPoint: 'tasks/v1/priority/'
}
const APIVariablesUpdate = {
  APIRequest: TaskPriorityUpdateRequest,
  APISuccess: TaskPriorityUpdateSuccess,
  APISuccess_2: TaskPriorityDetailsSuccess,
  APIFail: TaskPriorityUpdateFail,
  APIEndPoint: 'tasks/v1/priority/'
}
const APIVariablesDelete = {
  APIRequest: TaskPriorityDeleteRequest,
  APISuccess: TaskPriorityDeleteSuccess,
  APIFail: TaskPriorityDeleteFail,
  APIEndPoint: 'tasks/v1/priority/'
}

export const TaskPriorityCreateAPI = (item) => async (dispatch, getState) => {
  const cleandate = item.due_date === '' ? null : item.due_date;
  const cleantime = item.due_time === '' ? null : item.due_time;

  const API_Body = {
    order: item.order,
    name: item.name,
  }
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const TaskPriorityListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const TaskPriorityDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const TaskPriorityUpdateAPI = (item) => async (dispatch, getState) => {
  const cleandate = item.due_date === '' ? null : item.due_date;
  const cleantime = item.due_time === '' ? null : item.due_time;

  const API_Body = {
    order: item.order,
    name: item.name,
  }

  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}


export const TaskPriorityDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

// State is an empty object
export const TaskPriorityCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TaskPriorityCreateRequest:
      return {
        loading: true
      }

    case TaskPriorityCreateSuccess:
      return {
        loading: false,
        success: true,
        TaskPriority: action.payload
      }

    case TaskPriorityCreateFail:
      return {
        loading: false,
        error: action.payload
      }

    case TaskPriorityCreateReset:
      return {}


    default:
      return state
  }
}

export const TaskPriorityUpdateReducer = (state = { TaskPriority: {} }, action) => {
  switch (action.type) {
    case TaskPriorityUpdateRequest:
      return { loading: true }

    case TaskPriorityUpdateSuccess:
      return { loading: false, success: true }

    case TaskPriorityUpdateFail:
      return { loading: false, error: action.payload }

    case TaskPriorityUpdateReset:
      return { TaskPriority: {} }

    default:
      return state
  }
}

export const TaskPriorityDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TaskPriorityDeleteRequest:
      return { loading: true }

    case TaskPriorityDeleteSuccess:
      return {
        loading: false,
        success: true
      }

    case TaskPriorityDeleteFail:
      return { loading: false, error: action.payload }

    case TaskPriorityDeleteReset:
      return {}

    default:
      return state
  }
}

export const TaskPriorityDetailsReducer = (state = { loading: true, TaskPriority: [] }, action) => {
  switch (action.type) {
    case TaskPriorityDetailsRequest:
      return {
        ...state,
        loading: true
      }

    case TaskPriorityDetailsSuccess:
      return {
        loading: false,
        success: true,
        TaskPriority: action.payload
      }

    case TaskPriorityDetailsFail:
      return {
        loading: false,
        error: action.payload,
        TaskPriority: 'error'
      }

    case TaskPriorityDetailsReset:
      return { TaskPriority: {} }

    default:
      return state
  }
}

export const TaskPriorityListReducer = (state = { TaskPriority: [], fieldOptionValues: [] }, action) => {
  switch (action.type) {
    case TaskPriorityListRequest:
      return {
        loading: true
      }

    case TaskPriorityListSuccess:
      return {
        loading: false,
        success: true,
        fieldOptionValues: [...action.payload],
        TaskPriority: action.payload
      }

    case TaskPriorityListFail:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function PriorityListScreen() {

  const createAPI = TaskPriorityCreateAPI
  const listAPI = TaskPriorityListAllAPI
  const detailsAPI = TaskPriorityDetailsAPI
  const updateAPI = TaskPriorityUpdateAPI
  const deleteAPI = TaskPriorityDeleteAPI

  const resetCreate = TaskPriorityCreateReset
  const resetUpdate = TaskPriorityUpdateReset
  const resetViewDetails = TaskPriorityDetailsReset
  const resetDelete = TaskPriorityDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const TaskPriorityCreate = useSelector(state => state.TaskPriorityCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = TaskPriorityCreate

  const TaskPriorityList = useSelector(state => state.TaskPriorityList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    TaskPriority: dataList } = TaskPriorityList

  const TaskPriorityDetails = useSelector(state => state.TaskPriorityDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    TaskPriority: dataItem } = TaskPriorityDetails

  const TaskPriorityUpdate = useSelector(state => state.TaskPriorityUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = TaskPriorityUpdate

  const TaskPriorityDelete = useSelector(state => state.TaskPriorityDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = TaskPriorityDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingDetails
    )
  )

  useEffect(() => {
    dispatch(listAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }
  }, [successDelete, successUpdate,
    errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete])


  return (
    <ModuleHome
      // Shared items
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      rowOnClickAction='edit'
      add={true}
      edit={true}
      view={false}
      delete={(id) => dispatch(deleteAPI(id))}

      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      getDataList={listAPI}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}
    />
  )
}

export default PriorityListScreen