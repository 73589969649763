//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
// APIs
import {ItemCreate, ItemListAll,ItemDetails,ItemUpdate, ItemDelete} from '../../../APIs/genericAPIs'

export const moduleDefinitions = {
  moduleName: 'Event Visibility',
  moduleNameSingular: 'Event Visibility',
  moduleLink: '/system/settings/events/visibility/',
}

export const moduleFields = [
  {
    label: 'Event Visibility',
    fieldName: 'eventvisibility',
    fieldType: 'text',
    addFormOrder: 1,
    editFormOrder: 1,
    tableDisplayOrder: 1,
    tableDisplayPriority: 1,
  },
]

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
      fieldErrorMessage = ""
  switch (fieldName) {
    case "type":
      break
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}

const APIVariablesCreate = {
  APIRequest: 'EventVisibilityCreateRequest',
  APISuccess: 'EventVisibilityCreateSuccess' ,
  APIFail: 'EventVisibilityCreateFail' ,
  APIEndPoint: 'events/v1/visibility/add',
}
const APIVariablesListAll = {
  APIRequest: 'EventVisibilityListRequest' ,
  APISuccess: 'EventVisibilityListSuccess' ,
  APIFail: 'EventVisibilityListFail' ,
  APIEndPoint: 'events/v1/visibility/list'
}
const APIVariablesSelectOptions = {
  APIRequest: 'EventVisibilitySelectOptionsRequest',
  APISuccess: 'EventVisibilitySelectOptionsSuccess',
  APIFail: 'EventVisibilitySelectOptionsFail',
  APIEndPoint: 'events/v1/visibility/selectoptions'
}
const APIVariablesItemDetails = {
  APIRequest: 'EventVisibilityDetailsRequest' ,
  APISuccess: 'EventVisibilityDetailsSuccess' ,
  APIFail: 'EventVisibilityDetailsFail' ,
  APIEndPoint: 'events/v1/visibility/'
}
const APIVariablesUpdate = {
  APIRequest: 'EventVisibilityUpdateRequest' ,
  APISuccess: 'EventVisibilityUpdateSuccess ',
  APISuccess_2: 'EventVisibilityDetailsSuccess',
  APIFail: 'EventVisibilityUpdateFail' ,
  APIEndPoint: 'events/v1/visibility/'
}
const APIVariablesDelete = {
  APIRequest: 'EventVisibilityDeleteRequest',
  APISuccess: 'EventVisibilityDeleteSuccess' ,
  APIFail: 'EventVisibilityDeleteFail' ,
  APIEndPoint: 'events/v1/visibility/'
}

export const EventVisibilityCreateAPI = (item) => async (dispatch, getState) => {  
  const API_Body = {
    eventvisibility: item.eventvisibility,
      }
  ItemCreate(API_Body,APIVariablesCreate,dispatch,getState)
} 

export const EventVisibilityListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll,dispatch,getState)
}
export const EventVisibilitySelectOptionsAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesSelectOptions,dispatch,getState)
}

export const EventVisibilityDetailsAPI = (itemId)  => async (dispatch, getState) => { 
  ItemDetails(itemId,APIVariablesItemDetails,dispatch,getState)
}

export const EventVisibilityUpdateAPI = (item) => async (dispatch, getState) => {
  const API_Body = {
    eventvisibility: item.eventvisibility,
  }
  ItemUpdate(item,API_Body,APIVariablesUpdate,dispatch, getState)
}

export const EventVisibilityDeleteAPI = (itemId) => async (dispatch, getState) => { 
  ItemDelete(itemId,APIVariablesDelete,dispatch,getState) 
}


// State is an empty object
export const EventVisibilityCreateReducer = (state = {}, action) => {
  switch (action.type) {
      case 'EventVisibilityCreateRequest':
          return {
              loading: true
          }

      case 'EventVisibilityCreateSuccess':
          return {
              loading: false,
              success: true,
              status: action.payload
          }

      case 'EventVisibilityCreateFail':
          return {
              loading: false,
              error: action.payload
          }

      case 'EventVisibilityCreateReset':
          return {}


      default:
          return state
  }
}

export const EventVisibilityUpdateReducer = (state = { status: {} }, action) => {
  switch (action.type) {
      case 'EventVisibilityUpdateRequest':
          return { loading: true }

      case 'EventVisibilityUpdateSuccess':
          return { loading: false, success: true }

      case 'EventVisibilityUpdateFail':
          return { loading: false, error: action.payload }

      case 'EventVisibilityUpdateReset':
          return { status: {} }

      default:
          return state
  }
}

export const EventVisibilityDeleteReducer = (state = {}, action) => {
  switch (action.type) {
      case 'EventVisibilityDeleteRequest':
          return { loading: true }

      case 'EventVisibilityDeleteSuccess':
          return { 
            loading: false, 
            success: true }

      case 'EventVisibilityDeleteFail':
          return { loading: false, error: action.payload }

      case 'EventVisibilityDeleteReset':
            return { }

      default:
          return state
  }
}

export const EventVisibilityDetailsReducer = (state = { loading: true, status: [] }, action) => {
  switch (action.type) {
      case 'EventVisibilityDetailsRequest':
          return {
              ...state,
              loading: true
          }

      case 'EventVisibilityDetailsSuccess':
          return {
              loading: false,
              success: true,
              status: action.payload
          }

      case 'EventVisibilityDetailsFail':
          return {
              loading: false,
              error: action.payload,
              status: 'error'
          }

      case 'EventVisibilityDetailsReset':
        return { status: {} }

      default:
          return state
  }
}


export const EventVisibilityListReducer = (state = { statuss: [],fieldOptionValues:[] }, action) => {
  switch (action.type) {
      case 'EventVisibilityListRequest':
          return {
              loading: true
          }

      case 'EventVisibilityListSuccess':
          return {
              loading: false,
              fieldOptionValues: [...action.payload],
              success: true,
              statuss: action.payload
          }

      case 'EventVisibilityListFail':
          return {
              loading: false,
              error: action.payload
          }
      default:
          return state
  }
}

export const EventVisibilitySelectOptionsReducer = (state = { fieldOptionValues: [] }, action) => {
  switch (action.type) {
    case 'EventVisibilitySelectOptionsRequest':
      return {
        loading: true
      }

    case 'EventVisibilitySelectOptionsSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
      }

    case 'EventVisibilitySelectOptionsFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function EventVisibilityScreen() {

  const createAPI = EventVisibilityCreateAPI
  const listAPI = EventVisibilityListAllAPI
  const detailsAPI = EventVisibilityDetailsAPI
  const updateAPI = EventVisibilityUpdateAPI
  const deleteAPI = EventVisibilityDeleteAPI

  const resetCreate = 'EventVisibilityCreateReset'
  const resetUpdate = 'EventVisibilityUpdateReset'
  const resetViewDetails = 'EventVisibilityDetailsReset'
  const resetDelete = 'EventVisibilityDeleteReset'

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const EventVisibilityCreate = useSelector(state => state.EventVisibilityCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = EventVisibilityCreate

  const EventVisibilityList = useSelector(state => state.EventVisibilityList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    statuss: dataList } = EventVisibilityList

  const EventVisibilityDetails = useSelector(state => state.EventVisibilityDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    status: dataItem } = EventVisibilityDetails

  const EventVisibilityUpdate = useSelector(state => state.EventVisibilityUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = EventVisibilityUpdate

  const EventVisibilityDelete = useSelector(state => state.EventVisibilityDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = EventVisibilityDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingDetails
    )
  )


  useEffect(() => {
    dispatch(listAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
     if (errorLoadingList) {setServerErrorMessage(errorLoadingList)}
    if (errorCreate) {setServerErrorMessage(errorCreate)}
    if (errorUpdate) {setServerErrorMessage(errorUpdate)}
    if (errorGetDetails) {setServerErrorMessage(errorGetDetails)}
    if (errorDelete) {setServerErrorMessage(errorDelete)}
  }, [successDelete, successUpdate,
    errorLoadingList,errorCreate,errorUpdate,errorGetDetails, errorDelete])


  return (
    <ModuleHome
      // Shared items
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      rowOnClickAction='edit'
      add={true}
      edit={true}
      view={false}
      delete={(id) => dispatch(deleteAPI(id))}

      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      getDataList={listAPI}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}
    />
  )
}

export default EventVisibilityScreen