import { useState } from "react";

const TestScreen = () => {

  const [ numberOfIncompleteTasks, setNumberOfIncompleteTasks ] = useState (0) 
  // function TodoFooter({
  //   numberOfIncompleteTasks
  // })


  function TodoFooter({
    numberOfIncompleteTasks
}) {
    return (
        <div className="todo-footer">
            <p>{numberOfIncompleteTasks} {numberOfIncompleteTasks === 1 ? "task" : "tasks"} left</p>
            {/* <Link to="/followers">Followers</Link> */}
        </div>
    )
}

return (
  <>
    <div>
      <h1 data-testid="header1" >MMCY Dashboard</h1>
      <h3 title="Header">MMCY Dashboar</h3>
    </div>
    <TodoFooter numberOfIncompleteTasks={numberOfIncompleteTasks}/>
  </>
  )
};
export default TestScreen;
