//  React
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// Style
import formstyle from '../../style/form.module.css';
// Components
import ResponsiveContainer from './responsiveContatiner';
import Loader from '../loading/spinner';
import EditButton from '../buttons/editButton';
import ViewFields from '../fields/viewFields/viewFields'
// Hooks
import "../../hooks/tabs/tabs.css"
import Tabs from '../../hooks/tabs/useTabs';
import Tab from './viewTab';

const ViewModule = (props) => {
  const [fieldValues, setFieldValues] = useState([]);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  ///////////// Section 1 - Define fields////////////////////////////////
  const initialFieldValues = props.moduleFields.map((fields) => {
    return {
      fieldName: fields.fieldName,
      fieldLongName: fields.fieldLongName,
      value: '',
    };
  })

  // Create initial field values when screen first loads
  useEffect(() => {
    setFieldValues(initialFieldValues)
  }, [])

  useEffect(() => {
    // console.log('props.dataItem',props.dataItem)
    // console.log('props.itemId',props.itemId)
    if (props.dataItem === undefined || props.dataItem.id !== Number(props.itemId)) {
      dispatch(props.itemDetails(props.itemId))
    }
    else {
      updateAllFieldValues(props.dataItem)
    }
  }, [props.dataItem])

  const fieldValue = (field) => {
    const fieldstate = fieldValues.find(e => e.fieldName === field)
    if (fieldstate === undefined) { return '' } else { return fieldstate.value }
  }

  const fieldValueName = (field) => {
    const fieldstate = fieldValues.find(e => e.fieldName === field)
    if (fieldstate === undefined) { return '' } else { return fieldstate.valueName }
  }

  const updateAllFieldValues = (item) => {
    // update fields with database values
    const dbFieldValues = props.moduleFields.map((fields) => {
      return {
        fieldName: fields.fieldName,
        valueName: item[fields.fieldLongName],
        value: item[fields.fieldName],
      };
    })
    setFieldValues(dbFieldValues)
  }

  const heading = (
    <>
      <EditButton
        edit={props.edit}
        dataItem={props.dataItem}
        setFormModalVisibility={props.setFormModalVisibility}
        setFormTypeAction={props.setFormTypeAction}
        setTableRowClickItemID={props.setTableRowClickItemID}

      />
      {/* <button
        className={`${formstyle.blue} ${formstyle.editButton}`}
        onClick={() => props.edit(props.dataItem)}
      >
        <MdOutlineModeEditOutline />Edit
      </button> */}
      {props.loading ? <Loader /> :
        props.dataItem === undefined ? <span className={formstyle.errormessage}>Error</span> :
          !props.viewHeadingName ? props.dataItem.name : props.viewHeadingName}
    </>
  )

  // console.log('serverErrorMessage',props.serverErrorMessage)

  const currencyField = (field) => {
    // console.log('currencyField', field.fieldName, field.currencyCode)
    const getCurrencyCodeValue = fieldValueName(field.currencyCode)
    // console.log('getCurrencyCodeValue', getCurrencyCodeValue)
    if (getCurrencyCodeValue) {
      return (
        new Intl.NumberFormat("en-GB", {
          style: "currency",
          currency: getCurrencyCodeValue
        }).format(fieldValue(field.fieldName))
      )
    } else {
      return ''
    }
  }


  const checkboxField = (field) => {
    return (
      fieldValue(field.fieldName) === true ? 'Yes' : 'No'
    )
  }

  const dateField = (field) => {
    const date = fieldValue(field.fieldName)
    // console.log('date',date)
    // const value = fieldValue(field.fieldName)
    // const dateArray = value.split('-')
    // console.log('dateArray',dateArray)
    // const year = dateArray[0]
    // const month = dateArray[1]
    // const day = dateArray[2]

    // console.log(day,'/',month,'/',year)
    if (date) {
      const jsDate = new Date(date)
      // console.log('jsDate',jsDate)
      return (
        new Intl.DateTimeFormat("en-GB").format(jsDate)
      )
    } else {
    return 'not recorded'
    }
  }

  const timeField = (field) => {
    const time = fieldValue(field.fieldName)
    // console.log('time', time)

    if (time) {
      const timeArray = time.split(':')
      const hour = timeArray[0]
      const minutes = timeArray[1]
      return ( hour + ':' + minutes ) 
      } else {
      return 'not recorded'
    }
  }

  const genericField = (field) => {
    return (
      fieldValueName(field.fieldName) === undefined ? fieldValue(field.fieldName) : fieldValueName(field.fieldName)
    )
  }

  const contents = (
    <>
      {props.loading ? <Loader /> :
        <>
          {props.serverErrorMessage && <div className={formstyle.errormessage}>{props.serverErrorMessage}</div>}
          <form>
            <br></br>
            {props.moduleFields
              .sort((a, b) => a.viewOrder - b.viewOrder)
              .map((field) => {
                if (field.viewOrder !== 0)
                  return (
                    <>
                      {field.fieldGroupHeading && <p style={{ fontWeight: 'bold', marginBottom: '0px', marginTop: '5px' }}>{field.fieldGroupHeading}</p>}
                      <div
                        className={`${formstyle.input} 
                          ${field.fieldGroupPosition ?
                            field.fieldGroupPosition === "Left" ? formstyle.fieldGroupLeft :
                              field.fieldGroupPosition === "Middle" ? formstyle.fieldGroupMiddle :
                                field.fieldGroupPosition === "Right" && formstyle.fieldGroupRight :
                            formstyle.fieldGroupNone}
                          `}
                        style={{ width: field.fieldGroupSize }}
                      >
                        <label htmlFor={field.fieldName}>{field.label}: </label>
                        <span>
                        {/* <ViewFields
                        field={field}
                        fieldType={field.fieldType}
                        fieldName={field.fieldName}
                        fieldValue={fieldValue}
                        fieldValueName={fieldValueName}
                        /> */}

                          {field.fieldType === 'text' && genericField(field)}
                          {field.fieldType === 'autoCompleteText' && genericField(field)}

                          {field.fieldType === 'date' && dateField(field)}
                          {field.fieldType === 'time' && timeField(field)}
                          {field.fieldType === 'checkbox' && checkboxField(field)}
                          {field.fieldType === 'currency' && currencyField(field)}
                          {field.fieldType === 'number' && genericField(field)}

                          {field.fieldType === 'select' && genericField(field)}
                          {field.fieldType === 'selectnoid' && genericField(field)}
                          {field.fieldType === 'selectfromdb' && genericField(field)}
                          {field.fieldType === 'textarea' && genericField(field)}

                        </span>
                      </div>
                    </>
                  )
              })}
          </form>
        </>
      }
      {!props.viewTabs ? '' :
        <Tabs>
          {props.viewTabs.map((tab) => {
            return (
              <div label={tab.label}>
                <Tab
                  itemId={props.itemId}
                  fieldValue={fieldValue}
                  dataList={tab.dataList}

                  viewLinkIDField={tab.viewLinkIDField}
                  tabContent={tab.tabContent}
                  tableFields={tab.tableFields}
                  loading={tab.loading}
                  error={tab.error}

                  addLink={tab.addLink}
                  addLinkRef={tab.addLinkRef}
                  addadditionalLinkRef={tab.addadditionalLinkRef}

                  // view={(dataitem) => navigate(tab.viewLink + 'view:' + dataitem.id)}
                  viewLink={tab.viewLink}

                  // editLink={() => navigate(props.editLink + 'edit')}
                  editLink={tab.editLink}
                  edit={tab.edit}
                  delete={tab.delete}

                  setFormTypeAction={props.setFormTypeAction}
                  setFormModalVisibility={props.setFormModalVisibility}
                />
              </div>
            )
          })}
        </Tabs>
      }
    </>
  )

  return (
    <ResponsiveContainer
      heading={heading}
      contents={contents}
    />
  );
};
export default ViewModule;