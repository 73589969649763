import FixedContainer from '../../../../components/containers/fixedContatiner';

const MFASetup = () => {
  const heading = 'Setup 2FA'
  const contents = ( <>
<p>Coming Soon</p>
    </>)

  return (
    <FixedContainer
      heading={heading}
      contents={contents}
    />
  );
};
export default MFASetup;