import formstyle from '../../style/form.module.css';
import { MdSearch, MdClear } from 'react-icons/md';
import { useState } from 'react';

function SearchField(props) {

  const [searchInput, setSearchInput] = useState('')

  
  const handleSearch = (searchValue) => {
    setSearchInput(searchValue)
    if (searchInput !== '') {
      const searchedData = props.datalist.filter((item) => {
        return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase())
      })
      props.setSearchResults(searchedData)
    }
    else {
      props.setSearchResults(props.datalist)
    }
  }

  const handleSearchClear = () => {
    setSearchInput('')
    props.setSearchResults(props.datalist)
  }

  return (
    <div
        className={formstyle.wrapper}
        style={{
          position: 'relative',
          // right:'5px'
        }}>     
      
      <MdSearch 
            style={{
              position: 'relative',
              top: '7px',
              left:'30px',
              fontSize: '22px',
              // color: 'red',
              // cursor: 'pointer',
            }}
            />
      <input
        className={formstyle.searchBox}
        style={{ marginLeft: 5 }}
        type="text"
        placeholder={(props.moduleName? "Search " + props.moduleName + "..." : 'Search...')}
        value={searchInput}
        onChange={e => handleSearch(e.target.value)}
      />

      {searchInput && 
      <MdClear 
      style={{
        position: 'relative',
        top: '5px',
        right:'25px',
        fontSize: '20px',
        // color: 'red',
        cursor: 'pointer',
      }}
      
      onClick={()=>handleSearchClear()}/>}
      {/* {<MdClear onClick={() => handleSearchClear()} />} */}
    </div>
  )
}
export default SearchField