import style from '../../../style/global.module.css';
import formstyle from '../../../style/form.module.css';
import { Link } from "react-router-dom";
import mmcylogo from '../../../static/PrimaryColourTransparentNoWordsBorder.png';

const UserSignedOut = (props) => {
  return (
    <div className={style.section}>
      <div className={`${style.fixedcontainer} ${style.center} containter`}>
        <img
          className={style.mmcylogo}
          src={mmcylogo}
          alt="MMCY Business Solutions Logo" />
        {props.source === 'user' ?
          <>
            <h2>Signed Out</h2>
            <p>You have successfully signed out of the portal</p>
          </> : <>
            <h2>Your session has ended</h2>
            <p>To keep your account secure you will need to sign in again to access the portal</p>
          </>}
        <Link to='/signin'>
          <button className={formstyle.darkblue}>Sign In</button>
        </Link>
      </div>
    </div>
  );
};
export default UserSignedOut;