//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'

export const moduleDefinitions = {
  moduleName: 'Checklist Criteria',
  moduleNameSingular: 'Checklist Criteria',
  moduleLink: '/system/settings/inventory/checklist/criteria/',
  thOptionsWidth: '115px',
}

export const moduleFields = [
  {
    label: 'Criteria', 
    mandatory: 'true', 
    fieldName: 'name',
    fieldType: 'text',
    addFormOrder: '',
    editFormOrder: '',
    tableDisplayOrder: '',
    tableDisplayPriority: '',
  }
]

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
      fieldErrorMessage = ""

  switch (fieldName) {

    case "name":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Criteria is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break
      
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}


  ///////////// Constants /////////////////////////
export const InventoryChecklistCriteriaCreateRequest = 'InventoryChecklistCriteriaCreateRequest'
export const InventoryChecklistCriteriaCreateSuccess = 'InventoryChecklistCriteriaCreateSuccess'
export const InventoryChecklistCriteriaCreateFail = 'InventoryChecklistCriteriaCreateFail'
export const InventoryChecklistCriteriaCreateReset = 'Inventory_Checklist_CriteriaReset'

export const InventoryChecklistCriteriaDetailsRequest = 'InventoryChecklistCriteriaDetailsRequest'
export const InventoryChecklistCriteriaDetailsSuccess = 'InventoryChecklistCriteriaDetailsSuccess'
export const InventoryChecklistCriteriaDetailsFail = 'InventoryChecklistCriteriaDetailsFail'
export const InventoryChecklistCriteriaDetailsReset = 'InventoryChecklistCriteriaDetailsReset'

export const InventoryChecklistCriteriaUpdateRequest = 'InventoryChecklistCriteriaUpdateRequest'
export const InventoryChecklistCriteriaUpdateSuccess = 'InventoryChecklistCriteriaUpdateSuccess'
export const InventoryChecklistCriteriaUpdateFail = 'InventoryChecklistCriteriaUpdateFail'
export const InventoryChecklistCriteriaUpdateReset = 'InventoryChecklistCriteriaUpdateReset'

export const InventoryChecklistCriteriaDeleteRequest = 'InventoryChecklistCriteriaDeleteRequest'
export const InventoryChecklistCriteriaDeleteSuccess = 'InventoryChecklistCriteriaDeleteSuccess'
export const InventoryChecklistCriteriaDeleteFail = 'InventoryChecklistCriteriaDeleteFail'
export const InventoryChecklistCriteriaDeleteReset = 'InventoryChecklistCriteriaDeleteReset'

export const InventoryChecklistCriteriaListRequest = 'InventoryChecklistCriteriaListRequest'
export const InventoryChecklistCriteriaListSuccess = 'InventoryChecklistCriteriaListSuccess'
export const InventoryChecklistCriteriaListFail = 'InventoryChecklistCriteriaListFail'


  ///////////// API Config /////////////////////////

const APIVariablesCreate = {
  APIRequest: InventoryChecklistCriteriaCreateRequest,
  APISuccess: InventoryChecklistCriteriaCreateSuccess,
  APIFail: InventoryChecklistCriteriaCreateFail,
  APIEndPoint: 'inventory/v1/checklist/criteria/add',
}
const APIVariablesListAll = {
  APIRequest: InventoryChecklistCriteriaListRequest,
  APISuccess: InventoryChecklistCriteriaListSuccess,
  APIFail: InventoryChecklistCriteriaListFail,
  APIEndPoint: 'inventory/v1/checklist/criteria/list'
}
const APIVariablesItemDetails = {
  APIRequest: InventoryChecklistCriteriaDetailsRequest,
  APISuccess: InventoryChecklistCriteriaDetailsSuccess,
  APIFail: InventoryChecklistCriteriaDetailsFail,
  APIEndPoint: 'inventory/v1/checklist/criteria/'
}
const APIVariablesUpdate = {
  APIRequest: InventoryChecklistCriteriaUpdateRequest,
  APISuccess: InventoryChecklistCriteriaUpdateSuccess,
  APISuccess_2: InventoryChecklistCriteriaDetailsSuccess,
  APIFail: InventoryChecklistCriteriaUpdateFail,
  APIEndPoint: 'inventory/v1/checklist/criteria/'
}
const APIVariablesDelete = {
  APIRequest: InventoryChecklistCriteriaDeleteRequest,
  APISuccess: InventoryChecklistCriteriaDeleteSuccess,
  APIFail: InventoryChecklistCriteriaDeleteFail,
  APIEndPoint: 'inventory/v1/checklist/criteria/'
}

export const InventoryChecklistCriteriaCreateAPI = (item) => async (dispatch, getState) => {

  const API_Body = {
    name: item.name,
  }
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const InventoryChecklistCriteriaListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const InventoryChecklistCriteriaDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const InventoryChecklistCriteriaUpdateAPI = (item) => async (dispatch, getState) => {

  const API_Body = {
    name: item.name,
  }
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const InventoryChecklistCriteriaDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}
  
  ///////////// REDUCERS/////////////////////////
  // State is an empty object
  export const InventoryChecklistCriteriaCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case InventoryChecklistCriteriaCreateRequest:
            return {
                loading: true
            }
  
        case InventoryChecklistCriteriaCreateSuccess:
            return {
                loading: false,
                success: true,
                InventoryChecklistCriteria: action.payload
            }
  
        case InventoryChecklistCriteriaCreateFail:
            return {
                loading: false,
                error: action.payload
            }
  
        case InventoryChecklistCriteriaCreateReset:
            return {}
  
  
        default:
            return state
    }
  }
  
  export const InventoryChecklistCriteriaUpdateReducer = (state = { InventoryChecklistCriteria: {} }, action) => {
    switch (action.type) {
        case InventoryChecklistCriteriaUpdateRequest:
            return { loading: true }
  
        case InventoryChecklistCriteriaUpdateSuccess:
            return { loading: false, success: true }
  
        case InventoryChecklistCriteriaUpdateFail:
            return { loading: false, error: action.payload }
  
        case InventoryChecklistCriteriaUpdateReset:
            return { InventoryChecklistCriteria: {} }
  
        default:
            return state
    }
  }
  
  export const InventoryChecklistCriteriaDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case InventoryChecklistCriteriaDeleteRequest:
            return { loading: true }
  
        case InventoryChecklistCriteriaDeleteSuccess:
            return { 
              loading: false, 
              success: true }
  
        case InventoryChecklistCriteriaDeleteFail:
            return { loading: false, error: action.payload }
  
        case InventoryChecklistCriteriaDeleteReset:
              return { }
  
        default:
            return state
    }
  }
  
  export const InventoryChecklistCriteriaDetailsReducer = (state = { loading: true, InventoryChecklistCriteria: [] }, action) => {
    switch (action.type) {
        case InventoryChecklistCriteriaDetailsRequest:
            return {
                ...state,
                loading: true
            }
  
        case InventoryChecklistCriteriaDetailsSuccess:
            return {
                loading: false,
                InventoryChecklistCriteria: action.payload
            }
  
        case InventoryChecklistCriteriaDetailsFail:
            return {
                loading: false,
                error: action.payload
            }
  
  
        default:
            return state
    }
  }
   
  
  export const InventoryChecklistCriteriaListReducer = (state = { InventoryChecklistCriteria: [] }, action) => {
    switch (action.type) {
        case InventoryChecklistCriteriaListRequest:
            return {
                loading: true
            }
  
        case InventoryChecklistCriteriaListSuccess:
            return {
                loading: false,
                fieldOptionValues: [...action.payload],
                InventoryChecklistCriteria: action.payload
            }
  
        case InventoryChecklistCriteriaListFail:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
  }

function InventoryChecklistCriteriaListScreen() {
  const createAPI = InventoryChecklistCriteriaCreateAPI
  const listAPI = InventoryChecklistCriteriaListAllAPI
  const detailsAPI = InventoryChecklistCriteriaDetailsAPI
  const updateAPI = InventoryChecklistCriteriaUpdateAPI
  const deleteAPI = InventoryChecklistCriteriaDeleteAPI

  const resetCreate = InventoryChecklistCriteriaCreateReset
  const resetUpdate = InventoryChecklistCriteriaUpdateReset
  const resetViewDetails = InventoryChecklistCriteriaDetailsReset
  const resetDelete = InventoryChecklistCriteriaDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const InventoryChecklistCriteriaCreate = useSelector(state => state.InventoryChecklistCriteriaCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = InventoryChecklistCriteriaCreate

  const InventoryChecklistCriteriaList = useSelector(state => state.InventoryChecklistCriteriaList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    InventoryChecklistCriteria: dataList } = InventoryChecklistCriteriaList

  const InventoryChecklistCriteriaDetails = useSelector(state => state.InventoryChecklistCriteriaDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    InventoryChecklistCriteria: dataItem } = InventoryChecklistCriteriaDetails

  const InventoryChecklistCriteriaUpdate = useSelector(state => state.InventoryChecklistCriteriaUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = InventoryChecklistCriteriaUpdate

  const InventoryChecklistCriteriaDelete = useSelector(state => state.InventoryChecklistCriteriaDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = InventoryChecklistCriteriaDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingList || loadingDetails
    )
  )

  useEffect(() => {
    dispatch(listAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
     if (errorLoadingList) {setServerErrorMessage(errorLoadingList)}
    if (errorCreate) {setServerErrorMessage(errorCreate)}
    if (errorUpdate) {setServerErrorMessage(errorUpdate)}
    if (errorGetDetails) {setServerErrorMessage(errorGetDetails)}
    if (errorDelete) {setServerErrorMessage(errorDelete)}
  }, [successDelete, successUpdate,
    errorLoadingList,errorCreate,errorUpdate,errorGetDetails, errorDelete])


  return (
    <ModuleHome
    formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
      // Shared items
      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      rowOnClickAction='edit'
      add={true}
      edit={true}
      view={false}
      delete={(id) => dispatch(deleteAPI(id))}
      
      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      getDataList={listAPI}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}
     
        />

  )
}

export default InventoryChecklistCriteriaListScreen