//  React
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
// Components
import { ItemListAll } from '../../../APIs/genericAPIs'

const APIVariablesListAll = {
  APIRequest: 'SupportTicketPriorityOptionsListRequest',
  APISuccess: 'SupportTicketPriorityOptionsListSuccess',
  APIFail: 'SupportTicketPriorityOptionsListFail',
  APIEndPoint: 'support/v1/ticket/priorityoptions/list'
}

export const listAllSupportTicketPriorityOptions = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const SupportTicketPriorityOptionsListReducer = (state = { fieldOptionValues: [] }, action) => {
  switch (action.type) {
    case 'SupportTicketPriorityOptionsListRequest':
      return {
        loading: true
      }

    case 'SupportTicketPriorityOptionsListSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
      }

    case 'SupportTicketPriorityOptionsListFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function SupportTicketPriorityOptions() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listAllSupportTicketPriorityOptions())
  }, [])

  return 
}

export default SupportTicketPriorityOptions