import { useState } from "react";

import formstyle from '../../../style/form.module.css'
import { Label } from '../label';
import SelectFromDBField from './selectFromDBField'
import Autocomplete from './autoCompleteField'
import ToggleSwitch from '../../switches/toggleSwitch'
import BasicForm from "../../forms/BasicForm";
import SubForm from "../../forms/SubFrom";

const InputFields = (props) => {

  // Error Messages
  const displayFieldErrorMessage =
    (props.touched && props.hasError &&
      <div className={formstyle.fieldErrorMessage}>
        {props.fieldErrorMessage}
      </div>
    )

  const generic = (
    <>
      <input
        // Standard
        key={props.fieldName}
        id={props.fieldName}
        name={props.fieldName}
        type={props.fieldType}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}

        // Optional
        // mandatory={props.mandatory}
        disabled={props.disabled}

        // Type Specifics 
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
      />
    </>
  )

  const formatDate = (string) => {
    var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return new Date(string).toLocaleDateString([], options);
  }

  const datetimeLocal = () => { 
    const dateValue =()=> {
    if(props.value != undefined || props.value > 0 ) {
      const dbvalue = props.value
      return dbvalue.substring(0,16)
    } else  return props.value
  }

    return (
      <input
      // Standard
      key={props.fieldName}
      id={props.fieldName}
      name={props.fieldName}
      type={props.fieldType}
      value={dateValue(props.value)}
      onChange={props.onChange}
      onBlur={props.onBlur}

      // Optional
      // mandatory={props.mandatory}
      disabled={props.disabled}

      // Type Specifics 
      placeholder={props.placeholder}
      autoComplete={props.autoComplete}
    />
    )
  }
    

  const textarea = (
    <>
      <textarea
        // Standard
        key={props.fieldName}
        id={props.fieldName}
        name={props.fieldName}
        type={props.fieldType}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}

        // Optional
        // mandatory={props.mandatory}
        disabled={props.disabled}

        // Type Specifics 
        rows={props.rows}
      />
    </>
  )

  const select = () => {

    return (
      <>

        <select
          // Standard
          key={props.fieldName}
          id={props.fieldName}
          name={props.fieldName}
          type={props.fieldType}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}

          // Optional
          // mandatory={props.mandatory}
          disabled={props.disabled}

          // Type Specifics 
          // multiple={props.multiple}
        >
          <option
            key='0'
            hidden>
            Select...
          </option>
          {props.options.map((option) => (
            <option key={option.id}
              value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </>
    )
  }

  const selectfromdb = () => {
    return (
      <SelectFromDBField
        {...props}
        // label={props.label}
        id={props.fieldName}
        // fieldName={props.fieldName}
        // fieldType={props.fieldType}
        // value={props.value}
        // onChange={props.onChange}
        // onBlur={props.onBlur}

        // // Optional
        // mandatory={props.mandatory}
        // disabled={props.disabled}

        // // Type Specifics 
        // options={props.options}
        // addFormConditionalSelectBasedOn={props.addFormConditionalSelectBasedOn}
        // addFormConditionalSelectLinkField={props.addFormConditionalSelectLinkField}
        // conditionalSelectFieldValue={props.conditionalSelectFieldValue}

        // multipleSelect={props.multipleSelect}
        // handleMultiSelectAdd={props.handleMultiSelectAdd}
        // handleMultiSelectDelete={props.handleMultiSelectDelete}

        // // For Filters
        // // multiple={props.multiple}
        // filterIsChecked={props.filterIsChecked}
        // handleSetFilterValues={props.handleSetFilterValues}
        // filtermode={props.filtermode}

        // // Modal
        // addNewLink={props.addNewLink}
        // ItemCreate={props.ItemCreate}
      />
    )
  }

  const autoCompleteText = () => {
    // console.log('props.fieldName' ,props.fieldName)
    // console.log('props.fieldType' ,props.fieldType)
    // console.log('props.value', props.value)
    // console.log('props.fieldValueName', props.fieldValueName)
    // console.log('openAddNewModule', props.openAddNewModule)
     return (
      <>
        <Autocomplete
          {...props}
          id={props.fieldName}
          // fieldName={props.fieldName}
          // fieldType={props.fieldType}
          // value={props.value}
          // onChange={props.onChange}
          // onBlur={props.onBlur}

          // Optional
          // mandatory={props.mandatory}
          // placeholder={props.placeholder}
          // disabled={props.disabled}

          // Autocomplete
          // options={props.options}
          // handleChange={props.handleChange}
          // addNewLink={props.addNewLink}
          // For Filters
          // filtermode={props.filtermode}
          // handleSetFilterValues={props.handleSetFilterValues}
          //Filters and edit form
          // fieldValueName={props.fieldValueName}
          // formType={props.formType}

          // openAddNewModule={props.openAddNewModule}
        />
      </>
    )
  }

  const checkbox = (
    <>

      <div className={formstyle.checkboxRow}>
        <input
          key={props.fieldName}
          type="checkbox"
          value={props.value}
          disabled={props.disabled}
          checked={props.fieldIsChecked(props.fieldName)}
          onChange={e => props.handleChange(props.fieldName, props.value ===true? false : true)}
        />
        <span> {props.name}</span>

      </div>
    </>
  )
  const [toggleValue, setToggleValue] = useState(false);
  const toggleSwitch = () => {
    
      return (
  <ToggleSwitch 
   isChecked={toggleValue}
    handleToggle={() => setToggleValue(!toggleValue)}
  
  />
    )
  }
  return (
    <>  
      <div className={`${formstyle.input} 
     
          ${props.fieldGroupPosition ?
          props.fieldGroupPosition === "Left" ? formstyle.fieldGroupLeft :
            props.fieldGroupPosition === "Middle" ? formstyle.fieldGroupMiddle :
              props.fieldGroupPosition === "Right" && formstyle.fieldGroupRight :
          formstyle.fieldGroupNone}
        `}
        style={{ width: props.fieldGroupSize }}
      //  {props.fieldGroupSize && style={{width: props.fieldGroupSize}}}
      >
        {props.label &&
        <Label
          label={props.label}
          addNewLink={props.addNewLink}
          openAddNewModule={props.openAddNewModule}
          mandatory={props.mandatory}
          onClick={(e) => { e.preventDefault(); window.open(props.addNewLink,'_blank', 
            { state: 
              { modal: true ,
              action: 'Add' } })}}
        />
        }
        <div>
          {/* https://www.w3schools.com/tags/att_input_type.asp */}
          {props.fieldType === 'autoCompleteText' && autoCompleteText()}
          {props.fieldType === 'checkbox' && checkbox}
          {props.fieldType === 'currency' && generic}
          {props.fieldType === 'datetime' && generic}
          {props.fieldType === 'date' && generic}
          {props.fieldType === 'datetime-local' && datetimeLocal()}
          {props.fieldType === 'email' && generic}
          {props.fieldType === 'number' && generic}
          {props.fieldType === 'text' && generic}
          {props.fieldType === 'time' && generic}
          {props.fieldType === 'url' && generic}
          {props.fieldType === 'textarea' && textarea}
          {props.fieldType === 'toggle' && toggleSwitch()}
          {props.fieldType === 'select' && select()}
          {props.fieldType === 'selectfromdb' && selectfromdb()}

          {props.fieldType === 'subForm' && SubForm(props)}
        </div>
        {displayFieldErrorMessage}
      </div>

    </>
  )
};
export default InputFields;