//  React
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
// Components
import { ItemListAll } from '../../../APIs/genericAPIs'

const APIVariablesListAll = {
  APIRequest: 'SupportTicketDeviceTypeListRequest',
  APISuccess: 'SupportTicketDeviceTypeListSuccess',
  APIFail: 'SupportTicketDeviceTypeListFail',
  APIEndPoint: 'support/v1/ticket/devicetype/list'
}

export const listAllSupportTicketDeviceType = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}

export const SupportTicketDeviceTypeListReducer = (state = { fieldOptionValues: [] }, action) => {
  switch (action.type) {
    case 'SupportTicketDeviceTypeListRequest':
      return {
        loading: true
      }

    case 'SupportTicketDeviceTypeListSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
      }

    case 'SupportTicketDeviceTypeListFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

function SupportTicketDeviceType() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listAllSupportTicketDeviceType())
  }, [])

  return 
}

export default SupportTicketDeviceType