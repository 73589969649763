//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../../components/containers/moduleHome'
// APIs
import { ItemCreate, ItemListAll, ItemDetails, ItemUpdate, ItemDelete } from '../../../APIs/genericAPIs'
import { SailingLogTripSummaryByContactAPI } from '../trips'
import { SailingLogCrewSummaryByContactAPI } from '../passage/crew'
import { SailingLogBoatSummaryByContactAPI } from '../boats'
import { SailingLogContactQualificationSummaryByContactAPI } from '../contacts/qualifications'

const moduleDefinitions = {
  moduleName: 'Contacts',
  moduleNameSingular: 'Contact',
  moduleLink: '/fun/sailinglog/contacts/',
  linktoView: '/sailinglog/contact/list/items/?listid=',
  thOptionsWidth: '115px',
}

const moduleFields = [
  {
    label: 'Name',
    mandatory: 'true',
    fieldName: 'name',
    fieldType: 'text',
    addFormOrder: 10,
    editFormOrder: 10,
    viewOrder: 10,
    tableDisplayOrder: '',
    tableDisplayPriority: '1',
  }, {
    label: 'Type',
    fieldName: 'contact_type',
    fieldLongName: 'contact_type_name',
    fieldType: 'select',
    // fieldGroupPosition: 'Right',
    options: [{ id: '1', name: 'Person' }, { id: '2', name: 'Organisation' }],
    addFormInitialValue: '1',  // Optional
    addFormOrder: 20,
    editFormOrder: 20,
    viewOrder: 20,
    tableDisplayOrder: '',
    tableDisplayPriority: '2',
  }, {
    label: 'Known As',
    fieldName: 'known_as',
    fieldType: 'text',
    addFormOrder: 30,
    editFormOrder: 30,
    viewOrder: 30,
    tableDisplayOrder: '',
    tableDisplayPriority: '2',
  }, {
    label: 'Phone No.',
    fieldName: 'phone_no',
    fieldType: 'text',
    addFormOrder: 40,
    editFormOrder: 40,
    viewOrder: 40,
    tableDisplayOrder: '',
    tableDisplayPriority: '3',
  }, {
    label: 'Email',
    fieldName: 'email',
    fieldType: 'text',
    addFormOrder: 50,
    editFormOrder: 50,
    viewOrder: 50,
    tableDisplayOrder: '',
    tableDisplayPriority: '4',
  }, {
    label: 'Link',
    fieldName: 'link',
    fieldType: 'url',
    addFormOrder: 55,
    editFormOrder: 55,
    viewOrder: 60,
    tableDisplayOrder: '',
    tableDisplayPriority: '5',
  }, {
    label: 'Notes',
    fieldName: 'notes',
    fieldType: 'textarea',
    rows: "4",
    addFormOrder: 60,
    editFormOrder: 60,
    viewOrder: 70,
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
    tableFilterHide: true,
  }, {
    label: 'Total no. of Passages',
    fieldName: 'total_passages',
    fieldType: 'text',
    fieldGroupPosition: 'Left',
    // fieldGroupSize: '30%',
    addFormOrder: 0,
    editFormOrder: 0,
    viewOrder: 80,
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
    tableFilterHide: true,
  }, {
    label: 'No. of Passages over 60nm',
    fieldName: 'passages_over_60nm',
    fieldType: 'text',
    fieldGroupPosition: 'Right',
    // fieldGroupSize: '30%',
    addFormOrder: 0,
    editFormOrder: 0,
    viewOrder: 81,
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
    tableFilterHide: true,
  }, {
    label: 'Total Miles',
    fieldName: 'total_distance',
    fieldType: 'text',
    fieldGroupPosition: 'Left',
    fieldGroupSize: '30%',
    addFormOrder: 0,
    editFormOrder: 0,
    viewOrder: 82,
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
    tableFilterHide: true,
  }, {
    label: 'Total Miles (tidal)',
    fieldName: 'total_distance_tidal',
    fieldType: 'text',
    fieldGroupPosition: 'Middle',
    fieldGroupSize: '30%',
    addFormOrder: 0,
    editFormOrder: 0,
    viewOrder: 90,
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
    tableFilterHide: true,
  }, {
    label: 'Total Miles (not tidal)',
    fieldName: 'total_distance_not_tidal',
    fieldType: 'text',
    fieldGroupPosition: 'Right',
    fieldGroupSize: '30%',
    addFormOrder: 0,
    editFormOrder: 0,
    viewOrder: 100,
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
    tableFilterHide: true,
  }, {
    label: 'Total Days',
    fieldName: 'total_days',
    fieldType: 'text',
    fieldGroupPosition: 'Left',
    addFormOrder: 0,
    editFormOrder: 0,
    viewOrder: 110,
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
    tableFilterHide: true,
  }, {
    label: 'Total Days (as Skipper)',
    fieldName: 'days_as_skipper',
    fieldType: 'text',
    fieldGroupPosition: 'Right',
    addFormOrder: 0,
    editFormOrder: 0,
    viewOrder: 120,
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
    tableFilterHide: true,
  }, {
    label: 'Night Hours',
    fieldName: 'night_hours',
    fieldType: 'text',
    addFormOrder: 0,
    editFormOrder: 0,
    viewOrder: 130,
    tableDisplayOrder: '',
    tableDisplayPriority: '0',
    tableFilterHide: true,
  },
]

const fieldValidation = (fieldName, value) => {
  let hasError = false,
    fieldErrorMessage = ""

  switch (fieldName) {

    case "name":
      if (value.trim() === "") {
        hasError = true
        fieldErrorMessage = "Name is required"
      } else {
        hasError = false
        fieldErrorMessage = ""
      }
      break

    default:
      break
  }
  return { hasError, fieldErrorMessage }
}


///////////// Constants /////////////////////////
export const SailingLogContactCreateRequest = 'SailingLogContactCreateRequest'
export const SailingLogContactCreateSuccess = 'SailingLogContactCreateSuccess'
export const SailingLogContactCreateFail = 'SailingLogContactCreateFail'
export const SailingLogContactCreateReset = 'SailingLogContactCreateReset'

export const SailingLogContactDetailsRequest = 'SailingLogContactDetailsRequest'
export const SailingLogContactDetailsSuccess = 'SailingLogContactDetailsSuccess'
export const SailingLogContactDetailsFail = 'SailingLogContactDetailsFail'
export const SailingLogContactDetailsReset = 'SailingLogContactDetailsReset'

export const SailingLogContactUpdateRequest = 'SailingLogContactUpdateRequest'
export const SailingLogContactUpdateSuccess = 'SailingLogContactUpdateSuccess'
export const SailingLogContactUpdateFail = 'SailingLogContactUpdateFail'
export const SailingLogContactUpdateReset = 'SailingLogContactUpdateReset'

export const SailingLogContactDeleteRequest = 'SailingLogContactDeleteRequest'
export const SailingLogContactDeleteSuccess = 'SailingLogContactDeleteSuccess'
export const SailingLogContactDeleteFail = 'SailingLogContactDeleteFail'
export const SailingLogContactDeleteReset = 'SailingLogContactDeleteReset'

export const SailingLogContactListRequest = 'SailingLogContactListRequest'
export const SailingLogContactListSuccess = 'SailingLogContactListSuccess'
export const SailingLogContactListFail = 'SailingLogContactListFail'


///////////// API Config /////////////////////////

const APIVariablesCreate = {
  APIRequest: SailingLogContactCreateRequest,
  APISuccess: SailingLogContactCreateSuccess,
  APIFail: SailingLogContactCreateFail,
  APIEndPoint: 'sailinglog/v1/contact/add',
}
const APIVariablesListAll = {
  APIRequest: SailingLogContactListRequest,
  APISuccess: SailingLogContactListSuccess,
  APIFail: SailingLogContactListFail,
  APIEndPoint: 'sailinglog/v1/contact/list'
}
const APIVariablesSelectOptions = {
  APIRequest: 'SailingLogContactSelectOptionsRequest',
  APISuccess: 'SailingLogContactSelectOptionsSuccess',
  APIFail: 'SailingLogContactSelectOptionsFail',
  APIEndPoint: 'sailinglog/v1/contact/selectoptions'
}
const APIVariablesItemDetails = {
  APIRequest: SailingLogContactDetailsRequest,
  APISuccess: SailingLogContactDetailsSuccess,
  APIFail: SailingLogContactDetailsFail,
  APIEndPoint: 'sailinglog/v1/contact/'
}
const APIVariablesUpdate = {
  APIRequest: SailingLogContactUpdateRequest,
  APISuccess: SailingLogContactUpdateSuccess,
  APISuccess_2: SailingLogContactDetailsSuccess,
  APIFail: SailingLogContactUpdateFail,
  APIEndPoint: 'sailinglog/v1/contact/'
}
const APIVariablesDelete = {
  APIRequest: SailingLogContactDeleteRequest,
  APISuccess: SailingLogContactDeleteSuccess,
  APIFail: SailingLogContactDeleteFail,
  APIEndPoint: 'sailinglog/v1/contact/'
}

const API_Body_Contents = (item) => {
    return (
      {
        name: item.name,
        contact_type: item.contact_type,
        known_as: item.known_as,
        phone_no: item.phone_no,
        email: item.email,
        link: item.link,
        notes: item.notes,
      })
}

export const SailingLogContactCreateAPI = (item) => async (dispatch, getState) => {
  const API_Body = API_Body_Contents(item)
  ItemCreate(API_Body, APIVariablesCreate, dispatch, getState)
}

export const SailingLogContactListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll, dispatch, getState)
}
export const SailingLogContactSelectOptionsAPI = (query) => async (dispatch, getState) => {
  ItemListAll(APIVariablesSelectOptions, dispatch, getState,query )
}

export const SailingLogContactDetailsAPI = (itemId) => async (dispatch, getState) => {
  ItemDetails(itemId, APIVariablesItemDetails, dispatch, getState)
}

export const SailingLogContactUpdateAPI = (item) => async (dispatch, getState) => {
  const API_Body = API_Body_Contents(item)
  ItemUpdate(item, API_Body, APIVariablesUpdate, dispatch, getState)
}

export const SailingLogContactDeleteAPI = (itemId) => async (dispatch, getState) => {
  ItemDelete(itemId, APIVariablesDelete, dispatch, getState)
}

///////////// REDUCERS/////////////////////////
// State is an empty object
export const SailingLogContactCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SailingLogContactCreateRequest:
      return {
        loading: true
      }

    case SailingLogContactCreateSuccess:
      return {
        loading: false,
        success: true,
        SailingLogContact: action.payload
      }

    case SailingLogContactCreateFail:
      return {
        loading: false,
        error: action.payload
      }

    case SailingLogContactCreateReset:
      return {}


    default:
      return state
  }
}

export const SailingLogContactUpdateReducer = (state = { SailingLogContact: {} }, action) => {
  switch (action.type) {
    case SailingLogContactUpdateRequest:
      return { loading: true }

    case SailingLogContactUpdateSuccess:
      return { loading: false, success: true }

    case SailingLogContactUpdateFail:
      return { loading: false, error: action.payload }

    case SailingLogContactUpdateReset:
      return { SailingLogContact: {} }

    default:
      return state
  }
}

export const SailingLogContactDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SailingLogContactDeleteRequest:
      return { loading: true }

    case SailingLogContactDeleteSuccess:
      return {
        loading: false,
        success: true
      }

    case SailingLogContactDeleteFail:
      return { loading: false, error: action.payload }

    case SailingLogContactDeleteReset:
      return {}

    default:
      return state
  }
}

export const SailingLogContactDetailsReducer = (state = { loading: true, SailingLogContact: [] }, action) => {
  switch (action.type) {
    case SailingLogContactDetailsRequest:
      return {
        ...state,
        loading: true
      }

    case SailingLogContactDetailsSuccess:
      return {
        loading: false,
        SailingLogContact: action.payload
      }

    case SailingLogContactDetailsFail:
      return {
        loading: false,
        error: action.payload
      }


    default:
      return state
  }
}


export const SailingLogContactListReducer = (state = { SailingLogContact: [] }, action) => {
  switch (action.type) {
    case SailingLogContactListRequest:
      return {
        loading: true
      }

    case SailingLogContactListSuccess:
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
        SailingLogContact: action.payload
      }

    case SailingLogContactListFail:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const SailingLogContactSelectOptionsReducer = (state = { SailingLogContact: [] }, action) => {
  switch (action.type) {
    case 'SailingLogContactSelectOptionsRequest':
      return {
        loading: true
      }

    case 'SailingLogContactSelectOptionsSuccess':
      return {
        loading: false,
        fieldOptionValues: [...action.payload],
      }

    case 'SailingLogContactSelectOptionsFail':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}


function SailingLogContactScreen(props) {
  const createAPI = SailingLogContactCreateAPI
  const listAPI = SailingLogContactListAllAPI
  const detailsAPI = SailingLogContactDetailsAPI
  const updateAPI = SailingLogContactUpdateAPI
  const deleteAPI = SailingLogContactDeleteAPI

  const resetCreate = SailingLogContactCreateReset
  const resetUpdate = SailingLogContactUpdateReset
  const resetViewDetails = SailingLogContactDetailsReset
  const resetDelete = SailingLogContactDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const paramsItem = useParams()
  const paramsItemSplit = paramsItem.action.split(':')
  const paramsID = paramsItemSplit[1]

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const SailingLogContactCreate = useSelector(state => state.SailingLogContactCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = SailingLogContactCreate

  const SailingLogContactList = useSelector(state => state.SailingLogContactList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    SailingLogContact: dataList } = SailingLogContactList

  const SailingLogContactDetails = useSelector(state => state.SailingLogContactDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    SailingLogContact: dataItem } = SailingLogContactDetails

  const SailingLogContactUpdate = useSelector(state => state.SailingLogContactUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = SailingLogContactUpdate

  const SailingLogContactDelete = useSelector(state => state.SailingLogContactDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = SailingLogContactDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingList || loadingDetails
    )
  )

  const SailingLogCrewContactSummary = useSelector(state => state.SailingLogCrewContactSummary)
  const {
    loading: loadingCrewSummary,
    error: errorCrewSummary,
    SailingLogCrew: listCrewSummary } = SailingLogCrewContactSummary

  const SailingLogBoatContactSummary = useSelector(state => state.SailingLogBoatContactSummary)
  const {
    loading: loadingBoatSummary,
    error: errorBoatSummary,
    SailingLogBoat: listBoatSummary } = SailingLogBoatContactSummary

  const SailingLogContactQualificationContactSummary = useSelector(state => state.SailingLogContactQualificationContactSummary)
  const {
    loading: loadingContactQualificationSummary,
    error: errorContactQualificationSummary,
    SailingLogContactQualification: listContactQualificationSummary } = SailingLogContactQualificationContactSummary

  const viewTabs = [
    {
      label: 'Trips',
      viewLink: '/fun/sailinglog/trips/',
      viewLinkIDField: 'trip_name',
      addLinkRef: 'trip_name',
      dataList: listCrewSummary,
      loading: loadingCrewSummary,
      error: errorCrewSummary,
      // tabContent: (<> <p> TBC </p></> ),
      tableFields: [
        {
          label: 'Trip',
          fieldName: 'trip_name',
          fieldLongName: 'trip_name_name',
          fieldType: 'selectfromdb',
          tableDisplayOrder: '',
          tableDisplayPriority: '1',
        }, {
          label: 'Type',
          fieldName: 'trip_type',
          fieldLongName: 'trip_type_name',
          fieldType: 'selectfromdb',
          tableDisplayOrder: '',
          tableDisplayPriority: '2',
        }, {
          label: 'Boat',
          fieldName: 'boat_name',
          fieldType: 'selectfromdb',
          tableDisplayOrder: '',
          tableDisplayPriority: '1',
        }, {
          label: 'Role',
          fieldName: 'role',
          fieldLongName: 'role_name',
          fieldType: 'text',
          tableDisplayOrder: '',
          tableDisplayPriority: '4',
          tableFilterHide: true,
        }
      ]    
    }, {
        label: 'Boats',
        viewLink: '/fun/sailinglog/boats/',
        // viewLinkIDField: 'contact',
        addLink: '/fun/sailinglog/boats/',
        addLinkRef: 'boat',
        dataList: listBoatSummary,
        loading: loadingBoatSummary,
        error: errorBoatSummary,
        tabContent: (<> <p> Boats Owned by this contact </p></>),
        tableFields: [
          {
            label: 'Boat',
            fieldName: 'name',
            fieldType: 'text',
            tableDisplayOrder: '',
            tableDisplayPriority: '1',
          },         {
            label: 'Boat Type',
            fieldName: 'boat_type',
            fieldLongName: 'boat_type_name',
            fieldType: 'text',
            tableDisplayOrder: '',
            tableDisplayPriority: '2',
          } , {
            label: 'Make',
            fieldName: 'make',
            fieldType: 'text',
            tableDisplayOrder: '',
            tableDisplayPriority: '4',
          } , {
            label: 'Model',
            fieldName: 'model',
            fieldType: 'text',
            tableDisplayOrder: '',
            tableDisplayPriority: '3',
          }
  
        ]
    }, {
      label: 'Qualifications',
      viewLink: '/fun/sailinglog/contacts/qualifications/',
      // viewLinkIDField: 'contact',
      addLink: '/fun/sailinglog/contacts/qualifications/',
      addLinkRef: 'contact',
      dataList: listContactQualificationSummary,
      loading: loadingContactQualificationSummary,
      error: errorContactQualificationSummary,
      // tabContent: (<> <p> TBC </p></> ),
      tableFields: [
        {
          label: 'Qualification',
          fieldName: 'qualification',
          fieldLongName: 'qualification_name',
          fieldType: 'text',
          tableDisplayOrder: '',
          tableDisplayPriority: '1',
        }, {
          label: 'Date Issued',
          fieldName: 'achieved_date',
          fieldType: 'date',
          tableDisplayOrder: '',
          tableDisplayPriority: '2',
        }, {
          label: 'Expiry Date',
          fieldName: 'expiry_date',
          fieldType: 'date',
          tableDisplayOrder: '',
          tableDisplayPriority: '3',
        }
      ]

    },

  ]

  useEffect(() => {
    dispatch(SailingLogContactListAllAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
    if (errorLoadingList) { setServerErrorMessage(errorLoadingList) }
    if (errorCreate) { setServerErrorMessage(errorCreate) }
    if (errorUpdate) { setServerErrorMessage(errorUpdate) }
    if (errorGetDetails) { setServerErrorMessage(errorGetDetails) }
    if (errorDelete) { setServerErrorMessage(errorDelete) }
    if (paramsID) {
      dispatch(SailingLogCrewSummaryByContactAPI('?listid=' + paramsID))
      dispatch(SailingLogTripSummaryByContactAPI('?listid=' + paramsID))
      dispatch(SailingLogContactQualificationSummaryByContactAPI('?listid=' + paramsID))
      dispatch(SailingLogBoatSummaryByContactAPI('?listid=' + paramsID))
    }
  }, [successDelete, successUpdate,
    errorLoadingList, errorCreate, errorUpdate, errorGetDetails, errorDelete, paramsID])

  return (
    <ModuleHome
          formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
    moduleModalVisibility={props.moduleModalVisibility}
    setModuleModalVisibility={props.setModuleModalVisibility}

      paramsItem={paramsItem}
      moduleDefinitions={moduleDefinitions}
      moduleFields={moduleFields}

      //Table Data
      dataList={dataList}
      loadingList={loadingList}
      errorLoadingList={errorLoadingList}

      rowOnClickAction={'view'}
      view={(dataitem) => navigate(moduleDefinitions.moduleLink + 'view:' + dataitem.id)}
      add={true}
      edit={(dataitem) => navigate(moduleDefinitions.moduleLink + 'edit:' + dataitem.id)}
      delete={(id) => dispatch(deleteAPI(id))}

      //AddEditForm
      fieldValidation={fieldValidation}

      loading={loading}
      serverErrorMessage={serverErrorMessage}
      setServerErrorMessage={setServerErrorMessage}

      getDataList={listAPI}

      itemDetails={detailsAPI}
      dataItem={dataItem}
      itemDetailsReset={resetViewDetails}

      itemCreate={createAPI}
      successCreate={successCreate}
      resetCreate={resetCreate}

      itemUpdate={updateAPI}
      successUpdate={successUpdate}
      resetUpdate={resetUpdate}

      itemDelete={deleteAPI}
      successDelete={successDelete}
      resetDelete={resetDelete}

      //View Page
      viewTabs={viewTabs}
    />

  )
}

export default SailingLogContactScreen