import "./horizontalRadioSwitch.css";

const HorizontalRadioSwitch = (props) => {
  // console.log('props', props)
  return (
    <div className="horizontalRadioSwitchContainer">
      {props.switchValues.map((value) =>
        <div
          key={value.name}
          className={`horizontalRadioSwitchItem ${value.name == props.selected && "horizontalRadioSwitchItemSelected"}`}
          onClick={() => { props.onChange(value.name); }}
        >
          <label className="horizontalRadioSwitchLabel">{value.name}</label>
        </div>
      )}
    </div>
  );
}

export default HorizontalRadioSwitch;

