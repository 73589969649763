import { FilterModal } from '../modals/filterModal';
import useModal from '../../hooks/useModal';
import GenericButton from './genericButton';

const FilterButton = (props) => {

  const { show, toggle } = useModal();

  return (
    <>
      <GenericButton 
      name={props.name}
      onClick={toggle}
      icon={props.icon}
      iconSize={'25px'}
      disabled={props.disabled || props.loadingList}
      />

      {/* <button
        className={props.className}
        disabled={props.disabled || props.loadingList}
        onClick={toggle}>
        <props.icon />
        <span className='buttonText'>
          {props.name}
        </span>
      </button> */}
      <FilterModal
        show={show}
        hide={toggle}
        contents={props.contents}
        actionName={props.actionName}
        handleSave={props.handleAction}
        handleResetAllFilters={props.handleResetAllFilters} >
      </FilterModal>
    </>
  );
};
export default FilterButton;

