//  React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import ModuleHome from '../../components/containers/moduleHome'
// APIs
import {ItemCreate, ItemListAll,ItemDetails,ItemUpdate, ItemDelete} from '../../APIs/genericAPIs'

import { fieldStatusOptions } from "../systemSettings/systemDefinitions"

export const moduleDefinitions = {
  moduleName: 'Addresses',
  moduleNameSingular: 'Address',
  moduleLink: '/system/settings/address/',
  thOptionsWidth: '115px',
}

export const moduleFields = [
  {
    label: 'Status',
    fieldName: 'status',
    fieldType: 'select',
    options: fieldStatusOptions,
    addFormOrder: 0,
    editFormOrder: 10,
    tableDisplayOrder: 0,
    tableDisplayPriority: 0,
  },
  {
    label: 'Name',
    fieldName: 'name',
    fieldType: 'text',
    addFormOrder: 1,
    editFormOrder: 1,
    tableDisplayOrder: 1,
    tableDisplayPriority: 1,
  }
    // {
    //   label: 'Line 1',
    //   fieldName: 'line1',
    //   fieldType: 'text',
    //   addFormOrder: 1.1,
    //   editFormOrder: 1.1,
    //   tableDisplayOrder: 1.1,
    //   tableDisplayPriority: 1,
    // },
    // {
    //   label: 'Line 2',
    //   fieldName: 'line2',
    //   fieldType: 'text',
    //   addFormOrder: 2,
    //   editFormOrder: 2,
    //   tableDisplayOrder: 2,
    //   tableDisplayPriority: 1,
    // },
    // {
    //   label: 'Line 3',
    //   fieldName: 'line3',
    //   fieldType: 'text',
    //   addFormOrder: 3,
    //   editFormOrder: 3,
    //   tableDisplayOrder: 3,
    //   tableDisplayPriority: 1,
    // },
    // {
    //   label: 'Line 4',
    //   fieldName: 'line4',
    //   fieldType: 'text',
    //   addFormOrder: 4,
    //   editFormOrder: 4,
    //   tableDisplayOrder: 4,
    //   tableDisplayPriority: 3,
    // },
    // {
    //   label: 'Town/city',
    //   fieldName: 'towncity',
    //   fieldType: 'text',
    //   addFormOrder: 5,
    //   editFormOrder: 5,
    //   tableDisplayOrder: 5,
    //   tableDisplayPriority: 3,
    // },
    // {
    //   label: 'Country',
    //   fieldName: 'county',
    //   fieldType: 'text',
    //   addFormOrder: 6,
    //   editFormOrder: 6,
    //   tableDisplayOrder: 6,
    //   tableDisplayPriority: 3,
    // },
    // {
    //   label: 'Postcode',
    //   fieldName: 'postcode',
    //   fieldType: 'text',
    //   addFormOrder: 7,
    //   editFormOrder: 7,
    //   tableDisplayOrder: 7,
    //   tableDisplayPriority: 2,
    // },
    // {
    //   label: 'Country',
    //   fieldName: 'country',
    //   fieldType: 'text',
    //   addFormOrder: 8,
    //   editFormOrder: 8,
    //   tableDisplayOrder: 8,
    //   tableDisplayPriority: 4,
    // },
]

  export const AddressCreateRequest = 'AddressCreateRequest'
  export const AddressCreateSuccess = 'AddressCreateSuccess'
  export const AddressCreateFail = 'AddressCreateFail'
  export const AddressCreateReset = 'AddressCreateReset'
  
  export const AddressListRequest = 'AddressListRequest'
  export const AddressListSuccess = 'AddressListSuccess'
  export const AddressListFail = 'AddressListFail'
  
  export const AddressDetailsRequest = 'AddressDetailsRequest'
  export const AddressDetailsSuccess = 'AddressDetailsSuccess'
  export const AddressDetailsFail = 'AddressDetailsFail'
  export const AddressDetailsReset = 'AddressDetailsReset'
  
  export const AddressUpdateRequest = 'AddressUpdateRequest'
  export const AddressUpdateSuccess = 'AddressUpdateSuccess'
  export const AddressUpdateFail = 'AddressUpdateFail'
  export const AddressUpdateReset = 'AddressUpdateReset'
  
  export const AddressDeleteRequest = 'AddressDeleteRequest'
  export const AddressDeleteSuccess = 'AddressDeleteSuccess'
  export const AddressDeleteFail = 'AddressDeleteFail'
  export const AddressDeleteReset = 'AddressDeleteReset'

  const APIVariablesCreate = {
    APIRequest: AddressCreateRequest ,
    APISuccess: AddressCreateSuccess ,
    APIFail: AddressCreateFail ,
    APIEndPoint: 'shared/v1/address/add',
  }
  const APIVariablesListAll = {
    APIRequest: AddressListRequest ,
    APISuccess: AddressListSuccess ,
    APIFail: AddressListFail ,
    APIEndPoint: 'shared/v1/address/list'
  }
  const APIVariablesItemDetails = {
    APIRequest: AddressDetailsRequest ,
    APISuccess: AddressDetailsSuccess ,
    APIFail: AddressDetailsFail ,
    APIEndPoint: 'shared/v1/address/'
  }
  const APIVariablesUpdate = {
    APIRequest: AddressUpdateRequest ,
    APISuccess: AddressUpdateSuccess ,
    APISuccess_2: AddressDetailsSuccess,
    APIFail: AddressUpdateFail ,
    APIEndPoint: 'shared/v1/address/'
  }
  const APIVariablesDelete = {
    APIRequest: AddressDeleteRequest ,
    APISuccess: AddressDeleteSuccess ,
    APIFail: AddressDeleteFail ,
    APIEndPoint: 'shared/v1/address/'
  }
  
export const AddressCreateAPI = (item) => async (dispatch, getState) => {
  const API_Body = {
    status: 'Active',
    name: item.name,
    line1: item.line1,
    line2: item.line2,
    line3: item.line3,
    line4: item.line4,
    towncity: item.towncity,
    county: item.county,
    postcode: item.postcode,
    country: item.country,
      }
      ItemCreate(API_Body,APIVariablesCreate,dispatch,getState)
}

export const AddressListAllAPI = () => async (dispatch, getState) => {
  ItemListAll(APIVariablesListAll,dispatch,getState)
}

export const AddressDetailsAPI = (itemId)  => async (dispatch, getState) => { 
  ItemDetails(itemId,APIVariablesItemDetails,dispatch,getState)
}

export const AddressUpdateAPI = (item) => async (dispatch, getState) => {
  const cleanStartDate = item.start_datetime === '' ? null : item.start_datetime;
  const cleanEndDate = item.end_datetime === '' ? null : item.end_datetime

   const API_Body = {
    status: item.status,
    name: item.name,
    start_datetime: cleanStartDate,
    end_datetime: cleanEndDate,
    location: item.location,
    notes: item.notes,
  }
  ItemUpdate(item,API_Body,APIVariablesUpdate,dispatch, getState)
}

export const AddressDeleteAPI = (itemId) => async (dispatch, getState) => { 
  ItemDelete(itemId,APIVariablesDelete,dispatch,getState) 
}

export const fieldValidation = (fieldName, value) => {
  let hasError = false,
      fieldErrorMessage = ""
  switch (fieldName) {
    case "name":
      break
    default:
      break
  }
  return { hasError, fieldErrorMessage }
}


  // State is an empty object
  export const AddressCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case AddressCreateRequest:
            return {
                loading: true
            }
  
        case AddressCreateSuccess:
            return {
                loading: false,
                success: true,
                Address: action.payload
            }
  
        case AddressCreateFail:
            return {
                loading: false,
                error: action.payload
            }
  
        case AddressCreateReset:
            return {}
  
  
        default:
            return state
    }
  }
  
  export const AddressUpdateReducer = (state = { Address: {} }, action) => {
    switch (action.type) {
        case AddressUpdateRequest:
            return { loading: true }
  
        case AddressUpdateSuccess:
            return { loading: false, success: true }
  
        case AddressUpdateFail:
            return { loading: false, error: action.payload }
  
        case AddressUpdateReset:
            return { Address: {} }
  
        default:
            return state
    }
  }
  
  export const AddressDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case AddressDeleteRequest:
            return { loading: true }
  
        case AddressDeleteSuccess:
            return { 
              loading: false, 
              success: true }
  
        case AddressDeleteFail:
            return { loading: false, error: action.payload }
  
        case AddressDeleteReset:
              return { }
  
        default:
            return state
    }
  }
  
  export const AddressDetailsReducer = (state = { loading: true, Address: [] }, action) => {
    switch (action.type) {
        case AddressDetailsRequest:
            return {
                ...state,
                loading: true
            }
  
        case AddressDetailsSuccess:
            return {
                loading: false,
                Address: action.payload
            }
  
        case AddressDetailsFail:
            return {
                loading: false,
                error: action.payload
            }
  
  
        default:
            return state
    }
  }
  
  
  
  export const AddressListReducer = (state = { Address: [],fieldOptionValues:[]  }, action) => {
    switch (action.type) {
        case AddressListRequest:
            return {
                loading: true
            }
  
        case AddressListSuccess:
          // var obj = [{id: 999, name: 'Select...'}]
            return {
                loading: false,
                fieldOptionValues: [...action.payload],
                Address: action.payload,
            }
  
        case AddressListFail:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
  }

function AddressListScreen(props) {
  const createAPI = AddressCreateAPI
  const listAPI = AddressListAllAPI
  const detailsAPI = AddressDetailsAPI
  const updateAPI = AddressUpdateAPI
  const deleteAPI = AddressDeleteAPI

  const resetCreate = AddressCreateReset
  const resetUpdate = AddressUpdateReset
  const resetViewDetails = AddressDetailsReset
  const resetDelete = AddressDeleteReset

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const paramsItem = useParams()

   const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('')

  const AddressCreate = useSelector(state => state.AddressCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate } = AddressCreate

  const AddressList = useSelector(state => state.AddressList)
  const {
    loading: loadingList,
    error: errorLoadingList,
    success: successLoadingList,
    Address: dataList } = AddressList

  const AddressDetails = useSelector(state => state.AddressDetails)
  const {
    loading: loadingDetails,
    error: errorGetDetails,
    Address: dataItem } = AddressDetails

  const AddressUpdate = useSelector(state => state.AddressUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate } = AddressUpdate

  const AddressDelete = useSelector(state => state.AddressDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete } = AddressDelete

  const loading = (
    (paramsItem.id === undefined ? loadingCreate
      : loadingUpdate || loadingDelete || loadingDetails
    )
  )

  useEffect(() => {
    dispatch(listAPI())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(listAPI())
      dispatch({ type: resetUpdate })
    }
    if (successDelete) {
      dispatch(listAPI())
      dispatch({ type: resetDelete })
    }
     if (errorLoadingList) {setServerErrorMessage(errorLoadingList)}
    if (errorCreate) {setServerErrorMessage(errorCreate)}
    if (errorUpdate) {setServerErrorMessage(errorUpdate)}
    if (errorGetDetails) {setServerErrorMessage(errorGetDetails)}
    if (errorDelete) {setServerErrorMessage(errorDelete)}
  }, [successDelete, successUpdate,
    errorLoadingList,errorCreate,errorUpdate,errorGetDetails, errorDelete])

  return (
    <ModuleHome
    formModalVisibility={formModalVisibility}
    setFormModalVisibility={setFormModalVisibility}
    moduleModalVisibility={props.moduleModalVisibility}
    setModuleModalVisibility={props.setModuleModalVisibility}

    // Shared items
    paramsItem={paramsItem}
    moduleDefinitions={moduleDefinitions}
    moduleFields={moduleFields}

    //Table Data
    dataList={dataList}
    loadingList={loadingList}
    errorLoadingList={errorLoadingList}

    rowOnClickAction='edit'
    add={true}
    edit={true}
    view={false}
    delete={(id) => dispatch(deleteAPI(id))}

    //AddEditForm
    fieldValidation={fieldValidation}

    loading={loading}
    serverErrorMessage={serverErrorMessage}
    setServerErrorMessage={setServerErrorMessage}

    getDataList={listAPI}

    itemDetails={detailsAPI}
    dataItem={dataItem}
    itemDetailsReset={resetViewDetails}

    itemCreate={createAPI}
    successCreate={successCreate}
    resetCreate={resetCreate}

    itemUpdate={updateAPI}
    successUpdate={successUpdate}
    resetUpdate={resetUpdate}

    itemDelete={deleteAPI}
    successDelete={successDelete}
    resetDelete={resetDelete}
/>
)
}

export default AddressListScreen