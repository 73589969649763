import style from '../../style/global.module.css';
import { useDispatch, useSelector } from 'react-redux';

const Finance = () => {

  return (
    <div className={style.section}>
      <div className={style.fixedcontainer}>
      <h2>Finance</h2>
      <p>Finance coming soon</p>
      </div>
    </div>
  );
};
export default Finance;
